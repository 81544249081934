import { Injectable } from '@angular/core';
import { Pagination, SummaryBuyersData } from '@interfaces';
import { LinksModel } from '@model';
import { Store } from '@ngrx/store';
import { seller as actions } from '@store/actions';
import { seller as selectors } from '@store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class SummaryBuyersService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(data: SummaryBuyersData, identifier: string) {
    return {
      ...data,
      identifier,
      id: data.buyerGovernmentId
    };
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const data = await this.store
      .select(selectors.summaryBuyers.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    this.updateControl({ isLoading: true });

    try {
      const data = await this.monkeyecxService.get<any>(url).toPromise();
      const items = this.getEmbeddedData(data, 'buyers');
      const { _links } = data;

      this.update(items, identifier);

      this.updatePagination(_links, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  private update(data: SummaryBuyersData[], identifier: string) {
    this.store.dispatch(
      actions.summaryBuyersActions.updateAll({
        data: data.map((_: SummaryBuyersData) => {
          return this.mappingData(_, identifier);
        })
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.summaryBuyersActions.updateControl({ data }));
  }

  private updatePagination(data: any, identifier: string) {
    this.store.dispatch(
      actions.summaryBuyersActions.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }
}

<monkey-modal [config]="config" [modalRef]="modalRef">
  <div class="d-flex flex-column align-items-center justify-content-center loading-order">
    <span class="title">
      {{ 'SCREENS.SHARED.LOADING.TITLE' | translate }}
    </span>
    <ng-container *ngIf="(_progress$ | async)?.processData as progress">
      <progress
        class="monkey-progress monkey-progress__determinate monkey-progress__determinate-no-progress mt-3"
        [value]="progress?.step" [max]="progress?.totalSteps" *ngIf="progress; else noProgress">
      </progress>
      <h2 *ngIf="progress"> {{ onHandlePercent(progress) | percent }}</h2>
      <ng-template #noProgress>
        <progress class="monkey-progress monkey-progress__theme mt-3">
        </progress>
      </ng-template>
    </ng-container>
    <span class="description mt-2 mb-2">
      {{ 'SCREENS.SHARED.LOADING.DESCRIPTION' | translate }}
    </span>
    <ng-container *ngIf="_showClose">
      <monkey-button type="tertiary" color="theme" (click)="_onClose()">
        Cancelar
      </monkey-button>
    </ng-container>
  </div>
</monkey-modal>
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { spike as actions } from '@shared-store/actions';

export const featureKey = 'mecx-spike-shared-recomposition';

export interface State extends EntityState<interfaces.Recomposition> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

const entity = createEntityAdapter<interfaces.Recomposition>({
  selectId: (item: interfaces.Recomposition) => {
    return item.identifier;
  }
});

export const adapter: EntityAdapter<interfaces.Recomposition> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.recomposition.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data, id: action.data?.identifier },
      {
        ...state
      }
    );
  }),
  on(actions.recomposition.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { compareStartToEndDates } from '@utils';
import { SellerData } from 'app/interfaces';
import { SellerModel, SupplierModel } from 'app/model';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class SellerService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(data: SellerModel, supplier: SupplierModel) {
    const show = compareStartToEndDates(
      data.updatedAt,
      supplier.sellerLastUpdatedAt,
      'isBefore',
      false,
      true
    );

    return {
      ...data,
      showDetailsLastUpdated: show
    };
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.sellerActions.updateControl({
        data
      })
    );
  }

  private update(data: SellerData, identifier: string) {
    this.store.dispatch(
      actions.sellerActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, supplier: SupplierModel, identifier: string) {
    const { monkeyecxService } = this;
    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<SellerData>(url).toPromise();

      const model = this.mappingData(new SellerModel(data), supplier);

      this.update(model, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  public load(url: string, supplier: SupplierModel, identifier: string) {
    this.store.dispatch(actions.sellerActions.clear());

    this.loadData(url, supplier, identifier);
  }
}

import { Component, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { MonkeyEcxPaginationService } from 'monkey-front-core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentComponent {
  // #TODO testar se removendo o input consegue acessar normal o onScrollDown.next
  @Input()
  onScrollUp = new EventEmitter<any>().subscribe(() => {
    this.paginationService.execute('first');
  });

  @Input()
  onScrollDown = new EventEmitter<any>().subscribe(() => {
    this.paginationService.execute('next');
  });

  constructor(private paginationService: MonkeyEcxPaginationService) {
    // not to do
  }
}

import { BuyerSellersData } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buyer as actions } from '@store/actions';

export const featureKey = 'buyer-sellers-details';

export interface State extends EntityState<BuyerSellersData> {
  control: {
    isLoading: boolean;
    isLoadingDownload: boolean;
    isLoadingStatus: boolean;
    isLoadingRisk: boolean;
  };
  error: any;
}

const entity = createEntityAdapter<BuyerSellersData>({
  selectId: (item: BuyerSellersData) => {
    return `${item.identifier}-${item.id}`;
  }
});

export const adapter: EntityAdapter<BuyerSellersData> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null,
    isLoadingDownload: null,
    isLoadingStatus: null,
    isLoadingRisk: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.buyerSellersDetails.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data },
      {
        ...state
      }
    );
  }),
  on(actions.buyerSellersDetails.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.buyerSellersDetails.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectAll } = adapter.getSelectors();

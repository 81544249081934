<div class="buttons hidden-xs hidden-sm">
  <ng-container *ngIf="_isFolded">
    <a class="logo" [routerLink]="['/user/profile/companies']">
      <img class="logo-icon" [src]="_config?.theme?.logos?.toolbar" />
      <span class="logo-text secondary-text">
        {{ _config?.theme?.name }}
      </span>
    </a>
  </ng-container>
</div>
<div class="buttons hidden-md hidden-lg">
  <monkey-button color="theme" icon="menu-24" type="tertiary" (click)="toggleSidebarOpen('navbar')">
  </monkey-button>
</div>
<div class="d-flex align-items-center justify-content-end">
  <app-environment class="ml-2 mr-2 hidden-xs hidden-sm"></app-environment>
  <app-notifications class="mr-3"></app-notifications>
  <monkey-button-dropdown [label]="profileButton" *ngIf="_me?.name">
    <div class="mt-2">
      <monkey-button
        type="tertiary"
        color="theme"
        [routerLink]="'/app/profile/companies'"
        icon="refresh-16"
      >
        {{ 'SCREENS.TOOLBAR.PROFILE.CHANGE-COMPANY' | translate }}
      </monkey-button>
    </div>
    <div class="mt-2">
      <monkey-button
        type="tertiary"
        color="theme"
        [routerLink]="'/user/profile/edit'"
        icon="user-16"
      >
        {{ 'SCREENS.TOOLBAR.PROFILE.MY-PROFILE' | translate }}
      </monkey-button>
    </div>
    <div class="separator mt-2"></div>
    <div class="mt-2">
      <monkey-button type="tertiary" color="theme" (click)="logOut()" icon="logout-16">
        {{ 'SCREENS.TOOLBAR.PROFILE.LOGOUT' | translate }}
      </monkey-button>
    </div>
  </monkey-button-dropdown>
</div>

<ng-template #profileButton>
  <monkey-button-avatar type="none" [img]="_me?.pictureURL" size="md" [label]="_me?.name">
  </monkey-button-avatar>
  {{ _me?.name | monkeyecxTextTruncate : 10 }}
</ng-template>

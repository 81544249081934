import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GeneralSummaryData } from 'app/interfaces';
import { sponsor } from 'app/store/reducers';

declare type State = sponsor.fromGeneralSummary.State;

export const selectGeneralSummaryState = createFeatureSelector<sponsor.fromGeneralSummary.State>(
  sponsor.fromGeneralSummary.featureKey
);

export const selectAllGeneralSummary = createSelector(
  selectGeneralSummaryState,
  sponsor.fromGeneralSummary.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllGeneralSummary,
    (entities: GeneralSummaryData[]): GeneralSummaryData => {
      return entities.find((_: GeneralSummaryData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectGeneralSummaryState,
    (state: State): any => {
      return state.control;
    }
  );
};

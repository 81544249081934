import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from '@modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from '@modules/monkeyecx-i18n.module';
import { TranslateModule } from '@ngx-translate/core';
import { MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyCheckboxModule, MonkeyIconModule, MonkeyModalModule } from 'monkey-style-guide';
import { MECXSharedDynamicReportModalConfigService } from '../../providers';
import { MECXSharedDynamicReportConfigComponent } from './dynamic-report-config.component';

@NgModule({
  declarations: [MECXSharedDynamicReportConfigComponent],
  imports: [
    MonkeyEcxCommonModule,
    MonkeyModalModule,
    MonkeyIconModule,
    TranslateModule.forChild(),
    MonkeyEcxi18nModule,
    MonkeyEcxPipesModule,
    DragDropModule,
    MonkeyCheckboxModule
  ],
  providers: [MECXSharedDynamicReportModalConfigService]
})
export class MECXSharedDynamicReportConfigModule {}

import { Injectable } from '@angular/core';
import { GeneralSummaryData } from '@interfaces';
import { Store } from '@ngrx/store';
import { seller as actions } from '@store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class GeneralSummaryService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: any, identifier: string) {
    this.store.dispatch(
      actions.generalSummaryActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.generalSummaryActions.updateControl({ data }));
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    this.updateControl({ isLoading: true });

    try {
      const data = await this.monkeyecxService.get<GeneralSummaryData>(url).toPromise();

      this.update(data, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const load = createAction(
  '[MECX Shared Dashboard Conciliation] Load',
  props<{ url: string; identifier: string }>()
);
const updateOne = createAction(
  '[MECX Shared Dashboard Conciliation] Update One',
  props<{ data: interfaces.ConciliationDashboard }>()
);
const updateControl = createAction(
  '[MECX Shared Dashboard Conciliation] Update Control',
  props<{ data: any }>()
);

export { load, updateOne, updateControl };

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { shared as sharedActions } from 'app/store/actions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeService implements Resolve<any> {
  public _data$: any;

  constructor(private store: Store) {
    // not to do
  }

  private handleData() {
    const { store } = this;

    store.dispatch(sharedActions.meActions.start());
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    this.handleData();
  }
}

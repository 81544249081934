import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Pagination } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { buyer as actions } from 'app/store/actions';
import { buyer as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class OperationBankAccountsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const data = await this.store
      .select(selectors.operationBankAccount.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, updatePagination = true) {
    this.updateControl({ isLoading: true });

    const data = await this.monkeyecxService.get<any>(url).toPromise();
    const items = this.getEmbeddedData(data, 'bank-accounts');
    const { _links } = data;

    this.update(items, identifier);

    if (updatePagination) {
      this.updatePagination(_links, identifier);
    }

    this.updateControl({ isLoading: false });
  }

  private update(data: any[], identifier: string) {
    this.store.dispatch(
      actions.operationBankAccountsActions.updateAll({
        data: data.map((_: any) => {
          return {
            identifier,
            ..._
          };
        })
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.operationBankAccountsActions.updateControl({ data }));
  }

  private updatePagination(data: any, identifier: string) {
    this.store.dispatch(
      actions.operationBankAccountsActions.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  public async load(url: string, identifier: string) {
    const data = await this.store
      .select(selectors.operationBankAccount.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    this.loadData(url, identifier, !data);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }
}

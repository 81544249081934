import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BuyerOperationItemData, PaginationData } from 'app/interfaces';
import { buyer } from '../../actions';

export const featureKey = 'buyer-operation-items';

export interface State extends EntityState<BuyerOperationItemData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<BuyerOperationItemData> = createEntityAdapter<
  BuyerOperationItemData
>({
  selectId: (item: BuyerOperationItemData) => {
    const { receivableUuid } = item;
    return `${receivableUuid}`;
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(buyer.operationItemsActions.clear, () => {
    return {
      ids: [],
      entities: {},
      control: { isLoading: false },
      error: null,
      pagination: null
    };
  }),
  on(buyer.operationItemsActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(buyer.operationItemsActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(buyer.operationItemsActions.updateOne, (state, action) => {
    const { identifier, receivableUuid } = action.data;
    const id = `${identifier}-${receivableUuid}`;
    return adapter.upsertOne(
      { ...action.data, id },
      {
        ...state
      }
    );
  }),
  on(buyer.operationItemsActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

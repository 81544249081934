import { Utils } from 'app/core/utils';
import * as moment from 'moment';
import { MonkeyEcxModel, MonkeyEcxUtils } from 'monkey-front-core';

export class SellerModel extends MonkeyEcxModel {
  id: string;

  activityCode: string;

  address: string;

  addressComplement: string;

  addressNumber: string;

  alternativePhone: string;

  city: string;

  firstName: string;

  lastName: string;

  country: string;

  createUserId: string;

  createUserIp: string;

  createdAt: Date;

  email: string;

  fantasyName: string;

  bfDate: string;

  governmentId: string;

  governmentIdType: string;

  lastUserId: string;

  lastUserIp: string;

  legalNature: string;

  legalResponsibles: {
    birthdayDate: Date;
    createUserId: string;
    createUserIp: string;
    createdAt: Date;
    documentNumber: string;
    documentType: string;
    email: string;
    financialAuthority: boolean;
    financialAuthorityValue: number;
    governmentId: string;
    lastUserId: string;
    lastUserIp: string;
    name: string;
    phone: string;
    sign: boolean;
    type: string;
    updatedAt: Date;
  };

  mainPhone: string;

  neighborhood: string;

  phone: string;

  state: string;

  status: string;

  updatedAt: Date;

  zipCode: string;

  companyName: string;

  foundationDate: string;

  name: string;

  showDetailsLastUpdated: boolean;

  constructor(data?: any) {
    super();
    this.governmentId = data?.governmentId;
    this.governmentIdType =
      Utils.getDocumentType(data.governmentId) || data.governmentIdType || 'CNPJ';
    this.activityCode = data?.activityCode;
    this.address = data?.address;
    this.addressComplement = data?.addressComplement;
    this.addressNumber = data?.addressNumber;
    this.alternativePhone = data?.alternativePhone;
    this.city = data?.city;
    this.country = data?.country;
    this.createUserId = data?.createUserId;
    this.createUserIp = data?.createUserIp;
    this.createdAt = data?.createdAt;
    this.email = data?.email;
    this.fantasyName = data?.fantasyName;
    this.bfDate = data?.foundationDate ? moment(data?.foundationDate).format('YYYY-MM-DD') : null;
    if (this.governmentIdType === 'CNPJ') {
      this.firstName = data?.fantasyName;
      this.lastName = data?.companyName;
    } else {
      this.firstName = MonkeyEcxUtils.cutFirstLastName(data?.name, 'first');
      this.lastName = MonkeyEcxUtils.cutFirstLastName(data?.name, 'last');
    }
    this.lastUserId = data?.lastUserId;
    this.lastUserIp = data?.lastUserIp;
    this.legalNature = data?.legalNature;
    this.legalResponsibles = data?.legalResponsibles;
    this.mainPhone = data?.mainPhone;
    this.neighborhood = data?.neighborhood;
    this.phone = data?.phone;
    this.state = data?.state;
    this.status = data?.status;
    this.updatedAt = data?.updatedAt;
    this.zipCode = data?.zipCode;
    this.showDetailsLastUpdated = data?.showDetailsLastUpdated;
    this._links = data?._links;

    const actionId: any = this.getAction('self');
    this.id = actionId?.href?.split('/').pop();
  }
}

import { NgModule } from '@angular/core';
import { MonkeyButtonModule, MonkeyCheckboxModule } from 'monkey-style-guide';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxFormsModule } from 'app/shared/modules/monkeyecx-forms.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { SharedUserTermsComponent } from './user-terms.component';

@NgModule({
  declarations: [SharedUserTermsComponent],
  exports: [SharedUserTermsComponent],
  imports: [
    MonkeyButtonModule,
    MonkeyCheckboxModule,
    MonkeyEcxCommonModule,
    MonkeyEcxFormsModule,
    MonkeyEcxi18nModule
  ],
  providers: [SharedUserTermsComponent]
})
export class SharedUserTermsModule {}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { bankAccounts as actions } from '@shared-store/actions';

export const featureKey = 'mecx-shared-banks';

export interface State extends EntityState<interfaces.Banks> {
  error: any;
}

export const adapter: EntityAdapter<interfaces.Banks> = createEntityAdapter<interfaces.Banks>({
  sortComparer: null,
  selectId: (item: interfaces.Banks) => {
    return item.value;
  }
});

export const initialState: State = adapter.getInitialState({
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.banks.create, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.banks.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

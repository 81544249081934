import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BankAccountsData } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

declare type State = buyer.fromBankAccounts.State;

export const selectBankAccountsState = createFeatureSelector<buyer.fromBankAccounts.State>(
  buyer.fromBankAccounts.featureKey
);

export const selectAllBankAccounts = createSelector(
  selectBankAccountsState,
  buyer.fromBankAccounts.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllBankAccounts,
    (entities: BankAccountsData[]): BankAccountsData[] => {
      return entities.filter((_: BankAccountsData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectBankAccountsState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectBankAccountsState,
    (state: State): any => {
      return state.pagination?.[props.identifier];
    }
  );
};

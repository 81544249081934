import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxFormsModule } from 'app/shared/modules/monkeyecx-forms.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxDirectivesModule, MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyButtonModule, MonkeyInputModule } from 'monkey-style-guide';
import { NameDocumentChooseComponent } from './name-document-choose.component';

@NgModule({
  imports: [
    MonkeyEcxCommonModule,
    MonkeyEcxFormsModule,
    MonkeyEcxPipesModule,
    MonkeyEcxDirectivesModule,
    MonkeyButtonModule,
    MonkeyInputModule,
    MonkeyEcxi18nModule
  ],
  declarations: [NameDocumentChooseComponent],
  exports: [NameDocumentChooseComponent]
})
export class NameDocumentChooseModule {}

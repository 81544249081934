import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { buyer as actions } from 'app/store/actions';
import { buyer as services } from 'app/store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class OperationSimulationEffects {
  start$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.operationSimulationActions.start),
        map(({ sponsorId, minimumDaysToOffer, supplier }) => {
          this.service.start(sponsorId, minimumDaysToOffer, supplier);
        })
      );
    },
    {
      dispatch: false
    }
  );

  recalc$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.operationSimulationActions.recalcPaymentDateMinMax),
        map(({ id, action, args }) => {
          this.service.recalcPaymentDateMinMax(id, action, args);
        })
      );
    },
    {
      dispatch: false
    }
  );

  clear$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.operationActions.clear),
        map(() => {
          this.clear();
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private service: services.OperationSimulationService,
    private store: Store
  ) {
    // not to do
  }

  private clear() {
    // const { store } = this;
    // store.dispatch(actions.operationSimulationsActions.clear());
  }
}

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction(
  '[MECX Shared MailCampaigns] Clear All',
  props<{ identifier: string }>()
);
const edit = createAction(
  '[MECX Shared MailCampaigns] Edit Campaigns',
  props<{ data: interfaces.MailCampaignData; callback: Function }>()
);
const removeOne = createAction(
  '[MECX Shared MailCampaigns] Remove Campaigns',
  props<{ data: interfaces.MailCampaignData }>()
);
const updateAll = createAction(
  '[MECX Shared MailCampaigns] Update All',
  props<{ data: interfaces.MailCampaignData[] }>()
);
const updateControl = createAction(
  '[MECX Shared MailCampaigns] Update Control',
  props<{ data: any }>()
);
const updateOne = createAction(
  '[MECX Shared MailCampaigns] Update One',
  props<{ data: interfaces.MailCampaignData }>()
);
const updatePagination = createAction(
  '[MECX Shared MailCampaigns] Update Pagination',
  props<{ data: any }>()
);
const paginationLoad = createAction(
  '[MECX Shared MailCampaigns Pagination] Load',
  props<{ pagination: interfaces.Pagination; identifier: string }>()
);

const api = {
  delete: createAction(
    '[MECX Shared MailCampaigns] Delete',
    props<{ data: interfaces.MailCampaignData }>()
  ),
  load: createAction(
    '[MECX Shared MailCampaigns] Start',
    props<{ url: string; identifier: string }>()
  )
};

export {
  api,
  clear,
  edit,
  removeOne,
  paginationLoad,
  updateAll,
  updateControl,
  updateOne,
  updatePagination
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { buyer as actions } from 'app/store/actions';
import { buyer as services } from 'app/store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class OperationCheckoutEffects {
  start$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.operationCheckoutActions.start),
        map(({ id, password }) => {
          this.service.start(id, password);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private service: services.OperationCheckoutService,
    private store: Store
  ) {
    // not to do
  }

  private clear() {
    // const { store } = this;
    // store.dispatch(actions.operationcheckoutSimulationsActions.clear());
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { seller } from '@store/actions';
import { buildIdByLink } from '@utils';
import { OfferItemData, PaginationData } from 'app/interfaces';

export const featureKey = 'seller-offer-items';

export interface State extends EntityState<OfferItemData> {
  control: {
    isLoading: boolean;
    totalElements: number;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<OfferItemData> = createEntityAdapter<OfferItemData>({
  selectId: (item: OfferItemData) => {
    return buildIdByLink<OfferItemData>(item);
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null,
    totalElements: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(seller.offerItemsActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(seller.offerItemsActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(seller.offerItemsActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { createAction, props } from '@ngrx/store';
import { TemplateConfigurationsData } from 'app/interfaces';

export const templateConfigurationsActions = {
  load: createAction(
    '[Sponsor TemplateConfigurations] Load',
    props<{ identifier: string; url: string }>()
  ),
  updateTemplate: createAction(
    '[Sponsor TemplateConfigurations] Update Template Configurations',
    props<{
      data: TemplateConfigurationsData;
      identifier: string;
      url: string;
      typeHttp: 'POST' | 'PUT';
    }>()
  ),
  download: createAction(
    '[Sponsor TemplateConfigurations] Download Template Default',
    props<{ data: TemplateConfigurationsData }>()
  ),
  updateOne: createAction(
    '[Sponsor TemplateConfigurations] Upadate One',
    props<{ data: TemplateConfigurationsData }>()
  ),
  updateControl: createAction(
    '[Sponsor TemplateConfigurations] Update Control',
    props<{ data: any }>()
  )
};

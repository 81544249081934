import { MonkeyNavigation } from 'app/interfaces';

export const navigation: MonkeyNavigation[] = [
  {
    description: 'Home',
    icon: 'home-24',
    id: 'home',
    role: ['VIEW', 'SELECTOR', 'EXECUTE', 'ADMIN', 'HELP_DESK'],
    title: 'Home',
    translate: 'NAVIGATION.HOME.TITLE',
    translateDescription: 'NAVIGATION.HOME.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/home'
  },
  {
    description: 'Invoices',
    icon: 'invoice-24',
    id: 'invoices',
    role: ['EXECUTE', 'ADMIN', 'SELECTOR', 'HELP_DESK'],
    title: 'Invoices',
    translate: 'NAVIGATION.INVOICES.TITLE',
    translateDescription: 'NAVIGATION.INVOICES.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/invoices',
    featureToggle: 'BUYER_INVOICES'
  },
  {
    description: 'Purchases',
    icon: 'book-24',
    id: 'purchases',
    role: ['ADMIN', 'EXECUTE', 'SELECTOR', 'VIEW', 'HELP_DESK'],
    title: 'Purchases',
    translate: 'NAVIGATION.PURCHASES.TITLE',
    translateDescription: 'NAVIGATION.PURCHASES.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/purchases'
  },
  {
    description: 'Bank Accounts',
    icon: 'wallet-24',
    id: 'bank-accounts',
    role: ['ADMIN'],
    title: 'Bank Accounts',
    translate: 'NAVIGATION.BANK-ACCOUNTS.TITLE',
    translateDescription: 'NAVIGATION.BANK-ACCOUNTS.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/bank-accounts'
  },
  {
    description: 'ASSIGNMENTS',
    icon: 'document-24',
    id: 'assignments',
    role: ['ADMIN', 'EXECUTE', 'SELECTOR', 'VIEW', 'BACK_OFFICE', 'HELP_DESK'],
    title: 'Assignments',
    translate: 'NAVIGATION.ASSIGNMENTS.TITLE',
    translateDescription: 'NAVIGATION.ASSIGNMENTS.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/assignments'
  },
  {
    description: 'Buyer Sellers',
    icon: 'people-24',
    id: 'buyer-sellers',
    role: ['ADMIN', 'EXECUTE', 'BACK_OFFICE', 'HELP_DESK'],
    title: 'Approve Seller',
    translate: 'NAVIGATION.BUYER-SELLERS.TITLE',
    translateDescription: 'NAVIGATION.BUYER-SELLERS.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/buyer-sellers'
  },
  {
    description: 'Buyer Sponsors',
    icon: 'bank-24',
    id: 'buyer-sponsorss',
    role: ['ADMIN', 'EXECUTE', 'BACK_OFFICE', 'HELP_DESK'],
    title: 'Approve Sponsor',
    translate: 'NAVIGATION.BUYER-SPONSORS.TITLE',
    translateDescription: 'NAVIGATION.BUYER-SPONSORS.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/buyer-sponsors'
  },
  {
    description: 'Envio de E-mail',
    icon: 'mail-letter-fast-circle-24',
    id: 'buyer-mail',
    role: ['ADMIN', 'EXECUTE'],
    title: 'Envio de E-mail',
    featureToggle: 'BUYER_MAIL',
    translate: 'NAVIGATION.BUYER-MAIL.TITLE',
    translateDescription: 'NAVIGATION.BUYER-MAIL.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/mail'
  },
  {
    description: 'Tax Upload',
    icon: 'dollar-sign-24',
    id: 'tax-upload',
    role: ['ADMIN', 'EXECUTE'],
    title: 'Tax Upload',
    translate: 'NAVIGATION.TAX-UPLOAD.TITLE',
    translateDescription: 'NAVIGATION.TAX-UPLOAD.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/tax-upload'
  },
  {
    description: 'Eventos',
    icon: 'calendar-box-24',
    id: 'events',
    role: ['HELP_DESK'],
    title: 'Eventos',
    translate: 'NAVIGATION.BUYER-EVENTS.TITLE',
    translateDescription: 'NAVIGATION.BUYER-EVENTS.DESCRIPTION',
    type: 'item',
    url: '/app/buyer/settings/event-history'
  }
];

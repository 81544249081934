import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BuyerOperationSupplierSummariesData } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { buyer as actions } from 'app/store/actions';
import * as moment from 'moment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxConfig,
  MonkeyEcxConfigService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class OperationSimulationService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store,
    private router: Router,
    private configService: MonkeyEcxConfigService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private create(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationSimulationActions.create({
        data: {
          id,
          ...data
        }
      })
    );
  }

  private updateSimulationData(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationSimulationActions.updateSimulationData({
        data: {
          id,
          ...data
        }
      })
    );
  }

  private async createSimulation(
    sponsorId: number,
    minimumDaysToOffer: number,
    params: BuyerOperationSupplierSummariesData
  ) {
    const { router, configService, store } = this;
    const { identifier, supplierName, supplierGovernmentId, paymentValue, _links } = params;

    const config: MonkeyEcxConfig = await configService.config().pipe(take(1)).toPromise();

    let pendencies = {
      risk: true,
      'bank-account': true
    };

    let risk;
    let showChangeAccount = false;

    try {
      const buyerSellerData = await this.getGenericData(params, 'buyer-seller');

      const { href } = new LinksModel(buyerSellerData).getAction('bank-accounts');
      store.dispatch(actions.operationBankAccountsActions.load({ url: href, identifier }));

      risk = buyerSellerData.risk;
      showChangeAccount = true;

      pendencies = {
        risk: !buyerSellerData.risk,
        'bank-account': false
      };
    } catch (e) {
      // not to do
    }

    try {
      const minDays = minimumDaysToOffer || 1;
      const minDate = moment()
        .add(minDays + 1, 'day')
        .local()
        .format('YYYY-MM-DD');
      const paymentDateMinimum = moment().add(minDays, 'day').local().format('YYYY-MM-DD');
      const paymentDateMaximum = moment()
        .add((config?.market?.operation?.currentDaysToSearch || 0) + 1, 'days')
        .local()
        .format('YYYY-MM-DD');
      const totalSteps = pendencies?.risk || pendencies['bank-account'] ? 4 : 3;

      this.create(identifier, {
        pendencies,
        simulationData: {
          sponsorId,
          minimumDaysToOffer,
          supplierName,
          supplierGovernmentId,
          paymentDateMinimum,
          paymentDateMaximum,
          amount: paymentValue,
          risk,
          showChangeAccount
        },
        simulationHeader: {
          totalSteps,
          selectedStep: 1
        },
        paymentValue,
        minDate,
        _links
      });

      router.navigate([`/app/buyer/operation/${identifier}`], {
        skipLocationChange: true
      });
    } catch (e) {
      // not to do
    }
  }

  start(
    sponsorId: number,
    minimumDaysToOffer: number,
    supplier: BuyerOperationSupplierSummariesData
  ) {
    this.createSimulation(sponsorId, minimumDaysToOffer, supplier);
  }

  recalcPaymentDateMinMax(
    id: string,
    action: string,
    args: {
      daysFrom?: number;
      daysTo?: number;
      startDate?: string;
      endDate?: string;
    }
  ) {
    const { daysFrom, daysTo, startDate, endDate } = args;
    let paymentDateMinimum = '';
    let paymentDateMaximum = '';
    if (action === 'fixed') {
      paymentDateMinimum = moment().add(daysFrom, 'days').local().format('YYYY-MM-DD');
      paymentDateMaximum = moment().add(daysTo, 'days').local().format('YYYY-MM-DD');
    } else {
      paymentDateMinimum = startDate;
      paymentDateMaximum = endDate;
    }

    this.updateSimulationData(id, {
      paymentDateMinimum,
      paymentDateMaximum
    });
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthService } from 'app/core/services/auth';
import { CompaniesService } from 'app/core/services/company';
import { MonkeyEcxConfigBootstrap, MonkeyEcxNavigation } from 'monkey-front-core';
import { SidebarService } from '../../sidebar/sidebar.service';

@Component({
  selector: 'app-sidebar-actions-options-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class SidebarActionsOptionsMenuComponent {
  @Input() isFolded = false;

  @Input() configBootstrap: MonkeyEcxConfigBootstrap = null;

  @Input() companyType: string;

  @Input() closeMenu: Function;

  _navigation: MonkeyEcxNavigation[] = [];

  _selected$ = this.companiesService._selected$;

  _environment = environment;

  constructor(
    public _router: Router,
    private companiesService: CompaniesService,
    private authService: AuthService,
    private router: Router,
    private sidebarService: SidebarService
  ) {
    // not to do
  }

  onHandleLogout() {
    this.authService.logOut(true, false, () => {
      this.router.navigate(['/']);
    });
  }

  onHandleToggleSidebarOpened(name: string) {
    this.closeMenu();
    this.sidebarService.getSidebar(name).toggleOpen();
  }
}

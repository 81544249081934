import { Injectable } from '@angular/core';
import { Pagination, ReceivablesData } from '@interfaces';
import { LinksModel } from '@model';
import { Store } from '@ngrx/store';
import { seller as actions } from '@store/actions';
import { seller as selectors } from '@store/selectors';
import { diffInDaysIsGreaterThan } from '@utils';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxConfigService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class ReceivablesService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private configService: MonkeyEcxConfigService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(
    data: ReceivablesData,
    identifier: string,
    maximumDaysWithTax: number = null
  ) {
    const actionId: string = new LinksModel(data)?.getAction('self')?.href;
    const id = actionId?.split('/')?.pop();

    if (maximumDaysWithTax && diffInDaysIsGreaterThan(data.paymentDate, maximumDaysWithTax)) {
      data = {
        ...data,
        status: 'NO_PAYMENT_DATE'
      };
    }

    return {
      ...data,
      identifier,
      id
    };
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const data = await this.store
      .select(selectors.receivables.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, updatePagination = true) {
    this.updateControl({ isLoading: true });

    try {
      const data = await this.monkeyecxService.get<any>(url).toPromise();
      const items = this.getEmbeddedData(data, 'receivables');
      const { _links } = data;

      this.update(items, identifier);

      if (updatePagination) {
        this.updatePagination(_links, identifier);
      }
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  private async update(data: ReceivablesData[], identifier: string) {
    const config: any = await this.configService.config().pipe(take(1)).toPromise();

    let maximumDaysWithTax = null;
    try {
      maximumDaysWithTax = config.market.invoices.maximumDaysWithTax;
    } catch (e) {
      // not to dp
    }

    this.store.dispatch(
      actions.receivablesActions.updateAll({
        data: data.map((_: ReceivablesData) => {
          return this.mappingData(_, identifier, maximumDaysWithTax);
        })
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.receivablesActions.updateControl({ data }));
  }

  private updatePagination(data: any, identifier: string) {
    this.store.dispatch(
      actions.receivablesActions.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  public async load(url: string, identifier: string) {
    const { store } = this;

    const { hasDifference, hasField } = await store
      .select(selectors.receivables.paginationHasDifference({ identifier, url }))
      .pipe(take(1))
      .toPromise();

    if (hasDifference && hasField) {
      store.dispatch(actions.receivablesActions.clear({ identifier }));
    }

    this.loadData(url, identifier, hasDifference);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }
}

<ng-container *ngIf="!item.hidden">

  <!-- normal collapsable -->
  <a class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && !item.function"
    (click)="toggleOpen($event)">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.url -->
  <a class="nav-link" [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl && !item.function" (click)="toggleOpen($event)"
    [routerLink]="[item.url]" [routerLinkActive]="['active', 'accent']"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
    [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl -->
  <a class="nav-link" [ngClass]="item.classes"
    *ngIf="item.url && item.externalUrl && !item.function" (click)="toggleOpen($event)"
    [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.function -->
  <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function"
    (click)="toggleOpen($event);item.function()">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </span>

  <!-- item.url && item.function -->
  <a class="nav-link" [ngClass]="item.classes"
    *ngIf="item.url && !item.externalUrl && item.function"
    (click)="toggleOpen($event);item.function()" [routerLink]="[item.url]"
    [routerLinkActive]="['active', 'accent']"
    [routerLinkActiveOptions]="{exact: item.exactMatch || false}">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- item.externalUrl && item.function -->
  <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
    (click)="toggleOpen($event);item.function()" [href]="item.url"
    [target]="item.openInNewTab ? '_blank' : '_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <ng-template #itemContent>
    <span class="nav-link-title" [translate]="item.translate">{{(item.translate | translate) ||
      item.title}}</span>
    <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
      [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
      {{(item.badge.translate | translate) || item.badge.title}}
    </span>
  </ng-template>

  <div class="children" [@slideInOut]="isOpen">
    <ng-container *ngFor="let item of item.children">
      <monkey-nav-item *ngIf="item.type=='item'" [item]="item"></monkey-nav-item>
      <monkey-nav-collapsable *ngIf="item.type=='collapsable'" [item]="item">
      </monkey-nav-collapsable>
      <monkey-nav-group *ngIf="item.type=='group'" [item]="item"></monkey-nav-group>
    </ng-container>
  </div>

</ng-container>
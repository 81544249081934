import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'monkey-front-components';
import { MonkeyEcxMeCredentials, MonkeyEcxTokenStorageService } from 'monkey-front-core';
import {
  MonkeyModalConfig,
  MonkeyModalRefs,
  MonkeyStyleGuideModalService
} from 'monkey-style-guide';
import { takeUntil } from 'rxjs/operators';
import { SharedNewsletterService } from './newsletter.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'shared-newsletter',
  styleUrls: ['./newsletter.component.scss'],
  templateUrl: './newsletter.component.html'
})
export class SharedNewsletterComponent extends BaseComponent implements OnDestroy, OnInit {
  @Input() config: MonkeyModalConfig;

  @Input() modalRef: MonkeyModalRefs<any>;

  _isLoading = false;

  _meData: MonkeyEcxMeCredentials = null;

  _selected = 1;

  constructor(
    private modalService: MonkeyStyleGuideModalService,
    private newsletterService: SharedNewsletterService,
    private router: Router,
    private tokenStorage: MonkeyEcxTokenStorageService
  ) {
    super();
  }

  private async handleData() {
    this._meData = await this.tokenStorage.getMe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy(false);
  }

  ngOnInit(): void {
    const { newsletterService } = this;

    newsletterService.__onLoadingInProgress$
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((value: boolean) => {
        const update = this.config?.data?.update;
        this._isLoading = value;
        update({
          isLoading: value
        });
      });

    this.handleData();
  }

  activateNews(newsletter = true): void {
    const finish = this.config?.data?.finish;

    this.newsletterService.save(
      {
        newsletter
      },
      () => {
        if (newsletter) {
          this._selected = 2;
        } else {
          this.closeAll();
        }

        finish();
      }
    );
  }

  closeAll(): void {
    this.modalService.closeAll();
  }

  navigateToRegister(): void {
    this.closeAll();
    this.router.navigate(['/app/register/profiles']);
  }
}

import { createAction, props } from '@ngrx/store';
import { BuyerOperation } from 'app/interfaces';

export const operationActions = {
  start: createAction('[Buyer Operation] Start Operation', props<{ id: string }>()),
  create: createAction('[Buyer Operation] Create Operation', props<{ data: BuyerOperation }>()),
  startSummaryUpdate: createAction(
    '[Buyer Operation] Start Operation Summary Update',
    props<{ id: string }>()
  ),
  startRecalc: createAction('[Buyer Operation] Start Operation Recalc', props<{ id: string }>()),
  updateOperationData: createAction(
    '[Buyer Operation] Update Operation OperationData',
    props<{ data: any }>()
  ),
  updateOperationSummaryData: createAction(
    '[Buyer Operation] Update Operation OperationSummaryData',
    props<{ data: any }>()
  ),
  updateProcessData: createAction(
    '[Buyer Operation] Update Operation ProcessData',
    props<{ data: any }>()
  ),
  updateRecalcData: createAction(
    '[Buyer Operation] Update Operation RecalcData',
    props<{ data: any }>()
  ),
  updateControl: createAction('[Buyer Operation] Update Operation Control', props<{ data: any }>()),
  delete: createAction('[Buyer Operation] Delete Operation', props<{ data: BuyerOperation }>()),
  clear: createAction('[Buyer Operation] Clear All Operation')
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { shared as sharedActions } from 'app/store/actions';
import { shared as sharedSelectors } from 'app/store/selectors';
import { MonkeyEcxCommonsService } from 'monkey-front-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BanksService extends MonkeyEcxCommonsService implements Resolve<any> {
  constructor(private store: Store) {
    super();
  }

  private handleData() {
    const { store } = this;

    store.dispatch(sharedActions.banksActions.start());

    this.__data$ = store.select(sharedSelectors.banks.selectAll);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    this.handleData();
  }
}

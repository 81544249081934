/* eslint-disable object-curly-newline */
/* eslint-disable no-continue */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { MonkeyEcxNavigationItem } from 'monkey-front-core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  _onItemCollapsed: Subject<any>;

  _onItemCollapseToggled: Subject<any>;

  private onNavigationChanged: BehaviorSubject<any>;

  private onNavigationRegistered: BehaviorSubject<any>;

  private onNavigationUnregistered: BehaviorSubject<any>;

  private onNavigationItemAdded: BehaviorSubject<any>;

  private onNavigationItemUpdated: BehaviorSubject<any>;

  private onNavigationItemRemoved: BehaviorSubject<any>;

  private currentNavigationKey: string;

  private registry: { [key: string]: any } = {};

  constructor() {
    this._onItemCollapsed = new Subject();
    this._onItemCollapseToggled = new Subject();

    this.currentNavigationKey = null;
    this.onNavigationChanged = new BehaviorSubject(null);
    this.onNavigationRegistered = new BehaviorSubject(null);
    this.onNavigationUnregistered = new BehaviorSubject(null);
    this.onNavigationItemAdded = new BehaviorSubject(null);
    this.onNavigationItemUpdated = new BehaviorSubject(null);
    this.onNavigationItemRemoved = new BehaviorSubject(null);
  }

  get _onNavigationChanged(): Observable<any> {
    return this.onNavigationChanged.asObservable();
  }

  get _onNavigationRegistered(): Observable<any> {
    return this.onNavigationRegistered.asObservable();
  }

  get _onNavigationUnregistered(): Observable<any> {
    return this.onNavigationUnregistered.asObservable();
  }

  get _onNavigationItemAdded(): Observable<any> {
    return this.onNavigationItemAdded.asObservable();
  }

  get _onNavigationItemUpdated(): Observable<any> {
    return this.onNavigationItemUpdated.asObservable();
  }

  get _onNavigationItemRemoved(): Observable<any> {
    return this.onNavigationItemRemoved.asObservable();
  }

  register(key: any, navigation: any): void {
    if (this.registry[key]) {
      console.error(`The navigation with the key '${key}' already exists`);
      return;
    }
    this.registry[key] = navigation;
    this.onNavigationRegistered.next([key, navigation]);
  }

  unregister(key: any) {
    if (!this.registry[key]) {
      console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);
    }

    delete this.registry[key];
    this.onNavigationUnregistered.next(key);
  }

  getNavigation(key: any) {
    if (!this.registry[key]) {
      console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);

      return;
    }

    return this.registry[key];
  }

  getFlatNavigation(navigation, flatNavigation: MonkeyEcxNavigationItem[] = []): any {
    for (const item of navigation) {
      if (item.type === 'item') {
        flatNavigation.push(item);

        continue;
      }

      if (item.type === 'collapsable' || item.type === 'group') {
        if (item.children) {
          this.getFlatNavigation(item.children, flatNavigation);
        }
      }
    }

    return flatNavigation;
  }

  getCurrentNavigation(): any {
    if (!this.currentNavigationKey) {
      console.warn('The current navigation is not set.');

      return;
    }

    return this.getNavigation(this.currentNavigationKey);
  }

  setCurrentNavigation(key: any): void {
    if (!this.registry[key]) {
      console.warn(`The navigation with the key '${key}' doesn't exist in the registry.`);

      return;
    }

    this.currentNavigationKey = key;
    this.onNavigationChanged.next(key);
  }

  getNavigationItem(id, navigation = null): any | boolean {
    if (!navigation) {
      navigation = this.getCurrentNavigation();
    }

    for (const item of navigation) {
      if (item.id === id) {
        return item;
      }

      if (item.children) {
        const childItem = this.getNavigationItem(id, item.children);

        if (childItem) {
          return childItem;
        }
      }
    }

    return false;
  }

  getNavigationItemParent(id, navigation = null, parent = null): any {
    if (!navigation) {
      navigation = this.getCurrentNavigation();
      parent = navigation;
    }

    for (const item of navigation) {
      if (item.id === id) {
        return parent;
      }

      if (item.children) {
        const childItem = this.getNavigationItemParent(id, item.children, item);

        if (childItem) {
          return childItem;
        }
      }
    }

    return false;
  }

  addNavigationItem(item: any, id: any): void {
    const navigation: any[] = this.getCurrentNavigation();

    if (id === 'end') {
      navigation.push(item);
      this.onNavigationItemAdded.next(true);
      return;
    }

    if (id === 'start') {
      navigation.unshift(item);
      this.onNavigationItemAdded.next(true);
      return;
    }

    const parent: any = this.getNavigationItem(id);

    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push(item);
    }

    this.onNavigationItemAdded.next(true);
  }

  updateNavigationItem(id: any, properties: any): void {
    const navigationItem = this.getNavigationItem(id);
    if (!navigationItem) {
      return;
    }

    _.merge(navigationItem, properties);
    this.onNavigationItemUpdated.next(true);
  }

  removeNavigationItem(id: any): void {
    const item = this.getNavigationItem(id);

    if (!item) {
      return;
    }

    let parent = this.getNavigationItemParent(id);
    parent = parent.children || parent;
    parent.splice(parent.indexOf(item), 1);
    this.onNavigationItemRemoved.next(true);
  }
}

import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from '@modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from '@modules/monkeyecx-i18n.module';
import { MonkeyButtonModule, MonkeyCheckboxModule } from 'monkey-style-guide';
import { MECXSharedSupportButtonComponent } from './support-button.component';

@NgModule({
  declarations: [MECXSharedSupportButtonComponent],
  exports: [MECXSharedSupportButtonComponent],
  imports: [MonkeyButtonModule, MonkeyCheckboxModule, MonkeyEcxCommonModule, MonkeyEcxi18nModule],
  providers: [MECXSharedSupportButtonComponent]
})
export class MECXSharedSupportButtonModule {}

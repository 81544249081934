import { Pagination, ReceivablesData } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const receivablesActions = {
  clear: createAction('[Seller Receivables] Clear All', props<{ identifier: string }>()),
  load: createAction('[Seller Receivables] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction('[Seller Receivables] Update All', props<{ data: ReceivablesData[] }>()),
  updateControl: createAction('[Seller Receivables] Update Control', props<{ data: any }>()),
  updatePagination: createAction('[Seller Receivables] Update Pagination', props<{ data: any }>())
};

export const receivablesPaginationActions = {
  load: createAction(
    '[Seller ReceivablesPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { GeneralSummarySellerData } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller } from '@store/reducers';

declare type State = seller.fromGeneralSummary.State;

export const selectGeneralSummaryState = createFeatureSelector<seller.fromGeneralSummary.State>(
  seller.fromGeneralSummary.featureKey
);

export const selectAllGeneralSummary = createSelector(
  selectGeneralSummaryState,
  seller.fromGeneralSummary.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllGeneralSummary,
    (entities: GeneralSummarySellerData[]): GeneralSummarySellerData => {
      return entities.find((_: GeneralSummarySellerData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(selectGeneralSummaryState, (state: State): any => {
    return state.control;
  });
};

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { webhooks as actions } from '@shared-store/actions';

export const featureKey = 'mecx-shared-webhooks-redelivery';

export interface State extends EntityState<interfaces.WebhookRedeliveryData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: interfaces.Pagination;
}

export const adapter: EntityAdapter<interfaces.WebhookRedeliveryData> =
  createEntityAdapter<interfaces.WebhookRedeliveryData>({
    selectId: (item: interfaces.WebhookRedeliveryData) => {
      const { identifier, id, webHookId } = item;

      return `${identifier}-${webHookId || id}`;
    },
    sortComparer: null
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(actions.redelivery.updateOne, (state, action) => {
    return adapter.upsertOne(action.data, {
      ...state
    });
  }),
  on(actions.redelivery.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  }),
  on(actions.redelivery.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.redelivery.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.redelivery.deleteAction, (state, action) => {
    return adapter.removeOne(action.data.webHookId, state);
  }),
  on(actions.redelivery.clear, (state, action) => {
    const { identifier } = action;

    const ids = (adapter.getSelectors().selectIds(state) as string[]).filter(
      (id: string | number) => {
        return `${id}`.includes(identifier);
      }
    );

    const handled = { ...adapter.removeMany(ids, state) };
    handled.control = { isLoading: false };
    handled.pagination = {
      ...handled.pagination,
      [identifier]: null
    };
    return handled;
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

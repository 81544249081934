export const buyerEventHistoryTypes = [
  'BUYER_SELLER_APPROVED',
  'SUBSCRIPTION_UPDATED',
  'BUYER_SELLER_UPDATED',
  'SPONSOR_BUYER_UPDATED',
  'BUYER_SPONSOR_UPDATED',
  'PROFILE_UPDATED',
  'BUYER_SELLER_CREATED',
  'BUYER_SPONSOR_CREATED',
  'PROFILE_CREATED',
  'PURCHASE_CANCELLED_EVENT',
  'PURCHASE_CREATED_EVENT',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_UPDATED',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_CREATED',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_DELETED',
  'BUYER_ADHESION_TERM_CONFIGURATION_UPDATED',
  'BUYER_ADHESION_TERM_CONFIGURATION_CREATED',
  'BANK_ACCOUNT_UPDATED',
  'BANK_ACCOUNT_CREATED',
  'BANK_ACCOUNT_DELETED',
  'BUYER_LIMIT_CREATED',
  'BUYER_LIMIT_UPDATED',
  'NOTIFICATION_SIGNED_BY_SPONSOR',
  'NOTIFICATION_NOT_SIGNED_BY_SPONSOR',
  'BUYER_TAX_FILE_PROCESSED',
  'BUYER_TAX_FILE_PROCESSED_ERROR',
  'PURCHASE_RESPONSE_RECEIVED_EVENT',
  'SPONSOR_SELLER_REGISTERED',
  'BUYER_SELLER_UNAPPROVED',
  'BUYER_ADHESION_TERM_SIGNED',
  'BUYER_ADHESION_TERM_CREATED',
  'ASSIGNMENT_SIGNED_BY_BUYER',
  'ASSIGNMENT_SIGNED_BY_SELLER',
  'ASSIGNMENT_NOT_SIGNED_BY_BUYER',
  'ASSIGNMENT_NOT_SIGNED_BY_SELLER',
  'USER_CREATED',
  'USER_UPDATED',
  'USER_DELETED'
];

export const sellerEventHistoryTypes = [
  'BUYER_SELLER_APPROVED',
  'SUBSCRIPTION_UPDATED',
  'PROFILE_UPDATED',
  'PROFILE_CREATED',
  'PURCHASE_CANCELLED_EVENT',
  'PURCHASE_CREATED_EVENT',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_UPDATED',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_CREATED',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_DELETED',
  'BANK_ACCOUNT_UPDATED',
  'BANK_ACCOUNT_CREATED',
  'BANK_ACCOUNT_DELETED',
  'OFFER_CREATED_EVENT',
  'PURCHASE_RESPONSE_RECEIVED_EVENT',
  'BUYER_SELLER_UNAPPROVED',
  'PROGRAM_ADHESION_TERM_SIGNED',
  'PROGRAM_ADHESION_TERM_CREATED',
  'BUYER_ADHESION_TERM_SIGNED',
  'BUYER_ADHESION_TERM_CREATED',
  'ASSIGNMENT_SIGNED_BY_BUYER',
  'ASSIGNMENT_SIGNED_BY_SELLER',
  'ASSIGNMENT_NOT_SIGNED_BY_BUYER',
  'ASSIGNMENT_NOT_SIGNED_BY_SELLER',
  'USER_CREATED',
  'USER_UPDATED',
  'USER_DELETED'
];

export const sponsorEventHistoryTypes = [
  'ALL_SUPPLIERS_SPONSOR_TAX',
  'SUBSCRIPTION_UPDATED',
  'SUPPLIER_UPDATED',
  'SPONSOR_BUYER_UPDATED',
  'BUYER_SPONSOR_UPDATED',
  'ALL_SUPPLIERS_BLOCKED',
  'PROFILE_UPDATED',
  'SUPPLIER_CREATED',
  'SPONSOR_BUYER_CREATED',
  'PROFILE_CREATED',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_UPDATED',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_CREATED',
  'PROGRAM_ADHESION_TERM_CONFIGURATION_DELETED',
  'BANK_ACCOUNT_CREATED',
  'BANK_ACCOUNT_DELETED',
  'TEMPLATE_FILE_CREATED',
  'TEMPLATE_FILE_DELETED',
  'ALL_SUPPLIERS_UNBLOCKED',
  'NOTIFICATION_SIGNED_BY_SPONSOR',
  'NOTIFICATION_NOT_SIGNED_BY_SPONSOR',
  'PAYABLE_DELETED',
  'USER_CREATED',
  'USER_UPDATED',
  'USER_DELETED'
];

/* eslint-disable no-unused-vars */
import { Company } from 'app/interfaces';

export enum SearchFilters {
  SellerShoppingSellection = 'seller_shopping_sellection',
  SellerAuctionDetailsItems = 'seller_auction_details_items',
  SellerReceivables = 'seller_receivables',
  BuyerAuctions = 'buyer_auctions',
  BuyerBids = 'buyer_bids',
  BuyerBidDetailsItems = 'buyer_bid_details_items',
  SharedCompaniesFilter = 'shared_companies_filter'
}

export const filterFunction = {
  shared_companies_filter: (_: Company, filter: string) => {
    const handled = `${_.name}`.toLowerCase();
    return handled.includes(filter || '');
  }
};

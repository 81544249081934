import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { eventHistory as actions } from '@shared-store/actions';
import { buildIdByLink } from '@shared/app/core/utils/utils';

export const featureKey = 'mecx-shared-event-history';

export interface State extends EntityState<interfaces.EventHistory> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: interfaces.Pagination;
}

export const adapter: EntityAdapter<interfaces.EventHistory> =
  createEntityAdapter<interfaces.EventHistory>({
    selectId: (item: interfaces.EventHistory) => {
      return buildIdByLink<interfaces.EventHistory>(item);
    },
    sortComparer: null
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(actions.updateOne, (state, action) => {
    return adapter.upsertOne(action.data, {
      ...state
    });
  }),
  on(actions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  }),
  on(actions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.remove, (state, action) => {
    const { identifier, id } = action;
    return adapter.removeOne(`${identifier}-${id}`, state);
  }),
  on(actions.clear, (state: any) => {
    const handled = adapter.removeAll(state);
    handled.pagination = null;
    handled.control = { isLoading: false };
    return handled;
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

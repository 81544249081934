import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environments/environment';
import { SharedGenericAlertsComponent } from 'app/shared/components';
import * as moment from 'moment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCookieStorageService, MonkeyEcxUtils
} from 'monkey-front-core';
import { MonkeyStyleGuideModalFixedService } from 'monkey-style-guide';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericAlertsService extends MonkeyEcxCommonsService implements Resolve<any> {
  constructor(
    private cookieService: MonkeyEcxCookieStorageService,
    private modalService: MonkeyStyleGuideModalFixedService
  ) {
    super();
  }

  private onHandleSettingsCookie(params: {
    showedGenericAlertsSeller?: true
  }) {
    const { cookieService } = this;
    const cookie: string = cookieService.getCookie('monkey-app-settings');
    if (!cookie) {
      cookieService.setCookie(
        'monkey-app-settings',
        btoa(
          JSON.stringify({
            alerts: {
              ...params
            }
          })
        ),
        environment
      );
    } else {
      const decodedCookie = JSON.parse(atob(cookie));

      cookieService.setCookie(
        'monkey-app-settings',
        btoa(
          JSON.stringify({
            ...decodedCookie,
            alerts: {
              ...decodedCookie.alerts,
              ...params
            }
          })
        ),
        environment
      );
    }
  }

  private onHandleData() {
    const { cookieService, __params } = this;
    const { companyType, url } = __params;
    const { genericAlerts } = environment;
    const handled = genericAlerts?.[companyType];

    if (handled) {
      const { pathToShow, pathToEvict, name, img, startDate, endDate, noEndDate } = handled;
      const isPresent = moment().isBetween(moment(startDate), moment(endDate));
      const isEndDateEnabled = moment().isAfter(moment(startDate)) && noEndDate;

      const cookie: string = cookieService.getCookie('monkey-app-settings');
      const type = `showedGenericAlerts${MonkeyEcxUtils.capitalize(companyType)}`;
      let dontShow = false;
      try {
        dontShow = cookie && JSON.parse(atob(cookie))?.alerts?.[type];
      } catch (e) {
        // not to do
      }

      const isUrlEnabled = `${url}`.includes(pathToShow) && !`${url}`.includes(pathToEvict);
      const isAbleToShow = isUrlEnabled && !dontShow && (isPresent || isEndDateEnabled);

      if (isAbleToShow) {
        this.modalService.open(SharedGenericAlertsComponent, {
          color: 'theme',
          size: 'md',
          data: {
            img,
            title: `${name}.TITLE`,
            message: `${name}.MESSAGE`,
            callback: (dontShowAnymore: boolean) => {
              this.onHandleSettingsCookie({
                [`showedGenericAlerts${MonkeyEcxUtils.capitalize(companyType)}`]: dontShowAnymore
              });
            }
          }
        });
      }
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    super.resolve(route, state, {
      callbackMain: () => {
        this.__params = {
          ...route.data,
          url: state.url
        };

        this.onHandleData();
      }
    });

    return null;
  }
}

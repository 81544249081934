import { NgModule } from '@angular/core';
import { MonkeyIconModule } from 'monkey-style-guide';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { SharedNoResultsFoundComponent } from './no-results-found.component';

@NgModule({
  declarations: [SharedNoResultsFoundComponent],
  exports: [SharedNoResultsFoundComponent],
  imports: [MonkeyEcxCommonModule, MonkeyIconModule]
})
export class SharedNoResultsFoundModule {}

import { Injectable } from '@angular/core';
import { ReceivablesData } from '@interfaces';
import { LinksModel } from '@model';
import { Store } from '@ngrx/store';
import { seller as actions } from '@store/actions';
import { diffInDaysIsGreaterThan } from '@utils';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxConfigService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class ReceivablesDetailsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private configService: MonkeyEcxConfigService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(
    data: ReceivablesData,
    identifier: string,
    maximumDaysWithTax: number = null
  ) {
    const actionId: string = new LinksModel(data)?.getAction('self')?.href;
    const id = actionId?.split('/')?.pop();

    if (maximumDaysWithTax && diffInDaysIsGreaterThan(data.paymentDate, maximumDaysWithTax)) {
      data = {
        ...data,
        paymentDate: null,
        status: 'NO_PAYMENT_DATE'
      };
    }

    return {
      ...data,
      identifier,
      id
    };
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.receivablesDetailsActions.updateControl({
        data
      })
    );
  }

  private async update(data: ReceivablesData, identifier: string) {
    const config: any = await this.configService.config().pipe(take(1)).toPromise();

    let maximumDaysWithTax = null;
    try {
      maximumDaysWithTax = config.market.invoices.maximumDaysWithTax;
    } catch (e) {
      // not to dp
    }
    this.store.dispatch(
      actions.receivablesDetailsActions.updateOne({
        data: this.mappingData(data, identifier, maximumDaysWithTax)
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<ReceivablesData>(url).toPromise();

      this.update(data, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

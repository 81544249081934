import { createAction, props } from '@ngrx/store';
import { Pagination, ReceivablesSummaryData } from 'app/interfaces';

export const receivablesSummaryActions = {
  clear: createAction('[Seller ReceivablesSummary] Clear All'),
  load: createAction(
    '[Seller ReceivablesSummary] Load',
    props<{ url: string; identifier: string }>()
  ),
  updateAll: createAction(
    '[Seller ReceivablesSummary] Update All',
    props<{ data: ReceivablesSummaryData[] }>()
  ),
  updateControl: createAction('[Seller ReceivablesSummary] Update Control', props<{ data: any }>()),
  updatePagination: createAction(
    '[Seller ReceivablesSummary] Update Pagination',
    props<{ data: any }>()
  )
};

export const receivablesSummaryPaginationActions = {
  load: createAction(
    '[Seller ReceivablesSummaryPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

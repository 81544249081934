import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvoiceSummariesData } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

declare type State = buyer.fromInvoiceSummaries.State;

export const selectInvoiceSummariesState = createFeatureSelector<buyer.fromInvoiceSummaries.State>(
  buyer.fromInvoiceSummaries.featureKey
);

export const selectAllInvoiceSummaries = createSelector(
  selectInvoiceSummariesState,
  buyer.fromInvoiceSummaries.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllInvoiceSummaries,
    (entities: InvoiceSummariesData[]): InvoiceSummariesData => {
      return entities.find((_: InvoiceSummariesData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectInvoiceSummariesState,
    (state: State): any => {
      return state.control;
    }
  );
};

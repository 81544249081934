import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { assignments as actions } from '@shared-store/actions';

export const featureKey = 'mecx-shared-signatures-details';

export interface State extends EntityState<interfaces.SignaturesData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

export const adapter: EntityAdapter<interfaces.SignaturesData> =
  createEntityAdapter<interfaces.SignaturesData>({
    selectId: (item: interfaces.SignaturesData) => {
      return item.identifier;
    },
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: true,
  },
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(actions.signaturesDetails.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data, id: action.data?.identifier },
      {
        ...state,
      }
    );
  }),
  on(actions.signaturesDetails.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data,
      },
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction('[MECX Shared Report Configurations] Clear All');
const load = createAction(
  '[MECX Shared Report Configurations] Load One',
  props<interfaces.ReportConfigurationActionsLoad>()
);
const toggle = createAction(
  '[MECX Shared Report Configurations] Toggle',
  props<interfaces.ReportConfigurationActionsToggle>()
);
const order = createAction(
  '[MECX Shared Report Configurations] Order',
  props<interfaces.ReportConfigurationActionsOrder>()
);
const update = createAction(
  '[MECX Shared Report Configurations] Update',
  props<{ data: interfaces.ReportConfigurations }>()
);
const updateControl = createAction(
  '[MECX Shared Report Configurations] Update Control',
  props<{ data: { isLoading: boolean } }>()
);

export { clear, load, order, toggle, update, updateControl };

import { environment } from 'environments/environment';
import { MonkeyEcxModel } from 'monkey-front-core';

const { country } = environment;

interface Link {
  href: string;
  type: string;
}

interface Links {
  delete: Link;
  self: Link;
  seller: Link;
  update: Link;
}

export class BankAccountModel extends MonkeyEcxModel {
  account: string;

  accountDigit: string;

  agency: string;

  alias: string;

  bank: string;

  bankName: string;

  main: boolean;

  type: string;

  _links: Links;

  constructor(data?: any) {
    super();

    this.account = data?.account || '';
    this.accountDigit = data?.accountDigit || '';
    this.agency = data?.agency || '';
    this.bank = data?.bank || '';
    this.bankName = data?.bankName || '';
    this.type = data?.type || '';
    this.main = data?.main || false;
    this.alias = data?.alias || '';
    this._links = data?._links;
  }

  public toJSON() {
    const { bankName, agency, account, accountDigit, type } = this;
    let alias = '';

    if (bankName) {
      alias = `${bankName || ''} / Ag. ${agency || ''} / Cc. ${account || ''}-${accountDigit || ''}`;

      if (country === 'cl') {
        alias = `${bankName || ''} / C. ${account || ''}`;
      }
    } else {
      alias = this.alias;
    }

    let obj = null;

    if (type) {
      obj = {
        type
      };
    }

    return {
      account,
      accountDigit,
      agency,
      alias,
      bank: this.bank,
      bankName: this.bankName,
      main: this.main,
      ...obj
    };
  }
}

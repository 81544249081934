import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MonkeyEcxConfig, MonkeyEcxConfigService } from 'monkey-front-core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, OnDestroy {
  _config: MonkeyEcxConfig = null;

  _year = new Date().getFullYear();

  private sub = new Subscription();

  constructor(private monkeyConfigService: MonkeyEcxConfigService, private elementRef: ElementRef) {
    // not to do
  }

  private handleConfig() {
    const { _config } = this;
    if (_config?.program?.settings?.disableFooter) {
      this.elementRef.nativeElement.remove();
    }
  }

  ngOnInit(): void {
    this.sub.add(
      this.monkeyConfigService.config().subscribe((_: MonkeyEcxConfig) => {
        this._config = _;
        this.handleConfig();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}

<monkeyecx-progress-bar></monkeyecx-progress-bar>

<div id="main">
  <div id="container-1" class="container">
    <div id="container-2" class="container">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      <div id="container-3" class="container">
        <app-content></app-content>
      </div>

      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
  </div>
</div>

<ng-template #toolbar>
  <app-toolbar-seller-checkout [ngClass]="'below-fixed monkey-navy-400'">
  </app-toolbar-seller-checkout>
</ng-template>

<ng-template #footer>
  <app-footer [ngClass]="'below-fixed monkey-navy-400'"></app-footer>
</ng-template>
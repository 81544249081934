import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/services/auth';
import { BaseComponent } from 'monkey-front-components';
import {
  MonkeyEcxConfig,
  MonkeyEcxConfigService,
  MonkeyEcxMeCredentials,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { takeUntil } from 'rxjs/operators';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-toolbar-seller-checkout',
  templateUrl: './toolbar-seller-checkout.component.html',
  styleUrls: ['./toolbar-seller-checkout.component.scss']
})
export class ToolbarSellerCheckoutComponent extends BaseComponent implements OnInit, OnDestroy {
  _me: MonkeyEcxMeCredentials = null;

  _config: MonkeyEcxConfig = null;

  constructor(
    private sidebarService: SidebarService,
    private authenticationService: AuthService,
    private tokenStorageService: MonkeyEcxTokenStorageService,
    private router: Router,
    private monkeyConfigService: MonkeyEcxConfigService
  ) {
    super();
  }

  private async handleData() {
    this._me = await this.tokenStorageService.getMe();
  }

  ngOnInit(): void {
    this.tokenStorageService
      .meHasChanged()
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((val: any) => {
        this._me = val;
      });

    this.monkeyConfigService.config().subscribe((_: MonkeyEcxConfig) => {
      this._config = _;
    });

    this.handleData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  logOut() {
    this.authenticationService.logOut(true, false, () => {
      this.router.navigate(['/']);
    });
  }

  toggleSidebarOpen(key: string): void {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  openDownloads() {
    // #TODO
  }
}

import { Injectable } from '@angular/core';
import { BuyerSellersBankAccountsData } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { buyer as selectors } from '@store/selectors';
import {
  MonkeyEcxCollectionStore,
  MonkeyEcxCommonsStoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class BuyerSellersDetailsBankAccountsService extends MonkeyEcxCommonsStoreService<
  BuyerSellersBankAccountsData,
  MonkeyEcxCollectionStore
> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    public store: Store
  ) {
    super(monkeyecxService, tokenStorage, store, {
      actions: actions.buyerSellersDetailsBankAccounts,
      selectors: selectors.buyerSellersDetailsBankAccounts
    });
  }

  public load(args: any) {
    const { url, identifier } = args;

    this.store.dispatch(actions.buyerSellersDetailsBankAccounts.clear(identifier));

    this.loadData(url, identifier);
  }
}

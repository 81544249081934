<monkey-modal [config]="config" [modalRef]="modalRef">
  <div class="separator"></div>
  <div class="modal-body">
    <div class="d-flex justify-content-center">
      <monkey-stepper position="horizontal" [selectedStep]="_selected" [totalSteps]="2">
      </monkey-stepper>
    </div>
    <div class="modal-info">
      <div class="news-info" *ngIf="_selected === 1">
        <div class="title">
          <span>{{ 'MODALS.NEWSLETTER.NEWS.TITLE' | translate }}</span>
        </div>
        <div class="description">
          <span>{{ 'MODALS.NEWSLETTER.NEWS.DESCRIPTION' | translate }}</span>
        </div>
        <div class="button">
          <monkey-button color="theme" type="primary" [disabled]="_isLoading"
            [label]="'BUTTONS.ACTIVATE' | translate" (click)="activateNews(true)" class="mr-3">
          </monkey-button>

          <monkey-button color="error" type="tertiary" [disabled]="_isLoading"
            [label]="'BUTTONS.NO' | translate" (click)="activateNews(false)">
          </monkey-button>
        </div>
      </div>
      <div class="success-info" *ngIf="_selected === 2">
        <div class="title">
          <span>{{ 'MODALS.NEWSLETTER.SUCCESS.TITLE' | translate }}</span>
        </div>
        <div class="description">
          <span>{{ 'MODALS.NEWSLETTER.SUCCESS.DESCRIPTION' | translate }}</span>
        </div>
        <ng-container *ngIf="!_meData?.companies?.length">
          <div class="description bold">
            <span>{{ 'MODALS.NEWSLETTER.SUCCESS.COMPANY-WARNING' | translate }}</span>
          </div>
          <div class="button">
            <monkey-button color="theme" type="primary"
              [label]="'BUTTONS.REGISTER-COMPANY' | translate" (click)="navigateToRegister()">
            </monkey-button>
          </div>
        </ng-container>
        <div class="d-flex full-width justify-content-center mt-5">
          <monkey-button color="theme" type="secondary" [label]="'BUTTONS.CLOSE' | translate"
            (click)="closeAll()">
          </monkey-button>
        </div>
      </div>
    </div>
  </div>
</monkey-modal>
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PerfectScrollbarDirective } from 'app/core/directives/perfect-scrollbar';
import {
  MonkeyEcxConfig,
  MonkeyEcxConfigService,
  MonkeyEcxMeCredentials,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation/navigation.service';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  _me: MonkeyEcxMeCredentials = null;

  _config: MonkeyEcxConfig = null;

  _isFolded = false;

  private perfectScrollbar: PerfectScrollbarDirective;

  private unsubscribeAll: Subject<any>;

  constructor(
    private navigationService: NavigationService,
    private sidebarService: SidebarService,
    private tokenStorageService: MonkeyEcxTokenStorageService,
    private configService: MonkeyEcxConfigService,
    public router: Router
  ) {
    this.unsubscribeAll = new Subject();
  }

  @ViewChild(PerfectScrollbarDirective, {
    static: true
  })
  set directive(theDirective: PerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this.perfectScrollbar = theDirective;

    this.navigationService._onItemCollapseToggled
      .pipe(delay(500), takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.perfectScrollbar.update();
      });

    this.router.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationEnd;
        }),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          this.perfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
        });
      });
  }

  private async handleData() {
    this._me = await this.tokenStorageService.getMe();
  }

  ngOnInit() {
    const { router, tokenStorageService, configService, sidebarService } = this;

    router.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationEnd;
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe(() => {
        if (this.sidebarService.getSidebar('navbar')) {
          this.sidebarService.getSidebar('navbar').close();
        }
      });

    tokenStorageService
      .meHasChanged()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((val: any) => {
        this._me = val;
      });

    configService.config().subscribe((_: MonkeyEcxConfig) => {
      this._config = _;
    });

    sidebarService
      .getSidebar('navbar')
      .foldedChanged.pipe(takeUntil(this.unsubscribeAll))
      .subscribe((val: any) => {
        this._isFolded = val;
      });

    this.handleData();
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  toggleSidebarOpened() {
    this.sidebarService.getSidebar('navbar').toggleOpen();
  }

  toggleSidebarFolded() {
    this.sidebarService.getSidebar('navbar').toggleFold();
  }
}

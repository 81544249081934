import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxConfigService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { getFirstResult } from '../../utils';

const myWindow: any = window;

@Injectable({
  providedIn: 'root'
})
export class UserGuidingService extends MonkeyEcxCommonsService implements Resolve<any> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private configService: MonkeyEcxConfigService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  public async handleUserGuidingIdentify() {
    try {
      const { configService, tokenStorage } = this;
      if (!(myWindow.userGuiding && typeof myWindow.userGuiding.identify === 'function')) {
        return;
      }

      const { program } = await configService.config().pipe(getFirstResult()).toPromise();
      const { username } = await tokenStorage.getMe();

      myWindow.userGuiding.identify(username, {
        [program.token]: true,
        [environment.product]: true
      });
    } catch (_) {
      // ignore exceptions
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    super.resolve(route, state, {
      callbackMain: () => {
        setTimeout(() => {
          this.handleUserGuidingIdentify();
        }, 3000);
      }
    });
    return null;
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { shared as actions } from 'app/store/actions';
import { shared as services } from 'app/store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class CompaniesActionsEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.companiesActionsActions.load),
        map(({ url }) => {
          this.service.load(url);
        })
      );
    },
    {
      dispatch: false
    }
  );

  loadPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.companiesActionsPaginationActions.load),
        map(({ pagination }) => {
          this.service.loadPage(pagination);
        })
      );
    },
    {
      dispatch: false
    }
  );

  select$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.companiesActionsActions.select),
        map(({ id, companyType, url, option }) => {
          this.service.select(id, companyType, url, option);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.CompaniesActionsService) {
    // not to do
  }
}

import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxDirectivesModule, MonkeyEcxProgressBarModule } from 'monkey-front-core';
import { ContentModule } from '../components/content/content.module';
import { FooterModule } from '../components/footer/footer.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { SidebarModule } from '../components/sidebar/sidebar.module';
import { ToolbarSellerCheckoutModule } from '../components/toolbar-seller-checkout/toolbar-seller-checkout.module';
import { SellerCheckoutLayoutComponent } from './seller-checkout-layout.component';

@NgModule({
  imports: [
    MonkeyEcxCommonModule,
    ContentModule,
    ToolbarSellerCheckoutModule,
    FooterModule,
    NavbarModule,
    MonkeyEcxDirectivesModule,
    SidebarModule,
    MonkeyEcxProgressBarModule
  ],
  declarations: [SellerCheckoutLayoutComponent],
  exports: [SellerCheckoutLayoutComponent]
})
export class SellerCheckoutLayoutModule {}

import { Injectable } from '@angular/core';
import { PurchaseData } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { buyer as selectors } from '@store/selectors';
import {
  MonkeyEcxCollectionStore,
  MonkeyEcxCommonsStoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class PurchasesService extends MonkeyEcxCommonsStoreService<
  PurchaseData,
  MonkeyEcxCollectionStore
> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    store: Store
  ) {
    super(monkeyecxService, tokenStorage, store, {
      actions: actions.purchasesActions,
      selectors: selectors.purchases
    });
  }

  public async load(args: any) {
    const { url, identifier } = args;

    const { hasDifference } = await this.linksHasDifference(url, identifier);

    this.loadData(url, identifier, hasDifference);
  }

  public loadPage(args: any) {
    const { pagination, identifier } = args;

    this.loadPageData(pagination, identifier);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxProgressBarModule } from 'monkey-front-core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContentModule } from '../components/content/content.module';
import { FooterModule } from '../components/footer/footer.module';
import { NavbarCompaniesModule } from '../components/navbar-companies/navbar-companies.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { SidebarActionsModule } from '../components/sidebar-actions';
import { SidebarModule } from '../components/sidebar/sidebar.module';
import { ToolbarSellerModule } from '../components/toolbar-seller/toolbar-seller.module';
import { SellerLayoutComponent } from './seller-layout.component';

@NgModule({
  imports: [
    ContentModule,
    FooterModule,
    InfiniteScrollModule,
    MonkeyEcxProgressBarModule,
    NavbarModule,
    NavbarCompaniesModule,
    RouterModule,
    MonkeyEcxCommonModule,
    SidebarModule,
    ToolbarSellerModule,
    SidebarActionsModule
  ],
  declarations: [SellerLayoutComponent],
  exports: [SellerLayoutComponent]
})
export class SellerLayoutModule {}

import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable({
  providedIn: 'root'
})
export class AlertsUserService extends MonkeyEcxCommonsService {
  private route: string = `${environment.baseUrl}/uaa`;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertsService: MonkeyEcxAlertsService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  public send(i18n: any, update: Function, finish: Function) {
    const { monkeyecxService, route, alertsService } = this;
    const url = `${route}/forgot-pass-number`;

    update({
      isLoading: true
    });

    monkeyecxService.post<any>(`${url}`, null).subscribe(
      () => {
        alertsService.show({
          title: 'UPDATE-SENT',
          message: 'SECRET-PASSWORD-SENT',
          type: 'success',
          duration: 4000
        });

        update({
          isLoading: false
        });
        finish();
      },
      () => {
        update({
          isLoading: false
        });
      }
    );
  }
}

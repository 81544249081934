import { ReceivablesData } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller } from '@store/reducers';

declare type State = seller.fromReceivablesDetails.State;

export const selectReceivablesDetailsState =
  createFeatureSelector<seller.fromReceivablesDetails.State>(
    seller.fromReceivablesDetails.featureKey
  );

export const selectAllReceivablesDetails = createSelector(
  selectReceivablesDetailsState,
  seller.fromReceivablesDetails.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllReceivablesDetails,
    (entities: ReceivablesData[]): ReceivablesData => {
      return entities.find((_: ReceivablesData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectById = (props: { identifier: string; id: string }) => {
  return createSelector(
    selectAllReceivablesDetails,
    (entities: ReceivablesData[]): ReceivablesData => {
      return entities.find((_: ReceivablesData) => {
        return _.identifier === props.identifier && _.id === props.id;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(selectReceivablesDetailsState, (state: State): any => {
    return state.control;
  });
};

import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { RootService } from './root.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss', `./app.component.${environment.environment}.scss`]
})
export class AppComponent {
  constructor(public rootService: RootService) {
    // not to do
  }
}

import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyButtonModule, MonkeyIconModule } from 'monkey-style-guide';
import { SharedNoDataAvailableComponent } from './no-data-available.component';

@NgModule({
  declarations: [SharedNoDataAvailableComponent],
  exports: [SharedNoDataAvailableComponent],
  imports: [TranslateModule.forChild(), MonkeyEcxCommonModule, MonkeyIconModule, MonkeyButtonModule]
})
export class SharedNoDataAvailableModule {}

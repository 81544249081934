import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sponsor } from '@store/reducers';
import { urlHasField } from '@utils';
import { SupplierData } from 'app/interfaces';
import { parseUrl } from 'query-string';

declare type State = sponsor.fromSuppliers.State;

export const selectSuppliersState = createFeatureSelector<sponsor.fromSuppliers.State>(
  sponsor.fromSuppliers.featureKey
);

export const selectAllSuppliers = createSelector(
  selectSuppliersState, sponsor.fromSuppliers.selectAll
);

export const selectByIdentifier = (props: {
  identifier: string
}) => {
  return createSelector(
    selectAllSuppliers,
    (entities: SupplierData[]): SupplierData[] => {
      const result = entities.filter((_: SupplierData) => {
        return (
          _.identifier === props.identifier
        );
      });

      return result;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectSuppliersState,
    (state: State): any => {
      return state?.pagination?.[props.identifier];
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectSuppliersState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const paginationHasDifference = (props: { identifier: string; url: string }) => {
  return createSelector(
    selectSuppliersState,
    (state: State): any => {
      const { pagination } = state;
      const { identifier, url } = props;
      let hasDifference = false;
      let hasField = false;

      try {
        const handlePaginationUrl = parseUrl(`${pagination?.[identifier]?.url}`);
        const handleUrl = parseUrl(`${url}`);
        hasDifference = (handlePaginationUrl?.query?.search !== handleUrl?.query?.search) ||
          (handlePaginationUrl?.query?.sort !== handleUrl?.query?.sort);
        hasField = urlHasField(url, 'search') || urlHasField(url, 'sort');
      } catch (e) {
        // not to do
      }

      return {
        hasDifference,
        hasField
      };
    }
  );
};

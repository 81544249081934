import { Component, OnInit } from '@angular/core';
import { CompaniesService } from 'app/core/services/company';
import { SearchFilters } from 'app/core/utils';
import { Company } from 'app/interfaces';
import { BaseComponent } from 'monkey-front-components';
import { MonkeyEcxPaginationService } from 'monkey-front-core';
import { Observable } from 'rxjs';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-navbar-companies',
  templateUrl: './navbar-companies.component.html',
  styleUrls: ['./navbar-companies.component.scss']
})
export class NavbarCompaniesComponent extends BaseComponent implements OnInit {
  _data$: Observable<any>;

  _pagination$: Observable<any>;

  _selected$: Observable<any>;

  _showMenu = false;

  _filter = '';

  _filterFunc = SearchFilters.SharedCompaniesFilter;

  onHandleChangeFilter(event: any) {
    this._filter = event;
  }

  constructor(
    public _paginationService: MonkeyEcxPaginationService,
    private companiesService: CompaniesService,
    private sidebarService: SidebarService
  ) {
    super();
  }

  ngOnInit() {
    this._data$ = this.companiesService.__data$;
    this._pagination$ = this.companiesService.__pagination$;
    this._selected$ = this.companiesService._selected$;
  }

  onHandleToggleSidebarOpened(name: string) {
    this.sidebarService.getSidebar(name).toggleOpen();
  }

  onHandleNavigate(event: any, company: Company) {
    event.preventDefault();
    event.stopPropagation();
    this.companiesService.navigateToProfile(company);
  }

  onHandleScrollDown() {
    this._paginationService.execute('next', 'companies');
  }
}

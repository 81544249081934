import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PayablesData } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { sponsor as actions } from 'app/store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class PayablesDetailsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(data: PayablesData, identifier: string) {
    const actionId: string = new LinksModel(data)?.getAction('self')?.href;
    const displayId = actionId?.split('/')?.pop();
    const isAbleToExport = !!(new LinksModel(data)?.getAction('documents')?.href);

    return {
      ...data,
      identifier,
      displayId,
      isAbleToExport
    };
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.payablesDetailsActions.updateControl({
        data
      })
    );
  }

  private update(data: PayablesData, identifier: string) {
    this.store.dispatch(
      actions.payablesDetailsActions.updateOne({
        data: this.mappingData(data, identifier)
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;
    this.updateControl({ isLoading: true });

    const data = await monkeyecxService.get<PayablesData>(url).toPromise();

    this.update(data, identifier);

    this.updateControl({ isLoading: false });
  }

  public async load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

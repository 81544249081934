import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SharedStates } from 'app/interfaces';
import { shared } from '../../actions';

export const featureKey = 'shared-states';

export interface State extends EntityState<SharedStates> {
  error: any;
}

export const adapter: EntityAdapter<SharedStates> = createEntityAdapter<SharedStates>({
  sortComparer: null,
  selectId: (item: SharedStates) => {
    return item.value;
  }
});

export const initialState: State = adapter.getInitialState({
  error: null
});

export const reducer = createReducer(
  initialState,
  on(shared.statesActions.create, (state, action) => {
    return adapter.setAll(action.data, {
      ...state
    });
  }),
  on(shared.statesActions.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

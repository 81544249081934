import { createAction, props } from '@ngrx/store';
import { BuyerOperationSummaryItemsData, Pagination } from 'app/interfaces';

export const operationSummaryItemsActions = {
  clear: createAction('[Buyer OperationSummaryItems] Clear All'),
  load: createAction(
    '[Buyer OperationSummaryItems] Load',
    props<{ url: string; identifier: string }>()
  ),
  updateAll: createAction(
    '[Buyer OperationSummaryItems] Update All',
    props<{ data: BuyerOperationSummaryItemsData[] }>()
  ),
  updateControl: createAction(
    '[Buyer OperationSummaryItems] Update Control',
    props<{ data: any }>()
  ),
  updatePagination: createAction(
    '[Buyer OperationSummaryItems] Update Pagination',
    props<{ data: any }>()
  )
};

export const operationSummaryItemsPaginationActions = {
  load: createAction(
    '[Buyer OperationSummaryItemsPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { MonkeyEcxAuthenticationService, MonkeyEcxConfigService } from 'monkey-front-core';
import { AuthService } from './core/services/auth';
import { SplashScreenService } from './core/services/splash';

@Injectable({
  providedIn: 'root'
})
export class RootService {
  constructor(
    private authenticationService: MonkeyEcxAuthenticationService,
    private authService: AuthService,
    private splashService: SplashScreenService,
    private configService: MonkeyEcxConfigService
  ) {
    this.authenticationService.init({
      hadAtLeastOneLogin: this.authService.hadAtLeastOneLogin.bind(authService),
      redirectApp: this.authService.redirectApp.bind(authService),
      redirectLoginWelcomeBack: this.authService.redirectLoginWelcomeBack.bind(authService),
      isAuthorized: this.authService.isAuthorized.bind(authService),
      isTokenMandatory: this.authService.isTokenMandatory.bind(authService),
      isCompanyAuthorized: this.authService.isCompanyAuthorized.bind(authService),
      getRequestWithHeaders: this.authService.getRequestWithHeaders.bind(authService),
      getRequestWithHeadersAsync: this.authService.getRequestWithHeadersAsync.bind(authService),
      getRequestWithHeadersOb: this.authService.getRequestWithHeadersOb.bind(authService),
      refreshShouldHappen: this.authService.refreshShouldHappen.bind(authService),
      refreshToken: this.authService.refreshToken.bind(authService)
    });
    this.configService.init(
      () => {
        this.splashService.hide();
      },
      environment,
      'monkey-credit-front',
      'wl/sandbox'
    );
  }
}

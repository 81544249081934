import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/services/auth';
import { BaseComponent } from 'monkey-front-components';
import {
  MonkeyEcxConfig,
  MonkeyEcxConfigService,
  MonkeyEcxMeCredentials,
  MonkeyEcxRequestDownloadedHandlingService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { takeUntil } from 'rxjs/operators';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-toolbar-sponsor',
  templateUrl: './toolbar-sponsor.component.html',
  styleUrls: ['./toolbar-sponsor.component.scss']
})
export class ToolbarSponsorComponent extends BaseComponent implements OnInit, OnDestroy {
  _horizontalNavbar: boolean;

  _rightNavbar: boolean;

  _hiddenNavbar: boolean;

  _me: MonkeyEcxMeCredentials = null;

  _config: MonkeyEcxConfig = null;

  _qtdDownloads: number = 0;

  _isFolded = true;

  constructor(
    private sidebarService: SidebarService,
    private authenticationService: AuthService,
    private tokenStorageService: MonkeyEcxTokenStorageService,
    private monkeyConfigService: MonkeyEcxConfigService,
    private router: Router,
    private requestDownloadedHandlingService: MonkeyEcxRequestDownloadedHandlingService
  ) {
    super();
  }

  private async handleData() {
    this._me = await this.tokenStorageService.getMe();
  }

  ngOnInit(): void {
    const {
      tokenStorageService,
      requestDownloadedHandlingService,
      monkeyConfigService,
      sidebarService
    } = this;

    monkeyConfigService.config().subscribe((_: MonkeyEcxConfig) => {
      this._config = _;
    });

    tokenStorageService
      .meHasChanged()
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((val: any) => {
        this._me = val;
      });

    requestDownloadedHandlingService.get().subscribe((val: any) => {
      this._qtdDownloads = (val as any[]).length;
    });

    sidebarService
      .getSidebar('navbar')
      .foldedChanged.pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((val: any) => {
        this._isFolded = val;
      });

    this.handleData();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  logOut() {
    this.authenticationService.logOut(true, false, () => {
      this.router.navigate(['/']);
    });
  }

  toggleSidebarOpen(key: string): void {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  openDownloads() {
    // #TODO
  }
}

import { buyer as reducers } from '@store/reducers';
import { buyer as selectors } from '@store/selectors';
import { MonkeyEcxCommonsSelectors } from 'monkey-front-core';
import { createSelector } from '@ngrx/store';

export const { selectAll, selectData, selectByIdentifier, selectState } =
  MonkeyEcxCommonsSelectors.getSelectors(reducers.fromDashboard);

export const selectOneByIdentifier = () => {
  return createSelector(
    selectors.dashboard.dailyOperation.selectState,
    selectors.dashboard.generalSummary.selectState,
    selectors.dashboard.purchaseEvolution.selectState,
    (dailyOperationState: any, generalSummaryState: any, purchaseEvolutionState: any) => {
      const dailyOperationData = dailyOperationState.data;
      const generalSummaryData = generalSummaryState.data;
      const purchaseEvolutionData = purchaseEvolutionState.data;
      return { dailyOperationData, generalSummaryData, purchaseEvolutionData };
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectState,
    selectors.dashboard.dailyOperation.selectState,
    selectors.dashboard.generalSummary.selectState,
    selectors.dashboard.purchaseEvolution.selectState,
    (
      dashboardState: any,
      dailyOperationState: any,
      generalSummaryState: any,
      purchaseEvolutionState: any
    ) => {
      const isDashboardLoading = dashboardState?.control?.isLoading;
      const isDailyOperationLoading = dailyOperationState?.control?.isLoading;
      const isGeneralSummaryLoading = generalSummaryState?.control?.isLoading;
      const isPurchaseEvolutionLoading = purchaseEvolutionState?.control?.isLoading;

      return {
        isDashboardLoading,
        isDailyOperationLoading,
        isGeneralSummaryLoading,
        isPurchaseEvolutionLoading
      };
    }
  );
};

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum RoutesApplication {
  App = 'app',
  AppUser = 'app/user',
  AppUserProfiles = 'app/user/profiles',
  AppCompany = 'app/{companyType}',
  AppCompanyConfigUserUpdateSteps = 'app/{companyType}/config/user-update/{step}/{governmentId}',
  AppRegister = 'app/user/register',
  WhoYouAre = 'app/user/register/{companyType}/who-you-are',
  UserUpdateStepChoose = 'app/{companyType}/config/user-update/step-choose',
  AppRegisterSteps = 'app/user/register/{companyType}/{step}/{governmentId}',
  AppRegisterApproval = 'app/user/register/{companyType}/approval'
}

export enum QueueEvents {
  OnGoing,
  Finished,
  FinishedWithError
}

export enum DownloadEvents {
  OnGoing,
  Finished,
  FinishedWithError
}

export enum UploadEvents {
  OnGoing,
  Finished,
  FinishedWithError
}

export enum ModalEvents {
  GetInNoSign,
  ConfirmPassword,
  CloseConfirmPassword,
  ChangePasssword,
  CloseChangePassword,
  Close,
  Ok
}

export enum ApiEndPointsTokenNotMandatory {
  Assets = 'assets/',
  MonkeyEcx = '/monkeyecx',
  ViaCep = 'viacep.com.br/',
  AssetsMonkeyExc = 'assets.monkeyecx.com/',
  AssetsMonkeyExcPrd = 'assets.monkey.exchange/',
  AddressMonkeyExc = 'api-adress.monkeyecx.com/',
  AddressMonkeyExcPrd = 'api-adress.monkey.exchange/'
}

export enum ApiEndPointsHeaderNotMandatory {
  Assets = '/assets',
  PayablesUpload = 'payables-upload',
  PictureUser = '/user-pictures',
  Documents = 'documents/',
  BuyerTaxFile = '/buyer-tax-file',
  ViaCep = 'viacep.com.br/',
  AssetsMonkeyExc = 'assets.monkeyecx.com/',
  AssetsMonkeyExcPrd = 'assets.monkey.exchange/',
  AddressMonkeyExc = 'api-adress.monkeyecx.com/',
  AddressMonkeyExcPrd = 'api-adress.monkey.exchange/'
}

export enum AuthCredentials {
  ClientId = 'monkey-browser',
  ClientSecret = '74f6d5c4-201f-494d-bda6-141dba9473c3',
  Scope = 'ui'
}

export enum MaxFileSize {
  FileToUpload = 5 * 1024 * 1024
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuyerOperationSupplierSummariesData } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

declare type State = buyer.fromOperationSupplierSummaries.State;

export const selectOperationSupplierSummariesState =
  createFeatureSelector<buyer.fromOperationSupplierSummaries.State>(
    buyer.fromOperationSupplierSummaries.featureKey
  );

export const selectAllOperationSupplierSummaries = createSelector(
  selectOperationSupplierSummariesState,
  buyer.fromOperationSupplierSummaries.selectAll
);

export const selectById = (props: { id: string }) => {
  return createSelector(
    selectAllOperationSupplierSummaries,
    (entities: BuyerOperationSupplierSummariesData[]): BuyerOperationSupplierSummariesData => {
      return entities.find((_: BuyerOperationSupplierSummariesData) => {
        const { id } = _;
        return `${id}` === props.id;
      });
    }
  );
};

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllOperationSupplierSummaries,
    (entities: BuyerOperationSupplierSummariesData[]): BuyerOperationSupplierSummariesData[] => {
      return entities.filter((_: BuyerOperationSupplierSummariesData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectOperationSupplierSummariesState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectOperationSupplierSummariesState,
    (state: State): any => {
      return state.pagination?.[props.identifier];
    }
  );
};

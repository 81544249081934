import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyButtonModule } from 'monkey-style-guide';
import { SharedNoResultsAvailableComponent } from './no-results-available.component';

@NgModule({
  declarations: [SharedNoResultsAvailableComponent],
  exports: [SharedNoResultsAvailableComponent],
  imports: [MonkeyEcxCommonModule, MonkeyButtonModule]
})
export class SharedNoResultsAvailableModule {}

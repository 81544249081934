import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { credit as actions } from '@shared-store/actions';

export const featureKey = 'mecx-shared-mail-campaign-objectives';

export interface State extends EntityState<interfaces.MailCampaignObjectiveData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

export const adapter: EntityAdapter<interfaces.MailCampaignObjectiveData> =
  createEntityAdapter<interfaces.MailCampaignObjectiveData>({
    selectId: (item: interfaces.MailCampaignObjectiveData) => {
      return item.objectiveType;
    }
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.mailCampaignObjectives.create, (state, action) => {
    return adapter.setAll(action.data, {
      ...state
    });
  }),
  on(actions.mailCampaignObjectives.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

import { Injectable } from '@angular/core';
import { buyer as interfaces } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { FilterSponsorService } from '@store/services/buyer';

@Injectable()
export class PurchaseEvolutionService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private filterSponsorService: FilterSponsorService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string): Promise<void> {
    this.updateControl({ isLoading: true });

    try {
      const filterSponsorQuery = await this.filterSponsorService.buildSponsorFilter();

      const data = await this.monkeyecxService
        .get<interfaces.PurchaseEvolutionData>(`${url}?${filterSponsorQuery}`)
        .toPromise();

      const items = this.getEmbeddedData(data, 'purchase-evolutions');

      this.update(items);
    } finally {
      this.updateControl({ isLoading: false });
    }
  }

  private updateControl(data: { isLoading: boolean }): void {
    this.store.dispatch(
      actions.dashboard.purchaseEvolution.updateControl({
        data
      })
    );
  }

  private update(data: interfaces.PurchaseEvolutionData): void {
    this.store.dispatch(
      actions.dashboard.purchaseEvolution.updateOne({
        data
      })
    );
  }

  public load(url: string): void {
    this.store.dispatch(actions.dashboard.purchaseEvolution.clear({ identifier: '' }));
    this.loadData(url);
  }
}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedStates } from 'app/interfaces';
import { shared as sharedAction } from 'app/store/actions';
import { shared as sharedSelector } from 'app/store/selectors';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class StatesService extends MonkeyEcxCommonsService {
  private route = `${environment.urlAssets}/data/states.json`;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: SharedStates[]) {
    const { store } = this;

    store.dispatch(
      sharedAction.statesActions.create({
        data
      })
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  public getData() {
    const { monkeyecxService, route } = this;

    monkeyecxService.get<SharedStates[]>(route).subscribe((resp: any) => {
      if (resp.length) {
        this.update(resp);
      }
    });
  }

  private async verifyBeforeStart() {
    const { store } = this;

    const data = await store
      .select(sharedSelector.states.exists())
      .pipe(take(1))
      .toPromise();

    if (data) return;

    this.getData();
  }

  public start() {
    this.verifyBeforeStart();
  }
}

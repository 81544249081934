import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InvoiceData, Pagination } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { buyer as actions } from 'app/store/actions';
import { buyer as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxLinksModel,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class InvoicesService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const data = await this.store
      .select(selectors.invoices.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, updatePagination = true) {
    this.updateControl({ isLoading: true });

    const data = await this.monkeyecxService.get<any>(url).toPromise();
    const items = this.getEmbeddedData(data, 'buyer-payables');
    const { _links } = data;

    this.update(items, identifier);

    if (updatePagination) {
      this.updatePagination(_links, identifier);
    }

    this.updateControl({ isLoading: false });
  }

  private update(data: InvoiceData[], identifier: string) {
    this.store.dispatch(
      actions.invoicesActions.updateAll({
        data: data.map((_: InvoiceData) => {
          const { invoiceNumber } = _;
          const actionId: any = new MonkeyEcxLinksModel(_).getAction('self');
          const invoiceId = actionId?.href?.split('/')?.pop();

          return {
            identifier,
            ..._,
            invoiceId,
            id: `${identifier}-${invoiceNumber}`
          };
        })
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.invoicesActions.updateControl({ data }));
  }

  private updatePagination(data: any, identifier: string) {
    this.store.dispatch(
      actions.invoicesActions.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  public async load(url: string, identifier: string) {
    const { store } = this;

    const { hasDifference, hasField } = await store
      .select(selectors.invoices.paginationHasDifference({ identifier, url }))
      .pipe(take(1))
      .toPromise();

    if (hasDifference && hasField) {
      store.dispatch(actions.invoicesActions.clear({ identifier }));
    }

    this.loadData(url, identifier, hasDifference);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BuyerOperation } from 'app/interfaces';
import { LinksModel, ProcessDataModel } from 'app/model';
import { SharedLoadingComponent } from 'app/shared/components';
import { buyer as actions } from 'app/store/actions';
import { buyer as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxRequestSchedule,
  MonkeyEcxRequestScheduleService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { MonkeyStyleGuideModalService } from 'monkey-style-guide';
import { take } from 'rxjs/operators';

@Injectable()
export class OperationCheckoutService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private router: Router,
    private scheduleService: MonkeyEcxRequestScheduleService,
    private modalService: MonkeyStyleGuideModalService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage, {
      schedule: {
        service: scheduleService,
        options: {
          clearOnChangeRoute: true
        }
      }
    });
  }

  private onHandleClear() {
    const { store } = this;

    store.dispatch(actions.operationSummaryActions.clear());
    store.dispatch(actions.operationActions.clear());
    store.dispatch(actions.operationSimulationActions.clear());
    store.dispatch(actions.operationCheckoutActions.clear());
    store.dispatch(actions.operationItemsActions.clear());
    store.dispatch(actions.operationBankAccountsActions.clear());
  }

  private handleError(message: string) {
    const { router } = this;

    this.onHandleClear();

    router.navigate(['/app/buyer/checkout-error'], {
      state: {
        errorMessage: message
      }
    });
  }

  private onHandleCancelProgress() {
    const { __schedule, scheduleService, modalService } = this;
    modalService.closeAll();
    if (__schedule) scheduleService.removeSchedule(__schedule);
  }

  private onHandleProgress(id?: string, open = true) {
    const { modalService, store } = this;

    modalService.closeAll();
    if (open) {
      modalService.open(SharedLoadingComponent, {
        color: 'warning',
        size: 'sm',
        data: {
          progress$: store.select(selectors.operationCheckout.selectById({
            id
          })),
          onClose: this.onHandleCancelProgress.bind(this)
        }
      });
    }
  }

  private updateControl(data: any) {
    const { store } = this;

    store.dispatch(
      actions.operationCheckoutActions.updateControl({
        data
      })
    );
  }

  private updateProcessData(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationCheckoutActions.updateProcessData({
        data: {
          id,
          ...data
        }
      })
    );
  }

  private create(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationCheckoutActions.create({
        data: {
          id,
          ...data
        }
      })
    );
  }

  private onHandleCheckoutData(id: string) {
    const { router } = this;

    this.onHandleClear();

    router.navigate(['/app/buyer/checkout-success'], {
      state: {
        checkoutData: id
      }
    });
  }

  private confirmSchedule(id: string, data: ProcessDataModel, sch: MonkeyEcxRequestSchedule) {
    const { scheduleService } = this;
    const { processed, detail } = data;
    this.__schedule = sch;

    this.updateProcessData(id, {
      ...data
    });

    if (detail) {
      this.updateProcessData(id, {
        ...data,
        step: 100,
        totalSteps: 100
      });

      scheduleService.removeSchedule(sch);
      this.__schedule = null;
      this.handleError(detail);
    } else if (processed) {
      this.updateProcessData(id, {
        ...data,
        step: 100,
        totalSteps: 100
      });

      scheduleService.removeSchedule(sch);
      this.__schedule = null;

      setTimeout(() => {
        this.onHandleCheckoutData(id);
      }, 500);
    }
  }

  private buildSchedule(id: string, resp: any) {
    const { scheduleService } = this;
    const simulData = new LinksModel(resp);
    const { href, type } = simulData.getAction('processes');
    if (!href || !type) return;
    const { _links } = resp;

    this.__schedule = scheduleService.setSchedule(
      {
        method: `${type}`.toLowerCase(),
        url: href,
        data: {
          ...{
            _links
          }
        },
        action: (data: any, sch: any) => {
          return this.confirmSchedule(id, new ProcessDataModel(data), sch);
        }
      },
      1000
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [412],
      httpCodeIgnoreRedirect: [403]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async createCheckout(id: string, operation: BuyerOperation, params: any) {
    const { monkeyecxService, scheduleService } = this;
    if (this.__schedule) scheduleService.removeSchedule(this.__schedule);

    const { operationData } = operation;
    // #TODO poderia ter o link para recalculo
    const type = 'post';
    const { href } = new LinksModel(operationData).getAction('self');

    try {
      const resp = await monkeyecxService[type.toLowerCase()]<any>(
        `${href}/offers`,
        params).toPromise();

      this.buildSchedule(id, resp);
    } catch (err) {
      const { error, status } = err;
      const { notifications } = error;
      const message = notifications?.join(', ');
      if (status !== 403) {
        this.handleError(message);
      }
      this.onHandleProgress(id, false);
      this.updateControl({ isLoading: false });
    }
  }

  private async onHandleCheckout(id: string, password: any) {
    const { store } = this;

    const simul = await store
      .select(selectors.operationSimulation.selectById({ id }))
      .pipe(take(1))
      .toPromise();

    const operation = await store
      .select(selectors.operation.selectById({ id }))
      .pipe(take(1))
      .toPromise();

    this.updateControl({ isLoading: true });
    this.onHandleProgress(id, true);

    const { simulationData } = simul;
    const { bank, bankName, agency, account, accountDigit } = simulationData;

    this.create(id);

    this.createCheckout(id, operation, {
      ...password,
      bank,
      bankName,
      agency,
      account,
      accountDigit
    });
  }

  start(id: string, password: any) {
    this.onHandleCheckout(id, password);
  }
}

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const add = createAction(
  '[MECX Shared PersistentSchedule] Add',
  props<{ data: interfaces.PersistentScheduleData }>()
);
const check = createAction(
  '[MECX Shared PersistentSchedule] Check',
  props<{ identifier: string; callback: Function }>()
);
const remove = createAction(
  '[MECX Shared PersistentSchedule] Remove',
  props<{ identifier: string }>()
);

export { add, check, remove };

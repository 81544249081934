import { MonkeyEcxNavigation } from 'monkey-front-core';

export const navigation: MonkeyEcxNavigation[] = [
  {
    description: 'Home',
    icon: 'home-24',
    id: 'home',
    role: ['ADMIN', 'EXECUTE', 'VIEW', 'SELECTOR'],
    title: 'Home',
    translate: 'NAVIGATION.HOME.TITLE',
    translateDescription: 'NAVIGATION.HOME.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/home'
  },
  {
    description: 'Payables',
    icon: 'book-24',
    id: 'payables',
    role: ['ADMIN', 'EXECUTE', 'VIEW', 'BACK_OFFICE'],
    title: 'payables',
    translate: 'NAVIGATION.PAYABLES.TITLE',
    translateDescription: 'NAVIGATION.PAYABLES.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/payables'
  },
  {
    description: 'Suppliers',
    icon: 'people-24',
    id: 'sponsor-sellers',
    role: ['ADMIN', 'EXECUTE'],
    title: 'sponsor Sellers',
    translate: 'NAVIGATION.SUPPLIERS.TITLE',
    translateDescription: 'NAVIGATION.SUPPLIERS.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/suppliers'
  },
  {
    description: 'Assignments',
    icon: 'document-24',
    id: 'assignments',
    role: ['ADMIN', 'EXECUTE', 'VIEW', 'SELECTOR', 'BACK_OFFICE'],
    title: 'Assignments',
    translate: 'NAVIGATION.ASSIGNMENTS.TITLE',
    translateDescription: 'NAVIGATION.ASSIGNMENTS.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/assignments'
  },
  {
    description: 'Bank Accounts',
    icon: 'wallet-24',
    id: 'bank-accounts',
    role: ['ADMIN'],
    title: 'Bank Accounts',
    translate: 'NAVIGATION.BANK-ACCOUNTS.TITLE',
    translateDescription: 'NAVIGATION.BANK-ACCOUNTS.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/bank-accounts'
  },
  {
    description: 'Envio de E-mail',
    icon: 'mail-letter-fast-circle-24',
    id: 'sponsor-mail',
    role: ['ADMIN', 'EXECUTE'],
    title: 'Envio de E-mail',
    featureToggle: 'SPONSOR_MAIL',
    translate: 'NAVIGATION.SPONSOR-MAIL.TITLE',
    translateDescription: 'NAVIGATION.SPONSOR-MAIL.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/mail'
  },
  {
    description: 'Payables Upload',
    icon: 'dollar-sign-24',
    id: 'payables-upload',
    role: ['ADMIN', 'EXECUTE'],
    title: 'Payables Upload',
    translate: 'NAVIGATION.PAYABLES-UPLOAD.TITLE',
    translateDescription: 'NAVIGATION.PAYABLES-UPLOAD.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/payables-upload'
  },
  {
    description: 'Operation',
    icon: 'calendar-24',
    id: 'sponsor-operation',
    role: ['ADMIN', 'EXECUTE'],
    title: 'Operation',
    featureToggle: 'SPONSOR_OPERATION',
    translate: 'NAVIGATION.SPONSOR-OPERATION.TITLE',
    translateDescription: 'NAVIGATION.SPONSOR-OPERATION.DESCRIPTION',
    type: 'item',
    url: '/app/sponsor/operation-summary',
    featureByProgram: 'disableSponsorOperation'
  }
];

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction('[MECX Shared Conciliation] Clear All', props<{ identifier: string }>());
const create = createAction(
  '[MECX Shared Conciliation] Create',
  props<{ data: interfaces.Conciliation }>()
);
const load = createAction(
  '[MECX Shared Conciliation] Load',
  props<{ url: string; identifier: string }>()
);
const loadPagination = createAction(
  '[MECX Shared Conciliation Pagination] Load',
  props<{ pagination: interfaces.Pagination; identifier: string }>()
);
const remove = createAction(
  '[MECX Shared Conciliation] Delete',
  props<{ id: string; identifier: string }>()
);
const startExportWithMap = createAction(
  '[MECX Shared Conciliation] Start Export With Map',
  props<{ callback: Function; data: interfaces.ReportParams }>()
);
const updateAll = createAction(
  '[MECX Shared Conciliation] Update All',
  props<{ data: interfaces.Conciliation[] }>()
);
const updateAllWithMap = createAction(
  '[MECX Shared Conciliation] Update All With Map',
  props<{ data: interfaces.Conciliation[] }>()
);
const updateControl = createAction(
  '[MECX Shared Conciliation] Update Control',
  props<{ data: any }>()
);
const updateOne = createAction(
  '[MECX Shared Conciliation] Update One',
  props<{ data: interfaces.Conciliation }>()
);
const updatePagination = createAction(
  '[MECX Shared Conciliation] Update Pagination',
  props<{ data: any }>()
);

export {
  clear,
  create,
  load,
  loadPagination,
  remove,
  startExportWithMap,
  updateAll,
  updateAllWithMap,
  updateControl,
  updateOne,
  updatePagination
};

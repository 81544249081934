import { createAction, props } from '@ngrx/store';
import { GeneralSummaryData } from 'app/interfaces';

export const generalSummaryActions = {
  load: createAction(
    '[Sponsor GeneralSummary] Load',
    props<{ filter: string; identifier: string; url: string }>()
  ),
  filter: createAction(
    '[Sponsor GeneralSummary] Filter',
    props<{ filter: string; identifier: string }>()
  ),
  updateControl: createAction('[Sponsor GeneralSummary] Update Control', props<{ data: any }>()),
  updateOne: createAction(
    '[Sponsor GeneralSummary] Upadate One',
    props<{ data: GeneralSummaryData }>()
  )
};

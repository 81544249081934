/* eslint-disable indent */
import {
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { environment } from '@environments/environment';
import { MECX_DATE_FORMAT, MECX_LANG, MECX_TIMEZONEOFFSET } from 'monkey-front-core';

@NgModule()
export class MonkeyEcxLocaleModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: MonkeyEcxLocaleModule
  ) {
    if (parentModule) {
      throw new Error('MonkeyEcxLocaleModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(): ModuleWithProviders<MonkeyEcxLocaleModule> {
    return {
      ngModule: MonkeyEcxLocaleModule,
      providers: [
        {
          provide: LOCALE_ID,
          useValue: environment.locale.lang
        },
        {
          provide: MECX_LANG,
          useValue: environment.locale.lang
        },
        {
          provide: MECX_TIMEZONEOFFSET,
          useValue: environment.locale.timezoneOffset
        },
        {
          provide: MECX_DATE_FORMAT,
          useValue: environment.locale.dateFormat
        },
        { provide: DEFAULT_CURRENCY_CODE, useValue: environment.locale.defaultCurrency }
      ]
    };
  }
}

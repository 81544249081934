import { ReceivablesData } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const receivablesDetailsActions = {
  load: createAction(
    '[Seller ReceivablesDetails] Start',
    props<{ url: string; identifier: string }>()
  ),
  updateControl: createAction('[Seller ReceivablesDetails] Update Control', props<{ data: any }>()),
  updateOne: createAction(
    '[Seller ReceivablesDetails] Update One',
    props<{ data: ReceivablesData }>()
  )
};

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { seller as actions } from '@store/actions';
import { OfferReceivementAccountData } from 'app/interfaces';
import { LinksModel } from 'app/model';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class OfferReceivementAccountService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(data: OfferReceivementAccountData, identifier: string) {
    const actionId: string = new LinksModel(data)?.getAction('self')?.href;
    const id = actionId?.split('/')?.pop();

    return {
      ...data,
      identifier,
      id,
      type: 'RECEIVEMENT'
    };
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.offerReceivementAccountActions.updateControl({
        data
      })
    );
  }

  private update(data: OfferReceivementAccountData, identifier: string) {
    this.store.dispatch(
      actions.offerReceivementAccountActions.updateOne({
        data: this.mappingData(data, identifier)
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService, tokenStorage } = this;

    if ((await tokenStorage.getToken()).role !== 'ADMIN') return;

    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<OfferReceivementAccountData>(url).toPromise();

      this.update(data, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sponsor } from '@store/reducers';
import { SupplierData } from 'app/interfaces';

declare type State = sponsor.fromSuppliersDetails.State;

export const selectSuppliersDetailsState = createFeatureSelector<
  sponsor.fromSuppliersDetails.State
>(sponsor.fromSuppliersDetails.featureKey);

export const selectAllSuppliersDetails = createSelector(
  selectSuppliersDetailsState,
  sponsor.fromSuppliersDetails.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllSuppliersDetails,
    (entities: SupplierData[]): SupplierData => {
      return entities.find((_: SupplierData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectSuppliersDetailsState,
    (state: State): any => {
      return state.control;
    }
  );
};

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const start = createAction('[MECX Shared MailCampaignObjectives] Start', props<{ url: string }>());
const create = createAction(
  '[MECX Shared MailCampaignObjectives] Create',
  props<{ data: interfaces.MailCampaignObjectiveData[] }>()
);
const updateControl = createAction(
  '[MECX Shared MailCampaignObjectives] Update Control',
  props<{ data: any }>()
);

export { start, create, updateControl };

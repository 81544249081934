import { createAction, props } from '@ngrx/store';
import { PurchaseEvolutionData } from 'app/interfaces';

export const purchaseEvolutionActions = {
  load: createAction(
    '[Sponsor ChartsSummaryPurchaseEvolution] Load',
    props<{ url: string; identifier: string }>()
  ),
  updateControl: createAction(
    '[Sponsor ChartsSummaryPurchaseEvolution] Update Control',
    props<{ data: any }>()
  ),
  updateAll: createAction(
    '[Sponsor ChartsSummaryPurchaseEvolution] Update All',
    props<{ data: PurchaseEvolutionData[] }>()
  )
};

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { webhooks as actions } from '@shared-store/actions';
import { MonkeyEcxLinksModel } from 'monkey-front-core';

export const featureKey = 'mecx-shared-webhooks-configurations';

export interface State extends EntityState<interfaces.WebhookConfigurationData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: interfaces.Pagination;
}

export const adapter: EntityAdapter<interfaces.WebhookConfigurationData> =
  createEntityAdapter<interfaces.WebhookConfigurationData>({
    selectId: (item: interfaces.WebhookConfigurationData) => {
      const { identifier } = item;
      const actionId: any = new MonkeyEcxLinksModel(item)?.getAction('self');
      const id = actionId.href.split('/').pop();

      return `${identifier}-${id}`;
    },
    sortComparer: null
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(actions.configurations.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  }),
  on(actions.configurations.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.configurations.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.configurations.deleteAction, (state, action) => {
    return adapter.removeOne(action.identifier, state);
  }),
  on(actions.configurations.clear, (state, action) => {
    const { identifier } = action;

    const ids = (adapter.getSelectors().selectIds(state) as string[]).filter(
      (id: string | number) => {
        return `${id}`.includes(identifier);
      }
    );

    const handled = { ...adapter.removeMany(ids, state) };
    handled.control = { isLoading: false };
    handled.pagination = {
      ...handled.pagination,
      [identifier]: null
    };
    return handled;
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

import { buyer as interfaces } from '@interfaces';
import { createReducer, on } from '@ngrx/store';
import { buyer as actions } from '@store/actions';

export const featureKey = 'buyer-purchase-evolution';

export interface State {
  error: any;
  data: interfaces.PurchaseEvolutionData;
  control: {
    isLoading: boolean;
  };
}

export const initialState: State = {
  error: null,
  data: null,
  control: {
    isLoading: null
  }
};

export const reducer = createReducer(
  initialState,
  on(actions.dashboard.purchaseEvolution.clear, () => {
    return initialState;
  }),
  on(actions.dashboard.purchaseEvolution.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.dashboard.purchaseEvolution.updateOne, (state, action) => {
    return {
      ...state,
      data: action.data
    };
  })
);

export const selectAll = null;

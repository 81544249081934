import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction(
  '[MECX Shared Bank Accounts] Clear All',
  props<{ identifier: string }>()
);
const clearPendencyWithMap = createAction('[MECX Shared Banks] Clear Pendency With Map');
const load = createAction(
  '[MECX Shared Bank Accounts] Start',
  props<{ url: string; identifier: string }>()
);
const deleteAccount = createAction(
  '[MECX Shared Bank Accounts] Delete Account',
  props<{ data: interfaces.BankAccountData }>()
);
const updateAll = createAction(
  '[MECX Shared Bank Accounts] Update All',
  props<{ data: interfaces.BankAccountData[] }>()
);
const updateControl = createAction(
  '[MECX Shared Bank Accounts] Update Control',
  props<{ data: any }>()
);
const updateOne = createAction(
  '[MECX Shared Bank Accounts] Update One',
  props<{ data: interfaces.BankAccountData }>()
);
const updatePagination = createAction(
  '[MECX Shared Bank Accounts] Update Pagination',
  props<{ data: any }>()
);
const paginationLoad = createAction(
  '[MECX Shared Bank Accounts Pagination] Load',
  props<{ pagination: interfaces.Pagination; identifier: string }>()
);

const api = {
  delete: createAction(
    '[MECX Shared Bank Accounts] Delete',
    props<{ data: interfaces.BankAccountData }>()
  ),
  makeMain: createAction(
    '[MECX Shared Bank Accounts] Make Main',
    props<{ data: interfaces.BankAccountData; callback: Function }>()
  ),
  save: createAction(
    '[MECX Shared Bank Accounts] Save',
    props<{ data: interfaces.BankAccountData; url: string; callback: Function }>()
  )
};

export {
  api,
  clear,
  clearPendencyWithMap,
  deleteAccount,
  load,
  paginationLoad,
  updateAll,
  updateControl,
  updateOne,
  updatePagination
};

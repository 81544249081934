<a class="logo" [routerLink]="['/user/profile/companies']">
  <img class="logo-icon" [src]="_config?.theme?.logos?.toolbar">
  <span class="logo-text secondary-text">
    {{ _config?.theme?.name }}
  </span>
</a>
<app-environment class="ml-2 mr-2 hidden-xs hidden-sm"></app-environment>
<monkey-button-dropdown [label]="profileButton" *ngIf="_me?.name">
  <div class="mt-2">
    <monkey-button type="tertiary" color="theme" [routerLink]="'/user/profile/edit'" icon="user-16">
      {{ 'SCREENS.TOOLBAR.PROFILE.MY-PROFILE' | translate }}
    </monkey-button>
  </div>
  <div class="separator mt-2"></div>
  <div class="mt-2">
    <monkey-button type="tertiary" color="theme" (click)="logOut()" icon="logout-16">
      {{ 'SCREENS.TOOLBAR.PROFILE.LOGOUT' | translate }}
    </monkey-button>
  </div>
</monkey-button-dropdown>

<ng-template #profileButton>
  <monkey-button-avatar type="none" [img]="_me?.pictureURL" size="md" [label]="_me?.name">
  </monkey-button-avatar>
  {{ _me?.name | monkeyecxTextTruncate:10 }}
</ng-template>
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { GeneralSummaryData } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { sponsor as actions } from 'app/store/actions';
import { sponsor as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class GeneralSummaryService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: any, identifier: string, filter: string) {
    this.store.dispatch(
      actions.generalSummaryActions.updateOne({
        data: { ...data, identifier, filter }
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.generalSummaryActions.updateControl({ data }));
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, filter: string) {
    this.updateControl({ isLoading: true });

    const data = await this.monkeyecxService
      .get<GeneralSummaryData>(url.replace('{?period}', `?period=${filter}`))
      .toPromise();

    this.update(data, identifier, filter);

    this.updateControl({ isLoading: false });
  }

  public load(url: string, identifier: string, filter: string) {
    this.loadData(url, identifier, filter);
  }

  public async filter(identifier: string, filter: string) {
    const data = await this.store
      .select(selectors.dashboard.selectByIdentifier({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { href } = new LinksModel(data).getAction('program-summary');
    if (!href) return;
    this.loadData(href, identifier, filter);
  }
}

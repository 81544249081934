import { createFeatureSelector, createSelector } from '@ngrx/store';
import { buyer } from '@store/reducers';
import { BuyerOperationSummaryItemsData } from 'app/interfaces';

declare type State = buyer.fromOperationSummaryItems.State;

export const selectOperationSummaryItemsState = createFeatureSelector<
  buyer.fromOperationSummaryItems.State
>(buyer.fromOperationSummaryItems.featureKey);

export const selectAllOperationSummaryItems = createSelector(
  selectOperationSummaryItemsState,
  buyer.fromOperationSummaryItems.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllOperationSummaryItems,
    (entities: BuyerOperationSummaryItemsData[]): BuyerOperationSummaryItemsData[] => {
      return entities.filter((_: BuyerOperationSummaryItemsData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectOperationSummaryItemsState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectOperationSummaryItemsState,
    (state: State): any => {
      return state.pagination?.[props.identifier];
    }
  );
};

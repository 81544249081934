import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sponsor } from '@store/reducers';
import { SellerData } from 'app/interfaces';

declare type State = sponsor.fromSeller.State;

export const selectSellerState = createFeatureSelector<sponsor.fromSeller.State>(
  sponsor.fromSeller.featureKey
);

export const selectAllSeller = createSelector(selectSellerState, sponsor.fromSeller.selectAll);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllSeller,
    (entities: SellerData[]): SellerData => {
      return entities.find((_: SellerData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectSellerState,
    (state: State): any => {
      return state.control;
    }
  );
};

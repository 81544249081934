import { createAction, props } from '@ngrx/store';
import { SignaturesData } from 'app/interfaces';

export const signaturesDetailsActions = {
  load: createAction(
    '[Sponsor SignaturesDetails] Start',
    props<{ url: string; identifier: string }>()
  ),
  download: createAction(
    '[Sponsor SignaturesDetails] Download',
    props<{ data: SignaturesData; typeDocument: string }>()
  ),
  updateControl: createAction('[Sponsor SignaturesDetails] Update Control', props<{ data: any }>()),
  updateOne: createAction(
    '[Sponsor SignaturesDetails] Update One',
    props<{ data: SignaturesData }>()
  )
};

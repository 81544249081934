<monkeyecx-progress-bar></monkeyecx-progress-bar>

<div id="main">
  <div id="container-1" class="container">
    <div id="container-2" class="container">
      <div class="hidden-xs hidden-sm">
        <ng-container *ngTemplateOutlet="toolbarAlerts"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      <div id="container-3" class="container" infiniteScroll [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50" [scrollWindow]="false"
        (scrolled)="content.onScrollDown.next()">
        <div class="hidden-md hidden-lg">
          <ng-container *ngTemplateOutlet="toolbarAlerts"></ng-container>
        </div>
        <app-content #content></app-content>
      </div>
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
  </div>
</div>

<ng-template #toolbarAlerts>
  <app-toolbar-alerts [ngClass]="'below-fixed monkey-navy-400'">
  </app-toolbar-alerts>
</ng-template>

<ng-template #toolbar>
  <app-toolbar-profile [ngClass]="'below-fixed monkey-navy-400'"></app-toolbar-profile>
</ng-template>

<ng-template #footer>
  <app-footer [ngClass]="'below-fixed monkey-navy-400'"></app-footer>
</ng-template>
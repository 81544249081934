<div class="market-status market-status-open" *ngIf="_marketModel?.marketStatus === 'OPEN'">
  <monkey-icon icon="clock-20" color="#00875a" class="mr-2"></monkey-icon>
  <span [@animate]="{value:'*',params:{delay:'10ms', y:'10px'}}">
    {{ 'SCREENS.TOOLBAR.MARKET.OPEN' | translate }}
  </span>
</div>

<div class="market-status market-status-closed" *ngIf="_marketModel?.marketStatus === 'CLOSED'">
  <monkey-icon icon="clock-20" color="#fafafa" class="mr-2"></monkey-icon>
  {{ 'SCREENS.TOOLBAR.MARKET.CLOSED' | translate }}
</div>

<div class="market-status market-status-closed-soon"
  *ngIf="_marketModel?.marketStatus === 'CLOSED_SOON'">
  <monkey-icon icon="clock-20" color="#130203" class="mr-2"></monkey-icon>
  {{ 'SCREENS.TOOLBAR.MARKET.CLOSED_SOON' | translate }}
</div>
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { bankAccounts as actions } from '@shared/app/store/actions';
import { shared as selectors } from '@store/selectors';
import { getSelectorSync } from '@utils';
import { SponsorPendenciesService } from 'app/core/services/pendencies';
import { MonkeyEcxConfigService } from 'monkey-front-core';
import { map, take } from 'rxjs/operators';

@Injectable()
export class BanksMapperEffects {
  startWithMap$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.banks.startWithMap),
        map(({ companyType }) => {
          this.onHandleStart(companyType);
        })
      );
    },
    {
      dispatch: false
    }
  );

  clearPendencyWithMap$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.bankAccounts.clearPendencyWithMap),
        map(() => {
          this.onHandleClearPendency();
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private monkeyConfigService: MonkeyEcxConfigService,
    private sponsorPendency: SponsorPendenciesService
  ) {
    // not to do
  }

  private async onHandleStart(companyType: string) {
    const { store, monkeyConfigService } = this;

    if (companyType.toUpperCase() !== 'SPONSOR') return;

    store.dispatch(actions.banks.clear());

    let data = await getSelectorSync(store, selectors.banks.selectAll);

    const config: any = await monkeyConfigService.config().pipe(take(1)).toPromise();

    if (config?.banks) {
      data = config?.banks;
    }

    store.dispatch(
      actions.banks.create({
        data
      })
    );
  }

  private onHandleClearPendency() {
    this.sponsorPendency.clear(true);
  }
}

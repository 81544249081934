import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TemplateConfigurationsData } from 'app/interfaces';
import { sponsor } from 'app/store/reducers';

declare type State = sponsor.fromTemplateConfigurations.State;

export const selectTemplateConfigurationsState = createFeatureSelector<
  sponsor.fromTemplateConfigurations.State
>(sponsor.fromTemplateConfigurations.featureKey);

export const selectAllTemplateConfigurations = createSelector(
  selectTemplateConfigurationsState,
  sponsor.fromTemplateConfigurations.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllTemplateConfigurations,
    (entities: TemplateConfigurationsData[]): TemplateConfigurationsData => {
      return entities.find((_: TemplateConfigurationsData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectTemplateConfigurationsState,
    (state: State): any => {
      return state.control;
    }
  );
};

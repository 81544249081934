import { createAction, props } from '@ngrx/store';
import { OfferReceivementAccountData } from 'app/interfaces';

export const offerReceivementAccountActions = {
  load: createAction(
    '[Seller OfferReceivementAccount] Start',
    props<{ url: string; identifier: string }>()
  ),
  updateControl: createAction(
    '[Seller OfferReceivementAccount] Update Control',
    props<{ data: any }>()
  ),
  updateOne: createAction(
    '[Seller OfferReceivementAccount] Update One',
    props<{ data: OfferReceivementAccountData }>()
  )
};

/* eslint-disable no-console */
import { OverlayContainer } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/pt';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { fromShared } from '@shared-store/reducers';
import { MECXSharedSupportButtonModule } from '@shared/app/components/support-button';
import { root } from '@store/reducers';
import { environment } from 'environments/environment';
import {
  MECX_ENV,
  MonkeyEcxConfigModule,
  MonkeyEcxProgressBarModule,
  MonkeyFrontCoreModule,
  store as storeCore
} from 'monkey-front-core';
import {
  MonkeyStyleGuideModalFixedService,
  MonkeyStyleGuideModalService,
  MonkeyStyleGuideModule
} from 'monkey-style-guide';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IdleInterceptor } from './core/services/idle';
import { ClosedToMaintenanceModule } from './shared/components/modal/closed-to-maintenance';
import { SharedCompanyPendenciesModule } from './shared/components/modal/company-pendencies';
import { SharedNewsletterModule } from './shared/components/modal/newsletter';
import { SupportedBrowserModule } from './shared/components/modal/supported-browser';
import { VersionChangedModule } from './shared/components/modal/version-changed';
import { MonkeyEcxCommonModule } from './shared/modules/monkeyecx-common.module';
import { MonkeyEcxLocaleModule } from './shared/modules/monkeyecx-locale.module';
import { MonkeyEcxTzModule } from './shared/modules/monkeyecx-tz.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ClosedToMaintenanceModule,
    HttpClientModule,
    MonkeyEcxCommonModule,
    MonkeyEcxConfigModule.forRoot(),
    MonkeyEcxProgressBarModule,
    MonkeyEcxTzModule.forRoot(),
    MonkeyEcxLocaleModule.forRoot(),
    MonkeyStyleGuideModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    SharedCompanyPendenciesModule,
    SharedNewsletterModule,
    SupportedBrowserModule,
    TranslateModule.forRoot(),
    VersionChangedModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: [
          root.rootState,
          storeCore.reducers.fromMonkeyecxSeeder.seederReducer([
            'mecx-core-storage',
            'mecx-shared-persistent-schedule'
          ])
        ]
      }
    ),
    StoreModule.forFeature(
      fromShared.persistentSchedule.featureKey,
      fromShared.persistentSchedule.reducer
    ),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    MonkeyFrontCoreModule,
    MECXSharedSupportButtonModule
  ],
  bootstrap: [AppComponent],
  providers: [
    MonkeyStyleGuideModalService,
    MonkeyStyleGuideModalFixedService,
    {
      provide: MECX_ENV,
      useValue: environment.environment
    },
    {
      provide: OverlayContainer,
      useFactory: () => {
        const container = document.createElement('div');
        container.classList.add('cdk-overlay-container');
        class Class extends OverlayContainer {
          _createContainer(): void {
            document.querySelector('body').appendChild(container);
            this._containerElement = container;
          }
        }

        return new Class(container, undefined);
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IdleInterceptor,
      multi: true
    }
  ]
})
export class AppModule {}

import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BaseComponent } from 'monkey-front-components';
import { filter, takeUntil } from 'rxjs/operators';
import { SupportService } from './support.service';

const pathsToIgnore = ['/authentication/email/', '/authentication/login/'];

@Component({
  selector: 'mecx-shared-support-button',
  styleUrls: ['./support-button.component.scss'],
  templateUrl: './support-button.component.html'
})
export class MECXSharedSupportButtonComponent extends BaseComponent implements OnInit {
  @HostBinding('class.hide') public isHide = true;

  _isLoading = false;

  constructor(private service: SupportService, private router: Router) {
    super();
  }

  private handleConfiguration(event: NavigationStart) {
    const found =
      pathsToIgnore.some((path: string) => {
        return event.url.includes(path);
      }) || event.url === '/';
    this.isHide = found;
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationStart;
        }),
        takeUntil(this.__unsubscribeAll)
      )
      .subscribe((event: NavigationStart) => {
        this.handleConfiguration(event);
      });

    this.service
      .loading()
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((_: boolean) => {
        this._isLoading = _;
      });
  }

  @HostListener('click', ['$event'])
  onHandleClick(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.service.show();
  }
}

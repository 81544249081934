import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sponsor } from '@store/reducers';
import { SignaturesData } from 'app/interfaces';

declare type State = sponsor.fromSignaturesDetails.State;

export const selectSignaturesDetailsState =
  createFeatureSelector<sponsor.fromSignaturesDetails.State>(
    sponsor.fromSignaturesDetails.featureKey
  );

export const selectAllSignaturesDetails = createSelector(
  selectSignaturesDetailsState,
  sponsor.fromSignaturesDetails.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllSignaturesDetails,
    (entities: SignaturesData[]): SignaturesData => {
      return entities.find((_: SignaturesData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(selectSignaturesDetailsState, (state: State): any => {
    return state.control;
  });
};

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Pagination, PayablesData } from '@interfaces';
import { LinksModel } from '@model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { sponsor as actions } from '@store/actions';
import { sponsor as selectors } from '@store/selectors';
import {
  DecoratorsUtils,
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxPage,
  MonkeyEcxRequestQueueHandlingService,
  MonkeyEcxRequestSchedule,
  MonkeyEcxRequestScheduleService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService,
  Statics
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class PayablesService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertsService: MonkeyEcxAlertsService,
    private store: Store,
    private router: Router,
    private requestQueueHandlingService: MonkeyEcxRequestQueueHandlingService,
    private requestScheduleService: MonkeyEcxRequestScheduleService,
    private translateService: TranslateService
  ) {
    super(monkeyecxService, tokenStorage);

    this.geti18n(this.translateService, ['SERVICES']);
  }

  private updateControl(data: any) {
    const { store } = this;

    store.dispatch(
      actions.payables.updateControl({
        data
      })
    );
  }

  private updatePagination(data: any, identifier: string) {
    const { store } = this;

    store.dispatch(
      actions.payables.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  private update(data: any[], identifier: string) {
    const { store } = this;

    store.dispatch(
      actions.payables.updateAll({
        data: data.map((_: any) => {
          return {
            identifier,
            ..._
          };
        })
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, updatePagination = true) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoading: true });

    const data = await monkeyecxService.get<any>(url).toPromise();
    const items = this.getEmbeddedData(data, 'payables');
    const { _links, page } = data;

    this.update(items, identifier);
    if (updatePagination) {
      this.updatePagination(
        {
          ..._links,
          url
        },
        identifier
      );
    }

    this.updatePage(page, identifier);

    this.updateControl({ isLoading: false });
  }

  private updatePage?(args: MonkeyEcxPage, identifier: string) {
    const { store } = this;
    store.dispatch(
      actions.payables.updatePage({
        data: {
          [identifier]: args
        } as any
      })
    );
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const { store } = this;

    const data = await store
      .select(selectors.payables.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleDeleteAll(identifier: string) {
    const { store } = this;

    this.updateControl({ isLoading: true });

    try {
      const pagination = await store
        .select(selectors.payables.selectPagination({ identifier }))
        .pipe(take(1))
        .toPromise();

      const url = pagination?.url;
      if (!url) return;

      const resp = await this.monkeyecxService.delete<PayablesData>(url).toPromise();

      this.handleFinishDeleteAll(identifier, resp);
    } catch {
      this.onHandleMessage('error');
    }

    this.updateControl({ isLoading: false });
  }

  private handleFinishDeleteAll(identifier: string, resp: any) {
    const { requestQueueHandlingService, requestScheduleService, router, store } = this;

    const requestQueue = DecoratorsUtils.buildQueuePropertys({
      namei18n: 'QUEUES.NAME.PAYABLES-DELETE'
    });

    requestQueueHandlingService.setQueue(requestQueue);

    const {
      _links,
      _links: { process }
    } = resp;

    const sch = requestScheduleService.setSchedule({
      method: 'get',
      url: process.href,
      data: {
        ...{
          _links
        }
      },
      action: (data: any, sch: any) => {
        return this.confirmDeleteData(data, sch);
      },
      queue: requestQueue
    });

    router.navigate(['/app/sponsor/pages/deletion-in-progress'], {
      state: {
        deletionData: {
          id: sch.id,
          routeToGoBack: '/app/sponsor/payables'
        }
      }
    });

    store.dispatch(actions.payables.clear({ identifier }));
  }

  private confirmDeleteData(data: any, sch: MonkeyEcxRequestSchedule) {
    const { requestScheduleService, requestQueueHandlingService, router } = this;
    const { step, totalSteps, processed, detail } = data;
    const { queue } = sch;

    requestQueueHandlingService.updateQueueItem(queue, {
      loaded: step,
      total: totalSteps
    });

    if (detail || processed) {
      if (detail) {
        requestQueueHandlingService.finishQueueItem(
          queue,
          null,
          Statics.MonkeyEcxQueueEvents.FinishedWithError
        );
      } else {
        requestQueueHandlingService.finishQueueItem(queue, () => {
          requestQueueHandlingService.removeQueueItem(queue);
          router.navigate(['/app/sponsor/payables']);
        });
      }
      this.__onLoadingInProgress$.next(false);
      requestScheduleService.removeSchedule(sch);
    }
  }

  public deleteAll(identifier: string) {
    this.handleDeleteAll(identifier);
  }

  public async load(url: string, identifier: string) {
    const { store } = this;

    const { hasDifference, hasField } = await store
      .select(selectors.payables.paginationHasDifference({ identifier, url }))
      .pipe(take(1))
      .toPromise();

    if (hasDifference && hasField) {
      store.dispatch(actions.payables.clear({ identifier }));
    }

    this.loadData(url, identifier, hasDifference);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  public async delete(payable: any) {
    const { href, type } = new LinksModel(payable).getAction('delete');

    this.updateControl({ isLoading: true });

    try {
      await this.monkeyecxService[type.toLowerCase()]<PayablesData>(href).toPromise();

      this.store.dispatch(
        actions.payables.remove({
          data: payable
        })
      );

      this.onHandleMessage('success');
    } catch {
      this.onHandleMessage('error');
    }

    this.updateControl({ isLoading: false });
  }

  private onHandleMessage(type: 'success' | 'error') {
    this.alertsService.show({
      title: `${type.toUpperCase()}`,
      message: `${type.toUpperCase()}-PAYABLES-DELETE`,
      type: `${type}`,
      duration: 4000
    });
  }
}

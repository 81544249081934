import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DailyOperationData } from 'app/interfaces';
import { sponsor } from 'app/store/reducers';

declare type State = sponsor.fromDailyOperation.State;

export const selectDailyOperationState = createFeatureSelector<sponsor.fromDailyOperation.State>(
  sponsor.fromDailyOperation.featureKey
);

export const selectAllDailyOperation = createSelector(
  selectDailyOperationState,
  sponsor.fromDailyOperation.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllDailyOperation,
    (entities: DailyOperationData[]): DailyOperationData => {
      return entities.find((_: DailyOperationData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectDailyOperationState,
    (state: State): any => {
      return state.control;
    }
  );
};

import { createAction, props } from '@ngrx/store';
import { OfferItemData, Pagination } from 'app/interfaces';

export const offerItemsActions = {
  load: createAction('[Seller OfferItems] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction('[Seller OfferItems] Update All', props<{ data: OfferItemData[] }>()),
  updateControl: createAction('[Seller OfferItems] Update Control', props<{ data: any }>()),
  updatePagination: createAction('[Seller OfferItems] Update Pagination', props<{ data: any }>())
};

export const offerItemsPaginationActions = {
  load: createAction(
    '[Seller OfferItemsPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { SellingSummariesData } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { seller } from '@store/actions';
import { buildIdByLink } from '@utils';

export const featureKey = 'seller-selling-summaries';

export interface State extends EntityState<SellingSummariesData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

export const adapter: EntityAdapter<SellingSummariesData> =
  createEntityAdapter<SellingSummariesData>({
    selectId: (item: SellingSummariesData) => {
      return buildIdByLink<SellingSummariesData>(item);
    }
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(seller.sellingSummariesActions.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(seller.sellingSummariesActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(seller.sellingSummariesActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sponsor as actions } from 'app/store/actions';
import { sponsor as services } from 'app/store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class GeneralSummaryEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.generalSummaryActions.load),
        map(({ filter, identifier, url }) => {
          this.service.load(url, identifier, filter || '');
        })
      );
    },
    {
      dispatch: false
    }
  );

  filter$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.generalSummaryActions.filter),
        map(({ filter, identifier }) => {
          this.service.filter(identifier, filter || '');
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.GeneralSummaryService) {
    // not to do
  }
}

import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/services/auth';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class MeService extends MonkeyEcxCommonsService {
  private route: string = `${environment.baseUrl}/uaa`;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private authService: AuthService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async getData() {
    const { monkeyecxService, route, authService } = this;

    const data = await monkeyecxService.get<any>(`${route}/me`).pipe(take(1)).toPromise();

    authService.saveMeCredentials(
      {
        ...data.principal
      },
      null
    );
  }

  private async verifyBeforeStart() {
    const { tokenStorage } = this;

    const data = await tokenStorage.getMe();

    if (data?.programs) return;

    this.getData();
  }

  public start() {
    this.verifyBeforeStart();
  }
}

import { SellingSummariesData } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller as reducers } from '@store/reducers';

declare type State = reducers.fromSellingSummaries.State;

export const selectSellingSummariesState =
  createFeatureSelector<reducers.fromSellingSummaries.State>(
    reducers.fromSellingSummaries.featureKey
  );

export const selectAllSellingSummaries = createSelector(
  selectSellingSummariesState,
  reducers.fromSellingSummaries.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllSellingSummaries,
    (entities: SellingSummariesData[]): SellingSummariesData[] => {
      return entities.filter((_: SellingSummariesData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectAllowedAmountSum = (props: { identifier: string }) => {
  return createSelector(selectAllSellingSummaries, (entities: SellingSummariesData[]): any => {
    const data = entities.filter((_: SellingSummariesData) => {
      return _.identifier === props.identifier;
    });

    let allowedAmountSum = 0;

    if (data.length > 0) {
      allowedAmountSum = data
        .map((val: any) => {
          return val.amount;
        })
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        });
    }

    return allowedAmountSum;
  });
};

export const selectControl = () => {
  return createSelector(selectSellingSummariesState, (state: State): any => {
    return state.control;
  });
};

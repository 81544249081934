import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'monkey-front-components';
import {
  MonkeyEcxConfigBootstrap,
  MonkeyEcxConfigService,
  MonkeyEcxNavigationItem
} from 'monkey-front-core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SidebarService } from '../../sidebar/sidebar.service';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-nav-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavItemComponent extends BaseComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'nav-item';

  @Input() item: MonkeyEcxNavigationItem;

  private unsubscribeAll: Subject<any>;

  _configBootstrap: MonkeyEcxConfigBootstrap = null;

  _folded = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private configService: MonkeyEcxConfigService,
    private navigationService: NavigationService,
    private sidebarService: SidebarService,
    public router: Router
  ) {
    super();
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.configService.bootstrapConfig().subscribe((_: MonkeyEcxConfigBootstrap) => {
      this._configBootstrap = _;
    });

    merge(
      this.navigationService._onNavigationItemAdded,
      this.navigationService._onNavigationItemUpdated,
      this.navigationService._onNavigationItemRemoved
    )
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });

    this.sidebarService
      .getSidebar('navbar')
      .foldedChanged.pipe(takeUntil(this.unsubscribeAll))
      .subscribe((val: any) => {
        this._folded = val;

        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}

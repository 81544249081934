import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DashboardData } from 'app/interfaces';
import { sponsor as actions } from 'app/store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxConfig,
  MonkeyEcxConfigService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class DashboardService extends MonkeyEcxCommonsService {
  private config?: MonkeyEcxConfig;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store,
    private monkeyConfigService: MonkeyEcxConfigService
  ) {
    super(monkeyecxService, tokenStorage);
    this.monkeyConfigService.config().subscribe((_: MonkeyEcxConfig) => {
      this.config = _;
    });
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.dashboardActions.updateControl({
        data
      })
    );
  }

  private update(data: any, identifier: string) {
    this.store.dispatch(
      actions.dashboardActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService, store } = this;
    this.updateControl({ isLoading: true });

    const data = await monkeyecxService.get<DashboardData>(url).toPromise();

    this.update(data, identifier);

    const { _links: links } = data;

    store.dispatch(
      actions.dailyOperationActions.load({
        identifier,
        url: links?.['daily-operation-summary'].href
      })
    );

    store.dispatch(
      actions.generalSummaryActions.load({
        filter: '',
        identifier,
        url: links?.['program-summary'].href
      })
    );

    store.dispatch(
      actions.purchaseEvolutionActions.load({
        identifier,
        url: links?.['purchase-evolution'].href
      })
    );

    this.updateControl({ isLoading: false });
  }

  public async load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

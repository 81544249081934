import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuyerOperationSummaryData } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

declare type State = buyer.fromOperationSummary.State;

export const selectOperationSummaryState = createFeatureSelector<buyer.fromOperationSummary.State>(
  buyer.fromOperationSummary.featureKey
);

export const selectAllOperationSummary = createSelector(
  selectOperationSummaryState,
  buyer.fromOperationSummary.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllOperationSummary,
    (entities: BuyerOperationSummaryData[]): BuyerOperationSummaryData[] => {
      return entities.filter((_: BuyerOperationSummaryData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectOperationSummaryState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectOperationSummaryState,
    (state: State): any => {
      return state.pagination?.[props.identifier];
    }
  );
};

import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxPipesModule } from 'monkey-front-core';
import {
  MonkeyButtonModule,
  MonkeyIconModule,
  MonkeyInputModule,
  MonkeyModalModule
} from 'monkey-style-guide';
import { ClosedToMaintenanceComponent } from './closed-to-maintenance.component';

@NgModule({
  declarations: [ClosedToMaintenanceComponent],
  imports: [
    MonkeyEcxCommonModule,
    MonkeyButtonModule,
    MonkeyIconModule,
    MonkeyInputModule,
    MonkeyModalModule,
    MonkeyEcxPipesModule,
    MonkeyEcxi18nModule
  ],
  exports: [ClosedToMaintenanceComponent]
})
export class ClosedToMaintenanceModule {}

import { MonkeyRequestDownload, MonkeyRequestUpload } from 'app/interfaces';
import { MonkeyRequestItemQueue, MonkeyRequestQueue } from 'app/interfaces/request-queue';
import { MonkeyEcxUtils } from 'monkey-front-core';
import * as Statics from './statics';

export function buildQueuePropertys(queue: MonkeyRequestItemQueue): MonkeyRequestQueue {
  const params: MonkeyRequestQueue = {
    item: {
      ...queue,
      id: MonkeyEcxUtils.getRandomString(40)
    },
    status: Statics.QueueEvents.OnGoing
  };

  return params;
}

export function buildDownloadPropertys(
  name: string,
  description?: string,
  size?: number
): MonkeyRequestDownload {
  const params: MonkeyRequestDownload = {
    item: {
      name,
      description,
      id: `${MonkeyEcxUtils.getRandomString(40)}`,
      size,
      createdAt: new Date()
    },
    status: Statics.QueueEvents.OnGoing
  };

  return params;
}

export function buildUploadPropertys(name: string, description?: string): MonkeyRequestUpload {
  const params: MonkeyRequestUpload = {
    item: {
      name,
      description,
      id: `${MonkeyEcxUtils.getRandomString(40)}`
    },
    status: Statics.UploadEvents.OnGoing
  };

  return params;
}

export function hasMonkeyServiceAndHandlingProperties(context: any): boolean {
  // if (
  //   Object.prototype.hasOwnProperty.call(context, 'monkeyecxService') &&
  //   Object.prototype.hasOwnProperty.call(context, 'monkeyHandlingService')
  // ) {
  //   return true;
  // }
  // return false;
  return Object.prototype.hasOwnProperty.call(context, 'monkeyecxService');
}

import { Component, ElementRef, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedConfirmChangeComponent } from 'app/shared/components/modal/confirm-change';
import { environment } from 'environments/environment';
import { MonkeyStyleGuideModalService } from 'monkey-style-guide';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnvironmentComponent implements OnInit {
  private i18n: any;

  _env = environment.environment;

  constructor(
    private elementRef: ElementRef,
    private modalService: MonkeyStyleGuideModalService,
    private translateService: TranslateService
  ) {
    // not to do
  }

  ngOnInit() {
    if (environment.production) {
      this.elementRef.nativeElement.remove();
    } else {
      this.translateService
        .getStreamOnTranslationChange(['SCREENS.ENVIRONMENT', 'BUTTONS'])
        .subscribe((translations: any) => {
          this.i18n = translations;
        });
    }
  }

  @HostListener('click')
  onClick() {
    const { modalService, i18n, _env } = this;
    modalService.open(SharedConfirmChangeComponent, {
      action: {
        confirm: {
          action: () => {},
          actionColor: 'theme',
          actionType: 'primary',
          label: i18n?.BUTTONS['GOT-IT']
        }
      },
      data: {
        title: i18n['SCREENS.ENVIRONMENT']?.TITLE,
        description: i18n['SCREENS.ENVIRONMENT'][`${_env}-MESSAGE`.toUpperCase()]
      },
      hideClose: false,
      size: 'sm'
    });
  }
}

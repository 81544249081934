import { GeneralSummarySellerData } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const generalSummaryActions = {
  load: createAction('[Seller GeneralSummary] Load', props<{ identifier: string; url: string }>()),
  updateOne: createAction(
    '[Seller GeneralSummary] Upadate One',
    props<{ data: GeneralSummarySellerData }>()
  ),
  updateControl: createAction('[Seller GeneralSummary] Update Control', props<{ data: any }>())
};

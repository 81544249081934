import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyButtonModule, MonkeyIconModule } from 'monkey-style-guide';
import { EnvironmentModule } from '../environment/environment.module';
import { ToolbarSellerCheckoutComponent } from './toolbar-seller-checkout.component';

@NgModule({
  declarations: [ToolbarSellerCheckoutComponent],
  imports: [
    RouterModule,
    MonkeyEcxCommonModule,
    MonkeyEcxi18nModule,
    MonkeyEcxPipesModule,
    EnvironmentModule,
    MonkeyButtonModule,
    MonkeyIconModule
  ],
  exports: [ToolbarSellerCheckoutComponent]
})
export class ToolbarSellerCheckoutModule {}

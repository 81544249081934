<section class="header">
  <div class="group">
    <span class="title">{{ 'SHARED.COMPANIES.TITLE' | translate }}</span>
    <monkey-button type="tertiary" icon="close-12" color="theme" size="sm"
      (click)="onHandleToggleSidebarOpened('navbar-companies')"></monkey-button>
  </div>
  <app-navbar-companies-filter (onChangeFilter)="onHandleChangeFilter($event)">
  </app-navbar-companies-filter>
</section>

<section class="body">
  <div class="companies" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
    [scrollWindow]="false" (scrolled)="onHandleScrollDown()">
    <ng-container *ngIf="(_selected$ | async) as selected">
      <ng-container *ngIf="(_data$ | async) | dataSearch: _filter: _filterFunc as data">
        <div *ngFor="let company of data" class="company"
          [class.selected]="selected?.id === company?.id"
          [class.approval]="company.status === 'APPROVAL'">
          <div class="data">
            <span class="title">{{ company.name }}</span>
            <span class="sub-title">{{ company.governmentId | monkeyecxFormatDocument }}</span>
            <span class="type">
              {{ 'SIDEBAR-ACTIONS.PROFILE.TYPES.' + company?.type | translate }}
            </span>
          </div>
          <monkey-button type="secondary" color="theme" (click)="onHandleNavigate($event, company)"
            size="md">
            {{ 'BUTTONS.GET-IN' | translate }}
          </monkey-button>
        </div>
        <ng-container *ngIf="data?.length; else noData">
          <ng-container *ngIf="(_pagination$ | async) as pagination">
            <monkey-button type="tertiary" color="theme" *ngIf="pagination?.next"
              (click)="_paginationService.execute('next')">
              {{ 'BUTTONS.LOAD-MORE' | translate }}
            </monkey-button>
          </ng-container>
        </ng-container>
        <ng-template #noData>
          <div class="box-no-data">
            <span class="title-no-data">{{ 'SHARED.COMPANIES.NOT-FOUND' | translate }}</span>
            <monkey-button type="primary" color="success" [routerLink]="['/app/register/profiles']">
              {{ 'SCREENS.PROFILE.NEW-COMPANY.TITLE' | translate }}
            </monkey-button>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</section>
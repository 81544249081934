import { MonkeyNavigation } from 'app/interfaces';

export const navigation: MonkeyNavigation[] = [
  {
    description: 'Home',
    icon: 'home-24',
    id: 'home',
    role: ['ADMIN', 'EXECUTE', 'VIEW', 'SELECTOR'],
    title: 'Home',
    translate: 'NAVIGATION.HOME.TITLE',
    translateDescription: 'NAVIGATION.HOME.DESCRIPTION',
    type: 'item',
    url: '/app/seller/home'
  },
  {
    description: 'Receivables',
    icon: 'money-sign-24',
    id: 'receivables',
    role: ['ADMIN', 'EXECUTE'],
    title: 'Receivables',
    translate: 'NAVIGATION.RECEIVABLES.TITLE',
    translateDescription: 'NAVIGATION.RECEIVABLES.DESCRIPTION',
    type: 'item',
    url: '/app/seller/receivables'
  },
  {
    description: 'Offers',
    icon: 'book-24',
    id: 'offers',
    role: ['ADMIN', 'EXECUTE', 'VIEW', 'SELECTOR', 'BACK_OFFICE'],
    title: 'Offers',
    translate: 'NAVIGATION.OFFERS.TITLE',
    translateDescription: 'NAVIGATION.OFFERS.DESCRIPTION',
    type: 'item',
    url: '/app/seller/offers'
  },
  {
    description: 'Bank Accounts',
    icon: 'wallet-24',
    id: 'bank-accounts',
    role: ['ADMIN'],
    title: 'Bank Accounts',
    translate: 'NAVIGATION.BANK-ACCOUNTS.TITLE',
    translateDescription: 'NAVIGATION.BANK-ACCOUNTS.DESCRIPTION',
    type: 'item',
    url: '/app/seller/bank-accounts'
  },
  {
    description: 'Assignments',
    icon: 'document-24',
    id: 'assignments',
    role: ['ADMIN', 'EXECUTE', 'VIEW', 'BACK_OFFICE', 'SELECTOR'],
    title: 'Assignments',
    translate: 'NAVIGATION.ASSIGNMENTS.TITLE',
    translateDescription: 'NAVIGATION.ASSIGNMENTS.DESCRIPTION',
    type: 'item',
    url: '/app/seller/assignments'
  },
  {
    description: 'Adhesion Terms',
    icon: 'clipboard-24',
    id: 'adhesion-terms',
    role: ['ADMIN', 'EXECUTE', 'SELECTOR'],
    title: 'Adhesion Terms',
    translate: 'NAVIGATION.ADHESION-TERMS.TITLE',
    translateDescription: 'NAVIGATION.ADHESION-TERMS.DESCRIPTION',
    type: 'item',
    url: '/app/seller/adhesion-terms'
  }
];

import { HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  MonkeyEcxAuthCredentials,
  MonkeyEcxConfig,
  MonkeyEcxLinksModel,
  MonkeyEcxUtils
} from 'monkey-front-core';
import { parseUrl } from 'query-string';
import { first } from 'rxjs/operators';

export const timezoneOffset = moment().format('Z');

export function pageReposition(rootName?: string) {
  const container3 = document.getElementById('container-3');
  if (container3) container3.scrollTop = 0;
  if (rootName) {
    const root = document.getElementsByTagName(rootName).item(0);
    if (root) root.scrollTop = 0;
    const rootById = document.getElementById(rootName);
    if (rootById) rootById.scrollTop = 0;
  }
}

export function getDate(tp: any): string {
  const dateFormat = tp || 'dd/mm/yyyy';
  const ret = moment(Date.now()).format(dateFormat);
  return ret;
}

export function formatDate(date: string | Date, showTime?: false, format?: any): string {
  if (!MonkeyEcxUtils.persistNullEmptyUndefined(date)) return '';
  if (!format) {
    format = '- HH:mm';
  }
  let stillUtc: any = moment.utc(date).toDate();
  if (date.toString()?.indexOf(':') <= -1) {
    stillUtc = date;
    showTime = false;
  }
  const formatFrom = `YYYY/MM/DD${showTime ? ` ${format}` : ''}`;
  const formatTo = `DD/MM/YYYY${showTime ? ` ${format}` : ''}`;
  return `${moment(stillUtc, formatFrom).format(formatTo)}`;
}

export function compareStartToEndDates(
  startDate: any,
  endDate: any,
  type: string,
  withPartialTime = false,
  withFullTime = false
) {
  if (!startDate || !endDate) return false;
  const formatFullTime = 'HH:mm:ss';

  if (startDate.toString()?.indexOf(':') <= -1 || endDate.indexOf(':') <= -1) {
    withPartialTime = false;
    withFullTime = false;
  }

  const formatStartDate = `YYYY-MM-DD${
    withPartialTime || withFullTime ? ` ${formatFullTime}` : ''
  }`;
  const formatEndDate = `YYYY-MM-DD${withPartialTime || withFullTime ? ` ${formatFullTime}` : ''}`;

  const newStartDate = moment(startDate, formatStartDate);
  const newEndDate = moment(endDate, formatEndDate);

  return newStartDate[type](newEndDate);
}

export function getDocumentType(governmentId: string) {
  let documentType = MonkeyEcxUtils.getDocumentType(governmentId);
  if (environment.country === 'cl') documentType = 'CNPJ';
  return documentType;
}

export function arrayMerge(a: any[], b: any[], fieldToCompare: string) {
  const merged = _.merge(_.keyBy(a || [], fieldToCompare), _.keyBy(b || [], fieldToCompare));
  return _.values(merged);
}

export function isAndroid() {
  const userAgent = navigator?.userAgent?.toLowerCase();
  return userAgent?.includes('android');
}

export function isIOS() {
  const userAgent = navigator?.userAgent?.toLowerCase();
  const isApple = [
    'iPhone',
    'iPad',
    'iPod',
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator'
  ].includes(`${userAgent}`);
  return isApple;
}

export function paddLeft(value: number): string {
  value = value || 0;
  const length = `${value}`?.length;
  return length === 1 ? `0${value}` : `${value}`;
}

export function urlHasField(url: string, field: string) {
  const obj: any = parseUrl(url);
  return !!obj?.query?.[field];
}

export function buildIdByLink<T>(item: T, field: string = 'identifier'): string {
  const actionId = new MonkeyEcxLinksModel(item)?.getAction('self');
  const id = actionId?.href?.split('/')?.pop();

  return `${item[field]}-${id}`;
}

export function isSameOrigin(path: string): boolean {
  const initialPath: string = window.location.origin;
  return (initialPath || '') === path;
}

export function getLoginAppRedirect(param: MonkeyEcxAuthCredentials, config: MonkeyEcxConfig) {
  const { host } = window.location;
  const app = environment.apps.login;
  const params = new HttpParams()
    .append('username', param.username)
    .append('program', `${config?.program?.token}` || 'SANDBOX')
    .append('redirect_uri', `https://${host}/sso`)
    .toString();

  let path = `${app}/login-sso?${params}`;
  if (isSameOrigin(app)) {
    path = `/login-fed/${param.username}`;
  }

  return {
    isSameOrigin: isSameOrigin(app),
    path
  };
}

export function accessExternalApp(url: string, data: any, openBlank = true) {
  const inputContent = document.createElement('input');
  inputContent.name = 'monkey-token';
  inputContent.type = 'hidden';
  inputContent.value = data;

  const form = document.createElement('form');
  form.method = 'POST';
  if (openBlank) {
    form.target = '_blank';
  }
  form.action = url;
  form.enctype = 'multipart/form-data';
  form.style.display = 'none';
  form.appendChild(inputContent);
  document.body.appendChild(form);
  form.submit();
}

export function firstResult() {
  return first((val: any) => {
    return !!val && JSON.stringify(val) !== '{}';
  });
}

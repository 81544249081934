import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { TemplateType } from 'app/core/utils/template-type';
import { TemplateConfigurationsData } from 'app/interfaces';
import { LinksModel } from 'app/model';
import * as FileSaver from 'file-saver';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxServiceDownload,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class TemplateConfigurationsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertService: MonkeyEcxAlertsService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.templateConfigurationsActions.updateControl({
        data
      })
    );
  }

  private update(data: TemplateConfigurationsData, identifier: string) {
    this.store.dispatch(
      actions.templateConfigurationsActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404, 403]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;
    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<TemplateConfigurationsData>(url).toPromise();

      this.update(data, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleUpdateTemplate(
    data: TemplateConfigurationsData,
    identifier: string,
    url: string,
    typeHttp: 'PUT' | 'POST'
  ) {
    const { monkeyecxService, alertService } = this;

    this.updateControl({ isLoading: true });

    try {
      await monkeyecxService[typeHttp.toLowerCase()]<any>(`${url}`, data).toPromise();

      alertService.show({
        duration: 2000,
        message: typeHttp === 'POST' ? 'OPERATION' : 'UPDATE-MESSAGE',
        title: 'SUCCESS',
        type: 'success'
      });

      this.loadData(url, identifier);
    } catch (e) {
      this.updateControl({ isLoading: false });
    }
  }

  private async handleDeleteCustomTemplate(
    data: TemplateConfigurationsData,
    identifier: string,
    url: string,
    typeHttp: 'PUT' | 'POST'
  ) {
    const { monkeyecxService } = this;

    let href = data?.links['csv-file']?.href;

    if (!href) {
      href = data?.links['cnab-file']?.href;
    }

    this.updateControl({ isLoading: true });

    try {
      if (href) {
        await monkeyecxService.delete<any>(`${href}`).toPromise();
      }

      this.handleUpdateTemplate(data, identifier, url, typeHttp);
    } catch (e) {
      this.updateControl({ isLoading: false });
    }
  }

  @MonkeyEcxServiceDownload('SERVICES.DOWNLOAD.FILE', '', {
    requestInProgress: {
      showProgress: false
    }
  })
  private async handleDownload(data: TemplateConfigurationsData) {
    const { monkeyecxService } = this;

    let { href } = new LinksModel({ _links: data.links }).getAction('default-csv-file');
    let templateName = `template${TemplateType.CSV}`;

    if (!href) {
      href = data.links['default-cnab-file'].href;
      templateName = `template${TemplateType.CNAB}`;
    }

    this.updateControl({ isLoading: true });

    try {
      const { blob, filename } = await monkeyecxService
        .downloadOb<any>(`${href}`, this.getHTTPHeaderApplicationPDF(), templateName)
        .toPromise();

      FileSaver.saveAs(blob, filename);
    } catch (e) {
      // not to do
    }
    this.updateControl({ isLoading: false });
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }

  public updateTemplate(
    data: TemplateConfigurationsData,
    identifier: string,
    url: string,
    typeHttp: 'PUT' | 'POST'
  ) {
    if (typeHttp === 'POST') {
      this.handleUpdateTemplate(data, identifier, url, typeHttp);
    } else {
      this.handleDeleteCustomTemplate(data, identifier, url, typeHttp);
    }
  }

  public download(data: TemplateConfigurationsData) {
    this.handleDownload(data);
  }
}

import { BuyerSellersSponsorsData } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { MonkeyEcxPage, MonkeyEcxPaginationData } from 'monkey-front-core';

export const featureKey = 'buyer-sellers-details-sponsors';

export interface State extends EntityState<BuyerSellersSponsorsData> {
  control: {
    isLoading: boolean;
  };
  links?: MonkeyEcxPaginationData;
  page?: MonkeyEcxPage;
  error: any;
}

const entity = createEntityAdapter<BuyerSellersSponsorsData>({
  selectId: (item: BuyerSellersSponsorsData) => {
    return item.sponsorGovernmentId;
  }
});

export const adapter: EntityAdapter<BuyerSellersSponsorsData> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  links: null,
  page: null,
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.buyerSellersDetailsSponsors.updateAll, (state, action) => {
    return adapter.upsertMany([...action.data], {
      ...state
    });
  }),
  on(actions.buyerSellersDetailsSponsors.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.buyerSellersDetailsSponsors.updateLinks, (state, action) => {
    return {
      ...state,
      links: {
        ...action.data
      }
    };
  }),
  on(actions.buyerSellersDetailsSponsors.updatePage, (state, action) => {
    return {
      ...state,
      page: {
        ...state.page,
        ...action.data
      }
    };
  }),
  on(actions.buyerSellersDetailsSponsors.clear, (state: any) => {
    const handled = adapter.removeAll(state);
    handled.page = null;
    handled.links = null;
    handled.control = { isLoading: false };

    return handled;
  })
);

export const { selectAll } = adapter.getSelectors();

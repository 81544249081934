import { BuyerSellersData } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxCommonsActions } from 'monkey-front-core';

export const api = {
  download: createAction('[Buyer Sellers Details] Download', props<{ data: BuyerSellersData }>()),
  updateRisk: createAction(
    '[Buyer Sellers Details] Update Risk',
    props<{ data: BuyerSellersData; risk: string }>()
  ),
  updateStatus: createAction(
    '[Buyer Sellers Details] Update Status',
    props<{ data: BuyerSellersData; statusType: string }>()
  )
};

export const { load, updateControl, updateOne, clear } =
  MonkeyEcxCommonsActions.getActions<BuyerSellersData>('Buyer Sellers Details');

import { MonkeyEcxUtils } from 'monkey-front-core';

export class UserModel {
  username: string;

  firstName: string;

  lastName: string;

  cellPhone: string;

  nickname: string;

  smsNotification: boolean;

  locale: string;

  privacyPolicy: boolean;

  useTerms: boolean;

  newsletter: boolean;

  others: string;

  constructor(data?: any) {
    const first = MonkeyEcxUtils.cutFirstLastName(`${data.name}`, 'first');
    const last = MonkeyEcxUtils.cutFirstLastName(`${data.name}`, 'last');

    this.username = data?.username;
    this.firstName = data?.firstName || first;
    this.lastName = data?.lastName || last;
    this.cellPhone = data.cellPhone || '';
    this.nickname = data.nickname;
    this.smsNotification = data.smsNotification;
    this.locale = data.locale;
    this.privacyPolicy = data?.privacyPolicy;
    this.useTerms = data?.useTerms;
    this.newsletter = data?.newsletter;
    this.others = data?.others;
  }

  public toJSON() {
    return {
      name: `${this.firstName} ${this.lastName}`,
      email: `${this.username}`,
      cellPhone: this.cellPhone ? `${this.cellPhone}` : null,
      nickname: `${this.nickname}`,
      smsNotification: this.smsNotification,
      locale: this.locale,
      privacyPolicy: this.privacyPolicy,
      useTerms: this.useTerms,
      newsletter: this.newsletter,
      others: this.others
    };
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { GeneralSummaryData } from 'app/interfaces';
import { sponsor } from '../../actions';

export const featureKey = 'sponsor-general-summary';

export interface State extends EntityState<GeneralSummaryData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

export const adapter: EntityAdapter<GeneralSummaryData> = createEntityAdapter<GeneralSummaryData>({
  selectId: (item: GeneralSummaryData) => {
    return item.identifier;
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(sponsor.generalSummaryActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(sponsor.generalSummaryActions.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data, id: action.data?.identifier },
      {
        ...state
      }
    );
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { buyer as interfaces } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxCommonsActions } from 'monkey-front-core';

const featureName = 'Buyer Dashboard Filter Sponsor';

export const selectSponsor = createAction(
  `[${featureName}] Select Sponsor`,
  props<{ data: interfaces.FilterSponsorData }>()
);
export const clearSponsorSelected = createAction(`[${featureName}] Clear Sponsor Selected`);

export const { clear, load, updateAll, updateControl, updatePage, updateLinks, loadPagination } =
  MonkeyEcxCommonsActions.getActions<interfaces.FilterSponsorData>(featureName);

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction('[MECX Shared EventHistory] Clear All', props<{ identifier: string }>());
const create = createAction(
  '[MECX Shared EventHistory] Create',
  props<{ data: interfaces.EventHistory }>()
);
const load = createAction(
  '[MECX Shared EventHistory] Load',
  props<{ url: string; identifier: string }>()
);
const loadPagination = createAction(
  '[MECX Shared EventHistory Pagination] Load',
  props<{ pagination: interfaces.Pagination; identifier: string }>()
);
const remove = createAction(
  '[MECX Shared EventHistory] Delete',
  props<{ id: string; identifier: string }>()
);
const updateAll = createAction(
  '[MECX Shared EventHistory] Update All',
  props<{ data: interfaces.EventHistory[] }>()
);
const updateControl = createAction(
  '[MECX Shared EventHistory] Update Control',
  props<{ data: any }>()
);
const updateOne = createAction(
  '[MECX Shared EventHistory] Update One',
  props<{ data: interfaces.EventHistory }>()
);
const updatePagination = createAction(
  '[MECX Shared EventHistory] Update Pagination',
  props<{ data: any }>()
);

export {
  clear,
  create,
  load,
  loadPagination,
  remove,
  updateAll,
  updateControl,
  updateOne,
  updatePagination,
};

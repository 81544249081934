<div class="navbar-top" [ngClass]="'monkey-default-50'">
  <div class="d-flex flex-row" *ngIf="!_isFolded; else unfolded">
    <div class="buttons hidden-xs hidden-sm">
      <monkey-button color="theme" icon="menu-24" type="tertiary" (click)="toggleSidebarFolded()">
      </monkey-button>
    </div>
    <div class="logo" [routerLink]="['/user/profile/companies']">
      <img class="logo-icon" [src]="_config?.theme?.logos?.toolbar" />
    </div>
  </div>
  <ng-template #unfolded>
    <div class="d-flex flex-row">
      <div class="buttons hidden-xs hidden-sm">
        <monkey-button color="theme" icon="menu-24" type="tertiary" (click)="toggleSidebarFolded()">
        </monkey-button>
      </div>
    </div>
  </ng-template>
</div>
<div class="navbar-scroll-container" [ngClass]="'monkey-default-400'">
  <div class="navbar-content">
    <app-navigation layout="vertical"></app-navigation>
  </div>
</div>

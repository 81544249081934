import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyButtonModule, MonkeyIconModule } from 'monkey-style-guide';
import { EnvironmentModule } from '../environment/environment.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { ToolbarBuyerComponent } from './toolbar-buyer.component';

@NgModule({
  declarations: [ToolbarBuyerComponent],
  imports: [
    MonkeyButtonModule,
    MonkeyEcxCommonModule,
    MonkeyIconModule,
    MonkeyEcxPipesModule,
    MonkeyButtonModule,
    MonkeyIconModule,
    EnvironmentModule,
    NotificationsModule,
    RouterModule,
    MonkeyEcxi18nModule
  ],
  exports: [ToolbarBuyerComponent]
})
export class ToolbarBuyerModule {}

import { environment } from 'environments/environment';
import * as moment from 'moment-timezone';

export class ConfigTz {
  countryTzName: string;

  constructor() {
    this.countryTzName = environment.countryTzName;
    moment.tz.setDefault(this.countryTzName);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { BaseComponent } from 'monkey-front-components';
import { merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from './navigation.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent extends BaseComponent implements OnInit {
  @Input() layout = 'vertical';

  @Input() navigation: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigation = this.navigation || this.navigationService.getCurrentNavigation();

    this.navigationService._onNavigationChanged
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe(() => {
        this.navigation = this.navigationService.getCurrentNavigation();
        this.changeDetectorRef.markForCheck();
      });

    merge(
      this.navigationService._onNavigationItemAdded,
      this.navigationService._onNavigationItemUpdated,
      this.navigationService._onNavigationItemRemoved
    )
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }
}

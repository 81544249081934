import { createAction, props } from '@ngrx/store';
import { Company, Pagination } from 'app/interfaces';

export const companiesActions = {
  load: createAction('[Shared Companies] Start', props<{ url: string }>()),
  select: createAction(
    '[Shared Companies] Select Company',
    props<{ id: string; url: string; option?: string }>()
  ),
  updateOne: createAction('[Shared Companies] Update One', props<{ data: Company }>()),
  updatePagination: createAction('[Shared Companies] Update Pagination', props<{ data: any }>()),
  updateControl: createAction('[Shared Companies] Update Control', props<{ data: any }>()),
  updateAll: createAction('[Shared Companies] Update All', props<{ data: Company[] }>()),
  delete: createAction('[Shared Companies] Delete', props<{ data: Company }>()),
  clear: createAction('[Shared Companies] Clear All')
};

export const companiesPaginationActions = {
  load: createAction('[Shared CompaniesPagination] Load', props<{ pagination: Pagination }>())
};

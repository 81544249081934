<monkey-modal [config]="config" [modalRef]="modalRef">
  <div class="scroll" cdkScrollable>
    <section class="box-header">
      <div class="title-muted font-size-xs">
        <span class="beta">BETA</span>
        <span>{{ 'MECX-SHARED.SCREENS.DYNAMIC-REPORT.SUB-TITLE' | translate }}</span>
      </div>
    </section>
    <div class="box">
      <div class="template">
        <span class="title-data font-size-md">
          {{ 'MECX-SHARED.SCREENS.DYNAMIC-REPORT.TEMPLATE.TITLE' | translate }}
        </span>
        <span class="title-muted explanation">
          {{ 'MECX-SHARED.SCREENS.DYNAMIC-REPORT.TEMPLATE.SUB-TITLE' | translate }}
        </span>
        <ng-container *ngIf="_control$ | async as control">
          <ng-container *ngIf="!control.isLoading; else loading">
            <div class="d-flex flex-column d-flex-gap-2" *ngIf="_template$ | async as data">
              <div class="align-items-start d-flex card" *ngFor="let item of data.details">
                <monkey-checkbox
                  [description]="item.description"
                  [value]="item.selected"
                  (click)="onChangeOption($event, data.identifier, item.columnId, true)"
                >
                </monkey-checkbox>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="config">
        <span class="title-data font-size-md">
          {{ 'MECX-SHARED.SCREENS.DYNAMIC-REPORT.CONFIG.TITLE' | translate }}
        </span>
        <span class="title-muted explanation">
          {{ 'MECX-SHARED.SCREENS.DYNAMIC-REPORT.CONFIG.SUB-TITLE' | translate }}
        </span>
        <ng-container *ngIf="_control$ | async as control">
          <ng-container *ngIf="!control.isLoading; else loading">
            <ng-container *ngIf="_data$ | async as data">
              <div
                class="d-flex flex-column d-flex-gap-2 drag-list"
                cdkDropList
                (cdkDropListDropped)="onDrop($event, data.identifier, data.details)"
              >
                <div
                  class="align-items-start d-flex card move"
                  cdkDrag
                  *ngFor="let item of data.details"
                >
                  <monkey-icon icon="drag-drop-24"></monkey-icon>
                  <monkey-checkbox
                    [description]="item.description"
                    [value]="item.selected"
                    (click)="onChangeOption($event, data.identifier, item.columnId, false)"
                  >
                  </monkey-checkbox>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <ng-template #loading>
      <div class="d-flex flex-column d-flex-gap-2">
        <div class="align-items-start d-flex card" *ngFor="let item of [1, 2, 3]">
          <div class="loading"></div>
        </div>
      </div>
    </ng-template>
  </div>
</monkey-modal>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'monkey-front-components';
import { MonkeyEcxConfigBootstrap, MonkeyEcxConfigService } from 'monkey-front-core';
import { takeUntil } from 'rxjs/operators';
import { SidebarService } from '../sidebar/sidebar.service';

@Component({
  selector: 'app-sidebar-actions',
  templateUrl: './sidebar-actions.component.html',
  styleUrls: ['./sidebar-actions.component.scss']
})
export class SidebarActionsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  mainAction: {
    icon: string;
    route: string;
    feature?: string;
  } = null;

  @Input() companyType: string;

  @Input() routeTo: string;

  _isFolded = true;

  _configBootstrap: MonkeyEcxConfigBootstrap = null;

  constructor(
    private configService: MonkeyEcxConfigService,
    private sidebarService: SidebarService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    const { configService, sidebarService } = this;

    configService.bootstrapConfig().subscribe((_: MonkeyEcxConfigBootstrap) => {
      this._configBootstrap = _;
    });

    sidebarService
      .getSidebar('navbar')
      .foldedChanged.pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((val: any) => {
        this._isFolded = val;
      });
  }

  ngOnDestroy() {
    this.__unsubscribeAll.next();
    this.__unsubscribeAll.complete();
  }

  goToPage() {
    const { routeTo, router } = this;

    if (routeTo) {
      router.navigate([routeTo]);
    }
  }
}

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const load = createAction(
  '[MECX Shared SignaturesDetails] Start',
  props<{ url: string; identifier: string }>()
);
const download = createAction(
  '[MECX Shared SignaturesDetails] Download',
  props<{ data: interfaces.SignaturesData; typeDocument: string }>()
);
const updateControl = createAction(
  '[MECX Shared SignaturesDetails] Update Control',
  props<{ data: any }>()
);
const updateOne = createAction(
  '[MECX Shared SignaturesDetails] Update One',
  props<{ data: interfaces.SignaturesData }>()
);

export { load, download, updateOne, updateControl };

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction('[MECX Shared Signatures] Clear All', props<{ identifier: string }>());
const load = createAction(
  '[MECX Shared Signatures] Start',
  props<{ url: string; identifier: string }>()
);
const loadWaiting = createAction(
  '[MECX Shared Signatures] Start Waiting',
  props<{ url: string; identifier: string }>()
);
const genericAction = createAction(
  '[MECX Shared Signatures] Generic Action',
  props<{ data: interfaces.GenericActionData }>()
);
const updateAll = createAction(
  '[MECX Shared Signatures] Update All',
  props<{ data: interfaces.SignaturesData[] }>()
);
const updateControl = createAction(
  '[MECX Shared Signatures] Update Control',
  props<{ data: any }>()
);
const updatePagination = createAction(
  '[MECX Shared Signatures] Update Pagination',
  props<{ data: any }>()
);
const paginationLoad = createAction(
  '[MECX Shared Signatures Pagination] Load',
  props<{ pagination: interfaces.Pagination; identifier: string }>()
);

export {
  clear,
  loadWaiting,
  genericAction,
  load,
  paginationLoad,
  updateAll,
  updateControl,
  updatePagination,
};

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { reportConfigurations as actions } from '@shared-store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class MECXSharedDynamicReportExportService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  public async export(reportType: string, callback: Function) {
    const { store, tokenStorage } = this;
    const { companyType, companyId } = await tokenStorage.getToken();

    store.dispatch(
      actions.reportConfigurations.load({
        reportType,
        companyType,
        companyId,
        action: 'export',
        callback
      })
    );
  }
}

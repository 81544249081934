import { createAction, props } from '@ngrx/store';
import { BuyerOperationSimulation, BuyerOperationSupplierSummariesData } from 'app/interfaces';

export const operationSimulationActions = {
  start: createAction(
    '[Buyer OperationSimulation] Start OperationSimulation',
    props<{
      sponsorId: number;
      minimumDaysToOffer: number;
      supplier: BuyerOperationSupplierSummariesData;
    }>()
  ),
  create: createAction(
    '[Buyer OperationSimulation] Create OperationSimulation',
    props<{ data: BuyerOperationSimulation }>()
  ),
  updateSimulationData: createAction(
    '[Buyer OperationSimulation] Update OperationSimulation SimulationData',
    props<{ data: any }>()
  ),
  updateSimulationHeader: createAction(
    '[Buyer OperationSimulation] Update OperationSimulation SimulationHeader',
    props<{ data: any }>()
  ),
  delete: createAction(
    '[Buyer OperationSimulation] Delete OperationSimulation',
    props<{ data: BuyerOperationSimulation }>()
  ),
  recalcPaymentDateMinMax: createAction(
    '[Buyer OperationSimulation] Recalc OperationSimulation PaymentDateMinMax',
    props<{
      id: string;
      action: string;
      args: {
        daysFrom?: number;
        daysTo?: number;
        startDate?: string;
        endDate?: string;
      };
    }>()
  ),
  clear: createAction('[Buyer OperationSimulation] Clear All OperationSimulation')
};

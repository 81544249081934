/* eslint-disable no-console */
import { Pipe, PipeTransform } from '@angular/core';
import { filterFunction, SearchFilters } from 'app/core/utils';

@Pipe({
  name: 'dataSearch'
})
export class DataSearchPipe implements PipeTransform {
  transform(value: any[], filter: string, type: SearchFilters): any {
    if (!value) return [];

    if (!type) {
      console.error('type param is required');
      return [];
    }

    const func = filterFunction[type];

    if (!func) {
      console.error(`Search function (${type}) does't exists`);
      return [];
    }

    if (!filter) return value;

    return value?.filter((_: any) => {
      return func(_, filter);
    });
  }
}

import { Injectable } from '@angular/core';
import { MonkeyCompanyPendencies, MonkeyCompanyPendenciesValidated } from 'app/interfaces';
import { CompanyModel } from 'app/profile/companies/providers';
import { MonkeyEcxCommonsService, MonkeyEcxService } from 'monkey-front-core';

@Injectable({
  providedIn: 'root'
})
export class SponsorPendenciesService extends MonkeyEcxCommonsService {
  private pendencies: MonkeyCompanyPendencies = null;

  constructor(monkeyecxService: MonkeyEcxService) {
    super(monkeyecxService);
  }

  validate(company: CompanyModel, callback: Function) {
    const { _links } = company;
    const actions = {
    };

    Object.entries(_links).forEach(([key, value]) => {
      if (actions[key]) {
        actions[key](value);
      }
    });
  }

  public hasPendencies(type: string): MonkeyCompanyPendenciesValidated {
    const { pendencies } = this;
    return {
      hasPendencies: !!pendencies?.[type],
      ...pendencies?.[type]
    };
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buildIdByLink } from 'app/core/utils/utils';
import { InvoiceData, PaginationData } from 'app/interfaces';
import { buyer } from '../../actions';

export const featureKey = 'buyer-invoices';

export interface State extends EntityState<InvoiceData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<InvoiceData> = createEntityAdapter<InvoiceData>({
  selectId: (item: InvoiceData) => {
    const { identifier, invoiceId } = item;
    return `${identifier}-${invoiceId}`;
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(buyer.invoicesActions.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(buyer.invoicesActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(buyer.invoicesActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(buyer.invoicesActions.updateOne, (state, action) => {
    const id = buildIdByLink<InvoiceData>(action.data);
    return adapter.upsertOne(
      { ...action.data, id },
      {
        ...state
      }
    );
  }),
  on(buyer.invoicesActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { createAction, props } from '@ngrx/store';
import { BuyerOperationBankAccounts, Pagination } from 'app/interfaces';

export const operationBankAccountsActions = {
  clear: createAction('[Buyer OperationBankAccounts] Clear All'),
  load: createAction(
    '[Buyer OperationBankAccounts] Load',
    props<{ url: string; identifier: string }>()
  ),
  addOne: createAction(
    '[Buyer OperationBankAccounts] Add One',
    props<{ data: BuyerOperationBankAccounts }>()
  ),
  updateAll: createAction(
    '[Buyer OperationBankAccounts] Update All',
    props<{ data: BuyerOperationBankAccounts[] }>()
  ),
  updateControl: createAction(
    '[Buyer OperationBankAccounts] Update Control',
    props<{ data: any }>()
  ),
  updatePagination: createAction(
    '[Buyer OperationBankAccounts] Update Pagination',
    props<{ data: any }>()
  )
};

export const operationBankAccountsPaginationActions = {
  load: createAction(
    '[Buyer OperationBankAccountsPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { buyer as interfaces } from '@interfaces';
import { buyer as reducers } from '@store/reducers';
import { MonkeyEcxCommonsSelectors } from 'monkey-front-core';
import { createSelector } from '@ngrx/store';

export const { selectLinks, selectState, selectAll, selectControl, selectPage } =
  MonkeyEcxCommonsSelectors.getSelectors<interfaces.FilterSponsorData>(
    reducers.fromDashboard.fromFilterSponsor
  );

export const selectSponsorFilter = createSelector(selectState, (state: any): string | null => {
  return state.sponsorSelected;
});

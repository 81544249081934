import { NgModule } from '@angular/core';
import {
  MonkeyButtonModule,
  MonkeyIconModule,
  MonkeyInputModule,
  MonkeyModalModule
} from 'monkey-style-guide';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { VersionChangedComponent } from './version-changed.component';

@NgModule({
  declarations: [VersionChangedComponent],
  imports: [
    MonkeyEcxCommonModule,
    MonkeyButtonModule,
    MonkeyIconModule,
    MonkeyInputModule,
    MonkeyModalModule,
    MonkeyEcxi18nModule
  ],
  exports: [VersionChangedComponent]
})
export class VersionChangedModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CompanyModel } from 'app/profile/companies/providers';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import {
  MonkeyStyleGuideModalFixedService,
  MonkeyStyleGuideSnackbarService
} from 'monkey-style-guide';
import { Observable } from 'rxjs';
import { BuyerPendenciesService } from './buyer-pendencies.service';
import { SellerPendenciesService } from './seller-pendencies.service';
import { SponsorPendenciesService } from './sponsor-pendencies.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyPendenciesService extends MonkeyEcxCommonsService implements Resolve<any> {
  private modalRef: any;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorageService: MonkeyEcxTokenStorageService,
    private buyerPendencyService: BuyerPendenciesService,
    private modalService: MonkeyStyleGuideModalFixedService,
    private sellerPendencyService: SellerPendenciesService,
    private sponsorPendencyService: SponsorPendenciesService,
    private translateService: TranslateService,
    private monkeySnackbar: MonkeyStyleGuideSnackbarService,
    private router: Router
  ) {
    super(monkeyecxService, tokenStorageService);
  }

  private addToQueue(pendency: any) {
    const { message } = pendency;
    const found = this.__data.find((_) => {
      return _.message === message;
    });
    if (pendency?.message && !found) {
      this.__data.push(pendency);

      this.openModal();
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async getData() {
    const token = await this.tokenStorage.getToken();
    const companyId = token?.companyId;

    this.monkeyecxService
      .get(`${environment.baseUrl}/uaa/v2/profiles/${companyId}`)
      .subscribe((resp: CompanyModel) => {
        this.getPendencies(resp);
      });
  }

  private getPendencies(company: CompanyModel) {
    const { type } = company;

    this[`${type.toLowerCase()}PendencyService`].validate(company, this.addToQueue.bind(this));
  }

  private openModal() {
    const { __i18n, __data, router, monkeySnackbar } = this;
    const { message, href } = (__data || [])[0];
    monkeySnackbar.show({
      title: __i18n?.['SERVICES.WARNING']?.['COMPANY-PENDENCIES']?.TITLE,
      message: __i18n?.['SERVICES.WARNING']?.['COMPANY-PENDENCIES']?.[message],
      type: 'warning',
      keepOpen: true,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      action: {
        cancel: {
          style: 'button',
          action: () => {},
          label: __i18n?.BUTTONS?.CLOSE
        },
        confirm: {
          style: 'button',
          action: () => {
            router.navigate([href]);
          },
          label: __i18n?.BUTTONS?.FIX
        }
      }
    });
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const { translateService } = this;

    super.resolve(route, state, {
      callbackMain: () => {
        this.__data = [];

        this.getData();
      },
      translateOptions: {
        keys: ['BUTTONS', 'SERVICES.WARNING'],
        service: translateService
      }
    });

    return null;
  }
}

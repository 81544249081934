import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { shared as actions } from '@shared-store/actions';

export const featureKey = 'mecx-shared-persistent-schedule';

export interface State extends EntityState<interfaces.PersistentScheduleData> {}

export const adapter: EntityAdapter<interfaces.PersistentScheduleData> =
  createEntityAdapter<interfaces.PersistentScheduleData>({
    selectId: (item: interfaces.PersistentScheduleData) => {
      return item.identifier;
    }
  });

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,
  on(actions.persistentSchedule.add, (state, action) => {
    const data = {
      createAt: new Date(),
      ...action.data
    };
    return adapter.addOne(data, {
      ...state
    });
  }),
  on(actions.persistentSchedule.remove, (state, action) => {
    return adapter.removeOne(action.identifier, state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { urlHasField } from '@utils';
import { PayablesData } from 'app/interfaces';
import { sponsor } from 'app/store/reducers';
import { parseUrl } from 'query-string';

declare type State = sponsor.fromPayables.State;

export const selectPayablesState = createFeatureSelector<sponsor.fromPayables.State>(
  sponsor.fromPayables.featureKey
);

export const selectAllPayables = createSelector(
  selectPayablesState,
  sponsor.fromPayables.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(selectAllPayables, (entities: PayablesData[]): PayablesData[] => {
    const result = entities.filter((_: PayablesData) => {
      return _.identifier === props.identifier;
    });

    return result;
  });
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(selectPayablesState, (state: State): any => {
    return state?.pagination?.[props.identifier];
  });
};

export const selectControl = () => {
  return createSelector(selectPayablesState, (state: State): any => {
    return state.control;
  });
};

export const selectPage = (props: { identifier: string }) => {
  return createSelector(selectPayablesState, (state: State): any => {
    return state.page?.[props.identifier];
  });
};

export const paginationHasDifference = (props: { identifier: string; url: string }) => {
  return createSelector(selectPayablesState, (state: State): any => {
    const { pagination } = state;
    const { identifier, url } = props;
    let hasDifference = false;
    let hasField = false;

    try {
      const handlePaginationUrl = parseUrl(`${pagination?.[identifier]?.url}`);
      const handleUrl = parseUrl(`${url}`);
      hasDifference =
        handlePaginationUrl?.query?.search !== handleUrl?.query?.search ||
        handlePaginationUrl?.query?.sort !== handleUrl?.query?.sort;
      hasField = urlHasField(url, 'search') || urlHasField(url, 'sort');
    } catch (e) {
      // not to do
    }

    return {
      hasDifference,
      hasField
    };
  });
};

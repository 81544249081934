import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedStates } from 'app/interfaces';
import { shared } from 'app/store/reducers';

export const selectStatesState = createFeatureSelector<shared.fromStates.State>(
  shared.fromStates.featureKey
);

export const selectAll = createSelector(selectStatesState, shared.fromStates.selectAll);

export const exists = () => {
  return createSelector(
    selectAll,
    (entities: SharedStates[]): boolean => {
      return !!entities?.length;
    }
  );
};

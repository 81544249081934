import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { shared as actions } from 'app/store/actions';
import { shared as services } from 'app/store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class MeEffects {
  start$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.meActions.start),
        map(() => {
          this.service.start();
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.MeService) {
    // not to do
  }
}

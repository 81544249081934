import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SharedBanks } from 'app/interfaces';
import { sponsor } from '../../actions';

export const featureKey = 'sponsor-banks';

export interface State extends EntityState<SharedBanks> {
  error: any;
}

export const adapter: EntityAdapter<SharedBanks> = createEntityAdapter<SharedBanks>({
  sortComparer: null,
  selectId: (item: SharedBanks) => {
    return item.value;
  }
});

export const initialState: State = adapter.getInitialState({
  error: null
});

export const reducer = createReducer(
  initialState,
  on(sponsor.banksActions.create, (state, action) => {
    return adapter.setAll(action.data, {
      ...state
    });
  }),
  on(sponsor.banksActions.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedBanks } from 'app/interfaces';
import { sponsor } from 'app/store/reducers';

export const selectBanksState = createFeatureSelector<sponsor.fromBanks.State>(
  sponsor.fromBanks.featureKey
);

export const selectAll = createSelector(selectBanksState, sponsor.fromBanks.selectAll);

export const exists = () => {
  return createSelector(
    selectAll,
    (entities: SharedBanks[]): boolean => {
      return !!entities?.length;
    }
  );
};

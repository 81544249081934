import { Component, OnDestroy, OnInit } from '@angular/core';
import { SponsorMarketService } from 'app/core/services/market';
import { MarketModel } from 'app/model';
import { BaseComponent } from 'monkey-front-components';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar-sponsor-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class ToolbarSponsorMarketComponent extends BaseComponent implements OnInit, OnDestroy {
  _isLoading = true;

  _marketModel: MarketModel;

  constructor(private monkeyMarketService: SponsorMarketService) {
    super();
  }

  ngOnInit() {
    const { monkeyMarketService } = this;
    monkeyMarketService.__onDataChanged$.pipe(takeUntil(this.__unsubscribeAll)).subscribe(() => {
      const data = monkeyMarketService.__data;
      if (data) {
        this._marketModel = new MarketModel(data);
      }
    });

    monkeyMarketService.__onLoadingInProgress$
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((val: boolean) => {
        this._isLoading = val;
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy(false);
  }
}

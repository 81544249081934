import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { SupplierData } from 'app/interfaces';
import { SupplierModel } from 'app/model';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class SuppliersDetailsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertService: MonkeyEcxAlertsService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private handleLoadData(data: SupplierModel, identifier: string) {
    const { href } = data.getAction('self');

    if (href) {
      this.loadData(href, identifier);
    }
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.suppliersDetailsActions.updateControl({
        data
      })
    );
  }

  private update(data: SupplierData, identifier: string) {
    this.store.dispatch(
      actions.suppliersDetailsActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<SupplierData>(url).toPromise();

      this.update(new SupplierModel(data), identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleUpdateSupplier(data: SupplierModel, identifier: string) {
    const { monkeyecxService, alertService } = this;
    const { href, type } = data.getAction('supplier-update');

    this.updateControl({ isLoading: true });

    try {
      await monkeyecxService[type.toLowerCase()]<any>(`${href}`, data).toPromise();

      alertService.show({
        duration: 2000,
        message: 'UPDATE-MESSAGE',
        title: 'SUCCESS',
        type: 'success'
      });

      this.handleLoadData(data, identifier);
    } catch (e) {
      this.updateControl({ isLoading: false });
    }
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }

  public updateSupplier(data: SupplierModel, identifier: string) {
    this.handleUpdateSupplier(data, identifier);
  }
}

import { SellingSummariesData } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const sellingSummariesActions = {
  clear: createAction('[Seller SellingSummaries] Clear All', props<{ identifier: string }>()),
  load: createAction(
    '[Seller SellingSummaries] Load',
    props<{ url: string; identifier: string }>()
  ),
  updateAll: createAction(
    '[Seller SellingSummaries] Update All',
    props<{ data: SellingSummariesData[] }>()
  ),
  updateControl: createAction('[Seller SellingSummaries] Update Control', props<{ data: any }>())
};

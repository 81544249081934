import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuyerOperationCheckout } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

declare type State = buyer.fromOperationCheckout.State;

export const selectOperationCheckoutState = createFeatureSelector<buyer.fromOperationCheckout.State>(
  buyer.fromOperationCheckout.featureKey
);

export const selectAllOperationCheckout = createSelector(
  selectOperationCheckoutState,
  buyer.fromOperationCheckout.selectAll
);

export const selectById = (props: { id: string }) => {
  return createSelector(
    selectAllOperationCheckout,
    (entities: BuyerOperationCheckout[]): BuyerOperationCheckout => {
      return entities.find((_: any) => {
        return _.id === props.id;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectOperationCheckoutState,
    (state: State): any => {
      return state?.control;
    }
  );
};

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navbar-companies-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class NavbarCompaniesFilterComponent {
  @Input() isLoading = false;

  @Output() onChangeFilter = new EventEmitter<any>();

  _search = '';

  constructor() {
    // not to do
  }

  onSearchFilter(event: string) {
    this._search = event;
    if (!event) {
      this.onChangeFilter.next('');
      return;
    }
    event = `${event}`.toLowerCase();

    this.onChangeFilter.next(event);
  }
}

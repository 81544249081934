import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/core/services/auth';
import { Company, Pagination } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { shared as actions } from 'app/store/actions';
import { shared as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class CompaniesActionsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store,
    private router: Router,
    private authService: AuthService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: Company[]) {
    const { store } = this;
    store.dispatch(actions.companiesActionsActions.updateAll({ data }));
  }

  private updatePagination(data: any) {
    const { store } = this;

    store.dispatch(
      actions.companiesActionsActions.updatePagination({
        data
      })
    );
  }

  private updateControl(data: any) {
    const { store } = this;

    store.dispatch(
      actions.companiesActionsActions.updateControl({
        data
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  public async loadData(url: string, updatePagination = true) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoading: true });

    const data = await monkeyecxService.get<any>(url).toPromise();

    const items = this.getEmbeddedData(data, 'companies');
    const { _links } = data;

    this.update(items);
    if (updatePagination) {
      this.updatePagination({
        ..._links,
        url
      });
    }

    this.updateControl({ isLoading: false });
  }

  private async handlePageData(pagination: Pagination) {
    const { store } = this;

    const data = await store
      .select(selectors.companiesActions.selectPagination())
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href);
    }
  }

  private async verifyBeforeStart(url: string, updatePagination = true) {
    const { store } = this;

    const data = await store
      .select(selectors.companiesActions.exists())
      .pipe(take(1))
      .toPromise();

    if (data) return;

    this.loadData(url, updatePagination);
  }

  private async handleSelectedCompany(
    id: string,
    companyType: string,
    url: string,
    option?: string
  ) {
    const { store, router, monkeyecxService, tokenStorage, authService } = this;

    let data = await store
      .select(selectors.companiesActions.selectCompanyById({ id, companyType }))
      .pipe(take(1))
      .toPromise();

    if (!data) {
      data = await monkeyecxService.get<any>(`${url}/${id}`).toPromise();
      this.update([data]);
    }
    const { status, type } = data;

    if (status === 'ACTIVE') {
      tokenStorage.setToken({
        companyId: data?.id,
        governmentId: data?.governmentId,
        companyType: data?.type,
        programAdmin: data?.programAdmin,
        role: data?.role
      });

      if (option) {
        router.navigate([`/app/${type}/${option}`.toLowerCase()]);
      }
    } else {
      authService.clearProfileCredentials();
      this.router.navigate(['/app/profile/company-approval'], {
        state: {
          companyData: data
        }
      });
    }
  }

  public async load(url: string) {
    const { store } = this;

    const { hasDifference } = await store
      .select(selectors.companiesActions.paginationHasDifference({ url }))
      .pipe(take(1))
      .toPromise();

    this.verifyBeforeStart(url, hasDifference);
  }

  public loadPage(pagination: Pagination) {
    this.handlePageData(pagination);
  }

  public select(id: string, companyType: string, url: string, option?: string) {
    this.handleSelectedCompany(id, companyType, url, option);
  }
}

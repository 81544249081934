import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sponsor as actions } from '@store/actions';
import { sponsor as services } from '@store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class TemplateConfigurationsEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.templateConfigurationsActions.load),
        map(({ url, identifier }) => {
          this.service.load(url, identifier);
        })
      );
    },
    {
      dispatch: false
    }
  );

  updateTemplate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.templateConfigurationsActions.updateTemplate),
        map(({ data, identifier, url, typeHttp }) => {
          this.service.updateTemplate(data, identifier, url, typeHttp);
        })
      );
    },
    {
      dispatch: false
    }
  );

  downloadTemplate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.templateConfigurationsActions.download),
        map(({ data }) => {
          this.service.download(data);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.TemplateConfigurationsService) {
    // not to do
  }
}

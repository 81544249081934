import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { spike as actions } from '@shared-store/actions';

export const featureKey = 'mecx-spike-shared-recomposition-conciliation';

export interface State extends EntityState<interfaces.RecompositionConciliation> {
  control: {
    isLoading: boolean;
  };
  error: interfaces.RecompositionConciliation;
}

export const adapter: EntityAdapter<interfaces.RecompositionConciliation> =
  createEntityAdapter<interfaces.RecompositionConciliation>({
    selectId: (item: interfaces.RecompositionConciliation) => {
      const { id, identifier } = item;
      return `${identifier}-${id}`;
    }
 });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.conciliations.recomposition.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.conciliations.recomposition.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

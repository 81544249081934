import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserGuidingService } from '@auth/app/core/services/user-guiding';
import { EffectsModule } from '@ngrx/effects';
import {
  MECXSharedFakeDataAlertModule,
  MECXSharedFakeDataAlertService
} from '@shared/app/components';
import { StatesService } from 'app/core/services/address';
import { AlertsService, GenericAlertsService } from 'app/core/services/alerts';
import { BanksService } from 'app/core/services/bank';
import { CompaniesService } from 'app/core/services/company';
import { MarketService, SponsorMarketService } from 'app/core/services/market';
import { MeService } from 'app/core/services/me';
import { NotificationsService } from 'app/core/services/notifications';
import { CompanyPendenciesService } from 'app/core/services/pendencies';
import { SellerLayoutComponent } from 'app/layouts/seller/seller-layout.component';
import { SellerLayoutModule } from 'app/layouts/seller/seller-layout.module';
import * as effects from 'app/store/effects';
import { shared as services } from 'app/store/services';
import { MonkeyEcxAuthGuard, MonkeyEcxAuthGuardCompany } from 'monkey-front-core';
import { BuyerLayoutComponent } from './layouts/buyer/buyer-layout.component';
import { BuyerLayoutModule } from './layouts/buyer/buyer-layout.module';
import { ProfileLayoutComponent } from './layouts/profile/profile-layout.component';
import { SellerCheckoutLayoutComponent } from './layouts/seller-checkout/seller-checkout-layout.component';
import { SellerCheckoutLayoutModule } from './layouts/seller-checkout/seller-checkout-layout.module';
import { SponsorLayoutComponent } from './layouts/sponsor/sponsor-layout.component';
import { SponsorLayoutModule } from './layouts/sponsor/sponsor-layout.module';
import { SharedGenericAlertsModule } from './shared/components';

@NgModule({
  imports: [
    BuyerLayoutModule,
    RouterModule.forRoot([
      {
        path: '',
        children: [
          {
            path: '',
            redirectTo: '/authentication/email/login',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'authentication',
        loadChildren: () => {
          return import('@auth/app/main/main.module').then((m: any) => {
            return m.MainModule;
          });
        }
      },
      {
        path: 'user',
        children: [
          {
            path: 'profile',
            loadChildren: () => {
              return import('@auth/app/profile').then((m: any) => {
                return m.ProfileModule;
              });
            },
            canActivate: [MonkeyEcxAuthGuard],
            resolve: {
              MeService
            },
            runGuardsAndResolvers: 'always'
          }
        ]
      },
      {
        path: 'app',
        children: [
          {
            path: '',
            redirectTo: '/app/profile/companies',
            pathMatch: 'full'
          },
          {
            path: 'profile',
            loadChildren: () => {
              return import('app/profile/profile.module').then((m: any) => {
                return m.ProfileModule;
              });
            },
            resolve: {
              AlertsService,
              MeService
            },
            canActivate: [MonkeyEcxAuthGuard],
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'sponsor',
            component: SponsorLayoutComponent,
            loadChildren: () => {
              return import('app/sponsor/sponsor.module').then((m: any) => {
                return m.SponsorModule;
              });
            },
            resolve: {
              AlertsService,
              BanksService,
              CompaniesService,
              CompanyPendenciesService,
              MeService,
              SponsorMarketService,
              StatesService,
              NotificationsService
            },
            canActivate: [MonkeyEcxAuthGuardCompany],
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'buyer',
            component: BuyerLayoutComponent,
            loadChildren: () => {
              return import('app/buyer/buyer.module').then((m: any) => {
                return m.BuyerModule;
              });
            },
            resolve: {
              AlertsService,
              GenericAlertsService,
              BanksService,
              CompaniesService,
              CompanyPendenciesService,
              MeService,
              StatesService,
              NotificationsService
            },
            data: {
              companyType: 'buyer'
            },
            canActivate: [MonkeyEcxAuthGuardCompany],
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'seller',
            component: SellerLayoutComponent,
            loadChildren: () => {
              return import('app/seller/seller.module').then((m: any) => {
                return m.SellerModule;
              });
            },
            resolve: {
              AlertsService,
              GenericAlertsService,
              BanksService,
              CompaniesService,
              CompanyPendenciesService,
              MarketService,
              MeService,
              StatesService,
              NotificationsService
            },
            data: {
              companyType: 'seller'
            },
            canActivate: [MonkeyEcxAuthGuardCompany],
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'seller-checkout',
            component: SellerCheckoutLayoutComponent,
            loadChildren: () => {
              return import('./seller/shopping-checkout').then((m: any) => {
                return m.CheckoutModule;
              });
            },
            canActivate: [MonkeyEcxAuthGuardCompany],
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'register',
            loadChildren: () => {
              return import('app/register/register.module').then((m: any) => {
                return m.RegisterModule;
              });
            },
            resolve: {
              BanksService,
              StatesService
            },
            canActivate: [MonkeyEcxAuthGuard],
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'register-v2',
            children: [
              {
                path: '',
                loadChildren: () => {
                  return import('@register/app/main').then((m: any) => {
                    return m.MainModule;
                  });
                },
                canActivate: [MonkeyEcxAuthGuard]
              },
              {
                path: 'register-migration',
                loadChildren: () => {
                  return import('@register/app/main').then((m: any) => {
                    return m.MainModule;
                  });
                },
                canActivate: [MonkeyEcxAuthGuard]
              },
              {
                path: 'register-information',
                loadChildren: () => {
                  return import('@register/app/main').then((m: any) => {
                    return m.MainModule;
                  });
                },
                canActivate: [MonkeyEcxAuthGuard]
              },
              {
                path: 'register-error',
                loadChildren: () => {
                  return import('@register/app/main').then((m: any) => {
                    return m.MainModule;
                  });
                },
                canActivate: [MonkeyEcxAuthGuard]
              }
            ]
          },
          {
            path: 'notifications',
            component: ProfileLayoutComponent,
            children: [
              {
                path: '',
                loadChildren: () => {
                  return import('app/pages/notifications').then((m: any) => {
                    return m.NotificationsModule;
                  });
                }
              }
            ],
            canActivate: [MonkeyEcxAuthGuard],
            runGuardsAndResolvers: 'always'
          },
          {
            path: 'pages',
            component: ProfileLayoutComponent,
            children: [
              {
                path: '',
                loadChildren: () => {
                  return import('app/pages').then((m: any) => {
                    return m.PagesModule;
                  });
                }
              }
            ]
          }
        ],
        resolve: {
          MECXSharedFakeDataAlertService,
          UserGuidingService
        }
      },
      {
        path: '**',
        redirectTo: 'authentication/email/login'
      }
    ]),
    SellerCheckoutLayoutModule,
    SellerLayoutModule,
    SponsorLayoutModule,
    SharedGenericAlertsModule,
    MECXSharedFakeDataAlertModule,
    EffectsModule.forFeature([effects.shared.MeEffects, effects.userContext.UserContextEffects])
  ],
  providers: [services.MeService],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import { buyer as interfaces } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { buyer as selectors } from '@store/selectors';
import {
  MonkeyEcxCollectionStore,
  MonkeyEcxCommonsStoreBaseService,
  MonkeyEcxPagination,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class FilterSponsorService extends MonkeyEcxCommonsStoreBaseService<
  interfaces.FilterSponsorData,
  MonkeyEcxCollectionStore
> {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    public store: Store
  ) {
    super(monkeyecxService, tokenStorage, store, {
      actions: actions.dashboard.filterSponsor,
      selectors: selectors.dashboard.filterSponsor
    });
  }

  public load(args: any) {
    const { url, identifier } = args;

    this.store.dispatch(actions.dashboard.filterSponsor.clear(identifier));

    this.loadData(url, identifier);
  }

  public loadPage(pagination: MonkeyEcxPagination) {
    this.loadPageData(pagination);
  }

  public async buildSponsorFilter(): Promise<string> {
    let query = 'search=';

    const filterSponsor = await this.store
      .select(selectors.dashboard.filterSponsor.selectSponsorFilter)
      .pipe(take(1))
      .toPromise();

    if (filterSponsor) {
      query += `sponsorGovernmentId:'${filterSponsor}'`;
    }

    return query;
  }
}

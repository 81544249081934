import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { SignaturesData } from 'app/interfaces';
import { AssignmentModel, LinksModel } from 'app/model';
import * as FileSaver from 'file-saver';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxServiceDownload,
  MonkeyEcxTokenStorageService,
  MonkeyEcxUtils
} from 'monkey-front-core';

@Injectable()
export class SignaturesDetailsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.signaturesDetailsActions.updateControl({
        data
      })
    );
  }

  private update(data: SignaturesData, identifier: string) {
    this.store.dispatch(
      actions.signaturesDetailsActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;
    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<SignaturesData>(url).toPromise();

      this.update(new AssignmentModel(data), identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  @MonkeyEcxServiceDownload('SERVICES.DOWNLOAD.FILE', '', {
    requestInProgress: {
      showProgress: false
    }
  })
  private async handleDownload(data: SignaturesData, type = 'ASSIGNMENT') {
    const { monkeyecxService } = this;
    const { href } = new LinksModel(data).getAction('documents');

    this.updateControl({ isLoading: true });

    try {
      const { blob, filename } = await monkeyecxService
        .downloadOb<any>(
          `${href}`.replace('{?type}', `?type=${type}`),
          this.getHTTPHeaderApplicationPDF(),
          `${MonkeyEcxUtils.getRandomString(10)}.pdf`
        )
        .toPromise();

      FileSaver.saveAs(blob, filename);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }

  public download(data: SignaturesData, type: string) {
    this.handleDownload(data, type);
  }
}

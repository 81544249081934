import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { buyer as actions } from 'app/store/actions';
import { buyer as services } from 'app/store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class OperationItemsEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.operationItemsActions.load),
        map(({ url }) => {
          this.service.load(url);
        })
      );
    },
    {
      dispatch: false
    }
  );

  loadPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.operationItemsPaginationActions.load),
        map(({ pagination }) => {
          this.service.loadPage(pagination);
        })
      );
    },
    {
      dispatch: false
    }
  );

  toggle$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.operationItemsActions.toggle),
        map(({ id, data }) => {
          this.service.toggle(id, data);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.OperationItemsService) {
    // not to do
  }
}

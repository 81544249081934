import { Injectable } from '@angular/core';
import { SellingSummariesData } from '@interfaces';
import { Store } from '@ngrx/store';
import { seller as actions } from '@store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class SellingSummariesService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: false
    }
  })
  private async loadData(url: string, identifier: string) {
    this.updateControl({ isLoading: true });

    try {
      const data = await this.monkeyecxService.get<any>(url).toPromise();
      const items = this.getEmbeddedData(data, 'selling-summaries');

      this.update(items, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  private update(data: SellingSummariesData[], identifier: string) {
    this.store.dispatch(
      actions.sellingSummariesActions.updateAll({
        data: data.map((_: SellingSummariesData) => {
          return { ..._, identifier };
        })
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.sellingSummariesActions.updateControl({ data }));
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

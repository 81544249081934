import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BuyerOperationItemData, Pagination } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { buyer as actions } from 'app/store/actions';
import { buyer as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class OperationItemsService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: any[]) {
    this.store.dispatch(
      actions.operationItemsActions.updateAll({
        data
      })
    );
  }

  private updateOne(data: any) {
    this.store.dispatch(
      actions.operationItemsActions.updateOne({
        data
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.operationItemsActions.updateControl({ data }));
  }

  private updateOperationControl(data: any) {
    this.store.dispatch(actions.operationActions.updateControl({ data }));
  }

  private updatePagination(data: any) {
    this.store.dispatch(
      actions.operationItemsActions.updatePagination({
        data
      })
    );
  }

  private async handlePageData(pagination: Pagination) {
    const data = await this.store
      .select(selectors.operationItems.selectPagination())
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string) {
    this.updateControl({ isLoading: true });

    const data = await this.monkeyecxService.get<any>(url).toPromise();
    const items = this.getEmbeddedData(data, 'orders-items');
    const { _links } = data;

    this.update(items);
    this.updatePagination(_links);
    this.updateControl({ isLoading: false });
  }

  private async handleToggle(
    id: string,
    data: BuyerOperationItemData
  ) {
    try {
      const { monkeyecxService, store } = this;

      const model = new LinksModel(data);

      const actionAdd = model.getAction('add');
      const actionRemove = model.getAction('remove');

      const href = actionAdd?.href || actionRemove?.href;
      if (!href || data?.isLoading) return;
      const type = data?.selected ? 'delete' : 'post';

      this.updateOperationControl({ isLoading: true });

      this.updateOne({
        ...data,
        isLoading: true
      });

      await monkeyecxService[type.toLowerCase()]<any>(`${href}`).toPromise();

      this.updateOne({
        ...data,
        selected: type !== 'delete',
        isLoading: false
      });

      store.dispatch(
        actions.operationActions.startSummaryUpdate({
          id
        })
      );
    } catch (e) {
      // not to do
    }
  }

  public load(url: string) {
    this.loadData(url);
  }

  public loadPage(pagination: Pagination) {
    this.handlePageData(pagination);
  }

  public toggle(id: string, data: BuyerOperationItemData) {
    this.handleToggle(id, data);
  }
}

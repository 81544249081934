import { Component, Input, OnInit } from '@angular/core';
import { CompaniesService } from 'app/core/services/company';
import { BaseComponent } from 'monkey-front-components';
import { MonkeyEcxConfigBootstrap, MonkeyEcxConfigService } from 'monkey-front-core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar-actions-options',
  templateUrl: './sidebar-actions-options.component.html',
  styleUrls: ['./sidebar-actions-options.component.scss']
})
export class SidebarActionsOptionsComponent extends BaseComponent implements OnInit {
  @Input() isFolded = false;

  @Input() companyType: string;

  _showMenu = false;

  _configBootstrap: MonkeyEcxConfigBootstrap = null;

  _selected$ = this.companiesService._selected$;

  closeMenu = () => {
    this._showMenu = false;
  };

  constructor(
    private configService: MonkeyEcxConfigService,
    private companiesService: CompaniesService
  ) {
    super();
  }

  ngOnInit(): void {
    this.configService
      .bootstrapConfig()
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((_: MonkeyEcxConfigBootstrap) => {
        this._configBootstrap = _;
      });
  }
}

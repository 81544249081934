import { SummaryBuyersData } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller } from '@store/reducers';

declare type State = seller.fromSummaryBuyers.State;

export const selectSummaryBuyersState = createFeatureSelector<seller.fromSummaryBuyers.State>(
  seller.fromSummaryBuyers.featureKey
);

export const selectAllSummaryBuyers = createSelector(
  selectSummaryBuyersState,
  seller.fromSummaryBuyers.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllSummaryBuyers,
    (entities: SummaryBuyersData[]): SummaryBuyersData[] => {
      return entities.filter((_: SummaryBuyersData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(selectSummaryBuyersState, (state: State): any => {
    return state.control;
  });
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(selectSummaryBuyersState, (state: State): any => {
    return state.pagination?.[props.identifier];
  });
};

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buildIdByLink } from '@utils';
import { BuyerOperationBankAccounts, PaginationData } from 'app/interfaces';
import { buyer } from '../../actions';

export const featureKey = 'buyer-operation-bank-accounts';

export interface State extends EntityState<BuyerOperationBankAccounts> {
  control: {
    isLoading: boolean;
    selectedAccountId: string;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<BuyerOperationBankAccounts> = createEntityAdapter<
  BuyerOperationBankAccounts
>({
  selectId: (item: BuyerOperationBankAccounts) => {
    return buildIdByLink<BuyerOperationBankAccounts>(item);
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null,
    selectedAccountId: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(buyer.operationBankAccountsActions.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(buyer.operationBankAccountsActions.addOne, (state, action) => {
    return adapter.addOne(action.data, {
      ...state
    });
  }),
  on(buyer.operationBankAccountsActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(buyer.operationBankAccountsActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(buyer.operationBankAccountsActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

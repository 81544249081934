interface Link {
  href: string;
  type: string;
}

interface Links {
  items: Link;
  order: Link;
  process: Link;
  'receivement-account': Link;
  seller: Link;
  signature: Link;
}

export class OfferModel {
  account: string;

  accountDigit: string;

  agency: string;

  alias: string;

  approvedSummary: {
    averageTax: number;
    createUserId: string;
    createUserIp: string;
    createdAt: Date;
    lastUserId: string;
    lastUserIp: string;
    paymentValue: number;
    quantity: number;
    receiptValue: number;
    updatedAt: Date;
  };

  bank: string;

  bankName: string;

  createUserId: string;

  createUserIp: string;

  createdAt: Date;

  id: string;

  lastUserId: string;

  lastUserIp: string;

  paymentValue: number;

  receiptValue: number;

  receivementDate: Date;

  sponsorAddress: string;

  sponsorAddressComplement: string;

  sponsorAddressNumber: string;

  sponsorCity: string;

  sponsorCountry: string;

  sponsorGovernmentId: string;

  sponsorName: string;

  sponsorNeighborhood: string;

  sponsorPictureUrl: string;

  sponsorState: string;

  sponsorZipCode: string;

  status: string;

  tax: number;

  unapprovedSummary: {
    averageTax: number;
    createUserId: string;
    createUserIp: string;
    createdAt: Date;
    lastUserId: string;
    lastUserIp: string;
    paymentValue: number;
    quantity: number;
    receiptValue: number;
    updatedAt: Date;
  };

  updatedAt: Date;

  identifier: string;

  _links: Links;

  constructor(data?: any) {
    this.account = data?.account;
    this.accountDigit = data?.accountDigit;
    this.agency = data?.agency;
    this.alias = data?.alias;
    this.approvedSummary = data?.approvedSummary;
    this.bank = data?.bank;
    this.bankName = data?.bankName;
    this.createUserId = data?.createUserId;
    this.createUserIp = data?.createUserIp;
    this.createdAt = data?.createdAt;
    this.lastUserId = data?.lastUserId;
    this.lastUserIp = data?.lastUserIp;
    this.paymentValue = data?.paymentValue;
    this.receiptValue = data?.receiptValue;
    this.receivementDate = data?.receivementDate;
    this.sponsorAddress = data?.sponsorAddress;
    this.sponsorAddressComplement = data?.sponsorAddressComplement;
    this.sponsorAddressNumber = data?.sponsorAddressNumber;
    this.sponsorCity = data?.sponsorCity;
    this.sponsorCountry = data?.sponsorCountry;
    this.sponsorGovernmentId = data?.sponsorGovernmentId;
    this.sponsorName = data?.sponsorName;
    this.sponsorNeighborhood = data?.sponsorNeighborhood;
    this.sponsorPictureUrl = data?.sponsorPictureUrl;
    this.sponsorState = data?.sponsorState;
    this.sponsorZipCode = data?.sponsorZipCode;
    this.status = data?.status;
    this.tax = data?.tax;
    this.unapprovedSummary = data?.unapprovedSummary;
    this.updatedAt = data?.updatedAt;
    this.identifier = data?.identifier;
    this._links = data?._links;

    const actionId: any = this.getAction('self');
    this.id = actionId.href.split('/').pop();
  }

  public getAction(type: string): Link {
    const { _links } = this;
    const link = _links[type.toLowerCase()];
    return link;
  }
}

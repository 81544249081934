import { createAction, props } from '@ngrx/store';
import { BuyerOperationItemData, Pagination } from 'app/interfaces';

export const operationItemsActions = {
  clear: createAction('[Buyer OperationItems] Clear All'),
  delete: createAction('[Buyer OperationItems] Delete', props<{ data: BuyerOperationItemData }>()),
  load: createAction('[Buyer OperationItems] Load', props<{ url: string }>()),
  toggle: createAction(
    '[Buyer OperationItems] Toggle Item',
    props<{
      id: string;
      data: BuyerOperationItemData;
    }>()
  ),
  updateAll: createAction(
    '[Buyer OperationItems] Update All',
    props<{ data: BuyerOperationItemData[] }>()
  ),
  updateControl: createAction('[Buyer OperationItems] Update Control', props<{ data: any }>()),
  updateOne: createAction(
    '[Buyer OperationItems] Update One',
    props<{ data: BuyerOperationItemData }>()
  ),
  updatePagination: createAction('[Buyer OperationItems] Update Pagination', props<{ data: any }>())
};

export const operationItemsPaginationActions = {
  load: createAction('[Buyer OperationItemsPagination] Load', props<{ pagination: Pagination }>())
};

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReportBody, SharedReportParams } from 'app/interfaces';
import * as FileSaver from 'file-saver';
import {
  DecoratorsUtils,
  Link,
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxRequestQueueHandlingService,
  MonkeyEcxRequestSchedule,
  MonkeyEcxRequestScheduleService,
  MonkeyEcxService,
  MonkeyEcxServiceDownload,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { throwError } from 'rxjs';

@Injectable()
export class ReportService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private requestQueueHandlingService: MonkeyEcxRequestQueueHandlingService,
    private requestScheduleService: MonkeyEcxRequestScheduleService,
    private router: Router
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private confirmExportData(data: any, sch: MonkeyEcxRequestSchedule, fileName: string) {
    const { requestScheduleService, requestQueueHandlingService } = this;
    const { step, totalSteps, processed, detail, _links } = data;
    const { queue } = sch;
    requestQueueHandlingService.updateQueueItem(queue, {
      loaded: step,
      total: totalSteps
    });

    let status = 1;
    if (detail || processed) {
      const { file } = _links;
      if (detail) {
        status = 2;
      }

      const action = () => {
        requestQueueHandlingService.removeQueueItem(queue);
        if (processed) {
          this.download(file, fileName);
        }
      };

      requestQueueHandlingService.finishQueueItem(queue, action, status);

      requestScheduleService.removeSchedule(sch);
    }
  }

  @MonkeyEcxServiceDownload('SERVICES.DOWNLOAD.FILE', '', {
    requestInProgress: {
      showProgress: false
    }
  })
  private download(file: any, name: string) {
    const { href } = file;
    const { monkeyecxService } = this;

    this.__onLoadingInProgress$.next(true);

    monkeyecxService
      .downloadOb<any>(`${href}`, this.getHTTPHeaderApplicationPDF(), name || 'report.csv')
      .subscribe(
        ({ blob, filename }) => {
          FileSaver.saveAs(blob, filename);
          this.__onLoadingInProgress$.next(false);
        },
        () => {
          this.__onLoadingInProgress$.next(false);
        }
      );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  public exportData(
    link: Link,
    body: ReportBody,
    route: string,
    routeToGoBack: string,
    fileName: string
  ) {
    const { monkeyecxService, requestQueueHandlingService, requestScheduleService, router } = this;

    this.__onLoadingInProgress$.next(true);

    const { href, type } = link;

    monkeyecxService[type.toLowerCase()]<any>(href, body).subscribe(
      (resp: any) => {
        this.__onLoadingInProgress$.next(false);

        const requestQueue = DecoratorsUtils.buildQueuePropertys({
          namei18n: 'QUEUES.NAME.PURCHASES-EXPORT'
        });

        requestQueueHandlingService.setQueue(requestQueue);

        const {
          _links,
          _links: { process }
        } = resp;

        const sch = requestScheduleService.setSchedule({
          method: 'get',
          url: process.href,
          data: {
            ...{
              _links
            }
          },
          action: (data: any, sch: any) => {
            return this.confirmExportData(data, sch, fileName);
          },
          queue: requestQueue
        });

        router.navigate([route], {
          state: {
            downloadData: {
              id: sch.id,
              routeToGoBack
            }
          }
        });
      },
      (err: any) => {
        this.__onLoadingInProgress$.next(false);
        return throwError(err);
      }
    );
  }

  public start(args: SharedReportParams) {
    const { link, body, route, routeToGoBack, fileName } = args;

    this.exportData(link, body, route, routeToGoBack, fileName);
  }
}

import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { NotificationsService } from 'app/core/services/notifications';
import { SellerPendenciesService } from 'app/core/services/pendencies';
import { BaseComponent } from 'monkey-front-components';
import { NavigationService } from '../components/navigation/navigation.service';
import { navigation } from './seller-navigation';

@Component({
  selector: 'app-seller-layout',
  templateUrl: './seller-layout.component.html',
  styleUrls: ['./seller-layout.component.scss']
})
export class SellerLayoutComponent extends BaseComponent implements OnDestroy {
  navigation: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private navigationService: NavigationService,
    private platform: Platform,
    private monkeyNotificationsService: NotificationsService,
    private monkeyPendenciesService: SellerPendenciesService
  ) {
    super();

    this.navigation = navigation;

    this.navigationService.register('main-seller', this.navigation);
    this.navigationService.setCurrentNavigation('main-seller');

    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }
  }

  ngOnDestroy() {
    this.navigationService.unregister('main-seller');
  }
}

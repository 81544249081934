import { createAction, props } from '@ngrx/store';
import { PayablesData } from 'app/interfaces';

export const payablesDetailsActions = {
  load: createAction(
    '[Sponsor PayablesDetails] Start',
    props<{ url: string; identifier: string }>()
  ),
  updateControl: createAction('[Sponsor PayablesDetails] Update Control', props<{ data: any }>()),
  updateOne: createAction('[Sponsor PayablesDetails] Update One', props<{ data: PayablesData }>())
};

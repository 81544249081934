import { createAction, props } from '@ngrx/store';
import { Pagination, SupplierData } from 'app/interfaces';

export const suppliersActions = {
  clear: createAction('[Sponsor Suppliers] Clear All', props<{ identifier: string }>()),
  load: createAction('[Sponsor Suppliers] Start', props<{ url: string; identifier: string }>()),
  updateAll: createAction('[Sponsor Suppliers] Update All', props<{ data: SupplierData[] }>()),
  updateControl: createAction('[Sponsor Suppliers] Update Control', props<{ data: any }>()),
  updatePagination: createAction('[Sponsor Suppliers] Update Pagination', props<{ data: any }>())
};

export const suppliersPaginationActions = {
  load: createAction(
    '[Sponsor Suppliers] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxUtils
} from 'monkey-front-core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/** Deprecated */
@Injectable({
  providedIn: 'root'
})
export class AddressSearchService extends MonkeyEcxCommonsService {
  private route: string = `${environment.urlAddressSearchOld}`;

  constructor(monkeyecxService: MonkeyEcxService) {
    super(monkeyecxService);
  }

  private mappingData(resp: any) {
    const embedded = this.getEmbeddedData(resp, 'results') || [];
    return embedded.map((emb: any) => {
      let { zipCode } = emb;
      zipCode = MonkeyEcxUtils.handleOnlyNumbers(zipCode);
      return {
        ...emb,
        zipCode
      };
    });
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404, 403, 500, 503, 502]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  public getData(address: string, country = 'Brasil'): Observable<any> {
    const { monkeyecxService, route } = this;
    if (!address) return of(null);

    const { lang } = environment.locale;
    this.__onLoadingInProgress$.next(true);

    const url = `${route}?address=${address}&country=${country}&lang=${lang}`;
    return monkeyecxService.get<any>(url).pipe(
      map((resp: any) => {
        this.__onLoadingInProgress$.next(false);
        return this.mappingData(resp);
      }),
      catchError(() => {
        this.__onLoadingInProgress$.next(false);
        return of(null);
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyButtonModule } from 'monkey-style-guide';
import { SharedButtonActionComponent } from './button-action.component';

@NgModule({
  declarations: [SharedButtonActionComponent],
  imports: [MonkeyEcxCommonModule, MonkeyButtonModule, MonkeyEcxi18nModule],
  exports: [SharedButtonActionComponent]
})
export class SharedButtonActionModule {}

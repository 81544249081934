import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyIconModule, MonkeyModalModule } from 'monkey-style-guide';
import { SharedConfirmChangeComponent } from './confirm-change.component';

@NgModule({
  declarations: [SharedConfirmChangeComponent],
  exports: [SharedConfirmChangeComponent],
  imports: [MonkeyEcxCommonModule, MonkeyModalModule, MonkeyIconModule]
})
export class SharedConfirmChangeModule {}

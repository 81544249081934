import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { assignments as actions } from '@shared-store/actions';
import { buildIdByLink } from '@shared/app/core/utils/utils';

export const featureKey = 'mecx-shared-signatures';

export interface State extends EntityState<interfaces.SignaturesData> {
  control: {
    isLoading: boolean;
    isLoadingWaiting: boolean;
  };
  error: any;
  pagination?: interfaces.PaginationData;
}

export const adapter: EntityAdapter<interfaces.SignaturesData> =
  createEntityAdapter<interfaces.SignaturesData>({
    selectId: (item: interfaces.SignaturesData) => {
      return buildIdByLink<interfaces.SignaturesData>(item);
    },
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false,
    isLoadingWaiting: false,
  },
  error: null,
  pagination: null,
});

export const reducer = createReducer(
  initialState,
  on(actions.signatures.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state,
    });
  }),
  on(actions.signatures.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data,
      },
    };
  }),
  on(actions.signatures.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data,
      },
    };
  }),
  on(actions.signatures.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const load = createAction(
  '[MECX Shared Recomposition Conciliation] Load',
  props<{ url: string; identifier: string }>()
);
const updateAll = createAction(
  '[MECX Shared Recomposition Conciliation] Update All',
  props<{ data: interfaces.RecompositionConciliation[] }>()
);
const updateOne = createAction(
  '[MECX Shared Recomposition Conciliation] Update One',
  props<{ data: interfaces.RecompositionConciliation }>()
);
const updateControl = createAction(
  '[MECX Shared Recomposition Conciliation] Update Control',
  props<{ data: interfaces.RecompositionConciliation }>()
);
const updateAllWithMap = createAction(
  '[MECX Shared Recomposition Conciliation] Update All With Map',
  props<{ data: interfaces.RecompositionConciliation[] }>()
);

export { load, updateOne, updateAll, updateAllWithMap, updateControl };

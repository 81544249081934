import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buildIdByLink } from 'app/core/utils/utils';
import { BankAccountsData, PaginationData } from 'app/interfaces';
import { seller } from '../../actions';

export const featureKey = 'seller-bank-accounts';

export interface State extends EntityState<BankAccountsData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<BankAccountsData> = createEntityAdapter<BankAccountsData>({
  selectId: (item: BankAccountsData) => {
    return buildIdByLink<BankAccountsData>(item);
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(seller.bankAccountsActions.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(seller.bankAccountsActions.delete, (state, action) => {
    const id = buildIdByLink<BankAccountsData>(action.data);
    return adapter.removeOne(id, state);
  }),
  on(seller.bankAccountsActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(seller.bankAccountsActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(seller.bankAccountsActions.updateOne, (state, action) => {
    const id = buildIdByLink<BankAccountsData>(action.data);
    return adapter.upsertOne(
      { ...action.data, id },
      {
        ...state
      }
    );
  }),
  on(seller.bankAccountsActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxDirectivesModule } from 'monkey-front-core';
import { MonkeyButtonModule } from 'monkey-style-guide';
import { SidebarActionsOptionsModule } from '../sidebar-actions-options/sidebar-actions-options.module';
import { SidebarActionsComponent } from './sidebar-actions.component';

@NgModule({
  declarations: [SidebarActionsComponent],
  imports: [
    MonkeyButtonModule,
    MonkeyEcxCommonModule,
    MonkeyEcxi18nModule,
    MonkeyEcxDirectivesModule,
    SidebarActionsOptionsModule
  ],
  exports: [SidebarActionsComponent]
})
export class SidebarActionsModule {}

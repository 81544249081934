import { MonkeyEcxModel, MonkeyEcxUtils } from 'monkey-front-core';

const amendmentStatusIconsTypes = {
  NOT_REQUIRED: {
    icon: 'clock-16',
    color: '#557a89'
  },
  DECLINED: {
    icon: 'clock-16',
    color: '#fa3838'
  },
  SIGNED: {
    icon: 'check-16',
    color: '#00875a'
  },
  WAITING: {
    icon: 'clock-16',
    color: '#e0bd30'
  },
  EXPIRED: {
    icon: 'clock-16',
    color: '#424040'
  }
};

export class SupplierModel extends MonkeyEcxModel {
  id: string;

  adhesionTermStatus: string;

  allowed: boolean = false;

  allowedValue: number;

  amendmentsStatus: string;

  amountPurchased: any;

  automatedOffer: boolean = false;

  createUserId: string;

  createUserIp: string;

  createdAt: Date;

  lastUserId: string;

  lastUserIp: string;

  sellerLastUpdatedAt: Date;

  sponsorCanOffer: boolean = false;

  sponsorTax: number;

  supplierGovernmentId: string;

  supplierName: string;

  updatedAt: Date;

  amendmentStatusIcons: {
    icon: string;
    color: string;
  };

  constructor(data?: any) {
    super();
    this.adhesionTermStatus = data?.adhesionTermStatus;
    this.allowed = data?.allowed;
    this.allowedValue = data?.allowedValue || 0;
    this.amendmentsStatus = data?.amendmentsStatus;
    this.amountPurchased = data?.amountPurchased;
    this.automatedOffer = data?.automatedOffer;
    this.createUserId = data?.createUserId;
    this.createUserIp = data?.createUserIp;
    this.createdAt = data?.createdAt;
    this.lastUserId = data?.lastUserId;
    this.lastUserIp = data?.lastUserIp;
    this.sellerLastUpdatedAt = data?.sellerLastUpdatedAt;
    this.sponsorCanOffer = data?.sponsorCanOffer;
    this.sponsorTax = data?.sponsorTax;
    this.supplierGovernmentId = data?.supplierGovernmentId;
    this.supplierName = data?.supplierName;
    this.updatedAt = data?.updatedAt;
    this._links = data?._links;

    const actionId: any = this.getAction('self');
    this.id = actionId.href.split('/').pop();
    this.amendmentStatusIcons = amendmentStatusIconsTypes[this.amendmentsStatus];
  }

  public isRegistered() {
    return !!this._links?.seller;
  }

  public getInitials() {
    const { supplierName } = this;
    const first = MonkeyEcxUtils.cutFirstLastName(supplierName, 'first');
    const second = MonkeyEcxUtils.cutFirstLastName(supplierName, 'last');
    return first && second
      ? `${first.substring(0, 1)} ${second.substring(0, 1)}`
      : `${supplierName.substring(0, 2)}`;
  }
}

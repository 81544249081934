import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buildIdByLink } from 'app/core/utils/utils';
import { PaginationData, PayablesData } from 'app/interfaces';
import { sponsor } from 'app/store/actions';
import { MonkeyEcxPage } from 'monkey-front-core';

export const featureKey = 'sponsor-payables';

export interface State extends EntityState<PayablesData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: PaginationData;
  page?: MonkeyEcxPage;
}

export const adapter: EntityAdapter<PayablesData> = createEntityAdapter<PayablesData>({
  selectId: (item: PayablesData) => {
    return buildIdByLink<PayablesData>(item);
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false
  },
  error: null,
  page: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(sponsor.payables.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(sponsor.payables.remove, (state, action) => {
    const id = buildIdByLink<PayablesData>(action.data);

    const { identifier } = action.data;

    const handledPage = state?.page;
    const iden = handledPage?.[identifier];

    let totalElements = iden?.totalElements || 0;
    if (totalElements) totalElements -= 1;
    const page = {
      ...handledPage,
      [identifier]: {
        ...iden,
        totalElements
      }
    };

    return adapter.removeOne(id, { ...state, page });
  }),
  on(sponsor.payables.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  }),
  on(sponsor.payables.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(sponsor.payables.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(sponsor.payables.updatePage, (state, action) => {
    return {
      ...state,
      page: {
        ...state.page,
        ...action.data
      }
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

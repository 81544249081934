import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import {
  MonkeyEcxConfigService,
  MonkeyEcxMeCredentials,
  MonkeyEcxTokenCredentials,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { MonkeyStyleGuideSettingsService } from 'monkey-style-guide';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SupportService {
  private loaded = false;

  private readonly loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private readonly opened$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private tokenStorageService: MonkeyEcxTokenStorageService,
    private configService: MonkeyEcxConfigService,
    private styleGuideSettings: MonkeyStyleGuideSettingsService
  ) {
    // not to do
  }

  public async show() {
    const key = environment.zenDeskKey;
    if (!key) {
      return;
    }

    this.loading$.next(true);
    const { tokenStorageService, styleGuideSettings, configService } = this;

    const me: MonkeyEcxMeCredentials = await tokenStorageService.getMe();
    const token: MonkeyEcxTokenCredentials = await tokenStorageService.getToken();

    const style = await styleGuideSettings.settings().pipe(take(1)).toPromise();
    const config = await configService.config().pipe(take(1)).toPromise();

    const action = () => {
      if (me?.name && me?.username) {
        window.zE('webWidget', 'identify', {
          name: me?.name,
          email: me?.username,
          organization: `Program: ${config?.program?.token}`
        });
      }
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          color: {
            theme: `${style?.colors?.theme?.main}`,
            launcherText: `${style?.colors?.theme.contrast.main}`,
            header: `${style?.colors?.theme.main}`,
            button: `${style?.colors?.success.main}`
          },
          contactForm: {
            fields: [{ id: 1260824679990, prefill: { '*': token?.governmentId } }]
          },
          zIndex: 99,
          offset: {
            horizontal: '-8px',
            vertical: '1px'
          },
          mobile: {
            labelVisible: false
          },
          launcher: {
            mobile: {
              labelVisible: false
            }
          }
        }
      });

      const { lang } = environment.locale;
      window.zE('webWidget', 'setLocale', lang);
      window.zE('webWidget', 'show');
      window.zE('webWidget', 'open');
      window.zE('webWidget:on', 'close', () => {
        this.hide();
      });

      window.zE('webWidget:on', 'userEvent', (event: { action: string }) => {
        try {
          if (event.action === 'Contact Form Shown') {
            const name: any = document.getElementById('webWidget');
            const input = name?.contentWindow?.document?.getElementsByName('key:1260824679990')[0];

            input.disabled = true;
          }
        } catch (error) {
          // not to do
        }
      });

      this.opened$.next(true);

      setTimeout(() => {
        this.loading$.next(false);
      }, 1200);
    };

    if (!this.loaded) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.async = true;
      script.id = 'ze-snippet';
      script.src = `//static.zdassets.com/ekr/snippet.js?key=${key}`;
      script.onload = () => {
        window.zE('webWidget', 'hide');
        this.loaded = true;
        action();
      };
      script.onerror = () => {
        head?.removeChild(script);
      };
      head?.appendChild(script);
    } else {
      action();
    }
  }

  public hide() {
    if (this.loaded) {
      window.zE('webWidget', 'close');
      window.zE('webWidget', 'hide');
    }

    this.opened$.next(false);
  }

  public status(): Observable<boolean> {
    return this.opened$.asObservable();
  }

  public loading(): Observable<boolean> {
    return this.loading$.asObservable();
  }
}

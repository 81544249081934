import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuyerOperationBankAccounts } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

declare type State = buyer.fromOperationBankAccounts.State;

export const selectOperationBankAccountsState =
  createFeatureSelector<buyer.fromOperationBankAccounts.State>(
    buyer.fromOperationBankAccounts.featureKey
  );

export const selectAllOperationBankAccounts = createSelector(
  selectOperationBankAccountsState,
  buyer.fromOperationBankAccounts.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllOperationBankAccounts,
    (entities: BuyerOperationBankAccounts[]): BuyerOperationBankAccounts[] => {
      return entities.filter((_: BuyerOperationBankAccounts) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectBankMainAccount = (props: { identifier: string }) => {
  const { identifier } = props;
  return createSelector(
    selectByIdentifier({ identifier }),
    (entities: BuyerOperationBankAccounts[]): BuyerOperationBankAccounts => {
      return entities.find((_: BuyerOperationBankAccounts) => {
        return _.main;
      }) || entities[0];
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectOperationBankAccountsState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectOperationBankAccountsState,
    (state: State): any => {
      return state.pagination?.[props.identifier];
    }
  );
};

import { Pagination, SummaryBuyersData } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const summaryBuyersActions = {
  load: createAction('[Seller SummaryBuyers] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction(
    '[Seller SummaryBuyers] Update All',
    props<{ data: SummaryBuyersData[] }>()
  ),
  updateControl: createAction('[Seller SummaryBuyers] Update Control', props<{ data: any }>()),
  updatePagination: createAction('[Seller SummaryBuyers] Update Pagination', props<{ data: any }>())
};

export const summaryBuyersPaginationActions = {
  load: createAction(
    '[Seller SummaryBuyersPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  MonkeyButtonModule,
  MonkeyIconModule,
  MonkeyModalModule,
  MonkeyStepperModule
} from 'monkey-style-guide';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { SharedNewsletterComponent } from './newsletter.component';
import { SharedNewsletterService } from './newsletter.service';

@NgModule({
  declarations: [SharedNewsletterComponent],
  exports: [SharedNewsletterComponent],
  imports: [
    MonkeyButtonModule,
    MonkeyEcxCommonModule,
    MonkeyEcxi18nModule,
    MonkeyIconModule,
    MonkeyModalModule,
    MonkeyStepperModule,
    RouterModule
  ],
  providers: [SharedNewsletterService]
})
export class SharedNewsletterModule {}

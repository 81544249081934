import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NotificationsService } from 'app/core/services/notifications';
import { TokenCredentials } from 'app/interfaces';
import { BaseComponent } from 'monkey-front-components';
import { MonkeyEcxTokenStorageService } from 'monkey-front-core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationsComponent extends BaseComponent implements OnInit, OnDestroy {
  _isLoading = true;

  _tokenCredentials: TokenCredentials;

  _unread: number;

  constructor(
    private notificationsService: NotificationsService,
    private tokenStorageService: MonkeyEcxTokenStorageService
  ) {
    super();
  }

  private async handleData() {
    this._tokenCredentials = await this.tokenStorageService.getToken();
  }

  ngOnInit(): void {
    const { notificationsService } = this;

    notificationsService.__onLoadingInProgress$
      .pipe(takeUntil(this.__unsubscribeAll))
      .subscribe((val: boolean) => {
        this._isLoading = val;
      });

    notificationsService.__onDataChanged$.pipe(takeUntil(this.__unsubscribeAll)).subscribe(() => {
      this._unread = notificationsService.__page?.totalElements;
    });

    this.handleData();
  }

  ngOnDestroy() {
    super.ngOnDestroy(false);
  }
}

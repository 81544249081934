import { buyer as interfaces } from '@interfaces';
import { MonkeyEcxCommonsActions } from 'monkey-front-core';
import { createAction, props } from '@ngrx/store';

const featureName = 'Buyer Dashboard';

export const update = createAction(
  `[${featureName}] Update`,
  props<{ data: interfaces.DashboardData }>()
);

export const { updateControl, load } =
  MonkeyEcxCommonsActions.getActions<interfaces.DashboardData>(featureName);

import { NgModule } from '@angular/core';
import { PipesModule } from 'app/core/pipes';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxFormsModule } from 'app/shared/modules/monkeyecx-forms.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxDirectivesModule, MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyButtonModule, MonkeyInputModule } from 'monkey-style-guide';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NavigationModule } from '../navigation/navigation.module';
import { NavbarCompaniesFilterComponent } from './filter';
import { NavbarCompaniesComponent } from './navbar-companies.component';

@NgModule({
  declarations: [NavbarCompaniesComponent, NavbarCompaniesFilterComponent],
  imports: [
    MonkeyEcxCommonModule,
    MonkeyEcxFormsModule,
    MonkeyEcxi18nModule,
    MonkeyEcxDirectivesModule,
    MonkeyEcxPipesModule,
    MonkeyButtonModule,
    NavigationModule,
    MonkeyInputModule,
    InfiniteScrollModule,
    PipesModule
  ],
  exports: [NavbarCompaniesComponent]
})
export class NavbarCompaniesModule {}

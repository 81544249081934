import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { reportConfigurations as actions } from '@shared-store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class MECXSharedDynamicReportModalConfigService extends MonkeyEcxCommonsService {
  _template$ = null;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  public toggle(identifier: string, reportType: string, columnId: any, selected: boolean) {
    this.store.dispatch(
      actions.reportConfigurations.toggle({
        identifier,
        reportType,
        columnId,
        selected
      })
    );
  }

  public order(identifier: string, reportType: string, list: interfaces.ReportConfiguration[]) {
    let order = -1;
    const handled = list?.map((_: interfaces.ReportConfiguration) => {
      order += 1;
      return {
        ..._,
        order
      };
    });

    this.store.dispatch(
      actions.reportConfigurations.order({
        identifier,
        reportType,
        list: handled
      })
    );
  }
}

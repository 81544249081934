import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertsData } from 'app/interfaces';
import { SharedNewsletterComponent } from 'app/shared/components/modal/newsletter/newsletter.component';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCookieStorageService,
  MonkeyEcxMeCredentials,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService,
  MonkeyEcxUtils
} from 'monkey-front-core';
import { MonkeyStyleGuideModalService } from 'monkey-style-guide';
import { Observable } from 'rxjs';
import { AlertsUserService } from './alerts-user.service';

@Injectable({
  providedIn: 'root'
})
export class AlertsService extends MonkeyEcxCommonsService implements Resolve<any> {
  private i18n = null;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private cookieService: MonkeyEcxCookieStorageService,
    private modalService: MonkeyStyleGuideModalService,
    private translateService: TranslateService,
    private alertsUserService: AlertsUserService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private onHandlei18n() {
    if (this.i18n) {
      this.getData();

      return;
    }

    const i18nKeys = ['BUTTONS', 'MODALS.NEWSLETTER', 'SCREENS.TOOLBAR.ALERTS', 'SERVICES.WARNING'];

    this.translateService.onDefaultLangChange.subscribe(() => {
      this.i18n = this.translateService.instant(i18nKeys);
      this.getData();
    });

    this.translateService.getStreamOnTranslationChange(i18nKeys).subscribe(() => {
      this.i18n = this.translateService.instant(i18nKeys);
      this.getData();
    });
  }

  private onHandleSettingsCookie(params: { showedNewsletter?: true; showedMobileApps?: true }) {
    const { cookieService } = this;
    const cookie: string = cookieService.getCookie('monkey-app-settings');
    if (!cookie) {
      cookieService.setCookie(
        'monkey-app-settings',
        btoa(
          JSON.stringify({
            alerts: {
              ...params
            }
          })
        ),
        environment
      );
    } else {
      const decodedCookie = JSON.parse(atob(cookie));

      cookieService.setCookie(
        'monkey-app-settings',
        btoa(
          JSON.stringify({
            ...decodedCookie,
            alerts: {
              ...decodedCookie.alerts,
              ...params
            }
          })
        ),
        environment
      );
    }
  }

  private async getData() {
    const { i18n } = this;
    this.__data = [];

    const actions = {
      newsletter: {
        doCall: async () => {
          const { cookieService } = this;

          const me: MonkeyEcxMeCredentials = await this.tokenStorage.getMe();
          const id: string = MonkeyEcxUtils.getRandomString(30);
          let cookie: string = cookieService.getCookie('monkey-app-settings');
          if (cookie) {
            cookie = atob(cookie);
          }

          if (!me?.newsletter && !JSON.parse(cookie || '{}')?.alerts?.showedNewsletter) {
            const newsletter: AlertsData = {
              buttons: [
                {
                  action: () => {
                    this.modalService.open(SharedNewsletterComponent, {
                      color: 'theme',
                      data: {
                        finish: () => {
                          this.onHandleSettingsCookie({
                            showedNewsletter: true
                          });
                          this.removeItemData(id);
                        },
                        update: (param: any) => {
                          this.updateItemData({
                            id,
                            ...param
                          });
                        }
                      },
                      hideActions: true,
                      hideClose: false,
                      size: 'md',
                      title: i18n?.['MODALS.NEWSLETTER']?.TITLE
                    });
                  },
                  buttonLabel: i18n?.BUTTONS?.['SUBSCRIBE-HERE'],
                  type: 'primary'
                }
              ],
              id,
              text: i18n?.['SCREENS.TOOLBAR.ALERTS']?.NEWSLETTER?.TEXT
            };

            this.setData(newsletter);
          }
        }
      }
    };

    Object.entries(actions).forEach(([key]) => {
      if (actions[key]) {
        actions[key].doCall();
      }
    });
  }

  private removeItemData(id: string): void {
    this.__data = this.__data.filter((item: AlertsData) => {
      return item.id !== id;
    });

    this.__onDataChanged$.next(null);
  }

  private updateItemData(data: AlertsData): void {
    const saved = [...this.__data];

    this.__data = [
      ...saved.map((val: any) => {
        const valSaved = {
          ...val
        };
        if (val.id === data?.id) {
          return {
            ...valSaved,
            ...data
          };
        }
        return valSaved;
      })
    ];

    this.__onDataChanged$.next(null);
  }

  private setData(data: AlertsData): void {
    this.__data = [
      ...this.__data,
      {
        ...data
      }
    ];

    this.__onDataChanged$.next(null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    super.resolve(route, state, {
      callbackMain: () => {
        this.onHandlei18n();
      }
    });

    return null;
  }
}

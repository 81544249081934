import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  MonkeyCompanyPendencies,
  MonkeyCompanyPendenciesOnGoing,
  MonkeyCompanyPendenciesValidated
} from 'app/interfaces';
import { CompanyModel } from 'app/profile/companies/providers';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxFeatureToggleService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable({
  providedIn: 'root'
})
export class BuyerPendenciesService extends MonkeyEcxCommonsService {
  private requestOngoing: MonkeyCompanyPendenciesOnGoing = {
    'bank-accounts': false
  };

  private handledCallback: MonkeyCompanyPendencies = {
    'bank-accounts': {
      message: 'BUYER-BANK-ACCOUNTS',
      href: '/app/buyer/bank-accounts'
    }
  };

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private featureToggleService: MonkeyEcxFeatureToggleService,
    private router: Router
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private isAbleToCallAction(key: string) {
    const { router, handledCallback, requestOngoing } = this;
    const { url } = router;
    return !requestOngoing[key] && !(handledCallback[key].href === url);
  }

  private handleCallback(type: string, found: boolean, callback: Function) {
    const handle = this.handledCallback?.[type];
    let { message } = handle;
    const { href } = handle;
    if (handle) {
      if (found) {
        message = '';
      } else {
        this.__data = {
          ...this.__data,
          [type]: handle
        };
      }
    }
    callback({
      message,
      href
    });
  }

  private async getLocalRole() {
    const tokenCredentials = await this.tokenStorage.getToken();

    if (!tokenCredentials) return '';
    const { role } = tokenCredentials;

    if (tokenCredentials?.programAdmin === 'true' && role === 'ADMIN') {
      return 'PROGRAM_ADMIN';
    }

    return tokenCredentials?.role || '';
  }

  private async validateRole(roles: string[]) {
    const found = roles?.indexOf(await this.getLocalRole());

    let allowed = false;
    if (found > -1) allowed = true;

    if (!roles || roles.length === 0 || (await this.getLocalRole()) === 'PROGRAM_ADMIN')
      allowed = true;
    return allowed;
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [412, 404, 403],
      httpCodeIgnoreRedirect: [403]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private doCall(type: string, href: string, method: string, callback: Function) {
    this.requestOngoing[type] = true;

    this.monkeyecxService[method.toLowerCase()](href).subscribe(
      (resp: any) => {
        this.requestOngoing[type] = false;

        callback(resp);
      },
      () => {
        this.requestOngoing[type] = false;
      }
    );
  }

  validate(company: CompanyModel, callback: Function) {
    const { _links } = company;

    const actions = {
      'bank-accounts': {
        action: (action: any) => {
          const { href, type } = action;

          if (this.featureToggleService.getFlag('BUYER_ACCOUNT_NOT_REQUIRED')) {
            return;
          }

          if (this.hasPendencies('bank-accounts').hasPendencies) {
            this.handleCallback('bank-accounts', false, callback);
            return;
          }

          this.doCall('bank-accounts', href, type, (resp: any) => {
            const embedded = this.getEmbeddedData(resp, 'bank-accounts');
            const paymentAccount = embedded?.find((account: any) => {
              return account.type === 'PAYMENT';
            });
            const receivementAccount = embedded?.find((account: any) => {
              return account.type === 'RECEIVEMENT';
            });

            const found = paymentAccount && receivementAccount;

            this.handleCallback('bank-accounts', !!found, callback);
          });
        },
        roles: ['ADMIN']
      }
    };

    Object.entries(_links).forEach(async ([key, value]) => {
      if (actions[key] && this.isAbleToCallAction(key)) {
        const { roles } = actions[key];
        if (await this.validateRole(roles)) {
          actions[key].action(value);
        }
      }
    });
  }

  public hasPendencies(type: string): MonkeyCompanyPendenciesValidated {
    const { __data } = this;
    return {
      hasPendencies: !!__data?.[type],
      ...__data?.[type]
    };
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { MonkeyModalConfig } from 'monkey-style-guide';

@Component({
  selector: 'shared-shopping-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class SharedLoadingComponent implements OnInit {
  @Input() config: MonkeyModalConfig;

  @Input() modalRef: any;

  _progress$ = null;

  _onClose = () => { };

  _showClose = false;

  ngOnInit() {
    const { progress$, onClose } = this.config?.data || {};
    this._progress$ = progress$;
    if (onClose) {
      this._onClose = onClose;
    }

    setTimeout(() => {
      this._showClose = true;
    }, 5000);
  }

  onHandlePercent(orderProgress: any) {
    if (!orderProgress) return 0;
    const { step, totalSteps } = orderProgress;
    return (step || 0) / (totalSteps || 0);
  }

  onHandleClose() {
    this._onClose();
  }
}

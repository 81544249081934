import { createAction, props } from '@ngrx/store';
import { SellerData } from 'app/interfaces';
import { SupplierModel } from 'app/model';

export const sellerActions = {
  load: createAction(
    '[Sponsor Seller] Start',
    props<{ url: string; supplier: SupplierModel; identifier: string }>()
  ),
  updateControl: createAction('[Sponsor Seller] Update Control', props<{ data: any }>()),
  updateOne: createAction('[Sponsor Seller] Update One', props<{ data: SellerData }>()),
  clear: createAction('[Sponsor Seller] Clear All')
};

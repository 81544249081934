import { ReceivablesData } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller as reducers } from '@store/reducers';
import { urlHasField } from '@utils';
import { parseUrl } from 'query-string';

declare type State = reducers.fromReceivables.State;

export const selectReceivablesState = createFeatureSelector<reducers.fromReceivables.State>(
  reducers.fromReceivables.featureKey
);

export const selectAllReceivables = createSelector(
  selectReceivablesState,
  reducers.fromReceivables.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(selectAllReceivables, (entities: ReceivablesData[]): ReceivablesData[] => {
    return entities.filter((_: ReceivablesData) => {
      return _.identifier === props.identifier;
    });
  });
};

export const selectControl = () => {
  return createSelector(selectReceivablesState, (state: State): any => {
    return state.control;
  });
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(selectReceivablesState, (state: State): any => {
    return state.pagination?.[props.identifier];
  });
};

export const paginationHasDifference = (props: { identifier: string; url: string }) => {
  return createSelector(selectReceivablesState, (state: State): any => {
    const { pagination } = state;
    const { identifier, url } = props;
    let hasDifference = false;
    let hasField = false;

    try {
      const handlePaginationUrl = parseUrl(`${pagination?.[identifier]?.url}`);
      const handleUrl = parseUrl(`${url}`);
      hasDifference =
        handlePaginationUrl?.query?.search !== handleUrl?.query?.search ||
        handlePaginationUrl?.query?.sort !== handleUrl?.query?.sort;
      hasField = urlHasField(url, 'search') || urlHasField(url, 'sort');
    } catch (e) {
      // not to do
    }

    return {
      hasDifference,
      hasField
    };
  });
};

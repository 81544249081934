import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from 'app/core/pipes';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MECXProductsModule } from 'monkey-front-components';
import { MonkeyEcxDirectivesModule, MonkeyEcxPipesModule } from 'monkey-front-core';
import {
  MonkeyBadgeModule,
  MonkeyButtonModule,
  MonkeyIconModule,
  MonkeyInputModule
} from 'monkey-style-guide';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SidebarActionsOptionsMenuCompanyComponent } from './company';
import { FilterComponent } from './company/filter/filter.component';
import { SidebarActionsOptionsMenuComponent } from './menu.component';

@NgModule({
  declarations: [
    SidebarActionsOptionsMenuComponent,
    SidebarActionsOptionsMenuCompanyComponent,
    FilterComponent
  ],
  imports: [
    InfiniteScrollModule,
    MonkeyEcxDirectivesModule,
    MonkeyEcxPipesModule,
    MonkeyButtonModule,
    MonkeyBadgeModule,
    MonkeyIconModule,
    RouterModule,
    MonkeyEcxCommonModule,
    TranslateModule.forChild(),
    MonkeyInputModule,
    PipesModule,
    MECXProductsModule
  ],
  exports: [SidebarActionsOptionsMenuComponent]
})
export class SidebarActionsOptionsMenuModule {}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HandleIdleService } from './handle-idle.service';

@Injectable()
export class IdleInterceptor implements HttpInterceptor {
  constructor(private service: HandleIdleService) {
    // not to do
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        this.service._onStart.next(request);
        return event;
      })
    );
  }
}

import { buyer as interfaces } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { MonkeyEcxPage, MonkeyEcxPaginationData } from 'monkey-front-core';

export const featureKey = 'buyer-dashboard-filter-sponsor';

export interface State extends EntityState<interfaces.FilterSponsorData> {
  control: {
    isLoading: boolean;
  };
  sponsorSelected?: string;
  links?: MonkeyEcxPaginationData;
  page?: MonkeyEcxPage;
  error: any;
}

const entity = createEntityAdapter<interfaces.FilterSponsorData>({
  selectId: (item: interfaces.FilterSponsorData) => {
    return item.governmentId;
  }
});

export const adapter: EntityAdapter<interfaces.FilterSponsorData> = entity;

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  sponsorSelected: null,
  links: null,
  page: null,
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.dashboard.filterSponsor.updateAll, (state, action) => {
    return adapter.upsertMany([...action.data], {
      ...state
    });
  }),
  on(actions.dashboard.filterSponsor.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.dashboard.filterSponsor.updateLinks, (state, action) => {
    return {
      ...state,
      links: {
        ...action.data
      }
    };
  }),
  on(actions.dashboard.filterSponsor.updatePage, (state, action) => {
    return {
      ...state,
      page: {
        ...state.page,
        ...action.data
      }
    };
  }),
  on(actions.dashboard.filterSponsor.clear, (state: any) => {
    const handled = adapter.removeAll(state);
    handled.page = null;
    handled.links = null;
    handled.control = { isLoading: false };

    return handled;
  }),
  on(actions.dashboard.filterSponsor.selectSponsor, (state, action) => {
    return {
      ...state,
      sponsorSelected: action.data.governmentId
    };
  }),
  on(actions.dashboard.filterSponsor.clearSponsorSelected, (state) => {
    return {
      ...state,
      sponsorSelected: null
    };
  })
);

export const { selectAll } = adapter.getSelectors();

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedConfirmChangeModule } from 'app/shared/components/modal/confirm-change';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyIconModule } from 'monkey-style-guide';
import { EnvironmentComponent } from './environment.component';

@NgModule({
  declarations: [EnvironmentComponent],
  imports: [
    RouterModule,
    MonkeyEcxCommonModule,
    MonkeyEcxi18nModule,
    MonkeyIconModule,
    SharedConfirmChangeModule
  ],
  exports: [EnvironmentComponent]
})
export class EnvironmentModule {}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller } from '@store/reducers';
import { OfferReceivementAccountData } from 'app/interfaces';

declare type State = seller.fromOfferReceivementAccount.State;

export const selectOfferReceivementAccountState =
  createFeatureSelector<seller.fromOfferReceivementAccount.State>(
    seller.fromOfferReceivementAccount.featureKey
  );

export const selectAllOfferReceivementAccount = createSelector(
  selectOfferReceivementAccountState,
  seller.fromOfferReceivementAccount.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllOfferReceivementAccount,
    (entities: OfferReceivementAccountData[]): OfferReceivementAccountData => {
      return entities.find((_: OfferReceivementAccountData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(selectOfferReceivementAccountState, (state: State): any => {
    return state.control;
  });
};

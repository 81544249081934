import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { reports } from '../../actions';

export const featureKey = 'mecx-shared-reports';

export interface State extends EntityState<interfaces.Report> {
  error: any;
}

export const adapter: EntityAdapter<interfaces.Report> = createEntityAdapter<interfaces.Report>({
  sortComparer: null
});

export const initialState: State = adapter.getInitialState({
  error: null
});

export const reducer = createReducer(
  initialState,
  on(reports.reports.create, (state, action) => {
    return adapter.setOne(action.data, {
      ...state
    });
  }),
  on(reports.reports.updateControl, (state, action) => {
    return adapter.upsertOne(action.data, {
      ...state
    });
  }),
  on(reports.reports.remove, (state, action) => {
    return adapter.removeOne(action.data.id, state);
  }),
  on(reports.reports.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BuyerOperationSimulation } from 'app/interfaces';
import { LinksModel, ProcessDataModel } from 'app/model';
import { buyer as actions } from 'app/store/actions';
import { buyer as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxRequestSchedule,
  MonkeyEcxRequestScheduleService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { MonkeyStyleGuideModalService } from 'monkey-style-guide';
import { take } from 'rxjs/operators';

@Injectable()
export class OperationService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private router: Router,
    private scheduleService: MonkeyEcxRequestScheduleService,
    private modalService: MonkeyStyleGuideModalService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage, {
      schedule: {
        service: scheduleService,
        options: {
          clearOnChangeRoute: true
        }
      }
    });
  }

  private handleError(message: string) {
    const { router } = this;
    router.navigate(['/app/buyer/checkout-error'], {
      state: {
        errorMessage: message
      }
    });
  }

  private updateControl(data: any) {
    const { store } = this;

    store.dispatch(
      actions.operationActions.updateControl({
        data
      })
    );
  }

  private updateOperationData(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationActions.updateOperationData({
        data: {
          id,
          ...data
        }
      })
    );
  }

  private updateOperationSummaryData(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationActions.updateOperationSummaryData({
        data: {
          id,
          ...data
        }
      })
    );
  }

  private updateProcessData(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationActions.updateProcessData({
        data: {
          id,
          ...data
        }
      })
    );
  }

  private create(id: string, data?: any) {
    const { store } = this;
    store.dispatch(
      actions.operationActions.create({
        data: {
          id,
          ...data,
          recalcData: {
            valueFunding: 0,
            valueSpread: 0,
            valueRebate: 0
          }
        }
      })
    );
  }

  private async onHandleSummaryData(id: string) {
    const { store, monkeyecxService } = this;

    const data = await store
      .select(selectors.operation.selectById({ id }))
      .pipe(take(1))
      .toPromise();

    const { operationData } = data;
    const { href, type } = new LinksModel(operationData).getAction('order-summary');
    this.updateControl({ isLoading: true });

    try {
      const resp = await monkeyecxService[type.toLowerCase()]<any>(href).toPromise();

      this.updateOperationSummaryData(id, {
        ...resp
      });
    } catch (err) {
      const { error } = err;
      const { notifications } = error;
      const message = notifications?.join(', ');
      this.handleError(message);
    }

    this.updateControl({ isLoading: false });
  }

  private async onHandleRecalcData(id: string) {
    const { store, monkeyecxService } = this;

    const data = await store
      .select(selectors.operation.selectById({ id }))
      .pipe(take(1))
      .toPromise();

    const { operationData, recalcData } = data;
    // #TODO poderia ter o link para recalculo
    const type = 'put';
    const { href } = new LinksModel(operationData).getAction('self');
    this.updateControl({ isLoading: true });

    try {
      await monkeyecxService[type.toLowerCase()]<any>(href, {
        ...recalcData
      }).toPromise();

      this.onHandleSummaryData(id);
    } catch (err) {
      const { error } = err;
      const { notifications } = error;
      const message = notifications?.join(', ');
      this.handleError(message);
      this.updateControl({ isLoading: false });
    }
  }

  private async onHandleOperationData(id: string) {
    const { store, monkeyecxService } = this;

    const data = await store
      .select(selectors.operation.selectById({ id }))
      .pipe(take(1))
      .toPromise();

    const { processData } = data;
    const { href, type } = new LinksModel(processData).getAction('order');
    this.updateControl({ isLoading: true });

    try {
      const resp = await monkeyecxService[type.toLowerCase()]<any>(href).toPromise();

      this.updateOperationData(id, {
        ...resp
      });
    } catch (err) {
      const { error } = err;
      const { notifications } = error;
      const message = notifications?.join(', ');
      this.handleError(message);
    }

    this.updateControl({ isLoading: false });
  }

  private confirmSchedule(id: string, data: ProcessDataModel, sch: MonkeyEcxRequestSchedule) {
    const { scheduleService } = this;
    const { processed, detail } = data;
    this.__schedule = sch;

    this.updateProcessData(id, {
      ...data
    });

    if (detail) {
      this.updateProcessData(id, {
        ...data,
        step: 100,
        totalSteps: 100
      });

      scheduleService.removeSchedule(sch);
      this.__schedule = null;
      this.handleError(detail);
    } else if (processed) {
      this.updateProcessData(id, {
        ...data,
        step: 100,
        totalSteps: 100
      });

      scheduleService.removeSchedule(sch);
      this.__schedule = null;
      this.onHandleOperationData(id);
    }
  }

  private buildSchedule(id: string, resp: any) {
    const { scheduleService } = this;
    const simulData = new LinksModel(resp);
    const { href, type } = simulData.getAction('processes');
    if (!href || !type) return;
    const { _links } = resp;

    this.__schedule = scheduleService.setSchedule(
      {
        method: `${type}`.toLowerCase(),
        url: href,
        data: {
          ...{
            _links
          }
        },
        action: (data: any, sch: any) => {
          return this.confirmSchedule(id, new ProcessDataModel(data), sch);
        }
      },
      1000
    );
  }

  private async createOperation(simulation: BuyerOperationSimulation) {
    const { monkeyecxService, scheduleService } = this;
    if (this.__schedule) scheduleService.removeSchedule(this.__schedule);

    const { href, type } = new LinksModel(simulation).getAction('order');
    const { id, simulationData } = simulation;

    this.updateControl({ isLoading: true });

    this.updateProcessData(id, {
      step: 0,
      totalSteps: 100
    });

    try {
      const resp = await monkeyecxService[type.toLowerCase()]<any>(
        href,
        simulationData
      ).toPromise();

      this.buildSchedule(id, resp);
    } catch (err) {
      const { error } = err;
      const { notifications } = error;
      const message = notifications?.join(', ');
      this.handleError(message);
      this.updateControl({ isLoading: false });
    }
  }

  private async verifyBeforeStart(id: string) {
    const { store, router } = this;

    const data = await store
      .select(selectors.operationSimulation.selectById({ id }))
      .pipe(take(1))
      .toPromise();

    const { pendencies } = data;
    if (pendencies && (pendencies.risk || pendencies['bank-account'])) {
      router.navigate([`/app/buyer/operation/pendencies/${id}`]);
      return;
    }
    this.create(id);

    this.createOperation(data);
  }

  start(simulationId: string) {
    this.verifyBeforeStart(simulationId);
  }

  startSummaryUpdate(id: string) {
    this.onHandleSummaryData(id);
  }

  startRecalc(id: string) {
    this.onHandleRecalcData(id);
  }
}

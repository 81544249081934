<div
  class="sidebar-actions-container"
  [class.folded]="_isFolded"
  *ngIf="mainAction; let icon; of: mainAction?.icon; let route; of: mainAction?.route"
  monkeyecxFeature
  [featureName]="mainAction.feature"
  monkeyecxSecurity
  [securityRoles]="['ADMIN', 'EXECUTE']"
>
  <monkey-button
    type="primary"
    color="theme"
    [icon]="mainAction?.icon"
    [routerLink]="[mainAction?.route]"
    *ngIf="_isFolded; else notFoldedAction"
  >
  </monkey-button>
  <ng-template #notFoldedAction>
    <div class="action">
      <span class="title">
        {{ 'SIDEBAR-ACTIONS.' + companyType?.toUpperCase() + '.ACTION-TITLE' | translate }}
      </span>
      <monkey-button color="theme" type="tertiary" [routerLink]="[mainAction?.route]" size="md">
        {{ 'SIDEBAR-ACTIONS.' + companyType?.toUpperCase() + '.ACTION-BUTTON' | translate }}
      </monkey-button>
    </div>
  </ng-template>
</div>

<app-sidebar-actions-options [isFolded]="_isFolded" [companyType]="companyType">
</app-sidebar-actions-options>

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { formatDate } from '@utils';
import { PurchaseEvolutionData } from 'app/interfaces';
import { sponsor as actions } from 'app/store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class PurchaseEvolutionService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.purchaseEvolutionActions.updateControl({
        data
      })
    );
  }

  private update(data: any[], identifier: string) {
    const { store } = this;

    store.dispatch(
      actions.purchaseEvolutionActions.updateAll({
        data: data.map((_: any) => {
          return {
            identifier,
            ..._,
            date: formatDate(_.date)
          };
        })
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    this.updateControl({ isLoading: true });

    const data = await this.monkeyecxService.get<PurchaseEvolutionData>(url).toPromise();
    const items = this.getEmbeddedData(data, 'purchase-evolutions');

    this.update(items || [], identifier);

    this.updateControl({ isLoading: false });
  }

  public async load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

import { ReceivablesData } from '@interfaces';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { seller } from '@store/actions';
import { buildIdByLink } from '@utils';

export const featureKey = 'seller-receivables-details';

export interface State extends EntityState<ReceivablesData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

export const adapter: EntityAdapter<ReceivablesData> = createEntityAdapter<ReceivablesData>({
  selectId: (item: ReceivablesData) => {
    return buildIdByLink<ReceivablesData>(item);
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(seller.receivablesDetailsActions.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data },
      {
        ...state
      }
    );
  }),
  on(seller.receivablesDetailsActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

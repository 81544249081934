import { createAction, props } from '@ngrx/store';
import { InvoiceSummariesData } from 'app/interfaces';

export const invoiceSummariesActions = {
  load: createAction('[Buyer InvoiceSummaries] Load', props<{ identifier: string; url: string }>()),
  updateOne: createAction(
    '[Buyer InvoiceSummaries] Upadate One',
    props<{ data: InvoiceSummariesData }>()
  ),
  updateControl: createAction('[Buyer InvoiceSummaries] Update Control', props<{ data: any }>())
};

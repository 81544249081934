import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { reportConfigurations as actions } from '@shared-store/actions';
import { arrayMerge } from '@shared-utils';
import { cloneDeep } from 'lodash';

export const featureKey = 'mecx-shared-report-configurations';

export interface State extends EntityState<interfaces.ReportConfigurations> {
  error: any;
  control: {
    isLoading: boolean;
  };
}

export const adapter: EntityAdapter<interfaces.ReportConfigurations> =
  createEntityAdapter<interfaces.ReportConfigurations>({
    selectId: (item: interfaces.ReportConfigurations) => {
      return `${item.identifier}-${item.reportType}`;
    },
    sortComparer: null
  });

export const initialState: State = adapter.getInitialState({
  error: null,
  control: {
    isLoading: false
  }
});

export const reducer = createReducer(
  initialState,
  on(actions.reportConfigurations.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(actions.reportConfigurations.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.reportConfigurations.update, (state, action) => {
    return adapter.upsertMany([action.data], {
      ...state
    });
  }),
  on(actions.reportConfigurations.toggle, (state, action) => {
    const { identifier, reportType, columnId, selected } = action;
    const data = state.entities[`${identifier}-${reportType}`];

    const handled = data.details.map((val: any) => {
      let handledSelected = val.selected;
      let handledOrder = val.order;
      if (val.columnId === columnId) {
        handledSelected = selected;
        const max = data.details.reduce((a: any, b: any) => {
          return Math.max(a, b.order);
        }, -Infinity);
        handledOrder = max + 1;
        if (!selected) {
          handledOrder = 0;
        }
      }

      return {
        ...val,
        selected: handledSelected,
        order: handledOrder
      };
    });

    return adapter.upsertMany(
      [
        {
          ...data,
          details: handled,
          hasChanged: true
        }
      ],
      {
        ...state
      }
    );
  }),
  on(actions.reportConfigurations.order, (state, action) => {
    const { identifier, reportType, list } = action;
    const data = state.entities[`${identifier}-${reportType}`];

    const details = cloneDeep(data?.details || []);
    const handled = arrayMerge(details, list, 'columnId');

    return adapter.upsertMany(
      [
        {
          ...data,
          details: handled,
          hasChanged: true
        }
      ],
      {
        ...state
      }
    );
  })
);

export const { selectAll } = adapter.getSelectors();

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction('[MECX Shared Report] Clear All');
const create = createAction('[MECX Shared Report] Create', props<{ data: interfaces.Report }>());
const remove = createAction('[MECX Shared Report] Delete', props<{ data: interfaces.Report }>());
const start = createAction('[MECX Shared Report] Start', props<interfaces.ReportParams>());
const updateControl = createAction(
  '[MECX Shared Report] Update',
  props<{ data: interfaces.Report }>()
);

export { clear, create, remove, start, updateControl };

/* eslint-disable camelcase */
import { HttpErrorResponse, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getFirstResult } from '@utils';
import { root } from 'app/store/actions';
import { environment } from 'environments/environment';
import {
  MonkeyEcxAuthCredentials,
  MonkeyEcxConfig,
  MonkeyEcxConfigService,
  MonkeyEcxCookieStorageService,
  MonkeyEcxCoreService,
  MonkeyEcxHeaderCredentials,
  MonkeyEcxLoggedHandlingService,
  MonkeyEcxMeCredentials,
  MonkeyEcxService,
  MonkeyEcxTokenCredentials,
  MonkeyEcxTokenStorageService,
  MonkeyEcxUtils,
  MonkeyEcxi18nConfigService
} from 'monkey-front-core';
import { stringify } from 'query-string';
import { Observable, throwError } from 'rxjs';
import { take } from 'rxjs/operators';

const ApiEndPointsTokenNotMandatory = {
  Assets: 'assets/',
  MonkeyEcx: '/monkeyecx',
  ViaCep: 'viacep.com.br/',
  AssetsMonkeyEcx: 'assets.monkeyecx.com/',
  AssetsMonkeyEcxPrd: 'assets.monkey.exchange/',
  AddressMonkeyEcx: 'api-address.monkeyecx.com/',
  AddressMonkeyEcxOld: 'api-adress.monkeyecx.com/',
  AddressMonkeyEcxPrd: 'api-address.monkey.exchange/',
  AddressMonkeyEcxPrdOld: 'api-adress.monkey.exchange/'
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private routeAuth: string = `${environment.baseUrl}/uaa`;

  constructor(
    private monkeyecxService: MonkeyEcxService,
    private tokenStorage: MonkeyEcxTokenStorageService,
    private router: Router,
    private loggedHandlingService: MonkeyEcxLoggedHandlingService,
    private i18nConfigService: MonkeyEcxi18nConfigService,
    private cookieStorageService: MonkeyEcxCookieStorageService,
    private translateService: TranslateService,
    private configService: MonkeyEcxConfigService,
    private store: Store
  ) {
    // not to do
  }

  private async getClientIdSecretByAccess() {
    const { accessType } = (await this.tokenStorage.getToken()) || {
      accessType: ''
    };
    const tp = accessType === 'provider' ? 'idp_' : '';

    return {
      client_id: environment[`${tp}client_id`],
      client_secret: environment[`${tp}client_secret`]
    };
  }

  private async handleLocale(meCredentials: MonkeyEcxMeCredentials) {
    if (meCredentials.locale !== this.translateService.currentLang) {
      const config = await this.configService.config().pipe(getFirstResult()).toPromise();
      this.i18nConfigService.apply(environment);
      this.i18nConfigService.applyExternal(config, meCredentials.locale);
    }
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [400, 401, 403, 404],
      httpCodeIgnoreRedirect: [500, 503, 404, 403, 401]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async deleteToken() {
    const { monkeyecxService, routeAuth, tokenStorage } = this;
    const token = await tokenStorage.getToken();
    if (token?.accessToken) {
      monkeyecxService.delete(`${routeAuth}/oauth/token`).subscribe();
    }
  }

  private saveTokenCredentials(authCredentials: MonkeyEcxAuthCredentials) {
    this.setToken({
      username: authCredentials.username,
      program: authCredentials.program,
      accessToken: authCredentials.access_token,
      refreshToken: authCredentials.refresh_token
    });
  }

  setToken(token: MonkeyEcxTokenCredentials) {
    this.tokenStorage.setToken(token);
  }

  setMe(me: MonkeyEcxMeCredentials) {
    this.tokenStorage.setMe(me);
  }

  async isAuthorized() {
    const data = await this.tokenStorage.getToken();
    return !!data?.accessToken;
  }

  async isCompanyAuthorized() {
    const data = await this.tokenStorage.getToken();
    return !!data?.governmentId;
  }

  async hadAtLeastOneLogin() {
    const data = await this.tokenStorage.getToken();
    return !data?.accessToken && !!data?.program && !!data?.username;
  }

  isTokenMandatory(url: string): boolean {
    return !(
      Object.values(ApiEndPointsTokenNotMandatory).filter((val: any) => {
        return url.indexOf(val) > -1;
      }).length > 0
    );
  }

  async logOut(force?: boolean, redirect?: boolean, callback?: Function) {
    const { tokenStorage } = this;

    try {
      await this.deleteToken();
    } catch (e) {
      // not to do
    }

    this.loggedHandlingService.destroy();
    this.store.dispatch(root.rootActions.logout());
    tokenStorage.clear(force);

    if (redirect) {
      window.location.reload();
    }

    if (callback) {
      callback();
    }
  }

  async getToken() {
    return this.tokenStorage.getToken();
  }

  async getHeaders() {
    let tokenCredentials = null;
    tokenCredentials = await this.tokenStorage.getToken();

    const headers: MonkeyEcxHeaderCredentials = {
      Authorization: '',
      Program: ''
    };
    if (MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials.accessToken)) {
      headers.Authorization = `Bearer ${tokenCredentials.accessToken}`;
    }

    if (MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials.program)) {
      headers.Program = `${tokenCredentials.program}`;
    }

    return headers;
  }

  async getHeadersToUpload() {
    const tokenCredentials = await this.tokenStorage.getToken();

    const headers: any[] = [];

    if (MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials.accessToken)) {
      headers.push({
        name: 'Authorization',
        value: `Bearer ${tokenCredentials.accessToken}`
      });
    }

    if (MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials.program)) {
      headers.push({
        name: 'Program',
        value: `${tokenCredentials.program}`
      });
    }

    return headers;
  }

  getRequestWithHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const headers: any = this.getHeaders();
    if (this.isTokenMandatory(request.url)) {
      request = request.clone({
        setHeaders: headers
      });
    }
    return request;
  }

  async getRequestWithHeadersAsync(request: HttpRequest<any>) {
    const headers: any = await this.getHeaders();
    if (this.isTokenMandatory(request.url)) {
      if (!headers.Program) {
        throw new HttpErrorResponse({
          error: 'Program not found',
          headers,
          status: 401,
          statusText: 'Bad Request',
          url: request.url
        });
      }

      return headers;
    }
    return {};
  }

  getRequestWithHeadersOb(request: HttpRequest<any>): Observable<any> {
    const obs = new Observable((observer) => {
      let headers: any = this.getHeaders();
      if (this.isTokenMandatory(request.url)) {
        if (headers.Program) {
          observer.next(headers);
          return;
        }
        let count = 0;
        const interval = setInterval(() => {
          count += 1;
          if (count > 5) {
            clearInterval(interval);
            observer.error(
              new HttpErrorResponse({
                error: 'Program not found',
                headers,
                status: 401,
                statusText: 'Bad Request',
                url: request.url
              })
            );
          }
          headers = this.getHeaders();
          if (headers.Program) {
            clearInterval(interval);
            observer.next(headers);
          }
        }, 200);
      } else {
        observer.next({});
      }
    });

    return obs;
  }

  async redirectLoginWelcomeBack() {
    const tokenCredentials = await this.tokenStorage.getToken();
    if (tokenCredentials?.accessToken) {
      await this.logOut(false);
    }

    let route = 'email/login';
    if (
      MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials?.username) &&
      MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials?.program)
    ) {
      route = 'welcome-back';
    }

    this.router.navigate([`/authentication/${route}`]);
  }

  async redirectApp() {
    const tokenCredentials = await this.tokenStorage.getToken();
    if (
      MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials.username) &&
      MonkeyEcxUtils.persistNullEmptyUndefined(tokenCredentials.accessToken)
    ) {
      this.router.navigate(['app/profile']);
    }
  }

  refreshShouldHappen(response: HttpErrorResponse): boolean {
    const { status, url, error } = response;
    const { type } = error || {
      type: ''
    };

    const typed = 'ACCESS_DENIED#INVALID_TOKEN#INVALID_CLIENT';

    let handledType = type;
    if (!handledType && typeof error === 'object') {
      handledType = error?.error;
    }

    if (type === 'ERROR') return false;
    if (url?.includes('uaa/oauth/token') && typed.includes(`${handledType}`?.toUpperCase()))
      return false;
    if (url?.includes('/notifications')) {
      return false;
    }

    return status === 401;
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: false
    }
  })
  async me(authCredentials: MonkeyEcxAuthCredentials, config?: MonkeyEcxConfig, refresh = true) {
    const { routeAuth, monkeyecxService } = this;

    this.saveTokenCredentials(authCredentials);

    const resp = await monkeyecxService.get<any>(`${routeAuth}/me`).pipe(take(1)).toPromise();

    this.saveMeCredentials(
      {
        ...resp.principal,
        selectedLanguage: 'pt-BR'
      },
      config,
      refresh
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [400, 401, 403, 404],
      httpCodeIgnoreRedirect: [500, 503, 404, 403, 401]
    }
  })
  async refreshToken(persistThrow = true) {
    const { monkeyecxService, tokenStorage, routeAuth } = this;

    if (persistThrow) {
      return throwError(
        new HttpErrorResponse({
          status: 401
        })
      );
    }

    const tokenCredentials = await tokenStorage.getToken();

    const query = stringify({
      ...(await this.getClientIdSecretByAccess()),
      scope: 'ui',
      grant_type: 'refresh_token',
      access_token: tokenCredentials.accessToken,
      refresh_token: tokenCredentials.refreshToken,
      username: tokenCredentials.username
    });

    try {
      const resp = await monkeyecxService
        .post<any>(`${routeAuth}/oauth/token`, query, {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          })
        })
        .pipe(take(1))
        .toPromise();
      await this.me(resp);
    } catch (e) {
      return throwError(e);
    }

    return null;
  }

  saveMeCredentials(
    meCredentials: MonkeyEcxMeCredentials,
    config: MonkeyEcxConfig | undefined,
    refresh = true
  ) {
    const { programs } = meCredentials;

    if (config) {
      const programType = config.program.type;
      if (programType) {
        this.setToken({
          programType
        });
      }
    }

    this.setMe({
      name: meCredentials?.name,
      email: meCredentials?.email,
      username: meCredentials?.username,
      status: meCredentials?.status,
      pictureURL: meCredentials?.pictureURL,
      selectedLanguage: meCredentials?.selectedLanguage,
      companies: meCredentials?.companies,
      programs,
      nickname: meCredentials?.nickname,
      cellPhone: meCredentials?.cellPhone,
      smsNotification: meCredentials?.smsNotification,
      privacyPolicy: meCredentials?.privacyPolicy,
      useTerms: meCredentials?.useTerms,
      newsletter: meCredentials?.newsletter,
      locale: meCredentials?.locale,
      others: meCredentials?.others
    });

    this.handleLocale(meCredentials);
  }

  clearProfileCredentials() {
    ['companyId', 'governmentId', 'companyType', 'programAdmin', 'role'].forEach((key: string) => {
      localStorage.removeItem(key);
    });
  }
}

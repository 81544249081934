import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { seller } from '@store/actions';
import { buildIdByLink } from 'app/core/utils/utils';
import { OfferData, PaginationData } from 'app/interfaces';

export const featureKey = 'seller-offers';

export interface State extends EntityState<OfferData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<OfferData> = createEntityAdapter<OfferData>({
  selectId: (item: OfferData) => {
    return buildIdByLink<OfferData>(item);
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(seller.offersActions.clear, (state: any) => {
    return adapter.removeAll(state);
  }),
  on(seller.offersActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(seller.offersActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(seller.offersActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

import { createAction, props } from '@ngrx/store';
import { BankAccountsData, Pagination } from 'app/interfaces';

export const bankAccountsActions = {
  clear: createAction('[Seller BankAccounts] Clear All'),
  delete: createAction('[Seller BankAccounts] Delete', props<{ data: BankAccountsData }>()),
  load: createAction('[Seller BankAccounts] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction(
    '[Seller BankAccounts] Update All',
    props<{ data: BankAccountsData[] }>()
  ),
  updateControl: createAction('[Seller BankAccounts] Update Control', props<{ data: any }>()),
  updateOne: createAction('[Seller BankAccounts] Update One', props<{ data: BankAccountsData }>()),
  updatePagination: createAction('[Seller BankAccounts] Update Pagination', props<{ data: any }>())
};

export const bankAccountsPaginationActions = {
  load: createAction(
    '[Seller BankAccountsPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

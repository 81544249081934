import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from '@modules/monkeyecx-common.module';
import { MonkeyEcxFormsModule } from '@modules/monkeyecx-forms.module';
import { TranslateModule } from '@ngx-translate/core';
import { MonkeyButtonModule, MonkeyCheckboxModule, MonkeyModalModule } from 'monkey-style-guide';
import { MECXSharedFakeDataAlertComponent } from './fake-data-alert.component';

@NgModule({
  declarations: [MECXSharedFakeDataAlertComponent],
  imports: [
    MonkeyEcxCommonModule,
    MonkeyEcxFormsModule,
    MonkeyButtonModule,
    MonkeyCheckboxModule,
    MonkeyModalModule,
    TranslateModule.forChild()
  ],
  exports: [MECXSharedFakeDataAlertComponent]
})
export class MECXSharedFakeDataAlertModule {}

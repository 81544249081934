import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MonkeyModalConfig } from 'monkey-style-guide';

@Component({
  selector: 'shared-confirm-change',
  templateUrl: './confirm-change.component.html',
  styleUrls: ['./confirm-change.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SharedConfirmChangeComponent {
  @Input() config: MonkeyModalConfig;

  @Input() modalRef: any;

  _data: string;
}

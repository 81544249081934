<div class="box" [class.folded]="isFolded" #actionElement (click)="_showMenu = !_showMenu">
  <ng-container *ngIf="(_selected$ | async) as selected">
    <div class="icon">
      {{ selected?.name | uppercase | slice:0:1 }}
    </div>
    <ng-container *ngIf="!isFolded">
      <div class="d-flex flex-column">
        <span class="title">
          {{ selected?.name | monkeyecxTextTruncate: 20 }}
        </span>
        <span class="data">
          {{ selected?.type }}
        </span>
      </div>
      <monkey-icon icon="arrow-right-16" color="#4B4A53"></monkey-icon>
    </ng-container>
  </ng-container>
</div>

<app-sidebar-actions-options-menu
  *monkeyecxPopover="_showMenu; target: $any(actionElement); closed: closeMenu; dir: 'rt'"
  [isFolded]="_isFolded" [configBootstrap]="_configBootstrap" [companyType]="companyType"
  [closeMenu]="closeMenu">
</app-sidebar-actions-options-menu>
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedBanks } from 'app/interfaces';
import { shared } from 'app/store/reducers';

export const selectBanksState = createFeatureSelector<shared.fromBanks.State>(
  shared.fromBanks.featureKey
);

export const selectAll = createSelector(selectBanksState, shared.fromBanks.selectAll);

export const exists = () => {
  return createSelector(
    selectAll,
    (entities: SharedBanks[]): boolean => {
      return !!entities?.length;
    }
  );
};

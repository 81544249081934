import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import { NotificationsService } from 'app/core/services/notifications';
import { BuyerPendenciesService } from 'app/core/services/pendencies';
import { BaseComponent } from 'monkey-front-components';
import { NavigationService } from '../components/navigation/navigation.service';
import { navigation } from './buyer-navigation';

@Component({
  selector: 'app-buyer-layout',
  templateUrl: './buyer-layout.component.html',
  styleUrls: ['./buyer-layout.component.scss']
})
export class BuyerLayoutComponent extends BaseComponent implements OnDestroy {
  navigation: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private navigationService: NavigationService,
    private platform: Platform,
    private monkeyNotificationsService: NotificationsService,
    private monkeyPendenciesService: BuyerPendenciesService
  ) {
    super();

    this.navigation = navigation;

    this.navigationService.register('main-buyer', this.navigation);
    this.navigationService.setCurrentNavigation('main-buyer');

    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.classList.add('is-mobile');
    }
  }

  ngOnDestroy() {
    this.navigationService.unregister('main-buyer');
  }
}

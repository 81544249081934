import { Injectable } from '@angular/core';
import { buyer as interfaces } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import { buyer as selectors } from '@store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';
import { LinksModel } from '@model';
import { FilterSponsorService } from '@store/services/buyer';

@Injectable()
export class GeneralSummaryService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private filterSponsorService: FilterSponsorService,

    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, filter: string): Promise<void> {
    this.updateControl({ isLoading: true });

    try {
      const filterSponsorQuery = await this.filterSponsorService.buildSponsorFilter();
      const data = await this.monkeyecxService
        .get<interfaces.GeneralSummaryData>(
          `${url}&${filterSponsorQuery}`.replace('{?period}', `?period=${filter}`)
        )
        .toPromise();

      this.update(data, filter);
    } finally {
      this.updateControl({ isLoading: false });
    }
  }

  private update(data: interfaces.GeneralSummaryData, filter: string): void {
    this.store.dispatch(
      actions.dashboard.generalSummary.updateOne({
        data: {
          ...data,
          filter
        }
      })
    );
  }

  private updateControl(data: { isLoading: boolean }): void {
    this.store.dispatch(actions.dashboard.generalSummary.updateControl({ data }));
  }

  public load(url: string, filter: string): void {
    this.loadData(url, filter);
  }

  public async filter(filter: string): Promise<void> {
    this.store.dispatch(actions.dashboard.generalSummary.clear({ identifier: '' }));

    const data = await this.store
      .select(selectors.dashboard.selectData())
      .pipe(take(1))
      .toPromise();

    const { href } = new LinksModel(data).getAction('program-summary');

    if (!href) {
      return;
    }

    await this.loadData(href, filter);
  }
}

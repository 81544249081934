import { createFeatureSelector, createSelector } from '@ngrx/store';
import { urlHasField } from '@utils';
import { InvoiceData } from 'app/interfaces';
import { buyer } from 'app/store/reducers';
import { parseUrl } from 'query-string';

declare type State = buyer.fromInvoices.State;

export const selectInvoicesState = createFeatureSelector<buyer.fromInvoices.State>(
  buyer.fromInvoices.featureKey
);

export const selectAllInvoices = createSelector(
  selectInvoicesState,
  buyer.fromInvoices.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllInvoices,
    (entities: InvoiceData[]): InvoiceData[] => {
      return entities.filter((_: InvoiceData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectOneInvoiceById = (props: { id: string }) => {
  return createSelector(
    selectAllInvoices,
    (entities: InvoiceData[]): InvoiceData => {
      return entities.find((_: InvoiceData) => {
        return _.id === props.id;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectInvoicesState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectInvoicesState,
    (state: State): any => {
      return state.pagination?.[props.identifier];
    }
  );
};

export const paginationHasDifference = (props: { identifier: string; url: string }) => {
  return createSelector(
    selectInvoicesState,
    (state: State): any => {
      const { pagination } = state;
      const { identifier, url } = props;
      let hasDifference = false;
      let hasField = false;

      try {
        const handlePaginationUrl = parseUrl(`${pagination?.[identifier]?.url}`);
        const handleUrl = parseUrl(`${url}`);
        hasDifference = (handlePaginationUrl?.query?.search !== handleUrl?.query?.search) ||
          (handlePaginationUrl?.query?.sort !== handleUrl?.query?.sort);
        hasField = urlHasField(url, 'search') || urlHasField(url, 'sort');
      } catch (e) {
        // not to do
      }

      return {
        hasDifference,
        hasField
      };
    }
  );
};

<monkey-modal [config]="config" [modalRef]="modalRef" *ngIf="config">
  <div class="alert-header">
    <img src="/assets/images/fake-data-alert.jpg" />
  </div>
  <div class="alert-body">
    <span class="title">
      {{ 'MECX-SHARED.FAKE-DATA-ALERT.TITLE' | translate }}
    </span>
    <span class="message">
      {{ 'MECX-SHARED.FAKE-DATA-ALERT.SUB-TITLE' | translate }}
    </span>
    <span class="warning">
      <i class="mk-i mk-i-warning-outline font-size-lg text-normal-orange"></i>
      {{ 'MECX-SHARED.FAKE-DATA-ALERT.WARNING' | translate }}
    </span>
  </div>
  <div class="alert-footer">
    <monkey-button type="border" color="theme" (click)="onCancel()">
      {{ 'BUTTONS.CLOSE' | translate }}
    </monkey-button>

    <monkey-button type="primary" color="theme" (click)="onSubmit()">
      {{ 'MECX-SHARED.FAKE-DATA-ALERT.ACTION' | translate }}
    </monkey-button>
  </div>
</monkey-modal>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SharedBanks } from 'app/interfaces';
import { environment } from 'environments/environment';
import { MonkeyEcxCommonsService, MonkeyEcxCoreService, MonkeyEcxService } from 'monkey-front-core';
import { Observable } from 'rxjs';

/** Deprecated */
@Injectable()
export class BankSearchService extends MonkeyEcxCommonsService implements Resolve<any> {
  private routeBanks = `${environment.urlAssets}/data/banks.json`;

  constructor(monkeyecxService: MonkeyEcxService) {
    super(monkeyecxService);
    this.__data = null;
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  public getData() {
    const { monkeyecxService, routeBanks } = this;

    monkeyecxService.get<SharedBanks[]>(routeBanks).subscribe(
      (resp: any) => {
        if (resp.length) {
          this.__data = resp;
        }

        this.__onDataChanged$.next(null);
      },
      () => {
        this.__data = null;

        this.__onDataChanged$.next(null);
      }
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    super.resolve(route, state, {
      callbackMain: () => {
        this.__search = null;
        this.__data = null;
        this.__page = null;
        this.getData();
      }
    });
    return null;
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { spike as actions } from '@shared-store/actions';
import { MonkeyEcxLinksModel } from 'monkey-front-core';

export const featureKey = 'mecx-spike-shared-anticipations';

export interface State extends EntityState<interfaces.Anticipation> {
  error: any;
  pagination?: interfaces.Pagination;
  control: {
    isLoading: boolean;
  };
}

export const adapter: EntityAdapter<interfaces.Anticipation> =
  createEntityAdapter<interfaces.Anticipation>({
    selectId: (item: interfaces.Anticipation) => {
      const { identifier } = item;
      const actionId: any = new MonkeyEcxLinksModel(item)?.getAction('self');
      const id = actionId.href.split('/').pop();

      return `${identifier}-${id}`;
    }
  });

export const initialState: State = adapter.getInitialState({
  error: null,
  pagination: null,
  control: {
    isLoading: false
  }
});

export const reducer = createReducer(
  initialState,
  on(actions.anticipations.anticipations.updateOne, (state, action) => {
    return adapter.upsertOne(action.data, {
      ...state
    });
  }),
  on(actions.anticipations.anticipations.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  }),
  on(actions.anticipations.anticipations.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.anticipations.anticipations.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(actions.anticipations.anticipations.remove, (state, action) => {
    const { id, identifier } = action;
    return adapter.removeOne(`${identifier}-${id}`, state);
  }),
  on(actions.anticipations.anticipations.clear, (state: any) => {
    const handled = adapter.removeAll(state);
    handled.pagination = null;
    handled.control = { isLoading: false };
    return handled;
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

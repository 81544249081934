import { createAction, props } from '@ngrx/store';
import { OfferData, Pagination } from 'app/interfaces';

export const offersActions = {
  clear: createAction('[Seller Offers] Clear All', props<{ identifier: string }>()),
  load: createAction('[Seller Offers] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction('[Seller Offers] Update All', props<{ data: OfferData[] }>()),
  updateControl: createAction('[Seller Offers] Update Control', props<{ data: any }>()),
  updatePagination: createAction('[Seller Offers] Update Pagination', props<{ data: any }>())
};

export const offersPaginationActions = {
  load: createAction(
    '[Seller OffersPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedNewsletterService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertsService: MonkeyEcxAlertsService
  ) {
    super(monkeyecxService, tokenStorage);

    this.__onDataChanged$ = new BehaviorSubject(null);
  }

  async save(params: any, callback: Function) {
    const { monkeyecxService, tokenStorage, alertsService } = this;

    this.__onLoadingInProgress$.next(true);

    const { name, cellPhone, nickname, privacyPolicy, smsNotification, useTerms } =
      await this.tokenStorage.getMe();

    const me = {
      name,
      cellPhone,
      nickname,
      smsNotification,
      privacyPolicy,
      useTerms,
      ...params
    };

    try {
      await monkeyecxService.put(`${environment.baseUrl}/uaa/users`, me).toPromise();

      tokenStorage.setMe(me);

      callback();

      alertsService.show({
        title: 'INFORMATION',
        message: 'OPERATION',
        type: 'success',
        duration: 2000
      });
    } catch (e) {
      // not to do
    }

    this.__onLoadingInProgress$.next(false);
  }
}

import { PurchaseData } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxControlStore, MonkeyEcxPage, MonkeyEcxPagination } from 'monkey-front-core';

export const purchasesActions = {
  clear: createAction('[Buyer Purchases] Clear All', props<{ identifier: string }>()),
  load: createAction('[Buyer Purchases] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction('[Buyer Purchases] Update All', props<{ data: PurchaseData[] }>()),
  updateControl: createAction(
    '[Buyer Purchases] Update Control',
    props<{ data: MonkeyEcxControlStore }>()
  ),
  updateLinks: createAction('[Buyer Purchases] Update Links', props<{ data: any }>()),
  updatePage: createAction('[Buyer Purchases] Update Page', props<{ data: MonkeyEcxPage }>())
};

export const purchasesPaginationActions = {
  load: createAction(
    '[Buyer PurchasesPagination] Load',
    props<{ pagination: MonkeyEcxPagination; identifier: string }>()
  )
};

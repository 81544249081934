import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { shared as actions } from '@shared-store/actions';
import { AuthService } from 'app/core/services/auth';
import { map } from 'rxjs/operators';

@Injectable()
export class UserContextEffects {
  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.userContext.logout),
        map(() => {
          this.authService.logOut(true, true);
        })
      );
    },
    {
      dispatch: false
    }
  );

  saveMeCredentials$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.userContext.saveMeCredentials),
        map(({ data }) => {
          this.authService.saveMeCredentials(data, null);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private authService: AuthService) {
    // not to do
  }
}

import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxDirectivesModule } from 'monkey-front-core';
import { MonkeyButtonModule } from 'monkey-style-guide';
import { NavigationModule } from '../navigation/navigation.module';
import { NavbarComponent } from './navbar.component';

@NgModule({
  declarations: [NavbarComponent],
  imports: [MonkeyEcxCommonModule, MonkeyEcxDirectivesModule, MonkeyButtonModule, NavigationModule],
  exports: [NavbarComponent]
})
export class NavbarModule {}

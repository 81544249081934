import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxFormsModule } from 'app/shared/modules/monkeyecx-forms.module';
import { MonkeyButtonModule, MonkeyCheckboxModule, MonkeyModalModule } from 'monkey-style-guide';
import { SharedGenericAlertsComponent } from './generic-alerts.component';

@NgModule({
  declarations: [SharedGenericAlertsComponent],
  imports: [
    MonkeyEcxCommonModule,
    MonkeyEcxFormsModule,
    MonkeyButtonModule,
    MonkeyCheckboxModule,
    MonkeyModalModule,
    TranslateModule.forChild()
  ],
  exports: [SharedGenericAlertsComponent]
})
export class SharedGenericAlertsModule {}

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import * as interfaces from '@shared-interfaces';
import { MonkeyModalConfig } from 'monkey-style-guide';
import { MECXSharedDynamicReportModalConfigService } from '../../providers';

@Component({
  selector: 'mecx-shared-dynamic-report-config',
  styleUrls: ['./dynamic-report-config.component.scss'],
  templateUrl: './dynamic-report-config.component.html'
})
export class MECXSharedDynamicReportConfigComponent implements OnInit {
  @Input() config: MonkeyModalConfig;

  @Input() modalRef: any;

  _template$ = null;

  _data$ = null;

  _control$ = null;

  _currentDraggableEvent?: DragEvent;

  private reportType: string;

  constructor(private service: MECXSharedDynamicReportModalConfigService) {
    // not to do
  }

  ngOnInit(): void {
    this._template$ = this.config?.data.template$;
    this._data$ = this.config?.data.data$;
    this._control$ = this.config?.data.control$;
    this.reportType = this.config?.data?.reportType;
  }

  onChangeOption(event: PointerEvent, identifier: string, columnId: any, selected: boolean) {
    const { reportType } = this;
    event.stopPropagation();
    event.preventDefault();

    this.service.toggle(identifier, reportType, columnId, selected);
  }

  onDrop(event: CdkDragDrop<any[]>, identifier: string, list: interfaces.ReportConfiguration[]) {
    const { reportType } = this;
    const { previousIndex, currentIndex } = event;

    try {
      moveItemInArray(list, previousIndex, currentIndex);

      this.service.order(identifier, reportType, list);
    } catch (e) {
      // not to do
    }
  }
}

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-seller-checkout-layout',
  templateUrl: './seller-checkout-layout.component.html',
  styleUrls: ['./seller-checkout-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SellerCheckoutLayoutComponent {
  navigation: any;

  constructor() {
    // no to do
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { bankAccounts as actions } from '@shared/app/store/actions';
import { shared as selectors } from '@store/selectors';
import { getSelectorSync } from '@utils';
import { map } from 'rxjs/operators';

@Injectable()
export class BanksMapperEffects {
  startWithMap$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.banks.startWithMap),
        map(({ companyType }) => {
          this.onHandleStart(companyType);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private store: Store) {
    // not to do
  }

  private async onHandleStart(companyType: string) {
    const { store } = this;

    if (companyType.toUpperCase() !== 'SELLER') return;

    store.dispatch(actions.banks.clear());

    const data = await getSelectorSync(store, selectors.banks.selectAll);

    store.dispatch(
      actions.banks.create({
        data
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxFeatureToggleService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SponsorMarketService extends MonkeyEcxCommonsService implements Resolve<any> {
  private route: string = `${environment.baseUrl}/v2/sponsors`;

  private flagValidate = true;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private router: Router,
    private featureToggleService: MonkeyEcxFeatureToggleService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private onHandleFeature() {
    if (!this.flagValidate) {
      this.getData();
      return;
    }
    this.featureToggleService.onFlags
      .pipe(
        takeWhile(() => {
          return this.flagValidate;
        })
      )
      .subscribe((resp: any) => {
        if (resp) {
          this.flagValidate = false;
          this.getData();
        }
      });
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [403],
      httpCodeIgnoreRedirect: [500]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  public async getData() {
    const { monkeyecxService, route, tokenStorage } = this;
    const { companyId } = await tokenStorage.getToken();

    if (!this.featureToggleService.getFlag('SPONSOR_OPERATION')) {
      return;
    }

    this.__onLoadingInProgress$.next(true);
    monkeyecxService.get<any>(`${route}/${companyId}/markets`).subscribe(
      (resp: any) => {
        this.__data = resp;
        this.__onDataChanged$.next(null);
        this.__onLoadingInProgress$.next(false);
      },
      (err: any) => {
        this.__data = null;
        this.__page = null;
        this.__onDataChanged$.next(null);
        this.__onLoadingInProgress$.next(false);
      }
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const { router } = this;
    super.resolve(route, state, {
      callbackMain: () => {
        this.__search = null;
        this.__data = null;
        this.__page = null;

        this.onHandleFeature();
      },
      router
    });
    return null;
  }
}

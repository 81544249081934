import { createAction, props } from '@ngrx/store';
import { InvoiceData, Pagination } from 'app/interfaces';

export const invoicesActions = {
  clear: createAction('[Buyer Invocies] Clear All', props<{ identifier: string }>()),
  load: createAction('[Buyer Invocies] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction('[Buyer Invocies] Update All', props<{ data: InvoiceData[] }>()),
  updateControl: createAction('[Buyer Invocies] Update Control', props<{ data: any }>()),
  updateOne: createAction('[Buyer Invocies] Update One', props<{ data: InvoiceData }>()),
  updatePagination: createAction('[Buyer Invocies] Update Pagination', props<{ data: any }>())
};

export const invoicesPaginationActions = {
  load: createAction(
    '[Buyer InvociesPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedBanks } from 'app/interfaces';
import { shared as sharedAction } from 'app/store/actions';
import { shared as sharedSelector } from 'app/store/selectors';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class BanksService extends MonkeyEcxCommonsService {
  private route = `${environment.urlAssets}/data/banks.json`;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: SharedBanks[]) {
    const { store } = this;

    store.dispatch(
      sharedAction.banksActions.create({
        data
      })
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  public getData() {
    const { monkeyecxService, route } = this;

    monkeyecxService.get<SharedBanks[]>(route).subscribe((resp: any) => {
      if (resp.length) {
        this.update(resp);
      }
    });
  }

  private async verifyBeforeStart() {
    const { store } = this;

    const data = await store
      .select(sharedSelector.banks.exists())
      .pipe(take(1))
      .toPromise();

    if (data) return;

    this.getData();
  }

  public start() {
    this.verifyBeforeStart();
  }
}

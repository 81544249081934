import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OfferData, Pagination } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { seller as actions } from 'app/store/actions';
import { seller as selectors } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class OffersService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(data: OfferData, identifier: string) {
    const actionId: string = new LinksModel(data)?.getAction('self')?.href;
    const id = actionId?.split('/')?.pop();

    return {
      ...data,
      identifier,
      id
    };
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const data = await this.store
      .select(selectors.offers.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, updatePagination = true) {
    this.updateControl({ isLoading: true });

    try {
      const data = await this.monkeyecxService.get<any>(url).toPromise();
      const items = this.getEmbeddedData(data, 'offers');
      const { _links } = data;

      this.update(items, identifier);

      if (updatePagination) {
        this.updatePagination(_links, identifier);
      }
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  private update(data: OfferData[], identifier: string) {
    this.store.dispatch(
      actions.offersActions.updateAll({
        data: data.map((_: OfferData) => {
          return this.mappingData(_, identifier);
        })
      })
    );
  }

  private updateControl(data: any) {
    this.store.dispatch(actions.offersActions.updateControl({ data }));
  }

  private updatePagination(data: any, identifier: string) {
    this.store.dispatch(
      actions.offersActions.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  public async load(url: string, identifier: string) {
    const { store } = this;

    const { hasDifference, hasField } = await store
      .select(selectors.offers.paginationHasDifference({ identifier, url }))
      .pipe(take(1))
      .toPromise();

    if (hasDifference && hasField) {
      store.dispatch(actions.offersActions.clear({ identifier }));
    }

    this.loadData(url, identifier, hasDifference);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }
}

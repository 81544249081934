import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { PurchaseEvolutionData } from 'app/interfaces';
import { sponsor } from '../../actions';

export const featureKey = 'sponsor-purchase-evolution';

export interface State extends EntityState<PurchaseEvolutionData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

export const adapter: EntityAdapter<PurchaseEvolutionData> = createEntityAdapter<
  PurchaseEvolutionData
>({
  selectId: (item: PurchaseEvolutionData) => {
    return `${item.identifier}-${item.date}`;
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(sponsor.purchaseEvolutionActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(sponsor.purchaseEvolutionActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

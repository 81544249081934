import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction(
  '[MECX Shared Webhooks Redelivery] Clear All',
  props<{ identifier: string }>()
);
const create = createAction(
  '[MECX Shared Webhooks Redelivery] Create',
  props<{ data: interfaces.WebhookRedeliveryData }>()
);
const deleteAction = createAction(
  '[MECX Shared Webhooks Redelivery] Delete',
  props<{ data: interfaces.WebhookRedeliveryData }>()
);
const load = createAction(
  '[MECX Shared Webhooks Redelivery] Load',
  props<{ companyType: string; identifier: string; url: string }>()
);
const loadOne = createAction(
  '[MECX Shared Webhooks Redelivery] Load One',
  props<{ companyType: string; identifier: string; url: string }>()
);
const paginationLoad = createAction(
  '[MECX Shared Webhooks Redelivery Pagination] Load',
  props<{ companyType: string; identifier: string; pagination: interfaces.Pagination }>()
);
const redeliver = createAction(
  '[MECX Shared Webhooks Redelivery] Redeliver',
  props<{
    data: interfaces.WebhookRedeliveryData;
    detailsPath: string;
    callback?: Function;
  }>()
);
const updateAll = createAction(
  '[MECX Shared Webhooks Redelivery] Update All',
  props<{ data: interfaces.WebhookRedeliveryData[] }>()
);
const updateControl = createAction(
  '[MECX Shared Webhooks Redelivery] Update Control',
  props<{ data: any }>()
);
const updateOne = createAction(
  '[MECX Shared Webhooks Redelivery] Update One',
  props<{ data: interfaces.WebhookRedeliveryData }>()
);
const updatePagination = createAction(
  '[MECX Shared Webhooks Redelivery] Update Pagination',
  props<{ data: any }>()
);

export {
  clear,
  create,
  deleteAction,
  load,
  loadOne,
  paginationLoad,
  redeliver,
  updateAll,
  updateControl,
  updateOne,
  updatePagination
};

import { Component, Input, OnInit } from '@angular/core';
import { MonkeyModalConfig } from 'monkey-style-guide';

@Component({
  selector: 'mecx-shared-fake-data-alert',
  templateUrl: './fake-data-alert.component.html',
  styleUrls: ['./fake-data-alert.component.scss']
})
export class MECXSharedFakeDataAlertComponent implements OnInit {
  @Input() config: MonkeyModalConfig | null = null;

  @Input() modalRef: any;

  constructor() {
    // not to do
  }

  ngOnInit(): void {
    this.config = {
      ...this.config,
      action: {
        ...this.config.action
      }
    };
  }

  onCancel() {
    this.config.data?.cancel?.();
  }

  onSubmit() {
    this.config.data?.submit?.();
  }
}

import { createAction, props } from '@ngrx/store';
import { DailyOperationData } from 'app/interfaces';

export const dailyOperationActions = {
  load: createAction('[Sponsor DailyOperation] Load', props<{ url: string; identifier: string }>()),
  updateControl: createAction('[Sponsor DailyOperation] Update Control', props<{ data: any }>()),
  updateOne: createAction(
    '[Sponsor DailyOperation] Update One',
    props<{ data: DailyOperationData }>()
  )
};

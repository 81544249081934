import { AnimationBuilder, animate, style } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  private splashScreenEl: any;

  private player: any = null;

  constructor(private animationBuilder: AnimationBuilder, @Inject(DOCUMENT) private document: any) {
    this.splashScreenEl = this.document.body.querySelector('#monkey-splash-screen');
  }

  show(): void {
    this.player = this.animationBuilder
      .build([
        style({
          opacity: '0',
          zIndex: '99999'
        }),
        animate(
          '400ms ease',
          style({
            opacity: '1'
          })
        )
      ])
      .create(this.splashScreenEl);

    setTimeout(() => {
      this.player.play();
    }, 0);

    this.document.body.style.pointerEvents = 'none';
    this.splashScreenEl.style.display = 'block';
  }

  hide(): void {
    this.document.body.style.pointerEvents = 'all';
    this.splashScreenEl.style.display = 'none';
  }
}

/* eslint-disable indent */
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { ConfigTz } from 'app/core/config';

export const TZ_TOKEN = 'TZ_TOKEN';

@NgModule()
export class MonkeyEcxTzModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: MonkeyEcxTzModule
  ) {
    if (parentModule) {
      throw new Error('MonkeyEcxTzModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(): ModuleWithProviders<MonkeyEcxTzModule> {
    const value = new ConfigTz().countryTzName;
    return {
      ngModule: MonkeyEcxTzModule,
      providers: [
        {
          provide: TZ_TOKEN,
          useValue: value
        }
      ]
    };
  }
}

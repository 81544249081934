<monkey-modal [config]="config" [modalRef]="modalRef" *ngIf="config">
  <div class="alert-header">
    <img [src]="_img" />
  </div>
  <div class="alert-body">
    <span class="title">
      {{ _title | translate }}
    </span>
    <span class="message">
      {{ _message | translate }}
    </span>
  </div>
  <div class="alert-footer">
    <ng-template [ngIf]="_showAction">
      <monkey-checkbox
        [description]="'FIELD.DONT-SHOW-ANYMORE' | translate"
        (onChange)="onHandleChange($event)"
      >
      </monkey-checkbox>
    </ng-template>

    <monkey-button type="tertiary" color="theme" (click)="onClose()">
      {{ 'BUTTONS.CLOSE' | translate }}
    </monkey-button>
  </div>
</monkey-modal>

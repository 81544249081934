import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxDirectivesModule } from 'monkey-front-core';
import { MonkeyBadgeModule, MonkeyButtonModule, MonkeyIconModule } from 'monkey-style-guide';
import { NavCollapsableComponent } from './collapsable/collapsable.component';
import { NavItemComponent } from './item/item.component';
import { NavigationComponent } from './navigation.component';

@NgModule({
  declarations: [NavigationComponent, NavItemComponent, NavCollapsableComponent],
  imports: [
    MonkeyEcxCommonModule,
    RouterModule,
    MonkeyEcxi18nModule,
    MonkeyButtonModule,
    MonkeyIconModule,
    MonkeyBadgeModule,
    MonkeyEcxDirectivesModule
  ],
  exports: [NavigationComponent]
})
export class NavigationModule {}

<monkeyecx-progress-bar></monkeyecx-progress-bar>

<div id="main">
  <div id="container-1" class="container">
    <ng-container *ngTemplateOutlet="navBar"></ng-container>
    <div id="container-2" class="container">
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
      <ng-container *ngTemplateOutlet="navBarCompanies"></ng-container>
      <div
        id="container-3"
        class="container"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="content.onScrollDown.next()"
      >
        <app-content #content></app-content>
      </div>
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    </div>
  </div>
</div>

<ng-template #toolbar>
  <app-toolbar-buyer [ngClass]="'below-fixed monkey-navy-400'"></app-toolbar-buyer>
</ng-template>

<ng-template #footer>
  <app-footer [ngClass]="'below-fixed monkey-navy-400'"></app-footer>
</ng-template>

<ng-template #navBar>
  <app-sidebar
    name="navbar"
    class="navbar-app-sidebar"
    [folded]="true"
    lockedOpen="gt-sm"
    [foldedAutoTriggerOnHover]="false"
  >
    <app-navbar class="left-navbar"></app-navbar>
    <app-sidebar-actions
      companyType="buyer"
      [mainAction]="{ icon: 'cash-banknote-invoice-24', route: '/app/buyer/operation-summary' }"
    >
    </app-sidebar-actions>
  </app-sidebar>
</ng-template>

<ng-template #navBarCompanies>
  <app-sidebar
    name="navbar-companies"
    class="navbar-app-sidebar"
    [folded]="false"
    [foldedAutoTriggerOnHover]="false"
    [opened]="false"
  >
    <app-navbar-companies class="left-navbar"></app-navbar-companies>
  </app-sidebar>
</ng-template>

/* eslint-disable no-restricted-syntax */
import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MonkeyEcxNavigationItem } from 'monkey-front-core';
import { merge, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationService } from '../navigation.service';

@Component({
  selector: 'app-nav-collapsable',
  templateUrl: './collapsable.component.html',
  styleUrls: ['./collapsable.component.scss']
})
export class NavCollapsableComponent implements OnInit, OnDestroy {
  @Input() item: MonkeyEcxNavigationItem;

  @HostBinding('class') classes = 'nav-collapsable nav-item';

  @HostBinding('class.open') public isOpen = false;

  private unsubscribeAll: Subject<any>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService,
    private router: Router
  ) {
    this.unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationEnd;
        }),
        takeUntil(this.unsubscribeAll)
      )
      .subscribe((event: NavigationEnd) => {
        if (this.isUrlInChildren(this.item, event.urlAfterRedirects)) {
          this.expand();
        } else {
          this.collapse();
        }
      });

    this.navigationService._onItemCollapsed
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((clickedItem: any) => {
        if (clickedItem && clickedItem.children) {
          if (this.isChildrenOf(this.item, clickedItem)) {
            return;
          }

          if (this.isUrlInChildren(this.item, this.router.url)) {
            return;
          }

          if (this.item !== clickedItem) {
            this.collapse();
          }
        }
      });

    if (this.isUrlInChildren(this.item, this.router.url)) {
      this.expand();
    } else {
      this.collapse();
    }

    merge(
      this.navigationService._onNavigationItemAdded,
      this.navigationService._onNavigationItemUpdated,
      this.navigationService._onNavigationItemRemoved
    )
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  toggleOpen(ev: any) {
    ev.preventDefault();

    this.isOpen = !this.isOpen;

    this.navigationService._onItemCollapsed.next(this.item);
    this.navigationService._onItemCollapseToggled.next();
  }

  expand() {
    if (this.isOpen) {
      return;
    }

    this.isOpen = true;

    this.changeDetectorRef.markForCheck();
    this.navigationService._onItemCollapseToggled.next();
  }

  collapse() {
    if (!this.isOpen) {
      return;
    }

    this.isOpen = false;

    this.changeDetectorRef.markForCheck();
    this.navigationService._onItemCollapseToggled.next();
  }

  isChildrenOf(parent: any, item: any) {
    const { children } = parent;

    if (!children) {
      return false;
    }

    if (children.indexOf(item) > -1) {
      return true;
    }

    for (const child of children) {
      if (child.children) {
        if (this.isChildrenOf(child, item)) {
          return true;
        }
      }
    }

    return false;
  }

  isUrlInChildren(parent: any, url: any) {
    const { children } = parent;

    if (!children) {
      return false;
    }

    for (const child of children) {
      if (child.children) {
        if (this.isUrlInChildren(child, url)) {
          return true;
        }
      }

      if (child.url === url || url.includes(child.url)) {
        return true;
      }
    }

    return false;
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Company, PaginationData } from 'app/interfaces';
import { shared } from '../../actions';

export const featureKey = 'shared-companies';

export interface State extends EntityState<Company> {
  error: any;
  pagination?: PaginationData;
  control: {
    isLoading: boolean;
  };
}

export const adapter: EntityAdapter<Company> = createEntityAdapter<Company>({
  selectId: (item: Company) => {
    return item?.id;
  },
  sortComparer: null
});

export const initialState: State = adapter.getInitialState({
  error: null,
  pagination: null,
  control: {
    isLoading: false
  }
});

export const reducer = createReducer(
  initialState,
  on(shared.companiesActions.updateOne, (state, action) => {
    return adapter.upsertOne(action.data, {
      ...state
    });
  }),
  on(shared.companiesActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, state);
  }),
  on(shared.companiesActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: action.data
    };
  }),
  on(shared.companiesActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(shared.companiesActions.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

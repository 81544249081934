import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { seller } from '@store/actions';
import { buildIdByLink } from '@utils';
import { OfferReceivementAccountData } from 'app/interfaces';

export const featureKey = 'seller-offer-receivement-account';

export interface State extends EntityState<OfferReceivementAccountData> {
  control: {
    isLoading: boolean;
  };
  error: any;
}

export const adapter: EntityAdapter<OfferReceivementAccountData> =
  createEntityAdapter<OfferReceivementAccountData>({
    selectId: (item: OfferReceivementAccountData) => {
      return buildIdByLink<OfferReceivementAccountData>(item);
    }
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(seller.offerReceivementAccountActions.updateOne, (state, action) => {
    return adapter.upsertOne(
      { ...action.data },
      {
        ...state
      }
    );
  }),
  on(seller.offerReceivementAccountActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

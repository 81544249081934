import { createAction, props } from '@ngrx/store';
import { SharedReport, SharedReportParams } from 'app/interfaces';

export const reportActions = {
  start: createAction('[Report] Start', props<SharedReportParams>()),
  create: createAction('[Report] Create', props<{ data: SharedReport }>()),
  update: createAction('[Report] Update', props<{ data: SharedReport }>()),
  delete: createAction('[Report] Delete', props<{ data: SharedReport }>()),
  clear: createAction('[Report] Clear All')
};

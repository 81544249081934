import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction(
  '[MECX Shared Recomposition] Clear All',
  props<{ identifier: string }>()
);
const load = createAction(
  '[MECX Shared Recomposition] Load',
  props<{ identifier: string; url: string }>()
);
const save = createAction(
  '[MECX Shared Recomposition] Save',
  props<{
    url: string;
    companyType: string;
    data: interfaces.Recomposition;
    identifier: string;
  }>()
);
const updateOne = createAction(
  '[MECX Shared Recomposition] Update One',
  props<{ data: interfaces.Recomposition }>()
);
const updateControl = createAction(
  '[MECX Shared Recomposition] Update Control',
  props<{ data: any }>()
);

export { clear, load, save, updateOne, updateControl };

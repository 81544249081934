import { createAction, props } from '@ngrx/store';
import { BuyerOperationSupplierSummariesData, Pagination } from 'app/interfaces';

export const operationSupplierSummariesActions = {
  clear: createAction('[Buyer OperationSupplierSummaries] Clear All'),
  delete: createAction(
    '[Buyer OperationSupplierSummaries] Delete',
    props<{ data: BuyerOperationSupplierSummariesData }>()
  ),
  load: createAction(
    '[Buyer OperationSupplierSummaries] Load',
    props<{ url: string; identifier: string }>()
  ),
  updateAll: createAction(
    '[Buyer OperationSupplierSummaries] Update All',
    props<{ data: BuyerOperationSupplierSummariesData[] }>()
  ),
  updateControl: createAction(
    '[Buyer OperationSupplierSummaries] Update Control',
    props<{ data: any }>()
  ),
  updateOne: createAction(
    '[Buyer OperationSupplierSummaries] Update One',
    props<{ data: BuyerOperationSupplierSummariesData }>()
  ),
  updatePagination: createAction(
    '[Buyer OperationSupplierSummaries] Update Pagination',
    props<{ data: any }>()
  )
};

export const operationSupplierSummariesPaginationActions = {
  load: createAction(
    '[Buyer OperationSupplierSummariesPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BuyerOperation } from 'app/interfaces';
import { buyer } from '../../actions';

export const featureKey = 'buyer-operation';

export interface State extends EntityState<BuyerOperation> {
  error: any;
  control: {
    isLoading: boolean;
  };
}

export const adapter: EntityAdapter<BuyerOperation> = createEntityAdapter<BuyerOperation>({
  sortComparer: null
});

export const initialState: State = adapter.getInitialState({
  error: null,
  control: {
    isLoading: false
  }
});

export const reducer = createReducer(
  initialState,
  on(buyer.operationActions.create, (state, action) => {
    return adapter.setOne(action.data, {
      ...state
    });
  }),
  on(buyer.operationActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(buyer.operationActions.updateOperationData, (state, action) => {
    const { id } = action.data;
    const data = state.entities[id];
    return adapter.upsertOne(
      {
        ...data,
        operationData: {
          ...data?.operationData,
          ...action?.data
        }
      },
      {
        ...state
      }
    );
  }),
  on(buyer.operationActions.updateOperationSummaryData, (state, action) => {
    const { id } = action.data;
    const data = state.entities[id];
    return adapter.upsertOne(
      {
        ...data,
        operationSummaryData: {
          ...data?.operationSummaryData,
          ...action?.data
        }
      },
      {
        ...state
      }
    );
  }),
  on(buyer.operationActions.updateProcessData, (state, action) => {
    const { id } = action.data;
    const data = state.entities[id];
    return adapter.upsertOne(
      {
        ...data,
        processData: {
          ...data?.processData,
          ...action?.data
        }
      },
      {
        ...state
      }
    );
  }),
  on(buyer.operationActions.updateRecalcData, (state, action) => {
    const { id } = action.data;
    const data = state.entities[id];
    return adapter.upsertOne(
      {
        ...data,
        recalcData: {
          ...data?.recalcData,
          ...action?.data
        }
      },
      {
        ...state
      }
    );
  }),
  on(buyer.operationActions.delete, (state, action) => {
    return adapter.removeOne(action.data.id, state);
  }),
  on(buyer.operationActions.clear, () => {
    return {
      ids: [],
      entities: {},
      control: { isLoading: false },
      error: null
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

import { paddLeft } from 'app/core/utils/utils';
import * as moment from 'moment';

export class MarketModel {
  closedMarketHour: number;

  lastWorkingDay: string;

  marketStatus: string;

  nextWorkingDay: string;

  openMarketHour: number;

  today: string;

  constructor(data?: any) {
    this.closedMarketHour = data?.closedMarketHour;
    this.lastWorkingDay = data?.lastWorkingDay;
    this.marketStatus = data?.marketStatus;
    this.nextWorkingDay = data?.nextWorkingDay;
    this.openMarketHour = data?.openMarketHour;
    this.today = data?.today;

    const end = moment(`${this.today} ${paddLeft(data?.closedMarketHour)}:00:00`);
    const now = moment();
    const duration = moment.duration(end.diff(now));

    if (this.marketStatus === 'OPEN') {
      if (duration.hours() <= 2) {
        this.marketStatus = 'CLOSED_SOON';
      }
    }
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller } from '@store/reducers';
import { OfferItemData } from 'app/interfaces';

declare type State = seller.fromOfferItems.State;

export const selectOfferItemsState = createFeatureSelector<seller.fromOfferItems.State>(
  seller.fromOfferItems.featureKey
);

export const selectAllOfferItems = createSelector(
  selectOfferItemsState,
  seller.fromOfferItems.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(selectAllOfferItems, (entities: OfferItemData[]): OfferItemData[] => {
    return entities.filter((_: OfferItemData) => {
      return _.identifier === props.identifier;
    });
  });
};

export const selectControl = () => {
  return createSelector(selectOfferItemsState, (state: State): any => {
    return state.control;
  });
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(selectOfferItemsState, (state: State): any => {
    return state.pagination?.[props.identifier];
  });
};

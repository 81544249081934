import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedBanks } from 'app/interfaces';
import { sponsor as sponsorAction } from 'app/store/actions';
import { sponsor as sponsorSelector } from 'app/store/selectors';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxConfigService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class BanksService extends MonkeyEcxCommonsService {
  constructor(
    private monkeyConfigService: MonkeyEcxConfigService,
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: SharedBanks[]) {
    const { store } = this;

    store.dispatch(
      sponsorAction.banksActions.create({
        data
      })
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  public async getData() {
    const { monkeyConfigService } = this;

    const config: any = await monkeyConfigService
      .config()
      .pipe(take(1))
      .toPromise();

    this.update(config?.banks || null);
  }

  private async verifyBeforeStart() {
    const { store } = this;

    const data = await store
      .select(sponsorSelector.banks.exists())
      .pipe(take(1))
      .toPromise();

    if (data) return;

    this.getData();
  }

  public start() {
    this.verifyBeforeStart();
  }
}

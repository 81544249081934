import { createAction, props } from '@ngrx/store';
import { MonkeyEcxMeCredentials } from 'monkey-front-core';

const logout = createAction('[MECX User Context] Logout');

const saveMeCredentials = createAction(
  '[MECX User Context] Save Me Credentials',
  props<{ data: MonkeyEcxMeCredentials }>()
);

export { logout, saveMeCredentials };

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { InvoiceSummariesData } from 'app/interfaces';
import { buyer as actions } from 'app/store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class InvoiceSummariesService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.invoiceSummariesActions.updateControl({
        data
      })
    );
  }

  private update(data: any, identifier: string) {
    this.store.dispatch(
      actions.invoiceSummariesActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService } = this;
    this.updateControl({ isLoading: true });

    const data = await monkeyecxService.get<InvoiceSummariesData>(url).toPromise();

    this.update(data, identifier);

    this.updateControl({ isLoading: false });
  }

  public async load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

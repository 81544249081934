import { MonkeyEcxModel, MonkeyEcxUtils } from 'monkey-front-core';

interface Link {
  href: string;
  type: string;
}

interface Links {
  approve: Link;
  documents: Link;
  offer: Link;
  self: Link;
  seller: Link;
  unapprove: Link;
}

class Signatures {
  email: string;

  governmentId: string;

  name: string;

  status: string;

  constructor(data?: any) {
    this.email = data?.email;
    this.governmentId = data?.governmentId;
    this.name = data?.name;
    this.status = data?.status;
  }
}

export class AssignmentModel extends MonkeyEcxModel {
  amount: number;

  assignmentDocumentHash: string;

  buyerGovernmentId: string;

  buyerName: string;

  buyerSignature: string[];

  buyerSignatureStatus: string;

  buyerSignatures: Signatures[];

  createUserId: string;

  createUserIp: string;

  createdAt: Date;

  lastUserId: string;

  lastUserIp: string;

  notificationDocumentHash: string;

  reportCode: string;

  sellerGovernmentId: string;

  sellerName: string;

  sellerSignature: string[];

  sellerSignatureStatus: string;

  sellerSignatures: Signatures[];

  sponsorGovernmentId: string;

  sponsorName: string;

  sponsorSignature: string[];

  sponsorSignatureStatus: string;

  updatedAt: Date;

  initials: string;

  _links: Links;

  constructor(data?: any) {
    super();
    this.amount = data?.amount;
    this.buyerGovernmentId = data?.buyerGovernmentId;
    this.buyerName = data?.buyerName;
    this.buyerSignatureStatus = data?.buyerSignatureStatus;
    this.buyerSignatures = data?.buyerSignatures?.map((val: any) => {
      return new Signatures(val);
    });
    this.createUserId = data?.createUserId;
    this.createUserIp = data?.createUserIp;
    this.createdAt = data?.createdAt;
    this.lastUserId = data?.lastUserId;
    this.lastUserIp = data?.lastUserIp;
    this.reportCode = data?.reportCode;
    this.sellerGovernmentId = data?.sellerGovernmentId;
    this.sellerName = data?.sellerName;
    this.sellerSignature = data?.sellerSignature?.split('\n');
    this.buyerSignature = data?.buyerSignature?.split('\n');
    this.sponsorSignature = data?.sponsorSignature?.split('\n');
    this.sellerSignatureStatus = data?.sellerSignatureStatus;
    this.sellerSignatures = data?.sellerSignatures?.map((val: any) => {
      return new Signatures(val);
    });
    this.sponsorGovernmentId = data?.sponsorGovernmentId;
    this.sponsorName = data?.sponsorName;
    this.sponsorSignatureStatus = data?.sponsorSignatureStatus;
    this.updatedAt = data?.updatedAt;
    this.assignmentDocumentHash = data?.assignmentDocumentHash;
    this.notificationDocumentHash = data?.notificationDocumentHash;
    this._links = data?._links;
    this.initials = this.getInitials();
  }

  private getInitials() {
    const { sellerName } = this;

    const first = MonkeyEcxUtils.cutFirstLastName(sellerName, 'first');
    const second = MonkeyEcxUtils.cutFirstLastName(sellerName, 'last');

    return first && second
      ? `${first.substring(0, 1)}${second.slice(0, 1)}`
      : `${sellerName.substring(0, 2)}`;
  }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';
import { credit as actions } from '@shared-store/actions';
import { buildIdByLink } from '@shared/app/core/utils/utils';

export const featureKey = 'mecx-shared-mail-configuration';

export interface State extends EntityState<interfaces.MailCampaignData> {
  control: {
    isLoading: boolean;
  };
  sidebar: {
    selectedStep: string;
    selectedSubSteps: string;
  };
  error: any;
}

export const adapter: EntityAdapter<interfaces.MailCampaignData> =
  createEntityAdapter<interfaces.MailCampaignData>({
    selectId: (item: interfaces.MailCampaignData) => {
      return buildIdByLink<interfaces.MailCampaignData>(item);
    }
  });

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false
  },
  sidebar: {
    selectedStep: '',
    selectedSubSteps: ''
  },
  error: null
});

export const reducer = createReducer(
  initialState,
  on(actions.mailConfiguration.createCampaign, (state, action) => {
    return adapter.setOne(action.data, {
      ...state
    });
  }),
  on(actions.mailConfiguration.removeOne, (state, action) => {
    const key = buildIdByLink<interfaces.MailCampaignData>(action.data);
    return adapter.removeOne(key, state);
  }),
  on(actions.mailConfiguration.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(actions.mailConfiguration.updateSidebar, (state, action) => {
    return {
      ...state,
      sidebar: {
        ...state.sidebar,
        ...action.data
      }
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

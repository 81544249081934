import { createFeatureSelector, createSelector } from '@ngrx/store';
import { urlHasField } from '@utils';
import { Company } from 'app/interfaces';
import { shared } from 'app/store/reducers';
import { parseUrl } from 'query-string';

declare type State = shared.fromCompaniesActions.State;

export const selectCompaniesActionsState = createFeatureSelector<shared.fromCompaniesActions.State>(
  shared.fromCompaniesActions.featureKey
);

export const selectAllCompaniesActions = createSelector(
  selectCompaniesActionsState,
  shared.fromCompaniesActions.selectAll
);

export const selectCompanyById = (props: {
  id: string;
  companyType: string;
}) => {
  return createSelector(
    selectAllCompaniesActions,
    (entities: Company[]): Company => {
      const { id } = props;
      return entities.find((_: Company) => {
        return (
          `${_.id}` === `${id}`
        );
      });
    }
  );
};

export const selectPagination = () => {
  return createSelector(
    selectCompaniesActionsState,
    (state: State): any => {
      const { pagination } = state;
      return pagination;
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectCompaniesActionsState,
    (state: State): any => {
      return state?.control;
    }
  );
};

export const exists = () => {
  return createSelector(
    selectAllCompaniesActions,
    (entities: Company[]): boolean => {
      return !!entities?.length;
    }
  );
};

export const paginationHasDifference = (props: { url: string }) => {
  return createSelector(
    selectCompaniesActionsState,
    (state: State): any => {
      const { pagination } = state;
      const { url } = props;
      let hasDifference = false;
      let hasField = false;

      try {
        const handledPaginationUrl = parseUrl(`${pagination?.url}`);
        const handledUrl = parseUrl(`${url}`);
        hasDifference = (handledPaginationUrl?.query?.search !== handledUrl?.query?.search) ||
        (handledPaginationUrl?.query?.sort !== handledUrl?.query?.sort);
        hasField = urlHasField(url, 'search') || urlHasField(url, 'sort');
      } catch (e) {
        // not to do
      }

      return {
        hasDifference,
        hasField
      };
    }
  );
};

import { createAction, props } from '@ngrx/store';
import { BuyerOperationSummaryData, Pagination } from 'app/interfaces';

export const operationSummaryActions = {
  clear: createAction('[Buyer OperationSummary] Clear All'),
  delete: createAction(
    '[Buyer OperationSummary] Delete',
    props<{ data: BuyerOperationSummaryData }>()
  ),
  load: createAction('[Buyer OperationSummary] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction(
    '[Buyer OperationSummary] Update All',
    props<{ data: BuyerOperationSummaryData[] }>()
  ),
  updateControl: createAction('[Buyer OperationSummary] Update Control', props<{ data: any }>()),
  updateOne: createAction(
    '[Buyer OperationSummary] Update One',
    props<{ data: BuyerOperationSummaryData }>()
  ),
  updatePagination: createAction(
    '[Buyer OperationSummary] Update Pagination',
    props<{ data: any }>()
  )
};

export const operationSummaryPaginationActions = {
  load: createAction(
    '[Buyer OperationSummaryPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

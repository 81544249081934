import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PayablesData } from 'app/interfaces';
import { sponsor } from 'app/store/reducers';

declare type State = sponsor.fromPayablesDetails.State;

export const selectPayablesDetailsState = createFeatureSelector<sponsor.fromPayablesDetails.State>(
  sponsor.fromPayablesDetails.featureKey
);

export const selectAllPayablesDetails = createSelector(
  selectPayablesDetailsState,
  sponsor.fromPayablesDetails.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllPayablesDetails,
    (entities: PayablesData[]): PayablesData => {
      return entities.find((_: PayablesData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectPayablesDetailsState,
    (state: State): any => {
      return state.control;
    }
  );
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sponsor as actions } from 'app/store/actions';
import { sponsor as services } from 'app/store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class PayablesDetailsEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.payablesDetailsActions.load),
        map(({ identifier, url }) => {
          this.service.load(url, identifier);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.PayablesDetailsService) {
    // not to do
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { MonkeyModalConfig, MonkeyStyleGuideModalService } from 'monkey-style-guide';

@Component({
  selector: 'shared-generic-alerts',
  templateUrl: './generic-alerts.component.html',
  styleUrls: ['./generic-alerts.component.scss']
})
export class SharedGenericAlertsComponent implements OnInit {
  @Input() config: MonkeyModalConfig | null = null;

  @Input() modalRef: any;

  _img = '';

  _title = '';

  _message = '';

  _showAction = true;

  _dontShowAnymore = false;

  private callback: Function = () => {};

  constructor(private modalService: MonkeyStyleGuideModalService) {
    // not to do
  }

  ngOnInit() {
    const { config } = this;
    this._img = config?.data?.img;
    this._title = config?.data?.title;
    this._message = config?.data?.message;
    this._showAction = config?.data?.showAction ?? true;
    this.callback = config?.data?.callback;
  }

  onClose() {
    this.modalService.close(this.modalRef);
    this.callback?.(this._dontShowAnymore);
  }

  onHandleChange(check: any) {
    this._dontShowAnymore = check;
  }
}

import { createAction, props } from '@ngrx/store';
import { BuyerOperationCheckout } from 'app/interfaces';

export const operationCheckoutActions = {
  start: createAction(
    '[Buyer OperationCheckout] Start OperationCheckout',
    props<{ id: string; password: any }>()
  ),
  create: createAction(
    '[Buyer OperationCheckout] Create OperationCheckout',
    props<{ data: BuyerOperationCheckout }>()
  ),
  updateProcessData: createAction(
    '[Buyer OperationCheckout] Update OperationCheckout ProcessData',
    props<{ data: any }>()
  ),
  updateControl: createAction(
    '[Buyer OperationCheckout] Update OperationCheckout Control',
    props<{ data: any }>()
  ),
  delete: createAction(
    '[Buyer OperationCheckout] Delete OperationCheckout',
    props<{ data: BuyerOperationCheckout }>()
  ),
  clear: createAction('[Buyer OperationCheckout] Clear All OperationCheckout')
};

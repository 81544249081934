import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const startWithMap = createAction(
  '[MECX Shared Banks] Start With Map',
  props<{ companyType: string }>()
);
const create = createAction('[MECX Shared Banks] Create', props<{ data: interfaces.Banks[] }>());
const clear = createAction('[MECX Shared Banks] Clear All');

export { clear, create, startWithMap };

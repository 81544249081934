import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { sponsor } from '@store/actions';
import { buildIdByLink } from '@utils';
import { PaginationData, SignaturesData } from 'app/interfaces';

export const featureKey = 'sponsor-signatures';

export interface State extends EntityState<SignaturesData> {
  control: {
    isLoading: boolean;
    isLoadingWaiting: boolean;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<SignaturesData> = createEntityAdapter<SignaturesData>({
  selectId: (item: SignaturesData) => {
    return buildIdByLink<SignaturesData>(item);
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: false,
    isLoadingWaiting: false
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(sponsor.signaturesActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(sponsor.signaturesActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  }),
  on(sponsor.signaturesActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(sponsor.signaturesActions.clear, (state: any) => {
    return adapter.removeAll(state);
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

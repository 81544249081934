import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuyerOperation } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

declare type State = buyer.fromOperation.State;

export const selectOperationState = createFeatureSelector<buyer.fromOperation.State>(
  buyer.fromOperation.featureKey
);

export const selectAllOperation = createSelector(
  selectOperationState,
  buyer.fromOperation.selectAll
);

export const selectById = (props: { id: string }) => {
  return createSelector(
    selectAllOperation,
    (entities: BuyerOperation[]): BuyerOperation => {
      return entities.find((_: any) => {
        return _.id === props.id;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectOperationState,
    (state: State): any => {
      return state?.control;
    }
  );
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MarketService extends MonkeyEcxCommonsService implements Resolve<any> {
  private route: string = `${environment.baseUrl}/v2/sellers`;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private router: Router
  ) {
    super(monkeyecxService, tokenStorage);
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [403]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  public async getData() {
    const { monkeyecxService, route, tokenStorage } = this;
    const { companyId } = await tokenStorage.getToken();
    this.__onLoadingInProgress$.next(true);
    monkeyecxService.get<any>(`${route}/${companyId}/markets`).subscribe(
      (resp: any) => {
        this.__data = resp;
        this.__onDataChanged$.next(null);
        this.__onLoadingInProgress$.next(false);
      },
      (err: any) => {
        this.__data = null;
        this.__page = null;
        this.__onDataChanged$.next(null);
        this.__onLoadingInProgress$.next(false);
      }
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const { router } = this;
    super.resolve(route, state, {
      callbackMain: () => {
        this.__search = null;
        this.__data = null;
        this.__page = null;
        this.getData();
      },
      router
    });
    return null;
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BuyerOperationSimulation } from 'app/interfaces';
import { buyer } from 'app/store/reducers';

export const selectOperationSimulationState = createFeatureSelector<
  buyer.fromOperationSimulation.State
>(buyer.fromOperationSimulation.featureKey);

export const selectAllOperationSimulation = createSelector(
  selectOperationSimulationState,
  buyer.fromOperationSimulation.selectAll
);

export const selectById = (props: { id: string }) => {
  return createSelector(
    selectAllOperationSimulation,
    (entities: BuyerOperationSimulation[]): BuyerOperationSimulation => {
      return entities.find((_: any) => {
        return _.id === props.id;
      });
    }
  );
};

export const selectShowBankAccounts = (props: { id: string }) => {
  const { id } = props;
  return createSelector(
    selectById({ id }),
    (entity: BuyerOperationSimulation): boolean => {
      return entity.simulationData.showChangeAccount;
    }
  );
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { buyer as actions } from '@store/actions';
import { buyer as services } from '@store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class BuyerSellersDetailsEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.buyerSellersDetails.load),
        map((args) => {
          this.service.load(args);
        })
      );
    },
    {
      dispatch: false
    }
  );

  download$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.buyerSellersDetails.api.download),
        map(({ data }) => {
          this.service.download(data);
        })
      );
    },
    {
      dispatch: false
    }
  );

  updateStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.buyerSellersDetails.api.updateStatus),
        map(({ data, statusType }) => {
          this.service.updateStatus(data, statusType);
        })
      );
    },
    {
      dispatch: false
    }
  );

  updateRisk$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.buyerSellersDetails.api.updateRisk),
        map(({ data, risk }) => {
          this.service.updateRisk(data, risk);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.BuyerSellersDetailsService) {
    // not to do
  }
}

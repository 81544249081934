import { HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { MonkeyEcxAuthCredentials, MonkeyEcxConfig } from 'monkey-front-core';
import { first } from 'rxjs/operators';

export function accessExternalApp(url: string, data: any, openBlank = true) {
  const inputContent = document.createElement('input');
  inputContent.name = 'monkey-token';
  inputContent.type = 'hidden';
  inputContent.value = data;

  const form = document.createElement('form');
  form.method = 'POST';
  if (openBlank) {
    form.target = '_blank';
  }
  form.action = url;
  form.enctype = 'multipart/form-data';
  form.style.display = 'none';
  form.appendChild(inputContent);
  document.body.appendChild(form);
  form.submit();
}

export function isSameOrigin(path: string): boolean {
  const initialPath: string = window.location.origin;
  return (initialPath || '') === path;
}

export function pageReposition(rootName?: string) {
  const container3 = document.getElementById('container-3');
  if (container3) container3.scrollTop = 0;
  if (rootName) {
    const root = document.getElementsByTagName(rootName).item(0);
    if (root) root.scrollTop = 0;
    const rootById = document.getElementById(rootName);
    if (rootById) rootById.scrollTop = 0;
  }
}

export function getLoginAppRedirect(param: MonkeyEcxAuthCredentials, config: MonkeyEcxConfig) {
  const { host } = window.location;
  const app = environment.apps.login;
  const params = new HttpParams()
    .append('username', param.username)
    .append('program', `${config?.program?.token}` || 'SANDBOX')
    .append('redirect_uri', `https://${host}/sso`)
    .toString();

  let path = `${app}/login-sso?${params}`;
  if (isSameOrigin(app)) {
    path = `/login-fed/${param.username}`;
  }

  return {
    isSameOrigin: isSameOrigin(app),
    path
  };
}

export function getProgramByProduct(program: string, product: string) {
  let alias = '';
  if (product === 'SPIKE') {
    if (!'REDE#MARKETPLACE#'.includes(program)) {
      alias = 'SPK_';
    }
  }

  return `${alias}${program}`;
}

export function getFirstResult() {
  return first((val: any) => {
    return !!val && JSON.stringify(val) !== '{}';
  });
}

export const environment = {
  environment: 'prod',
  country: 'br',
  lang: 'pt-BR',
  countryTzName: 'Etc/GMT+3',
  production: true,
  baseUrl: 'https://gateway.monkey.credit',
  urlZipCodeSearch: 'https://viacep.com.br/ws',
  urlAddressSearch: 'https://api-address.monkey.exchange/',
  urlAddressSearchOld: 'https://api-adress.monkey.exchange/',
  urlAssets: 'https://assets.monkey.exchange/credit',
  urli18nAssets: 'https://assets.monkey.exchange/credit/data/i18n/{name}/{lang}.json',
  localAssets: '/assets',
  bootstrapAssets: '/assets/monkeyecx/bootstrap.json',
  client_secret: '74f6d5c4-201f-494d-bda6-141dba9473c3',
  client_id: 'monkey-browser',
  grant_type: 'password',
  idp_client_secret: 'vs792qlw41g38u0ft3f34ttxi21cc9x9s9llcsrlnogjb3b3j6',
  idp_client_id: 'monkey-idp-login',
  scope: 'ui',
  dataDogApplicationId: 'd16f61f0-1bcd-4f9f-8143-060df2c47269',
  dataDogClientToken: 'pub0a246a48a9ea11ba9f023822388eda28',
  termsUrl: {
    privacyPolicy: '/assets/files/monkey-politica-privacidade.pdf',
    useTerms: '/assets/files/termos-supply-bank.pdf',
    mfa: 'https://monkey.exchange/mfa.html?redirect=false'
  },
  launchDarklyClientSideID: '62b32c985da31a12c2cf3966',
  zenDeskKey: 'd7f9d06d-f8b2-456d-b871-e7f321519f91',
  zenDeskUrlDoc: '',
  apps: {
    login: 'https://login.monkey.credit'
  },
  paths: {
    redirectAfterLogin: '/app/profile/companies',
    redirectAfterLoginProduct: '/app/profile/companies',
    redirectApp: '/app/profile',
    redirectByCompanyType: (type: string) => {
      if (!type) return '/app/register/profiles';
      return `/app/${type}/home`;
    }
  },
  urlDomain: 'monkey.credit',
  product: 'SUPPLY_CHAIN',
  genericAlerts: {
    seller: {
      pathToShow: '/app/',
      pathToEvict: 'shopping',
      name: 'MECX-ALERTS.HOLIDAYS.30-12-2022',
      img: 'https://assets.monkeyecx.com/scf/images/alert-header.svg',
      startDate: '2022-12-26 00:00:00',
      endDate: '2022-12-30 23:59:59'
    },
    buyer: {
      pathToShow: '/app/',
      pathToEvict: 'operation-summary',
      name: 'MECX-ALERTS.HOLIDAYS.30-12-2022',
      img: 'https://assets.monkeyecx.com/scf/images/alert-header.svg',
      startDate: '2022-12-26 00:00:00',
      endDate: '2022-12-30 23:59:59'
    }
  },
  gtm: null,
  ssoProviders: {
    'ITAU-PRD': {
      token: 'ITAU',
      url: 'https://riscosacado.itau.com.br'
    },
    'ITAU-WORKFORCE': {
      token: 'ITAU',
      url: 'https://riscosacado.itau.com.br'
    }
  },
  locale: {
    timezoneOffset: '-03:00',
    defaultCurrency: 'BRL',
    lang: 'pt-BR',
    dateFormat: 'dd/MM/yyyy'
  }
};

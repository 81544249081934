import { createFeatureSelector, createSelector } from '@ngrx/store';
import { sponsor } from '@store/reducers';
import { SignaturesData } from 'app/interfaces';

declare type State = sponsor.fromSignatures.State;

export const selectSignaturesState = createFeatureSelector<sponsor.fromSignatures.State>(
  sponsor.fromSignatures.featureKey
);

export const selectAllSignatures = createSelector(
  selectSignaturesState,
  sponsor.fromSignatures.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(selectAllSignatures, (entities: SignaturesData[]): SignaturesData[] => {
    const result = entities.filter((_: SignaturesData) => {
      return _.identifier === props.identifier;
    });

    return result;
  });
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(selectSignaturesState, (state: State): any => {
    return state?.pagination?.[props.identifier];
  });
};

export const selectControl = () => {
  return createSelector(selectSignaturesState, (state: State): any => {
    return state.control;
  });
};

import { createAction, props } from '@ngrx/store';
import { BankAccountsData, Pagination } from 'app/interfaces';

export const bankAccountsActions = {
  clear: createAction('[Buyer BankAccounts] Clear All'),
  delete: createAction('[Buyer BankAccounts] Delete', props<{ data: BankAccountsData }>()),
  load: createAction('[Buyer BankAccounts] Load', props<{ url: string; identifier: string }>()),
  updateAll: createAction('[Buyer BankAccounts] Update All', props<{ data: BankAccountsData[] }>()),
  updateControl: createAction('[Buyer BankAccounts] Update Control', props<{ data: any }>()),
  updateOne: createAction('[Buyer BankAccounts] Update One', props<{ data: BankAccountsData }>()),
  updatePagination: createAction('[Buyer BankAccounts] Update Pagination', props<{ data: any }>())
};

export const bankAccountsPaginationActions = {
  load: createAction(
    '[Buyer BankAccountsPagination] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

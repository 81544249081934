import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Company } from 'app/interfaces';
import { shared as sharedActions } from 'app/store/actions';
import { shared as sharedSelectors } from 'app/store/selectors';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsService,
  MonkeyEcxPaginationService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService extends MonkeyEcxCommonsService implements Resolve<any> {
  private route: string = `${environment.baseUrl}/uaa/v2/profiles`;

  public _selected$: any;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store,
    private paginationService: MonkeyEcxPaginationService,
    private alertsService: MonkeyEcxAlertsService,
    private translateService: TranslateService
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private async handleData() {
    const { route, store, tokenStorage } = this;

    const url = `${route}?sort=createdAt,desc`;

    store.dispatch(sharedActions.companiesActionsActions.load({ url }));

    const { companyId, companyType } = await tokenStorage.getToken();

    if (companyId) {
      store.dispatch(
        sharedActions.companiesActionsActions.select({ id: companyId, companyType, url: route })
      );

      this._selected$ = store.select(
        sharedSelectors.companiesActions.selectCompanyById({ id: companyId, companyType })
      );
    }

    this.__data$ = store.select(sharedSelectors.companiesActions.selectAllCompaniesActions);

    this.__pagination$ = store.select(sharedSelectors.companiesActions.selectPagination());
  }

  private loadPage() {
    const { store } = this;

    store.dispatch(
      sharedActions.companiesActionsPaginationActions.load({
        pagination: {
          action: 'next'
        }
      })
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    super.resolve(route, state, {
      callbackMain: () => {
        this.__search = null;
        this.__data = null;
        this.__page = null;

        this.handleData();
      },
      paginationOptions: {
        service: this.paginationService,
        callback: this.loadPage.bind(this),
        name: 'companies'
      }
    });
  }

  async navigateToProfile(selected: Company) {
    const { store, route, alertsService, tokenStorage } = this;
    const { id, type } = selected;
    const { companyId } = await tokenStorage.getToken();

    if (`${id}` === `${companyId}`) {
      alertsService.show({
        title: 'INFORMATION',
        message: 'COMPANY-ALREADY-LOGGED',
        type: 'warning',
        duration: 4000
      });

      return;
    }

    store.dispatch(
      sharedActions.companiesActionsActions.select({
        id,
        companyType: type,
        url: route,
        option: 'home'
      })
    );
  }
}

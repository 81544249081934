import { buyer as interfaces } from '@interfaces';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxCommonsActions } from 'monkey-front-core';

const featureName = 'Buyer Dashboard General Summary';

export const filter = createAction(`[${featureName}] Filter`, props<{ filter: string }>());
export const load = createAction(`[${featureName}] Load`, props<{ url: string; filter: string }>());

export const { updateOne, clear, updateControl } =
  MonkeyEcxCommonsActions.getActions<interfaces.GeneralSummaryData>(featureName);

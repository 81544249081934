import { createAction, props } from '@ngrx/store';
import { GenericActionData, Pagination, SignaturesData } from 'app/interfaces';

export const signaturesActions = {
  clear: createAction('[Sponsor Signatures] Clear All', props<{ identifier: string }>()),
  load: createAction('[Sponsor Signatures] Start', props<{ url: string; identifier: string }>()),
  loadWaiting: createAction(
    '[Sponsor Signatures] Start Waiting',
    props<{ url: string; identifier: string }>()
  ),
  genericAction: createAction(
    '[Sponsor Signatures] Generic Action',
    props<{ data: GenericActionData }>()
  ),
  updateAll: createAction('[Sponsor Signatures] Update All', props<{ data: SignaturesData[] }>()),
  updateControl: createAction('[Sponsor Signatures] Update Control', props<{ data: any }>()),
  updatePagination: createAction('[Sponsor Signatures] Update Pagination', props<{ data: any }>())
};

export const signaturesPaginationActions = {
  load: createAction(
    '[Sponsor Signatures] Load',
    props<{ pagination: Pagination; identifier: string }>()
  )
};

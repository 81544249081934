import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { sponsor as selectors } from '@store/selectors';
import { GenericActionData, Pagination, SignaturesData } from 'app/interfaces';
import { AssignmentModel, LinksModel } from 'app/model';
import {
  MonkeyEcxAlertsService,
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class SignaturesService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private alertService: MonkeyEcxAlertsService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private updateControl(data: any) {
    const { store } = this;

    store.dispatch(
      actions.signaturesActions.updateControl({
        data
      })
    );
  }

  private updatePagination(data: any, identifier: string) {
    const { store } = this;

    store.dispatch(
      actions.signaturesActions.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  private update(data: SignaturesData[], identifier: string) {
    const { store } = this;

    store.dispatch(
      actions.signaturesActions.updateAll({
        data: data.map((_: SignaturesData) => {
          return {
            identifier,
            ..._
          };
        })
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, updatePagination = true) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<any>(url).toPromise();
      const items = this.getEmbeddedData(data, 'signatures');

      const { _links } = data;

      this.update(items, identifier);
      if (updatePagination) {
        this.updatePagination(
          {
            ..._links,
            url
          },
          identifier
        );
      }
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadDataWaiting(url: string, identifier: string) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoadingWaiting: true });

    try {
      const data = await monkeyecxService.get<any>(url).toPromise();
      const items = this.getEmbeddedData(data, 'signatures');

      const assignmentsWaiting = items.filter((assignment: AssignmentModel) => {
        return assignment.sponsorSignatureStatus === 'WAITING';
      });

      this.update(assignmentsWaiting, identifier);
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoadingWaiting: false });
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const { store } = this;

    const data = await store
      .select(selectors.signatures.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async handleGenericAction(data: GenericActionData) {
    const { monkeyecxService, alertService } = this;
    const { href, type } = new AssignmentModel(data.model).getAction(data.actionType);

    this.updateControl({ isLoadingWaiting: true });

    try {
      await monkeyecxService[type.toLowerCase()]<any>(href).toPromise();

      alertService.show({
        duration: 2000,
        message: 'UPDATE-MESSAGE',
        title: 'SUCCESS',
        type: 'success'
      });

      this.load(data._links.approved, data.identifier);
      this.loadWaiting(data._links.waiting, `${data.identifier}-WAITING`);
    } catch (e) {
      this.updateControl({ isLoadingWaiting: false });
    }
  }

  public async load(url: string, identifier: string) {
    this.store.dispatch(actions.signaturesActions.clear({ identifier }));
    this.loadData(url, identifier);
  }

  public loadWaiting(url: string, identifier: string) {
    this.store.dispatch(actions.signaturesActions.clear({ identifier }));
    this.loadDataWaiting(url, identifier);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }

  public genericAction(data: GenericActionData) {
    this.handleGenericAction(data);
  }
}

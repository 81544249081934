import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environments/environment';
import { Store } from '@ngrx/store';
import { shared } from '@shared-store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxMeCredentials,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { MonkeyStyleGuideModalFixedService } from 'monkey-style-guide';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MECXSharedFakeDataAlertComponent } from '../fake-data-alert.component';
import { UserModel } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class MECXSharedFakeDataAlertService
  extends MonkeyEcxCommonsService
  implements Resolve<any>
{
  private route: string = `${environment.baseUrl}/uaa/users`;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private modalService: MonkeyStyleGuideModalFixedService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private async submit() {
    const { monkeyecxService, route } = this;

    try {
      const others = btoa(JSON.stringify({ fakeDataHmgAccepted: true }));
      const me: MonkeyEcxMeCredentials = await this.tokenStorage.getMe();
      const payload = new UserModel({
        ...me,
        others
      }).toJSON();

      const data = await monkeyecxService.put<any>(route, payload).pipe(take(1)).toPromise();

      this.store.dispatch(shared.userContext.saveMeCredentials({ data }));

      this.modalService.closeAll();
    } catch (e) {
      // not to do
    }
  }

  private async handleData() {
    if (environment.production) return;
    const me: MonkeyEcxMeCredentials = await this.tokenStorage.getMe();
    let fakeDataHmgAccepted = false;

    try {
      const { others } = me;
      const handled = JSON.parse(atob(others) || '{}');
      fakeDataHmgAccepted = handled?.fakeDataHmgAccepted;
    } catch (e) {
      // not to do
    }

    if (!fakeDataHmgAccepted) {
      const cancel = () => {
        this.store.dispatch(shared.userContext.logout());
      };

      const submit = () => {
        this.submit();
      };

      this.modalService.closeAll();
      this.modalService.open(MECXSharedFakeDataAlertComponent, {
        color: 'theme',
        size: 'md',
        action: {},
        data: {
          cancel,
          submit
        },
        hideClose: true
      });
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    super.resolve(route, state, {
      callbackMain: () => {
        this.__search = route.queryParams;
        this.__data = null;
        this.__page = null;
        this.__links = null;

        this.handleData();
      }
    });
    return null;
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller } from '@store/reducers';
import { ReceivablesSummaryData } from 'app/interfaces';

declare type State = seller.fromReceivablesSummary.State;

export const selectReceivablesSummaryState =
  createFeatureSelector<seller.fromReceivablesSummary.State>(
    seller.fromReceivablesSummary.featureKey
  );

export const selectAllReceivablesSummary = createSelector(
  selectReceivablesSummaryState,
  seller.fromReceivablesSummary.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllReceivablesSummary,
    (entities: ReceivablesSummaryData[]): ReceivablesSummaryData[] => {
      return entities.filter((_: ReceivablesSummaryData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectReceivablesSummaryState,
    (state: State): any => {
      return state.control;
    }
  );
};

export const selectPagination = (props: { identifier: string }) => {
  return createSelector(
    selectReceivablesSummaryState,
    (state: State): any => {
      return state.pagination?.[props.identifier];
    }
  );
};

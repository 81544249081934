import { Injectable } from '@angular/core';
import { buyer as interfaces } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class DashboardService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private update(data: interfaces.DashboardData): void {
    this.store.dispatch(
      actions.dashboard.update({
        data
      })
    );
  }

  private updateControl(data: { isLoading: boolean }): void {
    this.store.dispatch(
      actions.dashboard.updateControl({
        data
      })
    );
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string): Promise<void> {
    const { monkeyecxService, store } = this;

    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<interfaces.DashboardData>(url).toPromise();
      const { _links: links } = data;

      store.dispatch(
        actions.dashboard.dailyOperation.load({
          identifier: '',
          url: links?.['daily-operation-summary']?.href
        })
      );

      store.dispatch(
        actions.dashboard.generalSummary.load({
          filter: '',
          url: links?.['program-summary']?.href
        })
      );

      store.dispatch(
        actions.dashboard.purchaseEvolution.load({
          identifier: '',
          url: links?.['purchase-evolution']?.href
        })
      );

      this.update(data);
    } finally {
      this.updateControl({ isLoading: false });
    }
  }

  public load(url: string): void {
    this.loadData(url);
  }
}

import { HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { SharedGenericAlertsComponent } from 'app/shared/components';
import { MonkeyEcxTokenCredentials, MonkeyEcxTokenStorageService } from 'monkey-front-core';
import { MonkeyStyleGuideModalFixedService } from 'monkey-style-guide';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from '../auth';

const IDLE_TIME = 900;

@Injectable({
  providedIn: 'root'
})
export class HandleIdleService {
  private count = 0;

  private timer = null;

  _onStart = new EventEmitter<HttpRequest<any>>();

  constructor(
    private storageService: MonkeyEcxTokenStorageService,
    private modalService: MonkeyStyleGuideModalFixedService,
    private authService: AuthService,
    private router: Router
  ) {
    this.init();
  }

  private init() {
    this._onStart.pipe(debounceTime(1000)).subscribe((request: HttpRequest<any>) => {
      this.verifyBeforeHandle(request);
    });
  }

  private verifyBeforeHandle(request: HttpRequest<any>) {
    const { url } = request;
    if (url.includes(environment.baseUrl) && !url.includes('/notifications')) {
      this.handle();
    }
  }

  private clear() {
    if (this.timer) {
      clearInterval(this.timer);
      this.count = 0;
      this.timer = null;
    }
  }

  private openAlert() {
    if (environment.environment !== 'hmg') {
      this.modalService.open(SharedGenericAlertsComponent, {
        color: 'theme',
        size: 'md',
        data: {
          img: '/assets/images/alert-header.svg',
          title: 'MODALS.IDLE-TIME.TITLE',
          message: 'MODALS.IDLE-TIME.MESSAGE',
          showAction: false
        }
      });
      this.authService.logOut(true, false, () => {
        this.router.navigate(['/']);
      });
    }
  }

  private async continueHandle() {
    this.clear();
    const data: MonkeyEcxTokenCredentials = await this.storageService.getToken();
    if (data.accessToken) {
      this.openAlert();
    }
  }

  private handle() {
    this.clear();

    this.timer = setInterval(() => {
      if (this.count >= IDLE_TIME) {
        this.continueHandle();
      }

      this.count += 1;
    }, 1000);
  }

  public destroy() {
    this.clear();
    this._onStart.complete();
  }
}

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DashboardData } from 'app/interfaces';
import { sponsor } from 'app/store/reducers';

declare type State = sponsor.fromDashboard.State;

export const selectDashboardState = createFeatureSelector<sponsor.fromDashboard.State>(
  sponsor.fromDashboard.featureKey
);

export const selectAllDashboard = createSelector(
  selectDashboardState,
  sponsor.fromDashboard.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAllDashboard,
    (entities: DashboardData[]): DashboardData => {
      return entities.find((_: DashboardData) => {
        return _.identifier === props.identifier;
      });
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectDashboardState,
    (state: State): any => {
      return state.control;
    }
  );
};

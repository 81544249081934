import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from '@modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from '@modules/monkeyecx-i18n.module';
import { MonkeyEcxDirectivesModule, MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyButtonModule } from 'monkey-style-guide';
import { MECXSharedActionsComponent } from './actions.component';
import { MECXSharedDynamicReportConfigModule } from './modal';
import {
  MECXSharedDynamicReportConfigService,
  MECXSharedDynamicReportExportService
} from './providers';

@NgModule({
  declarations: [MECXSharedActionsComponent],
  imports: [
    MonkeyButtonModule,
    MECXSharedDynamicReportConfigModule,
    MonkeyEcxCommonModule,
    MonkeyEcxDirectivesModule,
    MonkeyEcxPipesModule,
    MonkeyEcxi18nModule
  ],
  providers: [MECXSharedDynamicReportConfigService, MECXSharedDynamicReportExportService],
  exports: [MECXSharedActionsComponent]
})
export class MECXSharedActionsModule {}

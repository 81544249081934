import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxDirectivesModule, MonkeyEcxProgressBarModule } from 'monkey-front-core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContentModule } from '../components/content/content.module';
import { FooterModule } from '../components/footer/footer.module';
import { NavbarCompaniesModule } from '../components/navbar-companies/navbar-companies.module';
import { NavbarModule } from '../components/navbar/navbar.module';
import { SidebarActionsModule } from '../components/sidebar-actions';
import { SidebarModule } from '../components/sidebar/sidebar.module';
import { ToolbarBuyerModule } from '../components/toolbar-buyer/toolbar-buyer.module';
import { BuyerLayoutComponent } from './buyer-layout.component';

@NgModule({
  imports: [
    ContentModule,
    FooterModule,
    InfiniteScrollModule,
    MonkeyEcxProgressBarModule,
    NavbarModule,
    NavbarCompaniesModule,
    MonkeyEcxCommonModule,
    SidebarModule,
    ToolbarBuyerModule,
    SidebarActionsModule,
    MonkeyEcxDirectivesModule
  ],
  declarations: [BuyerLayoutComponent],
  exports: [BuyerLayoutComponent]
})
export class BuyerLayoutModule {}

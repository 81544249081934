import { Pagination, PayablesData } from '@interfaces';
import { PayableModel } from '@model';
import { createAction, props } from '@ngrx/store';
import { MonkeyEcxPage } from 'monkey-front-core';

const clear = createAction('[Sponsor Payables] Clear All', props<{ identifier: string }>());
const remove = createAction('[Sponsor Payables] Delete', props<{ data: PayablesData }>());
const removePayable = createAction(
  '[Sponsor Payables] Delete Payable',
  props<{ data: PayableModel }>()
);
const removeAllPayable = createAction(
  '[Sponsor Payables] Delete All Payable',
  props<{ identifier: string }>()
);
const load = createAction('[Sponsor Payables] Start', props<{ url: string; identifier: string }>());
const updateAll = createAction('[Sponsor Payables] Update All', props<{ data: PayablesData[] }>());
const updateControl = createAction('[Sponsor Payables] Update Control', props<{ data: any }>());
const updatePage = createAction('[Sponsor Payables] Update Page', props<{ data: MonkeyEcxPage }>());
const updatePagination = createAction(
  '[Sponsor Payables] Update Pagination',
  props<{ data: any }>()
);
const loadPagination = createAction(
  '[Sponsor Payables] Load Pagination',
  props<{ pagination: Pagination; identifier: string }>()
);

export {
  load,
  remove,
  removePayable,
  updateAll,
  updatePagination,
  updateControl,
  removeAllPayable,
  updatePage,
  clear,
  loadPagination
};

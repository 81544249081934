import { createAction, props } from '@ngrx/store';
import { SupplierData } from 'app/interfaces';
import { SupplierModel } from 'app/model';

export const suppliersDetailsActions = {
  load: createAction(
    '[Sponsor SuppliersDetails] Start',
    props<{ url: string; identifier: string }>()
  ),
  updateSupplier: createAction(
    '[Sponsor SuppliersDetails] Update Supplier',
    props<{ data: SupplierModel; identifier: string }>()
  ),
  updateControl: createAction('[Sponsor SuppliersDetails] Update Control', props<{ data: any }>()),
  updateOne: createAction('[Sponsor SuppliersDetails] Update One', props<{ data: SupplierData }>())
};

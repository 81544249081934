import { NgModule } from '@angular/core';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxDirectivesModule, MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyIconModule } from 'monkey-style-guide';
import { SidebarActionsOptionsMenuModule } from './menu/menu.module';
import { SidebarActionsOptionsComponent } from './sidebar-actions-options.component';

@NgModule({
  declarations: [SidebarActionsOptionsComponent],
  imports: [
    MonkeyEcxDirectivesModule,
    MonkeyEcxPipesModule,
    MonkeyIconModule,
    SidebarActionsOptionsMenuModule,
    MonkeyEcxCommonModule
  ],
  exports: [SidebarActionsOptionsComponent]
})
export class SidebarActionsOptionsModule {}

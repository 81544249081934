import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sponsor as actions } from '@store/actions';
import { sponsor as selectors } from '@store/selectors';
import { Pagination, SupplierData } from 'app/interfaces';
import { LinksModel, SupplierModel } from 'app/model';
import { unionBy } from 'lodash';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { take } from 'rxjs/operators';

@Injectable()
export class SuppliersService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private mappingData(data: SupplierModel[]): SupplierModel[] {
    if (!data.length) {
      return null;
    }

    return data.map((supplier: SupplierModel) => {
      return new SupplierModel(supplier);
    });
  }

  private updateControl(data: any) {
    const { store } = this;

    store.dispatch(
      actions.suppliersActions.updateControl({
        data
      })
    );
  }

  private updatePagination(data: any, identifier: string) {
    const { store } = this;

    store.dispatch(
      actions.suppliersActions.updatePagination({
        data: {
          [identifier]: data
        }
      })
    );
  }

  private update(data: SupplierData[], identifier: string) {
    const { store } = this;

    store.dispatch(
      actions.suppliersActions.updateAll({
        data: data.map((_: SupplierData) => {
          return {
            identifier,
            ..._
          };
        })
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string, updatePagination = true) {
    const { monkeyecxService } = this;

    this.updateControl({ isLoading: true });

    const data = await monkeyecxService.get<any>(url).toPromise();
    const embedded = this.mappingData(this.getEmbeddedData(data, 'suppliers'));

    const items = unionBy(this.__data || [], embedded, 'id');
    const { _links } = data;

    this.update(items, identifier);
    if (updatePagination) {
      this.updatePagination(
        {
          ..._links,
          url
        },
        identifier
      );
    }

    this.updateControl({ isLoading: false });
  }

  private async handlePageData(pagination: Pagination, identifier: string) {
    const { store } = this;

    const data = await store
      .select(selectors.suppliers.selectPagination({ identifier }))
      .pipe(take(1))
      .toPromise();

    const { action } = pagination;
    const { href } = new LinksModel({ _links: data }).getAction(action);

    if (href) {
      this.loadData(href, identifier);
    }
  }

  public async load(url: string, identifier: string) {
    const { store } = this;

    const { hasDifference, hasField } = await store
      .select(selectors.suppliers.paginationHasDifference({ identifier, url }))
      .pipe(take(1))
      .toPromise();

    if (hasDifference && hasField) {
      store.dispatch(actions.suppliersActions.clear({ identifier }));
    }

    this.loadData(url, identifier, hasDifference);
  }

  public loadPage(pagination: Pagination, identifier: string) {
    this.handlePageData(pagination, identifier);
  }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { shared as actions } from 'app/store/actions';
import { shared as services } from 'app/store/services';

@Injectable()
export class ReportEffects {
  start$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.reportActions.start),
        map(({ ...args }) => {
          this.service.start(args);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.ReportService) {
    // not to do
  }
}

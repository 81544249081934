import { createFeatureSelector, createSelector } from '@ngrx/store';
import { urlHasField } from '@utils';
import { Company } from 'app/interfaces';
import { shared } from 'app/store/reducers';
import { parseUrl } from 'query-string';

declare type State = shared.fromCompanies.State;

export const selectCompaniesState = createFeatureSelector<shared.fromCompanies.State>(
  shared.fromCompanies.featureKey
);

export const selectAllCompanies = createSelector(
  selectCompaniesState,
  shared.fromCompanies.selectAll
);

export const selectCompanyById = (props: {
  id: string;
}) => {
  return createSelector(
    selectAllCompanies,
    (entities: Company[]): Company => {
      const { id } = props;
      return entities.find((_: Company) => {
        return (
          `${_.id}` === `${id}`
        );
      });
    }
  );
};

export const selectPagination = () => {
  return createSelector(
    selectCompaniesState,
    (state: State): any => {
      const { pagination } = state;
      return pagination;
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectCompaniesState,
    (state: State): any => {
      return state?.control;
    }
  );
};

export const exists = () => {
  return createSelector(
    selectAllCompanies,
    (entities: Company[]): boolean => {
      return !!entities?.length;
    }
  );
};

export const paginationHasDifference = (props: { url: string }) => {
  return createSelector(
    selectCompaniesState,
    (state: State): any => {
      const { pagination } = state;
      const { url } = props;
      let hasDifference = false;
      let hasField = false;

      try {
        const handledPaginationUrl = parseUrl(`${pagination?.url}`);
        const handledUrl = parseUrl(`${url}`);
        hasDifference = (handledPaginationUrl?.query?.search !== handledUrl?.query?.search) ||
        (handledPaginationUrl?.query?.sort !== handledUrl?.query?.sort);
        hasField = urlHasField(url, 'search') || urlHasField(url, 'sort');
      } catch (e) {
        // not to do
      }

      return {
        hasDifference,
        hasField
      };
    }
  );
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { sponsor as actions } from '@store/actions';
import { sponsor as services } from '@store/services';
import { map } from 'rxjs/operators';

@Injectable()
export class PayablesEffects {
  load$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.payables.load),
        map(({ url, identifier }) => {
          this.service.load(url, identifier);
        })
      );
    },
    {
      dispatch: false
    }
  );

  loadPage$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.payables.loadPagination),
        map(({ pagination, identifier }) => {
          this.service.loadPage(pagination, identifier);
        })
      );
    },
    {
      dispatch: false
    }
  );

  delete$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.payables.removePayable),
        map(({ data }) => {
          this.service.delete(data);
        })
      );
    },
    {
      dispatch: false
    }
  );

  deleteAll$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.payables.removeAllPayable),
        map(({ identifier }) => {
          this.service.deleteAll(identifier);
        })
      );
    },
    {
      dispatch: false
    }
  );

  constructor(private actions$: Actions, private service: services.PayablesService) {
    // not to do
  }
}

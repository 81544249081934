import { MonkeyEcxModel } from 'monkey-front-core';

export class ProcessDataModel extends MonkeyEcxModel {
  createUserId: string;

  createUserIp: string;

  createdAt: Date;

  detail: any;

  lastUserId: string;

  lastUserIp: string;

  processed: boolean;

  step: number;

  totalSteps: number;

  type: string;

  updatedAt: Date;

  constructor(data?: any) {
    super();
    this.createUserId = data?.createUserId;
    this.createUserIp = data?.createUserIp;
    this.createdAt = data?.createdAt;
    this.detail = data?.detail;
    this.lastUserId = data?.lastUserId;
    this.lastUserIp = data?.lastUserIp;
    this.processed = data?.processed;
    this.step = data?.step;
    this.totalSteps = data?.totalSteps;
    this.type = data?.type;
    this.updatedAt = data?.updatedAt;
    this._links = data?._links;
  }
}

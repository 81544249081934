import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const load = createAction(
  '[MECX Shared Report Configuration Details] Load',
  props<interfaces.ReportConfigurationDetailsActionsLoad>()
);

const save = createAction(
  '[MECX Shared Report Configuration Details] Save',
  props<interfaces.ReportConfigurationDetailsActionsLoad>()
);

export { load, save };

import { createAction, props } from '@ngrx/store';
import { Company, Pagination } from 'app/interfaces';

export const companiesActionsActions = {
  load: createAction('[Shared Companies Actions] Start', props<{ url: string }>()),
  select: createAction(
    '[Shared Companies Actions] Select Company',
    props<{
      id: string;
      companyType: string;
      url: string;
      option?: string;
    }>()
  ),
  updateOne: createAction('[Shared Companies Actions] Update One', props<{ data: Company }>()),
  updatePagination: createAction(
    '[Shared Companies Actions] Update Pagination',
    props<{ data: any }>()
  ),
  updateControl: createAction('[Shared Companies Actions] Update Control', props<{ data: any }>()),
  updateAll: createAction('[Shared Companies Actions] Update All', props<{ data: Company[] }>()),
  delete: createAction('[Shared Companies Actions] Delete', props<{ data: Company }>()),
  clear: createAction('[Shared Companies Actions] Clear All')
};

export const companiesActionsPaginationActions = {
  load: createAction(
    '[Shared Companies ActionsPagination] Load',
    props<{ pagination: Pagination }>()
  )
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { MonkeyRequestSchedule } from 'app/interfaces';
import { environment } from 'environments/environment';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxRequestScheduleService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService extends MonkeyEcxCommonsService implements Resolve<any> {
  private route = `${environment.baseUrl}/v1`;

  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private router: Router,
    private scheduleService: MonkeyEcxRequestScheduleService
  ) {
    super(monkeyecxService, tokenStorage, {
      schedule: {
        service: scheduleService,
        options: {
          clearOnChangeRoute: true
        }
      }
    });
  }

  private async setSchedule() {
    const { route, tokenStorage } = this;
    const { companyId, companyType, role } = await tokenStorage.getToken();

    if (!companyId || !companyType || role === 'BACK_OFFICE') {
      return;
    }

    const url = `${route}/${companyType.toLowerCase()}s/${companyId}/notifications?read=false`;
    if (this.__schedule) this.scheduleService.removeGenericSchedule(this.__schedule);

    this.__schedule = this.scheduleService.setGenericSchedule(
      {
        method: 'get',
        url,
        action: (data: any, sch: any) => {
          return this.confirmNotificationData(data, sch);
        }
      },
      60000
    );
  }

  private confirmNotificationData(data: any, sch: MonkeyRequestSchedule) {
    this.__page = data?.page;
    this.__schedule = sch;

    this.__onDataChanged$.next(null);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
    const { router } = this;

    super.resolve(route, state, {
      callbackMain: () => {
        this.__search = null;
        this.__data = null;
        this.__page = null;
        this.setSchedule();
      },
      router
    });

    return null;
  }
}

import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const navigation = createAction(
  '[MECX Shared MailConfiguration] Navigation',
  props<{ url: string }>()
);
const createCampaign = createAction(
  '[MECX Shared MailConfiguration] Create Campaign',
  props<{ data: interfaces.MailCampaignData }>()
);
const removeOne = createAction(
  '[MECX Shared MailConfiguration] Remove One',
  props<{ data: interfaces.MailCampaignData }>()
);
const reschedule = createAction(
  '[MECX Shared MailConfiguration] Reschedule',
  props<{ url: string; data: interfaces.MailCampaignData }>()
);
const start = createAction(
  '[MECX Shared MailConfiguration] Start Configuration',
  props<{ data: interfaces.MailCampaignData; callback: Function }>()
);
const updateControl = createAction(
  '[MECX Shared MailConfiguration] Update Control',
  props<{ data: any }>()
);
const updateSidebar = createAction(
  '[MECX Shared MailConfiguration] Update Sidebar',
  props<{ data: interfaces.MailConfigurationSidebarData }>()
);

const api = {
  create: createAction(
    '[MECX Shared MailConfiguration] Create',
    props<{ data: interfaces.MailCampaignData; url: string; callback: Function }>()
  ),
  download: createAction('[MECX Shared MailConfiguration] Download', props<{ url: string }>()),
  upload: createAction(
    '[MECX Shared MailConfiguration] Upload',
    props<{ data: interfaces.MailConfigurationUploadData }>()
  ),
  send: createAction(
    '[MECX Shared MailConfiguration] Send',
    props<{ url: string; callback: Function }>()
  ),
  sendPreview: createAction(
    '[MECX Shared MailConfiguration] Send Preview',
    props<{ data: interfaces.MailConfigurationSendPreviewData; url: string }>()
  )
};

export {
  api,
  createCampaign,
  navigation,
  removeOne,
  reschedule,
  start,
  updateControl,
  updateSidebar
};

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MonkeyEcxCommonModule } from 'app/shared/modules/monkeyecx-common.module';
import { MonkeyEcxi18nModule } from 'app/shared/modules/monkeyecx-i18n.module';
import { MonkeyEcxDirectivesModule, MonkeyEcxPipesModule } from 'monkey-front-core';
import { MonkeyBadgeModule, MonkeyButtonModule } from 'monkey-style-guide';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    MonkeyBadgeModule,
    MonkeyButtonModule,
    MonkeyEcxCommonModule,
    MonkeyEcxDirectivesModule,
    MonkeyEcxPipesModule,
    RouterModule,
    MonkeyEcxi18nModule
  ],
  exports: [NotificationsComponent]
})
export class NotificationsModule {}

import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { CurrentStepKeepFilling, StepParams } from 'app/interfaces';
import { LinksModel } from 'app/model';
import { environment } from 'environments/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MonkeyEcxAuthCredentials, MonkeyEcxConfig, MonkeyEcxUtils } from 'monkey-front-core';
import { parseUrl } from 'query-string';
import { first } from 'rxjs/operators';

export const timezoneOffset = moment().format('Z');

export const registrationNextSteps = {
  'seller-cnpj': {
    company: 'address',
    address: 'phone',
    phone: 'legal-responsible',
    'legal-responsible': 'confirm-legal-responsible',
    'confirm-legal-responsible': 'document',
    document: 'bank-account',
    'bank-account': 'picture',
    picture: 'finish'
  },
  'seller-cpf': {
    person: 'address',
    address: 'document',
    document: 'bank-account',
    'bank-account': 'picture',
    picture: 'finish'
  },
  'sponsor-cnpj': {
    company: 'address',
    address: 'legal-responsible',
    'legal-responsible': 'confirm-legal-responsible',
    'confirm-legal-responsible': 'picture',
    picture: 'finish'
  },
  'sponsor-cpf': {
    person: 'address',
    address: 'document',
    document: 'picture',
    picture: 'finish'
  },
  'buyer-cnpj': {
    company: 'address',
    address: 'phone',
    phone: 'legal-responsible',
    'legal-responsible': 'confirm-legal-responsible',
    'confirm-legal-responsible': 'document',
    document: 'picture',
    picture: 'finish'
  }
};

export function getRegistrationStep(
  step: string,
  companyType: string,
  govType: string,
  next = true,
  canAddSponsorBankAccountStep = false
) {
  let dataRegistration: any = registrationNextSteps;

  if (canAddSponsorBankAccountStep) {
    dataRegistration = {
      ...dataRegistration,
      'sponsor-cnpj': {
        ...dataRegistration['sponsor-cnpj'],
        'confirm-legal-responsible': 'bank-account',
        'bank-account': 'picture'
      }
    };
  }
  const handledStep = Object.entries(dataRegistration[`${companyType}-${govType}`.toLowerCase()])
    .map(([key, value]) => {
      if (next && key === step) return value;
      if (!next && value === step) return key;
      return null;
    })
    .filter((_) => {
      return _;
    });
  let result = handledStep[0];
  if (result === 'confirm-legal-responsible' && !next) {
    result = 'legal-responsible';
  }

  return result;
}

export function getCurrentStepKeepFilling(
  data?: any,
  stepParams?: StepParams,
  companyType?: string,
  governmentIdType?: string
): CurrentStepKeepFilling {
  data = data || {};
  const links = data?._links || {};
  const requiredSteps: any[] = [
    {
      companyType: 'seller',
      governmentIdType: 'cnpj',
      steps: [
        {
          step: 'company',
          order: 1
        },
        {
          step: 'address',
          order: 2
        },
        {
          step: 'phone',
          order: 3
        },
        {
          step: 'legal-responsible',
          order: 4
        },
        {
          step: 'document',
          order: 6
        },
        {
          step: 'bank-account',
          order: 7
        },
        {
          step: 'picture',
          order: 8
        }
      ]
    },
    {
      companyType: 'seller',
      governmentIdType: 'cpf',
      steps: [
        {
          step: 'person',
          order: 1
        },
        {
          step: 'address',
          order: 2
        },
        {
          step: 'document',
          order: 3
        },
        {
          step: 'bank-account',
          order: 4
        },
        {
          step: 'picture',
          order: 5
        }
      ]
    },
    {
      companyType: 'sponsor',
      governmentIdType: 'cnpj',
      steps: [
        {
          step: 'company',
          order: 1
        },
        {
          step: 'address',
          order: 2
        },
        {
          step: 'legal-responsible',
          order: 3
        },
        {
          step: 'bank-account',
          order: 4
        },
        {
          step: 'picture',
          order: 5
        }
      ]
    },
    {
      companyType: 'sponsor',
      governmentIdType: 'cpf',
      steps: [
        {
          step: 'person',
          order: 1
        },
        {
          step: 'address',
          order: 2
        },
        {
          step: 'document',
          order: 3
        },
        {
          step: 'picture',
          order: 4
        }
      ]
    },
    {
      companyType: 'buyer',
      governmentIdType: 'cnpj',
      steps: [
        {
          step: 'company',
          order: 1
        },
        {
          step: 'address',
          order: 2
        },
        {
          step: 'phone',
          order: 3
        },
        {
          step: 'legal-responsible',
          order: 4
        },
        {
          step: 'document',
          order: 6
        },
        {
          step: 'picture',
          order: 7
        }
      ]
    }
  ]
    .find((val: any) => {
      return val.companyType === companyType && val.governmentIdType === governmentIdType;
    })
    .steps.filter((value: any) => {
      return value.step !== stepParams.currentStep;
    })
    .sort((orderA, orderB) => {
      if (orderA.order < orderB.order) {
        return -1;
      }
      if (orderA.order > orderB.order) {
        return 1;
      }
      return 0;
    });

  const find = requiredSteps
    .map((val: any) => {
      if (
        !Object.prototype.hasOwnProperty.call(links, `step-${val.step}`) &&
        !Object.prototype.hasOwnProperty.call(links, `${val.step}`)
      ) {
        return val;
      }
      return null;
    })
    .find((val: any) => {
      return MonkeyEcxUtils.persistNullEmptyUndefined(val);
    });

  if (MonkeyEcxUtils.persistNullEmptyUndefined(find)) {
    return {
      isFinished: false,
      next: find
    };
  }
  return {
    isFinished: true
  };
}

export function getCurrentStepKeepFillingByBody(
  data?: any,
  stepParams?: StepParams,
  companyType?: string,
  governmentIdType?: string,
  canAddSponsorBankAccountStep = false
): CurrentStepKeepFilling {
  data = data || {};
  const requiredSteps = {
    sellercnpj: [
      {
        id: 'company',
        step: 'Company',
        order: 1
      },
      {
        id: 'address',
        step: 'Address',
        order: 2
      },
      {
        id: 'phone',
        step: 'Phone',
        order: 3
      },
      {
        id: 'legal-responsible',
        step: 'LegalResponsible',
        order: 4
      },
      {
        id: 'document',
        step: 'Document',
        order: 6
      },
      {
        id: 'bank-account',
        step: 'BankAccount',
        order: 7
      },
      {
        id: 'picture',
        step: 'Picture',
        order: 8
      }
    ],
    sellercpf: [
      {
        id: 'person',
        step: 'Person',
        order: 1
      },
      {
        id: 'address',
        step: 'Address',
        order: 2
      },
      {
        id: 'document',
        step: 'Document',
        order: 3
      },
      {
        id: 'bank-account',
        step: 'BankAccount',
        order: 4
      },
      {
        id: 'picture',
        step: 'Picture',
        order: 5
      }
    ],
    sponsorcnpj: [
      {
        id: 'company',
        step: 'Company',
        order: 1
      },
      {
        id: 'address',
        step: 'Address',
        order: 2
      },
      {
        id: 'legal-responsible',
        step: 'LegalResponsible',
        order: 3
      },
      {
        id: 'picture',
        step: 'Picture',
        order: 5
      }
    ],
    sponsorcpf: [
      {
        id: 'person',
        step: 'Person',
        order: 1
      },
      {
        id: 'address',
        step: 'Address',
        order: 2
      },
      {
        id: 'document',
        step: 'Document',
        order: 3
      },
      {
        id: 'picture',
        step: 'Picture',
        order: 4
      }
    ],
    buyercnpj: [
      {
        id: 'company',
        step: 'Company',
        order: 1
      },
      {
        id: 'address',
        step: 'Address',
        order: 2
      },
      {
        id: 'phone',
        step: 'Phone',
        order: 3
      },
      {
        id: 'legal-responsible',
        step: 'LegalResponsible',
        order: 4
      },
      {
        id: 'document',
        step: 'Document',
        order: 6
      },
      {
        id: 'picture',
        step: 'Picture',
        order: 7
      }
    ]
  };

  if (canAddSponsorBankAccountStep) {
    requiredSteps.sponsorcnpj.push({
      id: 'bank-account',
      step: 'BankAccount',
      order: 4
    });
  }

  const steps = requiredSteps[`${companyType}${governmentIdType}`]
    .filter((value: any) => {
      return value.step !== stepParams.currentStep;
    })
    .sort((orderA, orderB) => {
      if (orderA.order < orderB.order) {
        return -1;
      }
      if (orderA.order > orderB.order) {
        return 1;
      }
      return 0;
    });

  if (`${companyType}${governmentIdType}` === 'sellercpf') {
    const { email } = data?.stepPerson || {};

    if (!MonkeyEcxUtils.isValidEmail(email)) {
      return {
        isFinished: false,
        next: steps[0]
      };
    }
  }

  const find = steps
    .map((val: any) => {
      if (!data[`step${val.step}`]) {
        return {
          ...val,
          step: `${val.step}`.toLowerCase()
        };
      }
      return null;
    })
    .find((val: any) => {
      return MonkeyEcxUtils.persistNullEmptyUndefined(val);
    });

  if (MonkeyEcxUtils.persistNullEmptyUndefined(find)) {
    return {
      isFinished: false,
      next: find
    };
  }
  return {
    isFinished: true
  };
}

export function pageReposition(rootName?: string) {
  const container3 = document.getElementById('container-3');
  if (container3) container3.scrollTop = 0;
  if (rootName) {
    const root = document.getElementsByTagName(rootName).item(0);
    if (root) root.scrollTop = 0;
    const rootById = document.getElementById(rootName);
    if (rootById) rootById.scrollTop = 0;
  }
}

export function getDate(tp: any): string {
  const dateFormat = tp || 'dd/mm/yyyy';
  const ret = moment(Date.now()).format(dateFormat);
  return ret;
}

export function formatDate(date: string | Date, showTime?: false, format?: any): string {
  if (!MonkeyEcxUtils.persistNullEmptyUndefined(date)) return '';
  if (!format) {
    format = '- HH:mm';
  }
  let stillUtc: any = moment.utc(date).toDate();
  if (date.toString()?.indexOf(':') <= -1) {
    stillUtc = date;
    showTime = false;
  }
  const formatFrom = `YYYY/MM/DD${showTime ? ` ${format}` : ''}`;
  const formatTo = `DD/MM/YYYY${showTime ? ` ${format}` : ''}`;
  return `${moment(stillUtc, formatFrom).format(formatTo)}`;
}

export function compareStartToEndDates(
  startDate: any,
  endDate: any,
  type: string,
  withPartialTime = false,
  withFullTime = false
) {
  if (!startDate || !endDate) return false;
  const formatFullTime = 'HH:mm:ss';

  if (startDate.toString()?.indexOf(':') <= -1 || endDate.indexOf(':') <= -1) {
    withPartialTime = false;
    withFullTime = false;
  }

  const formatStartDate = `YYYY-MM-DD${
    withPartialTime || withFullTime ? ` ${formatFullTime}` : ''
  }`;
  const formatEndDate = `YYYY-MM-DD${withPartialTime || withFullTime ? ` ${formatFullTime}` : ''}`;

  const newStartDate = moment(startDate, formatStartDate);
  const newEndDate = moment(endDate, formatEndDate);

  return newStartDate[type](newEndDate);
}

export function getDocumentType(governmentId: string) {
  let documentType = MonkeyEcxUtils.getDocumentType(governmentId);
  if (environment.country === 'cl') documentType = 'CNPJ';
  return documentType;
}

export function arrayMerge(a: any[], b: any[], fieldToCompare: string) {
  const merged = _.merge(_.keyBy(a || [], fieldToCompare), _.keyBy(b || [], fieldToCompare));
  return _.values(merged);
}

export function isAndroid() {
  const userAgent = navigator?.userAgent?.toLowerCase();
  return userAgent?.includes('android');
}

export function isIOS() {
  const userAgent = navigator?.userAgent?.toLowerCase();
  const isApple = [
    'iPhone',
    'iPad',
    'iPod',
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator'
  ].includes(`${userAgent}`);
  return isApple;
}

export function paddLeft(value: number): string {
  value = value || 0;
  const length = `${value}`?.length;
  return length === 1 ? `0${value}` : `${value}`;
}

export function urlHasField(url: string, field: string) {
  const obj: any = parseUrl(url);
  return !!obj?.query?.[field];
}

export function buildIdByLink<T>(item: T, field: string = 'identifier'): string {
  const actionId = new LinksModel(item)?.getAction('self');
  const id = actionId?.href?.split('/').pop() || MonkeyEcxUtils.getRandomString(3);

  return `${item[field]}-${id}`;
}

export function isSameOrigin(path: string): boolean {
  const initialPath: string = window.location.origin;
  return (initialPath || '') === path;
}

export function getLoginAppRedirect(param: MonkeyEcxAuthCredentials, config: MonkeyEcxConfig) {
  const { host } = window.location;
  const app = environment.apps.login;
  const params = new HttpParams()
    .append('username', param.username)
    .append('program', `${config?.program?.token}` || 'SANDBOX')
    .append('redirect_uri', `https://${host}/sso`)
    .toString();

  let path = `${app}/login-sso?${params}`;
  if (isSameOrigin(app)) {
    path = `/login-fed/${param.username}`;
  }

  return {
    isSameOrigin: isSameOrigin(app),
    path
  };
}

export function getFirstResult() {
  return first((val: any) => {
    return !!val && JSON.stringify(val) !== '{}';
  });
}

export async function getSelectorSync(store: Store, selector: any): Promise<any> {
  const result = await store
    .select(selector)
    .pipe(
      first((val: any) => {
        return !!val && JSON.stringify(val) !== '{}' && JSON.stringify(val) !== '[]';
      })
    )
    .toPromise();

  return result;
}

export function diffInDaysIsGreaterThan(date: Date, days: number) {
  const diff = moment(new Date(date)).diff(moment(), 'days');

  return diff > days;
}

export function hasDuplicateValueForKey(args: any[], key: string): boolean {
  const unique = new Set(
    args.map((v) => {
      return v[key];
    })
  );

  return unique.size < args.length;
}

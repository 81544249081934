import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { buyer } from '@store/actions';
import { BuyerOperationSummaryItemsData, PaginationData } from 'app/interfaces';
import { MonkeyEcxUtils } from 'monkey-front-core';

export const featureKey = 'buyer-operation-summary-items';

export interface State extends EntityState<BuyerOperationSummaryItemsData> {
  control: {
    isLoading: boolean;
  };
  error: any;
  pagination?: PaginationData;
}

export const adapter: EntityAdapter<BuyerOperationSummaryItemsData> = createEntityAdapter<
  BuyerOperationSummaryItemsData
>({
  selectId: (item: BuyerOperationSummaryItemsData) => {
    return `${item.identifier}-${MonkeyEcxUtils.getRandomString(3)}`;
  }
});

export const initialState: State = adapter.getInitialState({
  control: {
    isLoading: null
  },
  error: null,
  pagination: null
});

export const reducer = createReducer(
  initialState,
  on(buyer.operationSummaryItemsActions.clear, () => {
    return {
      ids: [],
      entities: {},
      control: { isLoading: false },
      error: null,
      pagination: null
    };
  }),
  on(buyer.operationSummaryItemsActions.updateAll, (state, action) => {
    return adapter.upsertMany(action.data, {
      ...state
    });
  }),
  on(buyer.operationSummaryItemsActions.updateControl, (state, action) => {
    return {
      ...state,
      control: {
        ...state.control,
        ...action.data
      }
    };
  }),
  on(buyer.operationSummaryItemsActions.updatePagination, (state, action) => {
    return {
      ...state,
      pagination: {
        ...state.pagination,
        ...action.data
      }
    };
  })
);

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

<div class="sidebar-actions-menu">
  <div class="header">
    <ng-container *ngIf="_selected$ | async as selected">
      <div class="icon">
        {{ selected?.name | uppercase | slice : 0 : 1 }}
      </div>
      <div class="d-flex flex-column">
        <span class="title">
          {{ selected?.name | monkeyecxTextTruncate : 20 }}
        </span>
        <span class="data">
          {{ selected?.governmentId | monkeyecxFormatDocument }}
        </span>
      </div>
    </ng-container>
  </div>
  <div class="body">
    <div monkeyecxSecurity [securityRoles]="['ADMIN', 'EXECUTE']">
      <a
        class="item"
        [class.nav-active]="this._router.isActive('/app/' + companyType + '/settings', false)"
        [routerLink]="['/app/' + companyType + '/settings']"
      >
        <monkey-icon icon="settings-18" color="#4B4A53"></monkey-icon>
        {{ 'NAVIGATION.SETTINGS.TITLE' | translate }}
      </a>
    </div>

    <a
      class="item"
      [class.nav-active]="this._router.isActive('/app/' + companyType + '/pages/support', false)"
      [routerLink]="['/app/' + companyType + '/pages/support']"
    >
      <monkey-icon icon="chat-18" color="#4B4A53"></monkey-icon>
      {{ 'NAVIGATION.SUPPORT.TITLE' | translate }}
    </a>

    <a class="item" (click)="onHandleToggleSidebarOpened('navbar-companies')">
      <monkey-icon icon="refresh-18" color="#4B4A53"></monkey-icon>
      {{ 'NAVIGATION.COMPANY-CHANGE.TITLE' | translate }}
      <span class="beta">BETA</span>
    </a>
  </div>

  <div class="actions" *ngIf="_environment?.zenDeskUrlDoc || companyType === 'buyer'">
    <a
      class="item"
      [href]="_environment?.zenDeskUrlDoc"
      target="_blank"
      *ngIf="_environment?.zenDeskUrlDoc"
    >
      {{ 'NAVIGATION.HELP-CENTRAL.TITLE' | translate }}
    </a>

    <a
      *ngIf="companyType === 'buyer'"
      class="item"
      href="https://status.monkey.exchange/"
      target="_blank"
    >
      {{ 'Status page' | translate }}
    </a>
  </div>

  <div class="bottom">
    <a class="item logout" (click)="onHandleLogout()">
      {{ 'NAVIGATION.LOGOUT.TITLE' | translate }}
    </a>
  </div>
</div>

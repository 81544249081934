import { createAction, props } from '@ngrx/store';
import * as interfaces from '@shared-interfaces';

const clear = createAction(
  '[MECX Shared Webhooks Configurations] Clear All',
  props<{ identifier: string }>()
);
const deleteAction = createAction(
  '[MECX Shared Webhooks Configurations] Delete',
  props<{ identifier: string }>()
);
const exclude = createAction(
  '[MECX Shared Webhooks Configurations] Exclude',
  props<{
    data: interfaces.WebhookConfigurationData;
    identifier: string;
  }>()
);
const load = createAction(
  '[MECX Shared Webhooks Configurations] Load',
  props<{ companyType: string; identifier: string; url: string }>()
);
const paginationLoad = createAction(
  '[MECX Shared Webhooks Configurations Pagination] Load',
  props<{ companyType: string; identifier: string; pagination: interfaces.Pagination }>()
);
const save = createAction(
  '[MECX Shared Webhooks Configurations] Save',
  props<{
    callback: Function;
    url: string;
    companyType: string;
    data: interfaces.WebhookConfigurationData;
    identifier: string;
  }>()
);
const updateAll = createAction(
  '[MECX Shared Webhooks Configurations] Update All',
  props<{ data: interfaces.WebhookConfigurationData[] }>()
);
const updateControl = createAction(
  '[MECX Shared Webhooks Configurations] Update Control',
  props<{ data: any }>()
);
const updatePagination = createAction(
  '[MECX Shared Webhooks Configurations] Update Pagination',
  props<{ data: any }>()
);

export {
  clear,
  deleteAction,
  exclude,
  load,
  paginationLoad,
  save,
  updateAll,
  updateControl,
  updatePagination
};

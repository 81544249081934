import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { BuyerOperationSimulation } from 'app/interfaces';
import { MonkeyEcxUtils } from 'monkey-front-core';
import { buyer } from '../../actions';

export const featureKey = 'buyer-operation-simulation';

export interface State extends EntityState<BuyerOperationSimulation> {
  error: any;
}

export const adapter: EntityAdapter<BuyerOperationSimulation> = createEntityAdapter<
  BuyerOperationSimulation
>({
  sortComparer: null
});

export const initialState: State = adapter.getInitialState({
  error: null
});

export const reducer = createReducer(
  initialState,
  on(buyer.operationSimulationActions.create, (state, action) => {
    return adapter.setOne(action.data, {
      ...state
    });
  }),
  on(buyer.operationSimulationActions.updateSimulationData, (state, action) => {
    const { id } = action.data;
    const data = state.entities[id];
    const { simulationData } = data;

    let pendencies = data?.pendencies;
    if (
      data?.pendencies?.risk &&
      MonkeyEcxUtils.persistNullEmptyUndefined(simulationData?.risk || action.data?.risk)
    ) {
      pendencies = {
        ...pendencies,
        risk: false
      };
    }

    if (
      data?.pendencies['bank-account'] &&
      MonkeyEcxUtils.persistNullEmptyUndefined(simulationData?.bank || action.data?.bank)
    ) {
      pendencies = {
        ...pendencies,
        'bank-account': false
      };
    }

    return adapter.upsertOne(
      {
        ...data,
        pendencies,
        simulationData: {
          ...simulationData,
          ...action?.data
        }
      },
      {
        ...state
      }
    );
  }),
  on(buyer.operationSimulationActions.updateSimulationHeader, (state, action) => {
    const { id } = action.data;
    const data = state.entities[id];
    return adapter.upsertOne(
      {
        ...data,
        simulationHeader: {
          ...data?.simulationHeader,
          ...action?.data
        }
      },
      {
        ...state
      }
    );
  }),
  on(buyer.operationSimulationActions.delete, (state, action) => {
    return adapter.removeOne(action.data.id, state);
  }),
  on(buyer.operationSimulationActions.clear, () => {
    return {
      ids: [],
      entities: {},
      control: { isLoading: false },
      error: null
    };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

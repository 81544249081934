import { DashboardData, GeneralSummarySellerData, ReceivablesData } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { seller as reducers } from '@store/reducers';
import { seller as selectors } from '@store/selectors';

declare type State = reducers.fromDashboard.State;

export const selectDashboardState = createFeatureSelector<reducers.fromDashboard.State>(
  reducers.fromDashboard.featureKey
);

export const selectAllDashboard = createSelector(
  selectDashboardState,
  reducers.fromDashboard.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(selectAllDashboard, (entities: DashboardData[]): DashboardData => {
    return entities.find((_: DashboardData) => {
      return _.identifier === props.identifier;
    });
  });
};

export const selectByIdentifierWithMap = (props: { identifier: string }) => {
  const { identifier } = props;

  return createSelector(
    selectors.generalSummary.selectByIdentifier({ identifier }),
    selectors.receivables.selectByIdentifier({ identifier }),
    (generalSummary: GeneralSummarySellerData, receivablesData: ReceivablesData[]): any => {
      const receivables = receivablesData.slice(0, 5);

      return {
        generalSummary,
        receivables
      };
    }
  );
};

export const selectControl = () => {
  return createSelector(selectDashboardState, (state: State): any => {
    return state.control;
  });
};

export const selectControlWithMap = () => {
  return createSelector(
    selectDashboardState,
    selectors.generalSummary.selectGeneralSummaryState,
    selectors.receivables.selectReceivablesState,
    (dashboard: State, generalSummary: any, receivables: any): any => {
      const isLoading =
        dashboard?.control?.isLoading ||
        generalSummary?.control?.isLoading ||
        receivables?.control?.isLoading;

      return { isLoading };
    }
  );
};

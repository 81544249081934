import { Injectable } from '@angular/core';
import { buyer as interfaces } from '@interfaces';
import { Store } from '@ngrx/store';
import { buyer as actions } from '@store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';
import { FilterSponsorService } from '@store/services/buyer';

@Injectable()
export class DailyOperationService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private filterSponsorService: FilterSponsorService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  @MonkeyEcxCoreService({
    httpResponse: {
      httpCodeIgnore: [404]
    },
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string): Promise<void> {
    this.updateControl({ isLoading: true });

    try {
      const filterSponsorQuery = await this.filterSponsorService.buildSponsorFilter();

      const data = await this.monkeyecxService
        .get<interfaces.DailyOperationData>(`${url}?${filterSponsorQuery}`)
        .toPromise();

      this.update(data);
    } finally {
      this.updateControl({ isLoading: false });
    }
  }

  private clearStore() {
    this.store.dispatch(actions.dashboard.dailyOperation.clear({ identifier: '' }));
  }

  private update(data: interfaces.DailyOperationData): void {
    this.store.dispatch(
      actions.dashboard.dailyOperation.updateOne({
        data
      })
    );
  }

  private updateControl(data: { isLoading: boolean }): void {
    this.store.dispatch(actions.dashboard.dailyOperation.updateControl({ data }));
  }

  public async load(url: string): Promise<void> {
    this.clearStore();
    this.loadData(url);
  }
}

import {
  BuyerSellersBankAccountsData,
  BuyerSellersData,
  BuyerSellersDetalisWithMapData,
  BuyerSellersSponsorsData
} from '@interfaces';
import { createSelector } from '@ngrx/store';
import { buyer as reducers } from '@store/reducers';
import { buyer as selectors } from '@store/selectors';
import { MonkeyEcxCommonsSelectors } from 'monkey-front-core';

export const { selectAll, selectState } = MonkeyEcxCommonsSelectors.getSelectors<BuyerSellersData>(
  reducers.fromBuyerSellersDetails
);

export const selectOneByIdentifier = (props: { identifier: string }) => {
  return createSelector(
    selectAll,
    selectors.buyerSellersDetailsBankAccounts.selectAll,
    selectors.buyerSellersDetailsSponsors.selectAll,
    (
      entities: BuyerSellersData[],
      bankAccountsEntities: BuyerSellersBankAccountsData[],
      sponsorsEntities: BuyerSellersSponsorsData[]
    ): BuyerSellersDetalisWithMapData => {
      const data = entities.find((_: BuyerSellersData) => {
        return _.identifier === props.identifier;
      });

      const bankAccounts = bankAccountsEntities.filter((_: BuyerSellersBankAccountsData) => {
        return _.identifier === props.identifier;
      });

      return {
        data,
        bankAccounts,
        sellersSponsors: sponsorsEntities
      };
    }
  );
};

export const selectControl = () => {
  return createSelector(
    selectState,
    selectors.buyerSellersDetailsBankAccounts.selectState,
    selectors.buyerSellersDetailsSponsors.selectState,
    (state: any, bankAccountsState: any, sponsorsState: any): any => {
      const isLoading = state?.control?.isLoading || bankAccountsState?.control?.isLoading;

      const { isLoadingStatus, isLoadingDownload, isLoadingRisk } = state.control;

      const isLoadingSponsors = sponsorsState?.control?.isLoading;

      return { isLoading, isLoadingDownload, isLoadingStatus, isLoadingRisk, isLoadingSponsors };
    }
  );
};

import { PurchaseData } from '@interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { buyer } from '@store/reducers';
import { urlHasField } from '@utils';
import { parseUrl } from 'query-string';

declare type State = buyer.fromPurchases.State;

export const selectPurchasesState = createFeatureSelector<buyer.fromPurchases.State>(
  buyer.fromPurchases.featureKey
);

export const selectAllPurchases = createSelector(
  selectPurchasesState,
  buyer.fromPurchases.selectAll
);

export const selectByIdentifier = (props: { identifier: string }) => {
  return createSelector(selectAllPurchases, (entities: PurchaseData[]): PurchaseData[] => {
    return entities.filter((_: PurchaseData) => {
      return _.identifier === props.identifier;
    });
  });
};

export const selectControl = () => {
  return createSelector(selectPurchasesState, (state: State): any => {
    return state.control;
  });
};

export const selectLinks = (props: { identifier: string }) => {
  return createSelector(selectPurchasesState, (state: State): any => {
    return state.links?.[props.identifier];
  });
};

export const linksHasDifference = (props: { identifier: string; url: string }) => {
  return createSelector(selectPurchasesState, (state: State): any => {
    const { links } = state;
    const { identifier, url } = props;
    let hasDifference = false;
    let hasField = false;

    try {
      const handlePaginationUrl = parseUrl(`${links?.[identifier]?.url}`);
      const handleUrl = parseUrl(`${url}`);
      hasDifference =
        handlePaginationUrl?.query?.search !== handleUrl?.query?.search ||
        handlePaginationUrl?.query?.sort !== handleUrl?.query?.sort;
      hasField = urlHasField(url, 'search') || urlHasField(url, 'sort');
    } catch (e) {
      // not to do
    }

    return {
      hasDifference,
      hasField
    };
  });
};

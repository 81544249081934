import { Injectable } from '@angular/core';
import { DashboardData } from '@interfaces';
import { Store } from '@ngrx/store';
import { seller as actions } from '@store/actions';
import {
  MonkeyEcxCommonsService,
  MonkeyEcxCoreService,
  MonkeyEcxService,
  MonkeyEcxTokenStorageService
} from 'monkey-front-core';

@Injectable()
export class DashboardService extends MonkeyEcxCommonsService {
  constructor(
    monkeyecxService: MonkeyEcxService,
    tokenStorage: MonkeyEcxTokenStorageService,
    private store: Store
  ) {
    super(monkeyecxService, tokenStorage);
  }

  private updateControl(data: any) {
    this.store.dispatch(
      actions.dashboardActions.updateControl({
        data
      })
    );
  }

  private update(data: any, identifier: string) {
    this.store.dispatch(
      actions.dashboardActions.updateOne({
        data: { ...data, identifier }
      })
    );
  }

  @MonkeyEcxCoreService({
    requestInProgress: {
      showProgress: true
    }
  })
  private async loadData(url: string, identifier: string) {
    const { monkeyecxService, store } = this;
    this.updateControl({ isLoading: true });

    try {
      const data = await monkeyecxService.get<DashboardData>(url).toPromise();

      this.update(data, identifier);

      const { _links: links } = data;

      store.dispatch(
        actions.generalSummaryActions.load({
          identifier,
          url: links?.['program-summary'].href
        })
      );
    } catch (e) {
      // not to do
    }

    this.updateControl({ isLoading: false });
  }

  public load(url: string, identifier: string) {
    this.loadData(url, identifier);
  }
}

/* eslint-disable max-classes-per-file */
import { AbstractControl } from '@angular/forms';
import * as moment from 'moment';
import { MonkeyEcxUtils } from 'monkey-front-core';

export class DateValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    if (control && control.value) {
      const dateFormat = 'MM-DD-YYYY';
      if (
        !moment(
          moment(control.value).format(dateFormat),
          ['DD/MM/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'],
          true
        ).isValid()
      ) {
        return {
          invalidDate: true
        };
      }
    }
    return null;
  }
}

export class DateRangeValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) {
      return null;
    }

    const dates = control.parent.get('dates').value;

    if (
      dates &&
      (!MonkeyEcxUtils.persistNullEmptyUndefined(dates.startDate) ||
        !MonkeyEcxUtils.persistNullEmptyUndefined(dates.endDate))
    ) {
      return {
        invalidDateRange: true
      };
    }

    return null;
  }
}

export class DocumentValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    if (control && control.value) {
      if (!MonkeyEcxUtils.isCPFCNPJValid(control.value)) {
        return {
          invalidCpfCnpj: true
        };
      }
    }
    return null;
  }
}

export class ZipCodeValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    if (control && control.value) {
      if (!MonkeyEcxUtils.isValidZipCode(control.value)) {
        return {
          invalidZipCode: true
        };
      }
    }
    return null;
  }
}

export class ComboValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    if (control && control.value !== '0') return null;
    return {
      invalidCombo: true
    };
  }
}

export class isTrueValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    if (control && control.value !== false) return null;
    return {
      invalidTrue: true
    };
  }
}

export class PasswordMatchValidation {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    const { parent } = control;
    const password = parent.get('password').value;
    const passwordConfirm = parent.get('passwordConfirm').value;
    if (!password || !passwordConfirm) return null;
    if (password === passwordConfirm) return null;
    return {
      passwordsNotMatching: true
    };
  }
}

export class UrlValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    if (control && control.value) {
      if (!MonkeyEcxUtils.isValidUrl(control.value)) {
        return {
          invalidUrl: true
        };
      }
    }
    return null;
  }
}

export class CustomDatesStartValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    const { parent } = control;

    const valueStart = parent.get('dateStart').value;
    const valueEnd = parent.get('dateEnd').value;
    let dateStart: Date;
    let dateEnd: Date;
    if (valueStart) {
      dateStart = new Date(valueStart);
    }
    if (valueEnd) {
      dateEnd = new Date(valueEnd);
    }

    if (!dateStart || !dateEnd) return null;
    if (dateStart > dateEnd) {
      return {
        dateStartMustBeLessThanAnd: true
      };
    }
    return null;
  }
}

export class CustomDatesEndValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;
    const { parent } = control;

    const valueStart = parent.get('dateStart').value;
    const valueEnd = parent.get('dateEnd').value;
    let dateStart: Date;
    let dateEnd: Date;
    if (valueStart) {
      dateStart = new Date(valueStart);
    }
    if (valueEnd) {
      dateEnd = new Date(valueEnd);
    }

    if (!dateEnd || !dateStart) return null;
    if (dateEnd < dateStart) {
      return {
        dateEndMustBeGreaterThanStart: true
      };
    }
    return null;
  }
}

export class UnlockRegisterBuyerValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;

    if (control && control.value !== '#MONKEYBUYER') {
      return {
        invalidUnlockRegister: true
      };
    }

    return null;
  }
}

export class UnlockRegisterSponsorValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;

    if (control && control.value !== '#MONKEYSPONSOR') {
      return {
        invalidUnlockRegister: true
      };
    }

    return null;
  }
}

export class InvoiceCSVDateFormatValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;

    const dateFormat = ['dd/MM/yyyy', 'dd/MM/yy'];

    if (control && !dateFormat.includes(control.value)) {
      return {
        invalidInvoiceDateFormat: true
      };
    }

    return null;
  }
}

export class InvoiceCNABDateFormatValidator {
  static do(control: AbstractControl) {
    if (!control.parent || !control) return null;

    const dateFormat = [
      'ddMMyyyy',
      'ddMMyy',
      'MMddyyyy',
      'MMddyy',
      'yyyyddMM',
      'yyddMM',
      'yyyyMMdd',
      'yyMMdd'
    ];

    if (control && !dateFormat.includes(control.value)) {
      return {
        invalidInvoiceDateFormat: true
      };
    }

    return null;
  }
}
